import { SERVER_NOTIFICATIONS_LABELS, SERVER_NOTIFICATION_TYPES } from '@/constants/notification.constants';
import type { Brand } from '@/types/brand.types';
import type { Notification, NotificationTaskPayload } from '@/types/notification.types';
import type { User } from '@/types/user.types';
import type { ROUTE_NAMES } from '@/constants/routes.constants';
import { h, type VNode } from 'vue';
import { UserProfileImage } from '@kleecks/ui-lib';
import NotificationTaskComponent from '@/components/notifications/components/NotificationTaskComponent.vue';

export type NotificationActionsCta = 'view-task';

export const formatNotificationCTA = (
  notification: Notification
):
  | {
      label: string;
      routeName?: keyof typeof ROUTE_NAMES;
      brandId?: string;
      instanceId?: string;
      action?: NotificationActionsCta;
      topic?: string;
    }
  | undefined => {
  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_ASSIGNED) {
    return {
      label: 'View Tasks',
      action: 'view-task',
      brandId: (notification.payload as NotificationTaskPayload).brand,
      instanceId: (notification.payload as NotificationTaskPayload).instanceId,
      topic: (notification.payload as NotificationTaskPayload).topic,
    };
  }

  return undefined;
};

export const formatNotificationIconComponent = (notification: Notification): VNode | undefined => {
  if (
    notification.code === SERVER_NOTIFICATION_TYPES.TASK_ASSIGNED ||
    notification.code === SERVER_NOTIFICATION_TYPES.TASK_UPDATED ||
    notification.code === SERVER_NOTIFICATION_TYPES.TASK_DELETED ||
    notification.code === SERVER_NOTIFICATION_TYPES.TASK_CREATED
  ) {
    const payload = notification.payload as NotificationTaskPayload;

    if (!payload.assignedBy?.name) return undefined;

    // if (!payload.assignedBy?.nome || !payload.assignedBy?.cognome) return undefined;
    return h(UserProfileImage, {
      user: {
        nome: payload.assignedBy.name,
        cognome: payload.assignedBy.surname,
      },
      size: 'md',
    });
  }

  return undefined;
};

export const formatNotificationComponent = (notification: Notification): VNode | null => {
  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_ASSIGNED) {
    return h(NotificationTaskComponent, {
      taskPayload: notification.payload as NotificationTaskPayload,
      notification,
    });
  }

  return null;
};

const formatUserName = (
  user: string | Array<{ name: string; surname: string }> | { name: string; surname: string }
) => {
  if (Array.isArray(user)) {
    return `${user?.[0]?.name} ${user?.[0]?.surname}`;
  }

  if (typeof user === 'string') {
    return user;
  }

  return `${user?.name} ${user?.surname}`;
};
export const formatNotificationLabelInHTML = (notification: Notification) => {
  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_CREATED) {
    const task = notification.payload as NotificationTaskPayload;
    return `<b>${formatUserName(task.assignedBy)}</b> assigned you a task in <b>${task.brand}</b>`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_UPDATED) {
    const task = notification.payload as NotificationTaskPayload;
    return `Task <b>${task.title}</b> has been updated`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_DELETED) {
    const task = notification.payload as NotificationTaskPayload;
    return `Task <b>${task.title}</b> has been deleted`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.BRAND_CREATED) {
    const brand = notification.payload as Brand;
    return `Brand <b>${brand.label || brand.name}</b> has been created`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.BRAND_UPDATED) {
    const brand = notification.payload as Brand;
    return `Brand <b>${brand.label || brand.name}</b> has been updated`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.BRAND_DELETED) {
    const brand = notification.payload as Brand;
    return `Brand <b>${brand.label || brand.name}</b> has been deleted`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.USER_CREATED) {
    const user = notification.payload as User;
    return `User <b>${user.nome} ${user.cognome} </b> has been created`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.USER_UPDATED) {
    const user = notification.payload as User;
    return `User <b>${user.nome} ${user.cognome} </b> has been updated`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.USER_DELETED) {
    const user = notification.payload as User;
    return `User <b>${user.nome} ${user.cognome} </b> has been deleted`;
  }

  if (notification.code === SERVER_NOTIFICATION_TYPES.TASK_ASSIGNED) {
    const task = notification.payload as NotificationTaskPayload;
    return `<b>${formatUserName(task.assignedBy)}</b> assigned you a task in <b>${task.brand}</b>`;
  }

  return (
    (SERVER_NOTIFICATIONS_LABELS as any)[notification.code] ||
    SERVER_NOTIFICATIONS_LABELS[SERVER_NOTIFICATION_TYPES.GENERIC_NOTIFICATION]
  );
};
