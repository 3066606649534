import type { ActivityDiff, TaskActivity } from '@/types/task.types';

import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { moduleDiff } from '@kleecks/modules-lib';

export const useModuleDiffs = (activities: MaybeRef<TaskActivity[]>) => {
  const diffs = computed<ActivityDiff[]>(() => {
    if (!unref(activities)) return [];

    const milestone = unref(activities)?.find(a => a?.milestone === true);

    const sorted = unref(activities)
      ?.map(el => el)
      ?.sort((a, b) => parseInt(a.ashid, 2) - parseInt(b.ashid, 2));

    if (!sorted.length) return [];

    return moduleDiff(sorted[0].name)(milestone?.data?.formData, sorted[0]?.data?.formData);
  });

  return { diffs };
};
