<script setup lang="ts">
import CreateTeamDialog from '@/components/dialogs/create-team/CreateTeamDialog.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import UserDetailsPanel from '@/components/panels/user-details-panel/UserDetailsPanel.vue';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useTeams } from '@/hooks/team/useTeams';
import { deleteTeamMutation } from '@/services/queries/team.query';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Team } from '@/types/team.types';
import type { User } from '@/types/user.types';
import { TrashIcon } from '@heroicons/vue/24/outline';
import type { Column } from '@kleecks/ui-lib';
import { DrawerContainer, HeadlessTable } from '@kleecks/ui-lib';
import { h, ref } from 'vue';

const searchValue = ref();
const { teams } = useTeams();
const { mutate: deleteTeam } = deleteTeamMutation();
const { notify } = useNotificationStore();
const { showDeleteAlert } = useDeleteAlertStore();

const handleDeleteTeam = (id: string, name: string) => {
  showDeleteAlert(`Are you sure that you want to delete the team ${name}?`, async () => {
    await deleteTeam({ id });
    notify(DEFAULT_NOTIFICATIONS.TEAM_DELETED(name));
  });
};
const columns: Column<Team>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cellClass: 'font-bold text-sm',
    sortable: true,
    // accessorFn: v => h('span', { class: 'font-bold' }, v.name),
  },
  {
    actions: true,
    component: v =>
      h('div', { class: 'flex justify-end' }, [
        h(TrashIcon, {
          class: 'size-4 hover:cursor-pointer text-kl-icon hover:text-kl-red-200',
          onClick: () => handleDeleteTeam(v.id, v.name),
        }),
      ]),
  },
];

const selectedUser = ref<User>();
const isCreateTeamDialogOpen = ref(false);

const closeCreateTeamDialog = () => {
  isCreateTeamDialogOpen.value = false;
};

const openCreateTeamDialog = () => {
  isCreateTeamDialogOpen.value = true;
};

const closeUserDetailsDrawer = () => {
  selectedUser.value = undefined;
};
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col py-8">
    <CreateTeamDialog
      :is-open="isCreateTeamDialogOpen"
      @close="closeCreateTeamDialog" />
    <div class="relative z-10">
      <DrawerContainer
        :title="selectedUser?.nome + ' ' + selectedUser?.cognome"
        :on-close="closeUserDetailsDrawer"
        :isShowing="!!selectedUser">
        <UserDetailsPanel
          v-if="selectedUser"
          v-show="selectedUser"
          :on-close="closeUserDetailsDrawer"
          :user="selectedUser" />
      </DrawerContainer>
    </div>
    <div class="flex min-h-0 w-full flex-1 flex-col items-end">
      <DashboardHeader
        v-model:search-value="searchValue"
        title="Teams"
        sub-title="The list of the teams of the platform."
        :cta="{
          onClick: openCreateTeamDialog,
          label: 'New Team',
        }" />

      <HeadlessTable
        font-size="xs"
        :global-filter-enabled="true"
        :initial-page-size="20"
        :elements="teams"
        :columns="columns" />
    </div>
  </div>
</template>
