import { defineStore } from 'pinia';
import type { VNode } from 'vue';
import { ref, shallowRef } from 'vue';

export const useDeleteAlertStore = defineStore('deleteAlert', () => {
  const isOpenValue = ref(false);
  const messageValue = ref<string>();
  const messageComponentValue = shallowRef<VNode | undefined>(undefined);
  const onDelete = shallowRef<(() => void) | undefined>(undefined);
  const onDismiss = shallowRef<(() => void) | undefined>(undefined);
  const confirmDelete = () => {
    if (onDelete.value) {
      onDelete.value();
    }
  };

  const showDeleteAlert = (message: string | VNode, deleteCb: () => void, dismissCb?: () => void) => {
    if (typeof message === 'string') {
      messageValue.value = message;
      messageComponentValue.value = undefined;
    } else if (typeof message === 'object') {
      messageComponentValue.value = message;
      messageValue.value = undefined;
    }
    isOpenValue.value = true;
    onDelete.value = deleteCb;
    onDismiss.value = dismissCb;
  };

  const closeDeleteAlert = () => {
    isOpenValue.value = false;
    onDelete.value = undefined;
    if (onDismiss.value) {
      onDismiss.value();
    }
  };

  return {
    showDeleteAlert,
    closeDeleteAlert,
    confirmDelete,
    isOpenValue,
    messageValue,
    messageComponentValue,
    onDelete,
  };
});
