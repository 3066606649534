// Expected output : 28/12/23 10.00
export const formatDate = (date: string | Date) => {
  let d: Date;
  if (typeof date === 'string') {
    d = new Date(date);
  } else {
    d = date;
  }
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hour = d.getHours();
  const minute = d.getMinutes();

  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${day}/${formattedMonth}/${year} ${formattedHour}:${formattedMinute}`;
};
// Expected output : January 10, 2023
export const formattedDateMonthDDYYYY = (date: string) => {
  if (!date) return '';
  const newDate = new Date(date);
  const formattedDate = newDate.toLocaleDateString('en-us', {
    timeZone: 'UTC',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const sp = formattedDate.split(' ');
  return `${sp[1]} ${sp[0]}, ${sp[2]}`;
};

// Expected output : 2024/03/27 10.00
export const formatDateISO = (date: string) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hour = d.getHours();
  const minute = d.getMinutes();
  return `${year}-${month}-${day} ${hour}:${minute}`;
};
