<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import NotificationList from '@/components/notifications/NotificationList.vue';
import { useNotifications } from '@/hooks/notifications/useNotifications';
import { readAllNotificationsMutation } from '@/services/queries/notification.query';
// import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { BellIcon } from '@heroicons/vue/24/outline';
import { DropdownSection } from '@kleecks/ui-lib';
import { ref, watch } from 'vue';

const { notifications, totalUnviewed } = useNotifications();
const pingNotifications = ref(false);
const { mutate: readAllNotifications } = readAllNotificationsMutation();

watch(notifications, (newValue, oldvalue) => {
  if (!newValue || !oldvalue) return;
  const notReaded = newValue?.filter(n => !n.viewed)?.length;
  const oldNotReaded = oldvalue?.filter(n => !n.viewed)?.length;
  if (notReaded > oldNotReaded) {
    pingNotifications.value = true;
    setTimeout(() => {
      pingNotifications.value = false;
    }, 2000);
  }
});
</script>

<template>
  <DropdownSection
    :custom-height="448"
    :custom-width="400">
    <template #dropdown-button-content>
      <span class="sr-only">Open notification menu</span>
      <div class="relative">
        <span
          v-if="totalUnviewed"
          style="transform: translate(50%, -50%)"
          class="absolute right-0.5 top-0 z-[1] flex min-w-5 items-center justify-center rounded-full bg-kl-red-200 p-1 text-kl-2xs text-white">
          {{ totalUnviewed > 99 ? '99+' : totalUnviewed }}
        </span>
        <BellIcon
          :class="{ 'animate-pulse': totalUnviewed }"
          class="size-6 text-kl-icon hover:text-kl-green-300" />
      </div>
    </template>
    <template #dropdown-section-content>
      <div class="flex h-full w-full flex-col justify-between">
        <div class="flex items-center px-6 pt-6">
          <span class="flex-1 font-roboto-flex text-kl-base font-medium text-kl-gray-500">Notifications</span>
          <span class="font-roboto-flex text-kl-2xs text-kl-gray-400">{{ totalUnviewed }} unread</span>
        </div>
        <div class="flex-1 overflow-y-scroll pl-6 pr-2 pt-2">
          <NotificationList
            v-if="notifications.length > 0"
            :notifications="notifications" />
          <p
            v-if="notifications?.length === 0"
            class="pl-6 pr-1.5 text-center font-roboto-flex">
            No notification available
          </p>
        </div>
        <div class="bg-kl-gray-100 p-4">
          <div class="flex items-center justify-between">
            <span
              class="cursor-pointer font-roboto-flex text-kl-xs text-kl-gray-500 transition-all duration-200 ease-in-out hover:underline"
              @click="readAllNotifications"
              >Mark all as read</span
            >
            <span
              class="font-roboto-flex text-kl-xs text-kl-gray-500 underline transition-all duration-200 ease-in-out hover:cursor-pointer hover:text-kl-green-300"
              >Show all Notification</span
            >
          </div>
        </div>
      </div>
    </template>
  </DropdownSection>
</template>
