<script setup lang="ts">
import KleecksSpinner from '@/assets/kleecks-spinner.gif';

interface InstanceWidgetContainerProps {
  loading: boolean;
  title: string;
  borderColor: string | undefined;
}

const props = defineProps<InstanceWidgetContainerProps>();
</script>

<template>
  <div class="flex flex-col rounded-md border-sec bg-white shadow-custom">
    <div class="flex w-full items-center justify-between rounded-t-md border-b border-sec">
      <div class="absolute self-start overflow-hidden rounded-t-md p-4">
        <div
          class="absolute -left-5 -top-5 h-10 w-10 rotate-45"
          :class="props.borderColor" />
      </div>
      <div class="flex-1 py-2 pl-6 text-primary">{{ props.title }}</div>
    </div>

    <div class="min-h-0 grow">
      <transition name="fadein">
        <div
          v-if="loading"
          class="flex h-full w-full items-center justify-center">
          <img
            class="flex h-10 w-10 items-center justify-center rounded-full"
            :src="KleecksSpinner"
            alt="" />
        </div>
      </transition>
      <slot />
    </div>
  </div>
</template>
