<script setup lang="ts"></script>

<template>
  <div class="m-4 rounded-md border p-4 shadow">
    <div class="flex animate-pulse space-x-4">
      <div class="flex-1 space-y-6 py-1">
        <div class="grid grid-cols-4 gap-4">
          <div class="col-span-2 h-2 rounded bg-slate-300"></div>
          <div class="col-span-1"></div>
          <div class="col-span-1 h-2 rounded bg-slate-300"></div>
        </div>
        <div class="h-40 w-full rounded bg-slate-300"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
          </div>
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
          </div>
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
          </div>
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1"></div>
            <div class="col-span-1 h-2 rounded bg-slate-300"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
