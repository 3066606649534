import { moduleActivitiesQuery } from '@/services/queries/module-property.query';
import type { TaskActivity } from '@/types/task.types';
import type { MaybeRef } from 'vue';
import { computed } from 'vue';

export const useModuleActivities = (
  taskId: MaybeRef<string | undefined>,
  moduleId: MaybeRef<string>,
  instanceId: MaybeRef<string | undefined>
) => {
  const query = moduleActivitiesQuery(taskId, moduleId, instanceId);

  const activities = computed<TaskActivity[]>(() => query.result.value?.moduleProperty || []);

  return { activities, ...query };
};
