import { updateModulePropertyMutation } from '@/services/queries/module-property.query';

export const useApproveProperty = () => {
  const { mutate: updateProperty } = updateModulePropertyMutation();

  const doApproveProperty = async (payload: {
    id: string;
    name: string;
    module: string;
    cas: string;
    taskId: string;
    data: any;
  }) => {
    const { id, name, module, cas, taskId, data } = payload;

    updateProperty({
      taskId,
      property: {
        id,
        name,
        module,
        cas,
        data,
        approved: true,
      },
    });
  };

  return { doApproveProperty };
};
