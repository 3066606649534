import { GetServerInstances } from '@/services/graphql/instance.graphql';
import { type ServerInstances } from '@/types/instance.types';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

export const useServerInstances = () => {
  const query = useQuery<{
    instances: ServerInstances[];
  }>(GetServerInstances);
  const instances = computed(() => query.result?.value?.instances?.flatMap(inst => inst.ids).sort() || []);

  return {
    instances,
    ...query,
  };
};
