<script setup lang="ts">
import DashboardWidgetContainer from '@/components/dashboards/dashboard-widget-container/DashboardWidgetContainer.vue';
import { MODULES_DESCRIPTIONS, MODULES_NAMES, STANDALONE_MODULES, type MODULES } from '@/constants/modules.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useModules } from '@/hooks/modules/useModules';
import { useBrandWithTasks } from '@/hooks/tasks/useBrandWithTasks';
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { StandardContainer } from '@kleecks/ui-lib';
import { computed, toRef, unref } from 'vue';
import { useRouter } from 'vue-router';

const moduleId = useRouteParam('moduleId');
const taskId = useRouteParam('taskId');
const brandId = useRouteParam('brandId');

const { modules } = useModules();
const router = useRouter();
const instanceId = useRouteParam('instanceId');
const isStandaloneModule = computed(() => STANDALONE_MODULES?.includes(moduleId.value as MODULES));
const keyId = toRef(() => router.currentRoute.value.query.keyId);
const { tasks, loading: loadingTasks } = useBrandWithTasks(brandId);
const task = computed(() => tasks.value?.find(t => t.id === unref(taskId)));
</script>

<template>
  <transition name="fade">
    <StandardContainer
      v-if="!loadingTasks && task"
      class="flex flex-col gap-8 py-8">
      <div
        class="flex cursor-pointer items-center gap-2 text-secondary underline hover:text-active"
        @click="() => router.options.history.go(-1)">
        <ArrowLeftIcon class="size-4 text-kl-icon" />
        <span class="text-base">Back to previous page</span>
      </div>

      <component
        :is="modules[moduleId as MODULES]"
        v-if="modules[moduleId as MODULES] !== undefined && isStandaloneModule"
        :key="taskId || 'default'"
        v-bind="{
          taskId: taskId,
          instanceId,
          isReadOnly: undefined,
        }" />
      <DashboardWidgetContainer
        v-else-if="moduleId && MODULES_NAMES[moduleId as MODULES]"
        v-slot="{ afterHeaderId }"
        :key="moduleId"
        :task-status="task?.status"
        :module-id="moduleId"
        :data-testid="moduleId"
        :name="MODULES_NAMES[moduleId as MODULES]"
        :description="MODULES_DESCRIPTIONS[moduleId as MODULES]"
        :has-permission="modules[moduleId as MODULES] !== undefined">
        <component
          :is="modules[moduleId as MODULES]"
          :key="'default'"
          v-bind="{
            taskId: taskId,
            instanceId,
            highlightedKeysId: keyId,
            isReadOnly: undefined,
            customSectionHeaderId: afterHeaderId,
          }" />
      </DashboardWidgetContainer>
    </StandardContainer>
  </transition>
</template>
