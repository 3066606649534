<script setup lang="ts">
import TasksTable from '@/components/tables/tasks-table/TasksTable.vue';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useUser } from '@/hooks/user/useUser';
import { useCurrentTaskDrawerStore } from '@/stores/useCurrentTaskDrawerStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';

import type { Task } from '@/types/task.types';
import type { User } from '@/types/user.types';
import { ArrowsRightLeftIcon, PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { BaseTextInput, Toggle } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';

const searchValue = ref('');

const taskUserIdFilter = ref<string>('');
const instanceId = useRouteParam('instanceId');
const brandId = useRouteParam('brandId');
const taskDrawerStore = useCurrentTaskDrawerStore();
const { instanceTasks } = useInstanceTasks(brandId, instanceId);
const { user } = useUser();
const { notify } = useNotificationStore();
const userStore = useUserStore();
const handleSelectTask = (taskId: string) => {
  taskDrawerStore.openDrawer(taskId);
};

const handleAction = (taskId: string, actionId: string) => {
  switch (actionId) {
    case 'details':
      handleSelectTask(taskId);
      break;

    case 'switch': {
      userStore.setSelectedTaskId(taskId);
      const task = instanceTasks.value.find(t => t.id === taskId);
      notify({
        type: NOTIFICATION_TYPES.INFO,
        title: 'Working task changed',
        message: `Now you're working on task ${task?.title}`,
      });
      break;
    }
    default:
      throw new Error(`Action ${actionId} not found`);
  }
};

const showMyTasks = (value: boolean) => {
  taskUserIdFilter.value = value ? user.value?.id! : '';
};

const taskActions = computed(() => {
  const res = [];
  if (user.value?.admin) {
    res.push({ icon: PencilIcon, code: 'edit' }, { icon: TrashIcon, code: 'delete' });
  }

  res.push({
    code: 'switch',
    icon: ArrowsRightLeftIcon,
    condition: (task: Task) =>
      task.assignedTo.some((u: User) => u.id === user.value?.id) && task.instance?.instanceId === instanceId.value,
  });

  return res;
});

const filteredTasks = computed(() =>
  instanceTasks.value.filter(task => {
    if (taskUserIdFilter.value) {
      return task.assignedTo.some((u: User) => u.id === taskUserIdFilter.value);
    }
    return true;
  })
);
</script>

<template>
  <div class="flex min-h-0 w-full flex-1 flex-col gap-4">
    <div class="flex w-full items-start">
      <div class="flex-1">
        <span class="text-2xl font-medium text-primary">Tasks</span><br />
        <span class="text-xs text-secondary">The list of all tasks in this instance. </span>
      </div>
    </div>

    <div class="flex items-center gap-4">
      <div class="flex-1">
        <BaseTextInput
          v-model="searchValue"
          placeholder="Search tasks name or description"
          input-classes="bg-white"
          type="search"
          class="w-full" />
      </div>

      <Toggle
        label="My Tasks"
        :checked="taskUserIdFilter === user?.id"
        :on-change="showMyTasks" />
    </div>

    <TasksTable
      :actions="taskActions"
      :tasks="filteredTasks"
      :on-action="handleAction" />
  </div>
</template>
