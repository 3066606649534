<script setup lang="ts">
import type { Dashboard } from '@/types/dashboard.types';
import { getDashboardVisibility, getDashboardBadge } from '@/utils/dashboard.utils';
import { Badge, WithTooltip } from '@kleecks/ui-lib';
import { GlobeAltIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

interface TypeCellProps {
  dashboard: Dashboard;
  brandName?: string;
}

const props = defineProps<TypeCellProps>();

const getBadgeSettings = computed(() => getDashboardBadge(props.dashboard, props.brandName));
</script>

<template>
  <div class="inline-flex items-center gap-x-2">
    <Badge
      v-if="getBadgeSettings"
      class="!ml-0 !bg-white"
      :label="getBadgeSettings.label"
      :color="getBadgeSettings.color"
      type="soft" />
    <WithTooltip label="Private Dashboard for all your brands">
      <GlobeAltIcon
        v-if="getDashboardVisibility(props.dashboard) === 'global'"
        class="size-5 text-kl-icon" />
    </WithTooltip>
  </div>
</template>
