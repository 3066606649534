import { WidgetMap, widgetFactory } from '@/helpers/widget-factory';
import type { WidgetPropsDefinition } from '@/types/widgets';

import type { MODULES } from '@/constants/modules.constants';
import { WIDGET_MODULES } from '@/constants/modules.constants';
import type { PermissionWidget } from '@/types/dashboard.types';
import { computed } from 'vue';
import { useModules } from '../modules/useModules';

const getPropFromMap = (props: WidgetPropsDefinition) => (prop: string) => props[prop as keyof typeof props];

const extractWidgetProps = (widget: PermissionWidget) => (props: WidgetPropsDefinition) => {
  if (!widget.propsToForward) return {};
  return widget.propsToForward.reduce((acc: Record<string, any>, prop: string) => {
    acc[prop] = getPropFromMap(props)(prop);
    return acc;
  }, {});
};

export const useWidgets = () => {
  const { modules } = useModules();
  const widgets = computed(() => {
    const widgetModuleMap = Object.keys(WidgetMap).reduce(
      (acc: { [key in MODULES]?: PermissionWidget[] }, widgetPackage) => {
        const widget = WidgetMap[widgetPackage as keyof typeof WidgetMap];
        if (!acc[widget.moduleName]) acc[widget.moduleName as MODULES] = [];
        acc[widget.moduleName]?.push(widget);
        return acc;
      },
      {}
    );

    return Object.keys(modules.value).reduce((acc: PermissionWidget[], module) => {
      if (!widgetModuleMap[module as MODULES]) return acc;
      return [...acc, ...widgetModuleMap[module as MODULES]!];
    }, []);
  });

  const availableWidgetProps: WidgetPropsDefinition = {
    title: 'Mocked title',
    value: 100,
    previousValue: 50,
  };

  const widgetsProps = computed(() =>
    widgets.value.reduce(
      (acc: Record<string, any>, widget) => ({
        ...acc,
        [widget.widgetPackage]: extractWidgetProps(widget)(availableWidgetProps),
      }),
      {}
    )
  );

  const widgetsMap = computed(() =>
    widgets.value.reduce((acc: Record<string, any>, widget) => {
      if (!modules.value[widget.moduleName]) return acc;
      if (WIDGET_MODULES.includes(widget.moduleName)) {
        const module = modules.value[widget.moduleName];
        if (!module) return acc;
        acc[widget.widgetPackage] = module;
      } else acc[widget.widgetPackage] = widgetFactory(widget);
      return acc;
    }, {})
  );

  return { widgetsMap, widgetsProps };
};
