<script setup lang="ts">
import type { MODULES } from '@/constants/modules.constants';
import { MODULES_ICON, MODULES_IDS, MODULES_NAMES, WIDGET_MODULES } from '@/constants/modules.constants';
import { useApproveProperty } from '@/hooks/modules/useApproveProperty';
// import { useModuleModalStore } from '@/stores/useModuleModalStore';
import type { Task, TaskActivity } from '@/types/task.types';
import { ChevronDownIcon, EyeIcon, PencilIcon } from '@heroicons/vue/24/outline';
import { DropdownMenu } from '@kleecks/ui-lib';
import type { MenuOption } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useUser } from '@/hooks/user/useUser';
import { TASK_STATUS } from '@/constants/task.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useRouter } from 'vue-router';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import ActivityDiffList from '../activity-diff-list/ActivityDiffList.vue';

interface ActivityCardProps {
  activity: TaskActivity;
  task: Task;
}

const props = defineProps<ActivityCardProps>();

// const moduleModalStore = useModuleModalStore();
const { user } = useUser();
const instanceId = useRouteParam('instanceId');
const { doApproveProperty } = useApproveProperty();
const isExpanded = ref(false);
const router = useRouter();
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const menuOptions = computed(() => {
  const res = [] as MenuOption[];
  if (
    user.value?.admin &&
    WIDGET_MODULES.includes(props.activity.name as MODULES) &&
    props.task.instance?.instanceId === instanceId.value
  ) {
    if (props.task.status === TASK_STATUS.APPROVED || props.task.status === TASK_STATUS.IN_REVIEW) {
      res.push({
        label: 'Edit Module',
        operation: 'edit-module',
        icon: PencilIcon,
      });
    } else {
      res.push({
        label: 'View Module',
        operation: 'view-module',
        icon: EyeIcon,
      });
    }
  }

  // if (user.value?.admin)
  //  res[0].options.push({
  //     label: 'Approve',
  //     operation: 'approve',
  //     icon: 'CheckIcon',
  //   });

  return res;
});

const handleMenuAction = (action: string, keyId?: string) => {
  if (action === 'edit-module') {
    router.push({
      name: ROUTE_NAMES.MODULE,
      params: { moduleId: props.activity.name as MODULES, taskId: props.task?.id },
      query: { keyId },
    });
    // moduleModalStore.displayModule(props.activity.name as MODULES, props.task.id, props.task?.instance!.instanceId, {
    //   isReadOnly: false,
    // });
    return;
  }

  if (action === 'view-module') {
    router.push({
      name: ROUTE_NAMES.MODULE,
      params: { moduleId: props.activity.name as MODULES, taskId: props.task?.id, keyId },
      query: { keyId },
    });
    return;
  }
  if (action === 'approve') {
    doApproveProperty({
      id: props.activity.id,
      name: props.activity.name,
      cas: props.activity.cas,
      data: props.activity.data,
      module: props.activity.module,
      taskId: props.task.id,
    });
    return;
  }

  throw new Error(`Unknown action ${action}`);
};

const isItemExpandable = computed<boolean>(() => {
  if (props.activity.module === MODULES_IDS.URL_REDIRECT) return false;

  return true;
});

const handleClickCard = () => {
  toggleExpand();
};
</script>

<template>
  <div class="w-full">
    <div
      class="virtualized-activity flex w-full items-stretch transition-shadow hover:shadow-sm"
      :class="{
        'cursor-pointer': isItemExpandable,
      }"
      @click="handleClickCard">
      <div class="rounded-md rounded-r-none border p-1">
        <img
          v-if="typeof MODULES_ICON[props.activity.name as MODULES] === 'string'"
          :src="MODULES_ICON[props.activity.name as MODULES] as string"
          class="size-7 p-[.15rem]" />
        <component
          :is="MODULES_ICON[props.activity.name as MODULES]"
          v-else
          class="size-7 p-[.15rem]" />
      </div>

      <div
        class="flex flex-1 items-center divide-x divide-sec rounded-sm rounded-l-none border border-l-0 border-kl-gray-200 bg-white py-2">
        <div class="flex flex-1 items-center pl-4 pr-2">
          <p class="flex-1 text-xs text-kl-gray-400">
            {{ MODULES_NAMES[props.activity.name as MODULES] || props.activity.name }}
          </p>
          <!-- <div
            :class="[
              'mr-1 rounded-3xl px-2 text-3xs text-white',
              isExpanded ? 'bg-kl-violet-100' : props.diffs?.length! > 0 ? 'bg-kl-violet-100/50' : 'bg-gray-secondary',
            ]">
            {{ props.diffs?.length || 0 }}
          </div> -->
          <ChevronDownIcon
            v-if="isItemExpandable"
            :class="[
              'h-4 w-4 cursor-pointer text-secondary transition-all duration-300 hover:text-active',
              {
                'rotate-180': isExpanded,
              },
            ]"
            @click="
              e => {
                e.stopPropagation();
                if (isItemExpandable) toggleExpand();
              }
            " />
        </div>

        <DropdownMenu
          v-if="menuOptions.length > 0"
          :menu-options="menuOptions"
          :on-send-option-operation="(operation: string) => handleMenuAction(operation)" />
      </div>
    </div>
    <transition
      enter-from-class="opacity-0"
      enter-active-class="transition duration-300"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 duration-300">
      <div
        v-if="isItemExpandable && isExpanded"
        class="mt-2 flex w-full flex-col items-center gap-4">
        <ActivityDiffList
          :module-id="props.activity.name as MODULES"
          :on-edit-module="WIDGET_MODULES.includes(props.activity.name as MODULES) ? (keyId) => handleMenuAction('edit-module', keyId) : undefined"
          :task-id="props.task.id" />
      </div>
    </transition>
  </div>
</template>
