import type { AppQueryParam } from '@/routes';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useQueryParam = (key: keyof AppQueryParam) => {
  const route = useRoute();

  const param = computed(() => route.query?.[key] as AppQueryParam[typeof key]);

  return param;
};
