<script setup lang="ts">
import { HeadlessTable, TaskStatusChip, UserProfileImage } from '@kleecks/ui-lib';

import { TASK_PRIORITY_COLOR, TASK_PRIORITY_LABEL } from '@/constants/task.constants';
import type { Task, TaskAction, TaskPriority } from '@/types/task.types';
import type { Column } from '@kleecks/ui-lib';
import type { FunctionalComponent } from 'vue';
import { computed, h } from 'vue';
import { useInstances } from '@/hooks/instance/useInstances';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import TasksTableAction from './TasksTableAction.vue';

interface TasksTableProps {
  tasks: Task[];
  actions?: {
    icon: FunctionalComponent;
    code: string;
    condition?: (task: Task) => boolean;
  }[];
  onAction?: (taskId: string, action: TaskAction) => void;
}

const props = defineProps<TasksTableProps>();
const brandId = useRouteParam('brandId');
const { instancesNames } = useInstances(brandId);

const handleTaskAction = (taskId: string, action: TaskAction) => {
  if (props.onAction) {
    props.onAction(taskId, action);
  } else {
    throw new Error(`No action handler provided ${action}`);
  }
};

const columns = computed<Column<Task>[]>(() => [
  {
    accessorKey: 'status',
    header: 'Status',
    cellClass: 'w-24',
    accessorFn: row => h(TaskStatusChip, { task: row }),
  },
  {
    accessorKey: 'priority',
    header: 'Priority',
    headerClass: 'w-24 flex flex-col items-center',
    cellClass: 'text-center w-24',
    component: (value: TaskPriority) =>
      h('span', { class: `${TASK_PRIORITY_COLOR[value]}` }, TASK_PRIORITY_LABEL[value]),
  },
  {
    header: 'Project',
    cellClass: 'w-32',
    accessorFn: (row: Task) =>
      h('div', {}, [
        h('p', { class: 'font-bold text-kl-xs' }, row.brand?.name),
        h(
          'p',
          { class: 'text-kl-2xs text-secondary mt-1' },
          row.instance?.instanceId
            ? instancesNames.value[row.instance.instanceId!] || row.instance.instanceId
            : 'No project'
        ),
      ]),
  },
  {
    accessorFn: (row: Task) =>
      h('div', { class: 'w-56' }, [
        h('p', { class: 'font-bold text-kl-xs' }, row.title),
        h('p', { class: 'truncate text-kl-2xs text-secondary mt-1' }, row.description || 'No description'),
      ]),
    header: 'Description',
  },
  {
    accessorFn: (row: Task) =>
      h('div', { class: 'text-kl-xs text-kl-gray-400' }, row.activitiesObject?.countNotMilestones),
    cellClass: 'text-center w-12',
    header: 'Activities',
    columnRendererClass: 'text-center w-full',
    sortable: true,
  },
  {
    accessorFn: (row: Task) => {
      const renders = row.assignedTo?.map(user =>
        h('div', { class: 'flex items-center gap-2 whitespace-nowrap' }, [
          h(UserProfileImage, { user, size: 'sm' }),
          h('p', { class: 'text-kl-xs text-kl-gray-400' }, `${user.nome} ${user.cognome}`),
        ])
      );
      return renders;
    },
    header: 'Assigned To',
  },
  {
    actions: true,
    component: (v: Task) =>
      h(TasksTableAction, {
        task: v,
        onAction: (taskId: string, action: TaskAction) => handleTaskAction(taskId, action),
      }),
  },
]);
</script>

<template>
  <HeadlessTable
    :on-row-click="row => handleTaskAction(row.id, 'details')"
    :elements="tasks"
    :columns="columns" />
</template>
