export const catchable =
  (fn: Function) =>
  (...args: any[]) => {
    try {
      return fn(...args);
    } catch (error) {
      /* DO NOTHING */
    }

    return undefined;
  };
