import type { TaskActivity } from '@/types/task.types';
import { useMutation, useQuery } from '@vue/apollo-composable';

import {
  AddModuleProperty,
  UpdateModuleProperty,
  GetModuleProperties,
} from '@/services/graphql/module-properties.graphql';
import type { MaybeRef } from 'vue';
import { unref } from 'vue';

interface MutationPayload {
  property: Partial<TaskActivity>;
  taskId?: string;
}
export const addModulePropertyMutation = () =>
  useMutation<
    {
      addModuleProperty: Partial<TaskActivity>;
    },
    MutationPayload
  >(AddModuleProperty);
export const updateModulePropertyMutation = () =>
  useMutation<
    {
      updateModuleProperty: Partial<TaskActivity>;
    },
    MutationPayload
  >(UpdateModuleProperty);

export const moduleActivitiesQuery = (
  taskId: MaybeRef<string | undefined>,
  moduleId: MaybeRef<string>,
  instanceId: MaybeRef<string | undefined>
) =>
  useQuery<{
    moduleProperty: TaskActivity[];
  }>(GetModuleProperties, () => ({
    task: unref(taskId),
    name: unref(moduleId),
    ...(!unref(taskId) && { instance: unref(instanceId) }),
  }));
