/* eslint-disable no-console */
import { APP_VERSION, ENVIRONMENT, SENTRY_DSN } from '@/constants/settings.constants';
import { GetUser } from '@/services/graphql/user.graphql';
import { COOKIES, getCookie, removeCookie } from '@kleecks/code-lib';
import '@kleecks/component-library/dist/main.css';
import '@kleecks/modules-lib/dist/main.css';
import '@kleecks/ui-lib/dist/main.css';
import Tracker from '@openreplay/tracker';
import * as Sentry from '@sentry/vue';
import { provideApolloClient } from '@vue/apollo-composable';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import VueApexCharts from 'vue3-apexcharts';
import App from './App.vue';
import { ROUTE_NAMES } from './constants/routes.constants';
import { apolloClient, initGraphql } from './init-graphql';
import { routes } from './routes';
import './style.css';
import type { User } from './types/user.types';
import { catchable } from './utils/lambdas.utils';

declare global {
  interface Window {
    tracker: Tracker;
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const pinia = createPinia();
const app = createApp(App);
app.use(VueApexCharts);
app.component('Apexchart', VueApexCharts);
app.component('QuillEditor', QuillEditor);

if (process.env.VITE_OPENREPLAY_KEY) {
  window.tracker = new Tracker({
    projectKey: process.env.VITE_OPENREPLAY_KEY,
    ingestPoint: 'https://openreplay2.kleecks.com/ingest',
  });
  window.tracker.start();
}

window.addEventListener('vite:preloadError', event => {
  // Ask to reload the page
  console.log('vite:preloadError', event);

  // window.location.reload(); // for example, refresh the page
});
router.beforeEach(async to => {
  if (!getCookie(COOKIES.TOKEN_KEY) && to.name !== ROUTE_NAMES.LOGIN) {
    return { name: ROUTE_NAMES.LOGIN, query: { redirect: catchable(() => encodeURIComponent(to.fullPath))() } };
  }

  if (!getCookie(COOKIES.TOKEN_KEY) && to.name === ROUTE_NAMES.LOGIN) {
    return true;
  }

  const apolloClientQuery = provideApolloClient(apolloClient)(async () => {
    const result = await apolloClient.query<{
      user: User;
    }>({
      query: GetUser,
    });

    return result;
  });

  try {
    const result = await apolloClientQuery;

    const isAuthenticated = result?.data.user.login;
    const isAdmin = result?.data.user.admin;

    if (!isAuthenticated && to.name !== ROUTE_NAMES.LOGIN) {
      return { name: ROUTE_NAMES.LOGIN, query: { redirect: catchable(() => encodeURIComponent(to.fullPath))() } };
    }

    if (isAuthenticated && to.name === ROUTE_NAMES.LOGIN) {
      return { name: ROUTE_NAMES.BRANDS };
    }

    if (to.name === ROUTE_NAMES.TASKS_MANAGEMENT && !isAdmin) {
      // eslint-disable-next-line no-param-reassign
      to.query.panel = 'tasks';
      // eslint-disable-next-line no-param-reassign
      to.name = ROUTE_NAMES.INSTANCE;
      return { ...to };
    }

    if (to.name === ROUTE_NAMES.INSTANCE) {
      if (to.query.panel === 'tasks' && isAdmin) {
        // eslint-disable-next-line no-param-reassign
        to.name = ROUTE_NAMES.TASKS_MANAGEMENT;
        return { ...to };
      }

      //   // Feature richiesta da Marco
      if (!to.query.panel) {
        // eslint-disable-next-line no-param-reassign
        to.query.panel = 'overview';
        return { ...to };
      }
      // if (to.query.panel !== 'ai') {
      //   setCookie(COOKIES.LAST_PANEL, to.query.panel as string);
      // }
    }

    return true;
  } catch (error) {
    removeCookie(COOKIES.TOKEN_KEY);
    return { name: ROUTE_NAMES.LOGIN, query: { redirect: catchable(() => encodeURIComponent(to.fullPath))() } };
  }
});

if (process.env.ENABLE_SENTRY === 'true') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: APP_VERSION === 'development' ? 'development' : 'production',
    release: process.env.VERSION,
    tracesSampleRate: 1.0,
    trackComponents: true,
    tracePropagationTargets: ['graphql.kleecks.com'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (ENVIRONMENT !== 'production') {
  console.debug('Performance monitoring enabled.');
  app.config.performance = true;
}

initGraphql(app);
app.use(pinia);
app.use(router);

// This should be the last thing you do
app.mount('#kapp');
