import { MODULES_LAZY_MAP, type MODULES } from '@/constants/modules.constants';
import { SERVER_BASE_URL } from '@/constants/settings.constants';
import type { ChatbotResult } from '@/services/queries/chatbot.query';
import type { AiMessageType, ChatbotMemory } from '@/types/chatbot.types';
import { COOKIES, generateUuid, getCookie } from '@kleecks/code-lib';
import { ref } from 'vue';

export const useChatbot = () => {
  const memory = ref<ChatbotMemory[]>([]);
  const aiMessages = ref<AiMessageType[]>([]);

  const selectModule = (moduleId: MODULES, messageId: string) => {
    aiMessages.value = aiMessages.value.map(message => {
      if (message.id === messageId) {
        return {
          ...message,
          moduleId,
          type: 'module',
        };
      }
      return message;
    });
  };

  const handleStreamingResponse = async (reader: ReadableStreamDefaultReader, messageId: string) => {
    const decoder = new TextDecoder();

    aiMessages.value = aiMessages.value.map(message => {
      if (message.id === messageId) {
        return {
          ...message,
          loading: false,
          isStreaming: true,
          label: `${message.label || ''}\n`,
        };
      }

      return message;
    });
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) {
        aiMessages.value = aiMessages.value.map(message => {
          if (message.id === messageId) {
            return {
              ...message,
              loading: false,
              isStreaming: false,
            };
          }
          return message;
        });
        break;
      }

      aiMessages.value = aiMessages.value.map(message => {
        if (message.id === messageId) {
          return {
            ...message,
            loading: false,
            isStreaming: true,
            label: `${message.label || ''}${decoder.decode(value).replaceAll('|', '')}`,
          };
        }

        return message;
      });
    }
  };

  const handleJSONResponse = (response: ChatbotResult, messageId: string) => {
    const answer = response?.output;
    if (answer) {
      aiMessages.value = aiMessages.value.map(message => {
        if (message.id === messageId) {
          const newMessage = {
            ...message,
            loading: false,
            label: answer?.text,
          };

          if (!answer.modules && !answer.activities) {
            return newMessage;
          }

          if (Array.isArray(answer.modules)) {
            newMessage.availableModules = answer.modules.filter(
              module => (MODULES_LAZY_MAP as any)[module] !== undefined
            );
          } else if (Array.isArray(answer.activities)) {
            newMessage.availableActivities = answer.activities;
          } else if (!Array.isArray(answer.modules)) {
            newMessage.moduleId = answer.modules;
            newMessage.type = 'module';
          }

          return newMessage;
        }
        return message;
      });
    } else {
      aiMessages.value = aiMessages.value.map(message =>
        message.loading && message.id === messageId
          ? { ...message, loading: false, label: 'Sorry, there was an error, retry!' }
          : message
      );
    }
  };

  const sendMessage = async (input: string) => {
    const messageId = generateUuid();

    aiMessages.value = [
      ...aiMessages.value,
      {
        id: generateUuid(),
        type: 'user',
        label: input,
      },
      {
        id: messageId,
        type: 'ai',
        loading: true,
      },
    ];
    try {
      const makeCall = async (useRag: boolean = false) => {
        const res = await fetch(`${SERVER_BASE_URL}/chatbot/chat`, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Bearer ${getCookie(COOKIES.TOKEN_KEY)}`,
          },
          body: JSON.stringify({
            input,
            use_rag: useRag,
          }),
        });

        return res;
      };

      const res = await makeCall();

      if (!res.ok) {
        throw new Error('Error');
      }
      const contentType = res.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const result = (await res.json()) as ChatbotResult;
        handleJSONResponse(result, messageId);
        if (result?.output?.modules?.length) {
          const secondRes = await makeCall(true);
          const secondContentType = secondRes.headers.get('content-type');

          if (secondContentType && secondContentType.includes('text/event-stream')) {
            const reader = secondRes.body?.getReader();
            if (!reader) {
              throw new Error('No reader found');
            }
            handleStreamingResponse(reader, messageId);
          }

          if (secondContentType && secondContentType.includes('application/json')) {
            const secondResult = (await secondRes.json()) as ChatbotResult;
            handleJSONResponse(secondResult, messageId);
          }
        }
      }

      if (contentType && contentType.includes('text/event-stream')) {
        const reader = res.body?.getReader();
        if (!reader) {
          throw new Error('No reader found');
        }
        handleStreamingResponse(reader, messageId);
      }
    } catch (e) {
      aiMessages.value = aiMessages.value.map(message =>
        message.loading && message.id === messageId
          ? { ...message, loading: false, label: 'Sorry, there was an error, retry!' }
          : message
      );
    }
  };

  return { sendMessage, aiMessages, memory, selectModule };
};
