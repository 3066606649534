import { getBrandTasksQuery } from '@/services/queries/brand.query';
import type { TaskStatus } from '@/types/task.types';
import type { Topic } from '@kleecks/code-lib';
import Fuse from 'fuse.js';
import type { MaybeRef, Ref } from 'vue';
import { computed } from 'vue';

export const useBrandWithTasks = (
  brandId: MaybeRef<string | undefined>,
  options: {
    search?: Ref<string>;
    userId?: Ref<string>;
    filters?: Ref<{
      topics: Topic[];
      statuses: TaskStatus[];
      assignees: string[];
    }>;
  } = {}
) => {
  const query = getBrandTasksQuery(brandId);

  const brand = computed(() => query.result.value?.brand);

  const tasks = computed(() => {
    if (!brand.value?.taskObject?.tasks) return [];

    return brand.value?.taskObject.tasks;
  });

  const assigneeOptions = computed<{ value: string; label: string }[]>(() => {
    if (!tasks.value) return [];
    const assignees = tasks.value
      .flatMap(el => el.assignedTo)
      .filter((el, i, arr) => arr.findIndex(e => e.id === el.id) === i);
    const res = [
      ...(assignees.map(el => ({ label: `${el.nome} ${el.cognome}`, value: el.id!, image: el.image })) || []),
    ];

    return res;
  });
  const filteredTasks = computed(() => {
    const { topics, statuses, assignees } = options.filters?.value || {};

    let res = [...tasks.value]?.filter(el => {
      if (topics?.length && !topics.includes(el.topic)) return false;
      if (statuses?.length && !statuses.includes(el.status)) return false;
      if (assignees?.length && !el.assignedTo?.find(u => assignees.includes(u.id))) return false;
      return true;
    });

    if (options.userId?.value) {
      res = res.filter(task => task.assignedTo?.find(el => el.id === options.userId?.value));
    }

    if (options.search?.value) {
      const fuse = new Fuse(res, {
        keys: ['title', 'description'],
        shouldSort: true,
        minMatchCharLength: 1,
        threshold: 0.3,
        location: 0,
      });

      const fuseSearch = fuse.search(options.search.value);
      return fuseSearch?.map(el => el.item);
    }
    return res?.toSorted((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  });

  const tasksCountPerStatus = computed(() =>
    tasks.value.reduce<{ [key in TaskStatus]: number }>((acc, t) => {
      acc[t.status] = (acc[t.status] || 0) + 1;
      return acc;
    }, {} as any)
  );

  const tasksCountPerAssignee = computed(() =>
    tasks.value.reduce<{ [key: string]: number }>((acc, t) => {
      const assigneeId = t.assignedTo?.[0].id;
      if (assigneeId) acc[assigneeId] = (acc[assigneeId] || 0) + 1;

      return acc;
    }, {} as any)
  );

  const tasksCountPerTopic = computed(() =>
    tasks.value.reduce<{ [key in Topic]: number }>((acc, t) => {
      acc[t.topic] = (acc[t.topic] || 0) + 1;
      return acc;
    }, {} as any)
  );
  return {
    tasks,
    brand,
    assigneeOptions,
    tasksCountPerStatus,
    tasksCountPerAssignee,
    tasksCountPerTopic,
    filteredTasks,
    ...query,
  };
};
