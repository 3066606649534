export const dragAndScrollSlider = (sliderElement: HTMLInputElement | undefined) => {
  if (sliderElement) {
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    sliderElement.addEventListener('mousedown', e => {
      isDown = true;
      sliderElement.classList.add('active');
      startX = e.pageX - sliderElement.offsetLeft;
      scrollLeft = sliderElement.scrollLeft;
    });
    sliderElement.addEventListener('mouseleave', () => {
      isDown = false;
      sliderElement.classList.remove('active');
    });
    sliderElement.addEventListener('mouseup', () => {
      isDown = false;
      sliderElement.classList.remove('active');
    });
    sliderElement.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - sliderElement.offsetLeft;
      const walk = x - startX;
      // eslint-disable-next-line no-param-reassign
      sliderElement.scrollLeft = scrollLeft - walk;
    });
  }
};
