import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useBrandWithTasks } from '@/hooks/tasks/useBrandWithTasks';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useCurrentTaskDrawerStore = defineStore('currentTaskDrawerStore', () => {
  const taskId = ref<string | undefined>(undefined);
  const brandId = useRouteParam('brandId');

  const { tasks } = useBrandWithTasks(brandId);
  const openDrawer = (tId: string) => {
    taskId.value = tId;
  };

  const closeDrawer = () => {
    taskId.value = undefined;
  };

  const task = computed(() => tasks.value.find(x => x.id === taskId.value));

  return {
    task,
    openDrawer,
    closeDrawer,
  };
});
