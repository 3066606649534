<script setup lang="ts">
import { getDashboardVisibility, isDashbordInReadOnlyMode, getDashboardBadge } from '@/utils/dashboard.utils';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useDeleteDashboard } from '@/hooks/dashboard/useDeleteDashboard';
import { useUser } from '@/hooks/user/useUser';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import type { Dashboard } from '@/types/dashboard.types';
import { ArrowTopRightOnSquareIcon, TrashIcon, GlobeAltIcon, PencilSquareIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { formattedDateMonthDDYYYY } from '@/utils/date.utils';
import { TOPIC_ICONS_MAP } from '@/constants/topic-icons.constants';
import { TOPIC_COLORS } from '@kleecks/code-lib';
import { WithTooltip, Badge } from '@kleecks/ui-lib';

interface DashboardCardProps {
  dashboard: Dashboard;
  brandName?: string;
  hideButtons?: boolean;
  hideWidgetsCounter?: boolean;
  onSendDashboardToEdit: (dashboard: Dashboard) => void;
}
const deleteAlertStore = useDeleteAlertStore();
const props = defineProps<DashboardCardProps>();

const { deleteDashboard, loading } = useDeleteDashboard();
const { user, isSuperAdmin } = useUser();

const isReadOnly = computed(() =>
  user.value ? isDashbordInReadOnlyMode(props.dashboard, user.value, isSuperAdmin.value) : true
);

const getBadgeSettings = computed(() => getDashboardBadge(props.dashboard, props.brandName));

// Manage Operations

const handelClickEdit = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  props.onSendDashboardToEdit(props.dashboard);
};

const handleClickDelete = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  deleteAlertStore.showDeleteAlert(`Are you sure to delete the dashboard ${props.dashboard.name} ?`, () =>
    deleteDashboard(props.dashboard.id)
  );
};

const router = useRouter();

const navigateToDashboard = () => {
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: props.dashboard.id } });
};
</script>

<template>
  <div
    class="relative rounded-lg border border-transparent shadow-[0_1px_3px_#00000029] transition duration-300 ease-in-out hover:border hover:border-kl-green-200"
    :class="[
      {
        'bg-gray-50': loading,
        'opacity-50': loading,
        'cursor-not-allowed': loading,
        'cursor-pointer bg-white': !loading,
      },
    ]"
    @click="navigateToDashboard">
    <div :class="['h-3 w-full rounded-t-md', TOPIC_COLORS[props.dashboard.topic!] ]" />

    <div class="flex flex-col gap-4 p-4">
      <div class="flex items-start gap-2">
        <div :class="['flex h-20 w-20 items-center justify-center rounded-md', TOPIC_COLORS[props.dashboard.topic!]]">
          <component
            :is="TOPIC_ICONS_MAP[props.dashboard.topic!]"
            class="h-12 w-12" />
        </div>

        <div class="h-24 min-w-0 flex-1">
          <div class="flex min-w-0 gap-4 self-start">
            <div class="min-w-0 flex-1 justify-start pl-2">
              <div class="mb-2 flex max-w-[75%] flex-wrap items-center gap-x-2 gap-y-1">
                <Badge
                  v-if="getBadgeSettings"
                  class="!ml-0 h-fit w-fit !bg-white"
                  :label="getBadgeSettings.label"
                  type="soft"
                  :color="getBadgeSettings.color" />
                <WithTooltip label="Private Dashboard for all your brands">
                  <GlobeAltIcon
                    v-if="getDashboardVisibility(props.dashboard) === 'global'"
                    class="size-5 text-kl-icon" />
                </WithTooltip>
              </div>
              <WithTooltip
                :label="props.dashboard.name"
                class="w-full">
                <h3 class="line-clamp-3 max-w-[75%] text-sm text-kl-gray-500">
                  {{ props.dashboard.name }}
                </h3>
              </WithTooltip>
              <WithTooltip
                v-if="props.dashboard.description"
                :label="props.dashboard.description">
                <p class="line-clamp-2 text-xs text-[#6B7280]">
                  {{ props.dashboard.description }}
                </p>
              </WithTooltip>
            </div>
            <div class="size-12 rounded border border-gray-secondary">
              <div class="flex h-[55%] items-center justify-center text-sm font-medium text-gray-700">
                {{ dashboard.data?.objects?.length || 0 }}
              </div>
              <div class="flex h-[45%] items-center justify-center bg-gray-secondary text-2xs text-kl-gray-500">
                Widgets
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex h-8 flex-col pt-2">
          <div
            v-if="dashboard?.createdAt"
            class="text-2xs text-secondary">
            Created on
            <span class="font-medium">{{ formattedDateMonthDDYYYY(dashboard?.createdAt?.toString()) }}</span>
          </div>

          <div
            v-if="dashboard?.createdBy"
            class="text-2xs text-secondary">
            Created by
            <span class="font-medium">{{ dashboard?.createdBy?.nome }} {{ dashboard?.createdBy?.cognome }}</span>
          </div>
        </div>
        <div
          v-if="!hideButtons"
          class="flex h-7 items-end justify-center gap-x-2">
          <WithTooltip
            v-if="!isReadOnly"
            label="Delete Dashboard">
            <TrashIcon
              class="size-5 text-kl-icon hover:text-kl-red-300"
              @click="e => handleClickDelete(e)" />
          </WithTooltip>
          <WithTooltip
            v-if="!isReadOnly"
            label="Edit Dashboard">
            <PencilSquareIcon
              class="size-5 text-kl-icon hover:text-kl-green-200"
              @click="e => handelClickEdit(e)" />
          </WithTooltip>
          <WithTooltip label="View dashboard">
            <ArrowTopRightOnSquareIcon
              class="size-5 text-kl-icon hover:text-kl-green-300"
              @click="navigateToDashboard" />
          </WithTooltip>
        </div>
      </div>
    </div>
  </div>
</template>
