import { useUserStore } from '@/stores/useUserStore';
import { COOKIES, getCookie } from '@kleecks/code-lib';
import type { MODULES } from '@/constants/modules.constants';
import { APP_VERSION, ENVIRONMENT } from '@/constants/settings.constants';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { setCookie } from '@kleecks/code-lib/src';
import { useRouteParam } from '../common/useRouteParam';
import { useInstance } from '../instance/useInstance';

export const useSandboxNavigate = () => {
  const brandId = useRouteParam('brandId');
  const instanceId = useRouteParam('instanceId');
  const { instance, instanceName } = useInstance(brandId, instanceId);
  const userStore = useUserStore();
  const { notify } = useNotificationStore();

  const navigateToSandbox = (
    obj: {
      url?: string;
      module?: MODULES;
      userLogin?: string;
      taskId?: string;
      optionalCookies?: {
        [key in (typeof COOKIES)[keyof typeof COOKIES]]?: string;
      };
    } = {}
  ) => {
    const { url, module, taskId, optionalCookies } = obj;
    const domainObj = instance.value?.domains?.filter(d => d.lang !== 'xx')?.[0];
    if (!domainObj) return;

    const domainUrl = domainObj.domain;
    if (!userStore.selectedTask && !taskId) {
      notify(DEFAULT_NOTIFICATIONS.NO_TASK_SELECTED);
      return;
    }

    const token = getCookie(COOKIES.TOKEN_KEY);

    const qpString = `&${COOKIES.MODULE}=${module || 'NONE'}&${COOKIES.IS_DEV_MODE}=${APP_VERSION === 'development'}`;

    const { host } = new URL(url || `https://${domainUrl}`);
    // Set cookie in the redirect url
    let redirectUrl = `${ENVIRONMENT !== 'production' ? 'http' : 'https'}://${host}/kleecks-services/autoLogin?url=${
      url || `https://${domainUrl}`
    }&encurl=${encodeURIComponent(url || `https://${domainUrl}`)}&expiration=${60 * 60}&sandboxId=${(
      taskId || userStore.selectedTask?.id
    )?.replaceAll('-', '_')}&${COOKIES.TOKEN_KEY}=${token}&${COOKIES.DOMAIN_ID}=${domainObj.id}&&${COOKIES.TASK_ID}=${
      taskId || userStore.selectedTask?.id
    }${qpString}`;

    if (brandId.value) {
      redirectUrl += `&${COOKIES.BRAND_ID}=${brandId.value}`;
    }

    if (instanceId.value) {
      redirectUrl += `&${COOKIES.INSTANCE_ID}=${instanceId.value}&${COOKIES.INSTANCE_NAME}=${instanceName.value}`;
    }

    if (obj.userLogin) {
      redirectUrl += `&${COOKIES.USER_LOGIN}=${obj.userLogin}`;
    }

    if (optionalCookies) {
      Object.entries(optionalCookies).forEach(([key, value]) => {
        redirectUrl += `&${key}=${value}`;
      });
    }

    redirectUrl += `&timestamp=${Date.now()}`;

    if (ENVIRONMENT !== 'production') {
      redirectUrl
        .split('?')[1]
        .split('&')
        .forEach(param => {
          const [key, value] = param.split('=');
          setCookie(key, value);
        });
    }

    console.debug('Redirecting to sandbox:', redirectUrl);
    window.open(redirectUrl, '_blank');
  };

  return { navigateToSandbox };
};
