import {
  EnableInstanceMutation,
  DisableInstanceMutation,
  UpdateInstanceMutation,
} from '@/services/graphql/instance.graphql';
import { InstanceFragment } from '@/services/graphql/fragments.graphql';
import type { Instance } from '@/types/instance.types';
import { useMutation } from '@vue/apollo-composable';

export const enableInstanceMutation = () =>
  useMutation<
    {
      enableInstance: boolean;
    },
    {
      instanceId: string;
    }
  >(EnableInstanceMutation, {
    update: (cache, { data }, { variables }) => {
      const instanceId = variables?.instanceId;
      if (data?.enableInstance && instanceId) {
        const instanceRef = cache.readFragment<Instance>({
          id: cache.identify({ instanceId, __typename: 'Instance' }),
          fragment: InstanceFragment,
          fragmentName: 'InstanceFragment',
        });

        if (instanceRef) {
          cache.writeFragment({
            id: cache.identify({ instanceId, __typename: 'Instance' }),
            fragment: InstanceFragment,
            fragmentName: 'InstanceFragment',
            data: {
              ...instanceRef,
              enabled: true,
            },
          });
        }
      }
    },
  });

export const disableInstanceMutation = () =>
  useMutation<
    {
      disableInstance: boolean;
    },
    {
      instanceId: string;
    }
  >(DisableInstanceMutation, {
    update: (cache, { data }, { variables }) => {
      const instanceId = variables?.instanceId;
      if (data?.disableInstance && instanceId) {
        const instanceRef = cache.readFragment<Instance>({
          id: cache.identify({ instanceId, __typename: 'Instance' }),
          fragment: InstanceFragment,
          fragmentName: 'InstanceFragment',
        });

        if (instanceRef) {
          cache.writeFragment({
            id: cache.identify({ instanceId, __typename: 'Instance' }),
            fragment: InstanceFragment,
            fragmentName: 'InstanceFragment',
            data: {
              ...instanceRef,
              enabled: false,
            },
          });
        }
      }
    },
  });

export const updateInstanceMutation = () =>
  useMutation<
    {
      updateInstance: any;
    },
    {
      instanceId: string;
      baseUrl: string;
      notes: string;
    }
  >(UpdateInstanceMutation);
