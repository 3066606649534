export const DEFAULT_NOTIFICATION_TIME = 3000;

export const SERVER_NOTIFICATION_TYPES = {
  GENERIC_NOTIFICATION: 0,
  BRAND_CREATED: 100,
  BRAND_UPDATED: 101,
  BRAND_DELETED: 102,
  DASHBOARD_CREATED: 110,
  DASHBOARD_UPDATED: 111,
  DASHBOARD_DELETED: 112,
  USER_CREATED: 120,
  USER_UPDATED: 121,
  USER_DELETED: 122,
  TASK_CREATED: 130,
  TASK_UPDATED: 131,
  TASK_DELETED: 132,
  CRAWL_STARTED: 133,
  TEAM_CREATED: 140,
  TEAM_UPDATED: 141,
  TEAM_DELETED: 142,
  IMAGE_UPLOADED: 150,
  FILE_UPLOADED: 160,
  MODULEPROPERTY_UPDATED: 170,
  TASK_ASSIGNED: 1300,
  TASK_IN_REVIEW: 1301,
  TASK_APPROVED: 1302,
  TASK_PUBLISHED: 1303,
  TASK_REJECTED: 1304,
} as const;

export const SERVER_NOTIFICATIONS_LABELS = {
  [SERVER_NOTIFICATION_TYPES.GENERIC_NOTIFICATION]: 'Generic notification',
  [SERVER_NOTIFICATION_TYPES.BRAND_CREATED]: 'Brand created',
  [SERVER_NOTIFICATION_TYPES.BRAND_UPDATED]: 'Brand updated',
  [SERVER_NOTIFICATION_TYPES.BRAND_DELETED]: 'Brand deleted',
  [SERVER_NOTIFICATION_TYPES.DASHBOARD_CREATED]: 'Dashboard created',
  [SERVER_NOTIFICATION_TYPES.DASHBOARD_UPDATED]: 'Dashboard updated',
  [SERVER_NOTIFICATION_TYPES.DASHBOARD_DELETED]: 'Dashboard deleted',
  [SERVER_NOTIFICATION_TYPES.USER_CREATED]: 'User created',
  [SERVER_NOTIFICATION_TYPES.USER_UPDATED]: 'User updated',
  [SERVER_NOTIFICATION_TYPES.USER_DELETED]: 'User deleted',
  [SERVER_NOTIFICATION_TYPES.TASK_CREATED]: 'Task created',
  [SERVER_NOTIFICATION_TYPES.TASK_UPDATED]: 'Task updated',
  [SERVER_NOTIFICATION_TYPES.TASK_DELETED]: 'Task deleted',
  [SERVER_NOTIFICATION_TYPES.TEAM_CREATED]: 'Team created',
  [SERVER_NOTIFICATION_TYPES.TEAM_UPDATED]: 'Team updated',
  [SERVER_NOTIFICATION_TYPES.TEAM_DELETED]: 'Team deleted',
  [SERVER_NOTIFICATION_TYPES.IMAGE_UPLOADED]: 'Image uploaded',
  [SERVER_NOTIFICATION_TYPES.FILE_UPLOADED]: 'File uploaded',
  [SERVER_NOTIFICATION_TYPES.MODULEPROPERTY_UPDATED]: 'Module property updated',
  [SERVER_NOTIFICATION_TYPES.TASK_ASSIGNED]: 'Task assigned',
  [SERVER_NOTIFICATION_TYPES.TASK_IN_REVIEW]: 'Task in review',
  [SERVER_NOTIFICATION_TYPES.TASK_APPROVED]: 'Task approved',
  [SERVER_NOTIFICATION_TYPES.TASK_PUBLISHED]: 'Task published',
  [SERVER_NOTIFICATION_TYPES.TASK_REJECTED]: 'Task rejected',
} as const;

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
} as const;

export const DEFAULT_NOTIFICATIONS = {
  CONNECTOR_DISCONNECTED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Connector disconnected!',
    message: `Brand succesfully disconnected from ${name}.`,
  }),
  CONNECTOR_CONNECTED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Connector connected!',
    message: `Brand succesfully connected to ${name}.`,
  }),
  ERROR: (message: string) => ({
    type: NOTIFICATION_TYPES.ERROR,
    title: 'Error!',
    message,
  }),
  DASHBOARD_MODULE_ALREADY_ADDED: {
    type: NOTIFICATION_TYPES.WARNING,
    title: 'Module already added!',
    message: 'The module you are trying to add is already in your dashboard.',
  },
  NO_TASK_SELECTED: {
    type: NOTIFICATION_TYPES.WARNING,
    title: 'No task selected!',
    message: "Please select a task to continue. If you don't have one contact the administrator",
  },
  TASK_DELETED: (title: string) => ({
    type: NOTIFICATION_TYPES.WARNING,
    title: 'Task deleted succesfully!',
    message: `The task ${title} was deleted succesfully.`,
  }),
  TASK_SENT_IN_REVIEW: (title: string) => ({
    type: NOTIFICATION_TYPES.INFO,
    title: 'Task sent in review!',
    message: `The task ${title} was sent in review succesfully.`,
  }),
  TASK_REOPENED: (title: string) => ({
    type: NOTIFICATION_TYPES.INFO,
    title: 'Task reopened!',
    message: `The task ${title} was reopened succesfully.`,
  }),
  TASK_CREATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: `Task ${name} created succesfully!`,
    message: `The task ${name} was created succesfully.`,
  }),
  TASK_PUBLISHED: {
    type: NOTIFICATION_TYPES.INFO,
    title: 'Task published succesfully!',
  },
  TASK_UPDATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Task updated succesfully!',
    message: `The task ${name} was updated succesfully.`,
  }),
  USER_UPDATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'User updated succesfully!',
    message: `The user ${name} was updated succesfully.`,
  }),
  ERROR_CREATE_USER: {
    type: NOTIFICATION_TYPES.ERROR,
    title: 'Error creating user!',
    message: 'There was an error creating the user. Please try again.',
  },
  USER_CREATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'User created succesfully!',
    message: `The user ${name} was created succesfully.`,
  }),
  USER_DELETED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'User deleted succesfully!',
    message: `The user ${name} was deleted succesfully.`,
  }),
  DASHBOARD_CREATED: {
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Dashboard created succesfully!',
  },
  DASHBOARD_DELETED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: `Dashboard deleted`,
    message: `The dashboard ${name} was deleted succesfully.`,
  }),
  DASHBOARD_MODULE_ADDED: {
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Module added succesfully!',
    message: 'You can now see it in your dashboard.',
  },
  DASHBOARD_ROW_ADDED: {
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Row added succesfully!',
    message: 'You can now see it in your dashboard.',
  },
  DASHBOARD_WIDGET_ADDED: {
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Widget added succesfully!',
    message: 'You can now see it in your dashboard.',
  },
  DASHBOARD_WIDGET_REMOVED: {
    type: NOTIFICATION_TYPES.INFO,
    title: 'Widget removed succesfully!',
    message: 'Now is no longer visible in your dashboard.',
  },
  DASHBOARD_MODULE_REMOVED: {
    type: NOTIFICATION_TYPES.INFO,
    title: 'Module removed succesfully!',
    message: 'Now is no longer visible in your dashboard.',
  },
  DASHBOARD_COL_DELETED: {
    type: NOTIFICATION_TYPES.INFO,
    title: 'Column removed succesfully!',
    message: 'Now is no longer visible in your dashboard.',
  },
  TEAM_CREATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Team created succesfully!',
    message: `The team ${name} was created succesfully.`,
  }),
  TEAM_DELETED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Team deleted succesfully!',
    message: `The team ${name} was deleted succesfully.`,
  }),
  BRAND_UPDATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Brand updated succesfully!',
    message: `The brand ${name} was updated succesfully.`,
  }),
  BRAND_CREATED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Brand created succesfully!',
    message: `The brand ${name} was created succesfully.`,
  }),
  BRAND_DELETED: (name: string) => ({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Brand deleted succesfully!',
    message: `The brand ${name} was deleted succesfully.`,
  }),
  GENERATE_ERROR_AI_CONTENT: {
    type: NOTIFICATION_TYPES.ERROR,
    title: 'Error generating AI content!',
    message: 'There was an error generating the AI content. Please try again.',
  },
  GENERATE_SUCCESS_AI_CONTENT: {
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'AI content generated succesfully!',
    message: 'The AI content was generated succesfully.',
  },
} as const;
