<script setup lang="ts">
import { useUser } from '@/hooks/user/useUser';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { WithTooltip } from '@kleecks/ui-lib';

interface ModuleAiContainerProps {
  moduleName: string;
  moduleDescription: string;
  onAddModuleToDashboard?: () => void;
  isAddButtonEnabled: boolean;
}

const { user } = useUser();

const props = defineProps<ModuleAiContainerProps>();
</script>

<template>
  <div class="w-full rounded-md bg-white p-4 shadow-custom">
    <div class="flex items-start">
      <div class="flex-1">
        <span class="font-medium text-primary">{{ props.moduleName }}</span
        ><br />
        <span class="max-w-[90%] text-sm text-kl-gray-400">{{ props.moduleDescription }}</span>
      </div>
      <div class="flex items-center gap-4">
        <WithTooltip label="Add to dashboard">
          <PlusCircleIcon
            v-if="user?.admin && props.isAddButtonEnabled"
            class="h-5 w-5 cursor-pointer text-secondary hover:text-active"
            @click="props.onAddModuleToDashboard" />
        </WithTooltip>
      </div>
    </div>
    <div class="relative flex min-h-72 flex-col pt-8">
      <slot />
    </div>
  </div>
</template>
