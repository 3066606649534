import {
  createOrUpdateModulesDictionaryMutation,
  modulesDictionaryQuery,
} from '@/services/queries/module-dictionary.query';
import { getLabelFromDictionary } from '@kleecks/code-lib';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';

export const useModulesDictionary = (lang?: MaybeRef<string>) => {
  const query = modulesDictionaryQuery(lang || 'en');
  const mutation = createOrUpdateModulesDictionaryMutation();
  const dictionary = computed(() => query.result.value?.modulesDictionary || {});

  const updateDictionary = async (data: any) => {
    await mutation.mutate({
      lang: unref(lang)!,
      data,
    });
  };

  const getLabel = (key: string, hideFallback?: boolean) => getLabelFromDictionary(dictionary.value, key, hideFallback);
  return {
    dictionary,
    getLabel,
    updateDictionary,
  };
};
