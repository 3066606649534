<script setup lang="ts">
import * as Yup from 'yup';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useTeams } from '@/hooks/team/useTeams';
import { useCreateUser } from '@/hooks/user/useCreateUser';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { BaseTextInput, BaseToggle, Button, Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, watch } from 'vue';
import { ValidationError } from 'yup';
import { useUsers } from '@/hooks/user/useUsers';

interface CreateUserDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const props = defineProps<CreateUserDialogProps>();
const { teams } = useTeams();
const { notify } = useNotificationStore();

const { users } = useUsers();

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  team: Yup.string().required('Team is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const errors = ref<Record<string, string>>({});

const teamsOptions = computed(() =>
  teams.value.map(t => ({
    label: t.name,
    value: t.id,
  }))
);

const userValues = ref<{
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  team: string;
  email: string;
  password: string;
}>({
  isAdmin: false,
  firstName: '',
  lastName: '',
  team: '',
  email: '',
  password: '',
});

const doesUserExist = computed(() => users.value.some(u => u.login === userValues.value.email));

watch(
  () => props.isOpen,
  () => {
    if (!props.isOpen) return;
    errors.value = {};
    userValues.value = {
      isAdmin: false,
      firstName: '',
      lastName: '',
      team: '',
      email: '',
      password: '',
    };
  }
);

const { mutate: createUser, loading: isUserCreating } = useCreateUser();

const handleClose = () => {
  userValues.value = {
    isAdmin: false,
    firstName: '',
    lastName: '',
    team: '',
    email: '',
    password: '',
  };
  props.onClose();
};

const validateForm = async () => {
  try {
    await schema.validate(userValues.value, { abortEarly: false });
    errors.value = {};
    return true;
  } catch (err) {
    if (err instanceof ValidationError) {
      errors.value = err.inner.reduce<{
        [key: string]: string;
      }>((acc, error) => {
        if (error.path) acc[error.path] = error.message;
        return acc;
      }, {});
    }
  }

  return false;
};

const handleCreateUser = async () => {
  if (!(await validateForm())) return;

  const payload = {
    login: userValues.value.email,
    nome: userValues.value.firstName,
    cognome: userValues.value.lastName,
    admin: userValues.value.isAdmin,
    password: userValues.value.password,
    teamId: userValues.value.team,
  };
  const res = await createUser({
    user: payload,
  });

  if (res?.data?.createUser?.id) {
    notify(DEFAULT_NOTIFICATIONS.USER_CREATED(`${userValues.value.firstName} ${userValues.value.lastName}`));

    userValues.value = {
      isAdmin: false,
      firstName: '',
      lastName: '',
      team: '',
      email: '',
      password: '',
    };

    props.onConfirm();
  } else {
    notify(DEFAULT_NOTIFICATIONS.ERROR_CREATE_USER);
  }
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-enter-key-press="handleCreateUser"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      title="Add user"
      sub-title="Add a new user into the Rainbow">
      <form>
        <div class="flex w-[34rem] gap-4">
          <div class="flex flex-1 flex-col gap-4">
            <BaseTextInput
              id="user_first_name"
              v-model="userValues.firstName"
              :status="{
                valid: !errors.firstName,
                message: errors.firstName,
              }"
              placeholder="First name"
              label="First name*"
              name="user_first_name" />

            <BaseTextInput
              id="user_last_name"
              v-model="userValues.lastName"
              :status="{
                valid: !errors.lastName,
                message: errors.lastName,
              }"
              placeholder="Last name"
              label="Last name*"
              name="user_last_name" />

            <Combobox
              id="user_team"
              v-model="userValues.team"
              label="Team*"
              placeholder="Select a team"
              :status="{
                valid: !errors.team,
                message: errors.team,
              }"
              name="user_team"
              :items="teamsOptions" />
          </div>
          <div class="flex flex-1 flex-col gap-4 border-l border-sec pl-4">
            <BaseTextInput
              id="user_email"
              v-model="userValues.email"
              type="email"
              label="Email*"
              placeholder="name@example.com"
              :status="{
                valid: !doesUserExist && !errors.email,
                message: doesUserExist ? 'This email is already used' : errors.email,
              }"
              name="user_email" />
            <BaseTextInput
              id="user_password"
              v-model="userValues.password"
              label="Password*"
              type="password"
              :status="{
                valid: !errors.password,
                message: errors.password,
              }"
              autocomplete="new-password"
              name="user_password" />
            <BaseToggle
              v-model="userValues.isAdmin"
              label="Admin" />
          </div>
        </div>
        <div class="mt-8 flex justify-end">
          <Button
            variant="primary"
            size="sm"
            :is-loading="isUserCreating"
            :disabled="isUserCreating || doesUserExist"
            @click="handleCreateUser">
            Create
          </Button>
        </div>
      </form>
    </Modal>
  </DialogContainer>
</template>
