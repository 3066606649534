<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useUpdateBrand } from '@/hooks/brand/useUpdateBrand';
import { useDisableInstance } from '@/hooks/instance/useDisableInstance';
import { useEnableInstance } from '@/hooks/instance/useEnableInstance';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useServerInstances } from '@/hooks/instance/useServerInstances';
import type { Brand } from '@/types/brand.types';
import { BaseToggle, Button, DialogContainer, Modal, Combobox, BaseTextInput } from '@kleecks/ui-lib';
import { computed, ref, toRef, watch } from 'vue';
import * as yup from 'yup';
import { INSTANCE_MAP } from '@/constants/instance.constants';
import { useUpdateInstance } from '@/hooks/instance/useUpdateInstance';
import { useBrand } from '@/hooks/brand/useBrand';

interface EditBrandDialogProps {
  isOpen: boolean;
  onClose: () => void;
  brand: Brand;
  environment?: string;
}

const props = defineProps<EditBrandDialogProps>();
const { mutate: updateBrand } = useUpdateBrand();
const { notify } = useNotificationStore();
const { enableInstance } = useEnableInstance();
const { disableInstance } = useDisableInstance();
const { updateInstance } = useUpdateInstance();
const { refetch: refetchBrands } = useBrands();
const brandIdRef = toRef(() => props.brand.id);
const { refetch: refetchBrand } = useBrand(brandIdRef);
const { instances } = useServerInstances();

const isSaving = ref(false);
const yupSchema = yup.object().shape({
  environment: yup.string().nullable(),
  instance: yup.string().nullable(),
  activeInstance: yup.string().nullable(),
  instanceUrl: yup.string().url('Instance url must be a valid url (e.g. https://example.com)'),
});

const { validateSchema, errors } = useSchemaValidation(yupSchema);

interface EnvData {
  value: string;
  label: string;
}
const availableEnv = computed(() => {
  if (props.environment) {
    return [
      {
        value: props.environment,
        label: INSTANCE_MAP[props.environment as keyof typeof INSTANCE_MAP],
      },
    ];
  }
  return Object.keys(INSTANCE_MAP).reduce((acc, key) => {
    if (!props.brand?.instances?.[key as keyof typeof INSTANCE_MAP]) {
      acc.push({ value: key, label: INSTANCE_MAP[key as keyof typeof INSTANCE_MAP] });
    }
    return acc;
  }, [] as EnvData[]);
});

const projectValues = ref<{
  environment: string;
  instance: string;
  activeInstance: boolean;
  instanceUrl?: string;
}>({
  environment: props.environment || '',
  instance: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.instanceId || '',
  activeInstance: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.enabled || false,
  instanceUrl: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.baseUrl,
});

const handleClose = () => {
  props.onClose();
};

const isSaveEnabled = computed(() => projectValues.value.environment! && projectValues.value.instance!);

const handleSaveBrand = async () => {
  try {
    isSaving.value = true;
    if (!(await validateSchema(projectValues.value))) return;

    await updateBrand({
      brand: {
        name: props.brand.name,
        label: props.brand.label,
        instances: {
          [projectValues.value.environment?.toUpperCase()]: projectValues.value.instance,
        },
      },
    });
    if (
      projectValues.value.activeInstance !==
      props.brand.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.enabled
    ) {
      if (projectValues.value.activeInstance) {
        await enableInstance(projectValues.value.instance!);
      } else {
        await disableInstance(projectValues.value.instance!);
      }
    }

    if (
      projectValues.value.instanceUrl &&
      projectValues.value.instanceUrl !==
        props.brand.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.baseUrl
    ) {
      await updateInstance(projectValues.value.instance, projectValues.value.instanceUrl, '');
    }

    await refetchBrand();
    await refetchBrands();
    notify(DEFAULT_NOTIFICATIONS.BRAND_UPDATED(props.brand.name!));
    handleClose();
  } finally {
    isSaving.value = false;
  }
};

watch(
  () => props.isOpen,
  () => {
    if (!props.isOpen) return;
    projectValues.value = {
      instanceUrl: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.baseUrl,
      environment: props.environment || '',
      instance: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.instanceId || '',
      activeInstance: props.brand?.instances?.[props.environment as keyof typeof INSTANCE_MAP]?.enabled || false,
    };
  }
);
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-enter-key-press="handleSaveBrand"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      :title="`${!environment ? 'Create' : 'Edit'} Project`"
      :sub-title="`Edit the brand ${props.brand?.name}`">
      <div class="flex w-[320px] flex-col gap-4">
        <Combobox
          id="environment"
          v-model="projectValues.environment"
          label="Environment*"
          :items="availableEnv"
          placeholder="Select a Environment"
          :status="{
            message: errors.environment,
            valid: !errors.environment,
          }" />
        <Combobox
          id="instances"
          v-model="projectValues.instance"
          label="Instance ID*"
          :items="instances"
          placeholder="Add instance ID"
          :status="{
            message: errors.instance,
            valid: !errors.instance,
          }" />
        <BaseTextInput
          id="instanceUrl"
          v-model="projectValues.instanceUrl"
          label="Instance Url"
          placeholder="Add instance Url"
          :status="{
            message: errors.instanceUrl,
            valid: !errors.instanceUrl,
          }" />
        <div class="flex items-center justify-between">
          <BaseToggle
            v-model="projectValues.activeInstance"
            label="Set as active" />
          <Button
            variant="primary"
            :is-loading="isSaving"
            :disabled="!isSaveEnabled"
            @click="handleSaveBrand">
            {{ !environment ? 'Create' : 'Save' }}
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
