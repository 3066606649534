<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useInstances } from '@/hooks/instance/useInstances';
import { usePublishTask } from '@/services/queries/task.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Task } from '@/types/task.types';
import { BaseTextInput, Button, DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref, toRefs } from 'vue';

interface PublishTaskDialogProps {
  isOpen: boolean;
  brandId?: string;
  task: Task;
  onClose: () => void;
}
const props = defineProps<PublishTaskDialogProps>();

const { mutate: publishTask, loading } = usePublishTask();
const { notify } = useNotificationStore();
const brandId = ref(props.brandId);
const { instancesNames } = useInstances(brandId);

const { title, brand } = toRefs(props.task);

const handlePublishTask = async () => {
  await publishTask({ publishTaskId: props.task.id, instanceId: props.task.instance?.instanceId! });
  notify(DEFAULT_NOTIFICATIONS.TASK_PUBLISHED);
  props.onClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="props.onClose">
    <Modal
      :on-close="props.onClose"
      class="w-96"
      title="Publish to"
      :sub-title="`Publish the task into the instance ${
        instancesNames[props.task.instance?.instanceId || ''] || props.task.instance?.instanceId
      } of ${props.task.brand.label}`">
      <div class="flex flex-col gap-4">
        <BaseTextInput
          id="task_name"
          v-model="title"
          type="text"
          label="Task Name*"
          name="task_name"
          :disabled="true" />

        <BaseTextInput
          id="task_brand"
          v-model="brand.label"
          label="Brand*"
          type="text"
          name="task_brand"
          :disabled="true" />

        <BaseTextInput
          id="task_instance"
          v-model="instancesNames[task.instance?.instanceId!]"
          type="text"
          label="Project*"
          name="task_instance"
          :disabled="true" />

        <Button
          class="mt-4 self-end"
          type="button"
          variant="primary"
          :disabled="loading"
          :is-loading="loading"
          @click="handlePublishTask">
          Publish
        </Button>
      </div>
    </Modal>
  </DialogContainer>
</template>
