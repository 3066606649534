<script setup lang="ts">
import BrandDetailsDrawer from '@/components/drawers/brand-details/BrandDetailsDrawer.vue';
import BrandDetailDashboardHeader from '@/components/headers/brand-detail-dashboard-header/BrandDetailDashboardHeader.vue';
import ProjectCard from '@/components/projects/project-card/ProjectCard.vue';
import BrandDetailsSkeletonLoader from '@/components/skeletons/BrandDetailsSkeletonLoader.vue';
import InstancesSkeletonLoader from '@/components/skeletons/InstancesSkeletonLoader.vue';
// import InstancesTable from '@/components/tables/instances-table/InstancesTable.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useBrand } from '@/hooks/brand/useBrand';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstances } from '@/hooks/instance/useInstances';
import { StandardContainer, Tabs } from '@kleecks/ui-lib';
import { useRouter } from 'vue-router';

import { computed, ref } from 'vue';
import ConnectorsList from '@/components/connectors/ConnectorsList.vue';
import { useUser } from '@/hooks/user/useUser';
import CreateProjectDialog from '@/components/dialogs/create-project/CreateProjectDialog.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/solid';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';
import { useChatbotStore } from '@/stores/useChatbotStore';
import NotFound from '../not-found/NotFound.vue';

const chatbotStore = useChatbotStore();
const router = useRouter();
const brandId = useRouteParam('brandId');
const { instances, instancesNames, instancesEnable } = useInstances(brandId);
const { brand, loading } = useBrand(brandId);
const { getLabel } = useApplicationLabels();

const currentTabId = ref(router.currentRoute.value.query.tab?.toString() || 'projects');
const { user, isSuperAdmin } = useUser();
const openCreateProject = ref(false);
const environmentSelected = ref('');
const tabs = computed(() => {
  if (user.value?.admin) {
    return [
      { name: 'Projects', id: 'projects' },
      { name: 'Connectors', id: 'connectors' },
    ];
  }

  return [];
});

const closeProjectDialog = () => {
  openCreateProject.value = false;
  environmentSelected.value = '';
};

const openCreateProjectDialog = () => {
  openCreateProject.value = true;
  environmentSelected.value = '';
};

const openEditProjectDialog = (env: string) => {
  openCreateProject.value = true;
  environmentSelected.value = env;
};

const dashboardHeaderCTA = computed(() => {
  if (!isSuperAdmin.value) {
    return undefined;
  }

  if (!brand.value?.instances?.preprod || !brand.value?.instances?.prod || !brand.value?.instances?.staging) {
    return {
      onClick: openCreateProjectDialog,
      label: 'New Project',
    };
  }
  return undefined;
});

const handleSelectInstance = (instanceId: string) => {
  chatbotStore.resetChatbot();
  router.push({
    name: ROUTE_NAMES.INSTANCE,
    params: {
      brandId: brandId.value,
      instanceId,
    },
    query: {
      panel: 'overview',
    },
  });
};
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('BRAND_DASHBOARD.hero.help')"
    modal-title="Brand dashboard Info" />
  <CreateProjectDialog
    v-if="brand"
    :is-open="openCreateProject"
    :on-close="closeProjectDialog"
    :brand="brand"
    :environment="environmentSelected" />
  <NotFound v-if="!loading && !brand" />
  <div
    v-else
    class="dashboard-gradient flex flex-1 flex-col">
    <StandardContainer class="flex flex-1 flex-col">
      <div class="flex flex-1 items-start">
        <div class="flex flex-1 flex-col self-stretch border-r border-gray-200 bg-sec pr-4">
          <div class="flex min-h-0 grow flex-col gap-8 py-4">
            <BrandDetailDashboardHeader
              :show-skeleton="loading"
              :name="brand?.label || ''"
              :url="brand?.mainUrl || ''"
              :cta="dashboardHeaderCTA" />

            <div class="relative flex min-h-0 flex-col gap-8">
              <Tabs
                v-if="tabs?.length"
                :currentTabId="currentTabId"
                :tabs="tabs"
                :on-change="tabId => (currentTabId = tabId)" />
              <div
                v-if="loading"
                class="w-full">
                <InstancesSkeletonLoader />
              </div>

              <div
                class="min-h-0 overflow-y-auto pb-8 transition-all duration-500"
                :class="{
                  'opacity-0': loading,
                  'opacity-100': !loading,
                }">
                <div
                  v-if="currentTabId === 'projects'"
                  class="absolute flex flex-wrap gap-8">
                  <div
                    v-if="instances.length === 0"
                    class="flex h-64 w-[400px] flex-col items-center justify-center gap-3 rounded border border-dashed border-kl-violet-100 bg-kl-violet-100/10"
                    @click="openCreateProjectDialog">
                    <div class="text-center text-sm text-kl-violet-200">
                      No Projects yet available.<br />Please,
                      <span class="cursor-pointer underline">create a Project.</span>
                    </div>
                    <PlusCircleIcon class="size-8 cursor-pointer text-kl-violet-100" />
                  </div>
                  <ProjectCard
                    v-for="instance in instances"
                    v-else
                    :key="instance?.instanceId"
                    tabindex="0"
                    :data-cy="`project-cards-${instance?.instanceId}`"
                    :brand-id="brandId"
                    :show-edit="isSuperAdmin"
                    :show-delete="isSuperAdmin"
                    :show-id="isSuperAdmin"
                    :name="instancesNames[instance?.instanceId]"
                    :disabled="!instancesEnable[instance?.instanceId]"
                    :brandName="brand?.label || brand?.name || ''"
                    :crawls-count="0"
                    :instance-id="instance?.instanceId"
                    crawl-status="In Progress"
                    :url="instance.baseUrl ?? ''"
                    :aggregated-tasks-count="instance?.taskAggregatedCount"
                    :openEditProjectDialog="openEditProjectDialog"
                    @keydown.enter="
                      () => {
                        if (instancesEnable[instance?.instanceId]) {
                          handleSelectInstance(instance?.instanceId);
                        }
                      }
                    "
                    @click="
                      () => {
                        if (instancesEnable[instance?.instanceId]) {
                          handleSelectInstance(instance?.instanceId);
                        }
                      }
                    " />
                </div>

                <ConnectorsList v-if="currentTabId === 'connectors'" />
              </div>
            </div>
          </div>
        </div>

        <div class="sticky top-0 w-96 items-start self-stretch">
          <div
            v-if="loading"
            class="w-full">
            <BrandDetailsSkeletonLoader />
          </div>

          <transition
            enter-from-class="opacity-0"
            enter-active-class="transition duration-1000">
            <BrandDetailsDrawer
              v-if="brand && !loading"
              :brand="brand" />
          </transition>
        </div>
      </div>
    </StandardContainer>
  </div>
</template>
