import type { KeyToEdit } from '../modules-dictionary/modules-dictionary.constants';

export const APPLICATION_DICTIONARY_SECTIONS = {
  BRANDS_PAGE: 'Brands Page',
  BRAND_DASHBOARD: 'Brand Dashboard',
  MODULES_LIBRARY: 'Modules Library',
  DASHBOARDS_LIBRARY: 'Dashboard Library',
  INSTANCE_DASHBOARD: 'Instance Dashboard',
  TASKS_MANAGEMENT: 'Tasks Management',
  SIDE_PANEL: 'Side Panel',
} as const;

export type ApplicationDictionarySections = keyof typeof APPLICATION_DICTIONARY_SECTIONS;

const SIDE_PANEL_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'introduction',
    fields: ['html'],
  },
];
const HOMEPAGE_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'hero',
    fields: ['title', 'subtitle', 'cta', 'help'],
  },
] as const;

const BRAND_DASHBOARD_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'hero',
    fields: ['help'],
  },
] as const;

const MODULES_LIBRARY_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'hero',
    fields: ['help'],
  },
] as const;

const DASHBOARDS_LIBRARY_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'hero',
    fields: ['help'],
  },
] as const;

const INSTANCE_DASHBOARD_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'help',
    fields: ['text'],
  },
] as const;

const TASKS_MANAGEMENT_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'help',
    fields: ['text'],
  },
] as const;

export const APPLICATION_FIELDS_MAPPING: { [key in ApplicationDictionarySections]?: KeyToEdit[] } = {
  BRANDS_PAGE: HOMEPAGE_FIELDS_TO_EDIT,
  BRAND_DASHBOARD: BRAND_DASHBOARD_FIELDS_TO_EDIT,
  MODULES_LIBRARY: MODULES_LIBRARY_FIELDS_TO_EDIT,
  DASHBOARDS_LIBRARY: DASHBOARDS_LIBRARY_FIELDS_TO_EDIT,
  INSTANCE_DASHBOARD: INSTANCE_DASHBOARD_FIELDS_TO_EDIT,
  TASKS_MANAGEMENT: TASKS_MANAGEMENT_FIELDS_TO_EDIT,
  SIDE_PANEL: SIDE_PANEL_FIELDS_TO_EDIT,
} as const;

export type AvailableApplicationLabels = (typeof APPLICATION_FIELDS_MAPPING)[ApplicationDictionarySections];
