import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useBrand } from '../brand/useBrand';
import { useRouteParam } from '../common/useRouteParam';
import { useDashboard } from '../dashboard/useDashboard';

export const useRainbowTitle = () => {
  const router = useRouter();
  const brandId = useRouteParam('brandId');
  const dashboardId = useRouteParam('dashboardId');
  const { brand } = useBrand(brandId);
  const { dashboard } = useDashboard(dashboardId, brandId);

  const createTitleWithPipe = (title: string | undefined) => {
    if (!title) return '';
    return `${title} | `;
  };
  const title = computed(() => {
    const prefix = 'Kleecks Rainbow';
    const routeName = router.currentRoute.value?.name;

    if (routeName === ROUTE_NAMES.BRANDS) {
      return `Brands List | ${prefix}`;
    }

    if (routeName === ROUTE_NAMES.BRAND) {
      return `${createTitleWithPipe(brand.value?.label)}${prefix}`;
    }

    if (routeName === ROUTE_NAMES.INSTANCE) {
      return `${createTitleWithPipe(brand.value?.label)}${prefix}`;
    }

    if (routeName === ROUTE_NAMES.DASHBOARD) {
      return `${createTitleWithPipe(dashboard.value?.name)}${prefix}`;
    }

    if (routeName === ROUTE_NAMES.TASKS_MANAGEMENT) {
      return `Tasks Management | ${prefix}`;
    }
    return '';
  });

  watch(title, newTitle => {
    if (!newTitle?.length) {
      document.title = 'Kleecks Rainbow';
    } else document.title = `${newTitle}`;
  });
};
