import { DEFAULT_USERS_PER_PAGE } from '@/constants/user.constants';
import {
  CreateUserMutation,
  GetUser,
  GetUsers,
  UpdateUserMutation,
  UserUpdatedSubscription,
  UserCreatedSubscription,
  DeleteUserMutation,
} from '@/services/graphql/user.graphql';
import type { IpAddressUser } from '@/types/ip-address-user.types';
import type { ModulePermission } from '@/types/permissions.types';
import type { User } from '@/types/user.types';
import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable';
import type { Ref } from 'vue';

interface GetUsersPayload {
  take: number;
  skip?: number;
  where?: {
    row?: {
      contains: string;
    };
  };
}

export interface UserCreateInput {
  login: string;
  nome: string;
  cognome: string;
  teamId: string;
  admin: boolean;
  password: string;
}

export type UserUpdateInput = Partial<{
  login: string;
  nome: string;
  cognome: string;
  teamId: string;
  admin: boolean;
  password: string;
  acceptanceClauses: boolean;
  acceptanceClausesIp: string;
  follows: string[];
  permissions: ModulePermission[];
  restrictionsIP: Partial<IpAddressUser[]>;
}>;

export const getUserQuery = () =>
  useQuery<{
    user: User;
  }>(GetUser);

export const getAllUsersQuery = () =>
  useQuery<{
    users: User[];
  }>(
    GetUsers,
    {},
    {
      errorPolicy: 'all',
    }
  );

export const getAllUsersPaginatedQuery = (search: Ref<string | undefined>) =>
  useQuery<
    {
      users: User[];
    },
    GetUsersPayload
  >(GetUsers, () => ({
    take: DEFAULT_USERS_PER_PAGE,
    skip: 0,
    ...(search?.value
      ? {
          where: {
            row: {
              contains: search.value,
            },
          },
        }
      : {}),
  }));

export const createUserMutation = () =>
  useMutation<
    {
      createUser: User;
    },
    { user: UserCreateInput }
  >(CreateUserMutation, {
    update: (cache, { data }) => {
      if (!data?.createUser) return;
      const { users } = cache.readQuery<{ users: User[] }>({ query: GetUsers }) || { users: [] };
      cache.writeQuery({
        query: GetUsers,
        data: {
          users: [...users, data?.createUser],
        },
      });
    },
  });

export const deleteUserMutation = () =>
  useMutation<
    {
      deleteUser: Partial<User>;
    },
    { login: string }
  >(DeleteUserMutation, {
    update: (cache, { data }) => {
      const id = cache.identify({
        __typename: 'User',
        id: data?.deleteUser.id,
      });
      cache.evict({ id });
    },
  });
export const updateUserMutation = () =>
  useMutation<
    { user: User },
    {
      user: UserUpdateInput;
      login: string;
    }
  >(UpdateUserMutation);

export const userUpdatedSubscription = () =>
  useSubscription<{
    UserUpdated: User;
  }>(UserUpdatedSubscription);

export const userCreatedSubscription = () =>
  useSubscription<{
    UserCreated: User;
  }>(UserCreatedSubscription);
