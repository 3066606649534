<script setup lang="ts">
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import type { ExternalModules, MODULES } from '@/constants/modules.constants';
import { MODULES_LAZY_MAP } from '@/constants/modules.constants';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';

import { useNotificationStore } from '@/stores/useNotificationStore';
import { ArrowDownOnSquareIcon, WalletIcon } from '@heroicons/vue/24/outline';
import { Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref } from 'vue';
import { useApplicationDictionary } from '@/hooks/application-dictionary/useApplicationDictionary';
import DictionaryForm from './DictionaryForm.vue';
import type { ApplicationDictionarySections } from './application-dictionary.constants';
import { APPLICATION_DICTIONARY_SECTIONS, APPLICATION_FIELDS_MAPPING } from './application-dictionary.constants';

const language = ref('en');
const { dictionary, updateDictionary } = useApplicationDictionary(language);

const notificationStore = useNotificationStore();
const selectedSection = ref<ApplicationDictionarySections>();
const showPreview = ref(false);
const keyIdForPreview = ref();
const moduleToEdit = ref();

const handleSelectSection = (section: ApplicationDictionarySections) => {
  if (selectedSection.value === section) {
    selectedSection.value = undefined;
    return;
  }
  moduleToEdit.value = JSON.parse(JSON.stringify(dictionary.value?.[section] || {}));
  const keysToEdit = APPLICATION_FIELDS_MAPPING[section];

  keysToEdit?.forEach(x => {
    if (!moduleToEdit.value[x.id]) {
      moduleToEdit.value[x.id] = {};
    }
    x.fields.forEach(f => {
      if (typeof f !== 'string') {
        f.fields.forEach(subF => {
          if (!moduleToEdit.value[x.id][f.id]) {
            moduleToEdit.value[x.id][f.id] = {};
          }
          if (!moduleToEdit.value[x.id][f.id][subF]) {
            moduleToEdit.value[x.id][f.id][subF] = '';
          }
        });
      }
    });
  });
  selectedSection.value = section;
};

const saveApplicationDictionary = async (section: ApplicationDictionarySections) => {
  await updateDictionary({
    ...(dictionary.value || {}),
    [section]: moduleToEdit.value,
  });

  notificationStore.notify({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Dictionary updated successfully',
  });
};

const downloadDictionaryJson = () => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(dictionary.value))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', 'dictionary.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const handleOpenKeyPreview = (keyId: string) => {
  keyIdForPreview.value = keyId;
  showPreview.value = true;
};
</script>

<template>
  <DialogContainer
    v-if="showPreview"
    :on-close="() => (showPreview = false)"
    :is-open="!!showPreview">
    <Modal
      title="Module preview"
      :on-close="() => (showPreview = false)"
      class="w-[1200px]">
      <div class="relative flex min-h-56 flex-col">
        <component
          :is="MODULES_LAZY_MAP[selectedSection as ExternalModules]"
          :highlightedKeysId="keyIdForPreview"
          :isReadOnly="true"
          :modules-dictionary="{
          ...dictionary,
          [selectedSection as MODULES]: moduleToEdit,
        }" />
      </div>
    </Modal>
  </DialogContainer>
  <div class="flex min-h-0 flex-1 flex-col pt-8">
    <DashboardHeader
      title="Application dictionary"
      sub-title="Review and edit the labels of the modules" />

    <div class="w-96 pb-4">
      <Combobox
        v-model="language"
        label="Language"
        class="bg-white"
        :items="[
          { label: 'English', value: 'en' },
          { label: 'Spanish', value: 'es' },
          { label: 'Italian', value: 'it' },
        ]" />
    </div>
    <div class="flex min-h-0 flex-1 flex-col gap-8 overflow-visible lg:flex-row lg:items-stretch">
      <div class="flex w-full flex-col self-stretch rounded-md border-sec bg-white shadow-custom lg:w-96">
        <div class="flex w-full items-center gap-2 border-b border-kl-gray-200 p-4">
          <WalletIcon class="size-6 text-kl-icon" />
          <span class="flex-1 font-medium text-primary">Sections</span>
          <div
            class="flex cursor-pointer items-center gap-2 text-primary underline hover:text-active"
            @click="downloadDictionaryJson">
            Download
            <ArrowDownOnSquareIcon class="size-5" />
          </div>
        </div>
        <div class="mb-1 flex min-h-0 grow flex-col divide-y divide-kl-gray-200 overflow-y-auto">
          <div
            v-for="section in (Object.keys(APPLICATION_DICTIONARY_SECTIONS) as ApplicationDictionarySections[])"
            :key="section"
            class="flex cursor-pointer items-center gap-4 px-4 py-2 text-primary hover:bg-kl-gray-100"
            :class="{ 'bg-kl-gray-200 hover:bg-kl-gray-200': selectedSection === section }"
            @click="handleSelectSection(section)">
            {{ APPLICATION_DICTIONARY_SECTIONS[section] }}
          </div>
        </div>
      </div>

      <div
        v-if="selectedSection"
        class="flex min-h-0 flex-1 flex-col self-stretch rounded-md border-sec bg-white pb-2 shadow-custom">
        <div class="flex w-full items-center gap-2 border-b border-kl-gray-200 p-4">
          {{ APPLICATION_DICTIONARY_SECTIONS[selectedSection] }}
        </div>
        <div class="flex min-h-0 flex-1 flex-col p-4">
          <DictionaryForm
            v-if="APPLICATION_FIELDS_MAPPING[selectedSection]"
            v-model="moduleToEdit"
            hide-preview-button
            :open-key-preview="handleOpenKeyPreview"
            :on-save="data => saveApplicationDictionary(selectedSection!)"
            :on-close="() => (selectedSection = undefined)"
            :keys-to-edit="APPLICATION_FIELDS_MAPPING[selectedSection]!" />

          <div v-else>No schema configured, please contact the support.</div>
        </div>
      </div>
    </div>
  </div>
</template>
