/* eslint-disable no-console */
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import {
  taskCreatedSubscription,
  taskDeletedSubscription,
  taskUpdatedSubscription,
} from '@/services/queries/task.query';
import { userCreatedSubscription, userUpdatedSubscription } from '@/services/queries/user.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { addNewNotificationToCache, addNewTaskToCache, deleteTaskFromCache } from '@/utils/cache.utils';
import { useApolloClient } from '@vue/apollo-composable';
import { notificationCreatedSubscription } from '@/services/queries/notification.query';
import { notificationEventCreatedSubscription } from '@/services/queries/subscriptions.query';
import { useRouteParam } from '../common/useRouteParam';
import { useBrandWithTasks } from '../tasks/useBrandWithTasks';
import { useUser } from '../user/useUser';

export const useSubscriptions = () => {
  const { notify } = useNotificationStore();
  const apolloClient = useApolloClient().client;
  const brandId = useRouteParam('brandId');

  const { user } = useUser();
  const { tasks } = useBrandWithTasks(brandId);

  notificationCreatedSubscription().onResult(notification => {
    if (!notification.data?.NotificationCreated) return;
    addNewNotificationToCache(apolloClient.cache, notification.data?.NotificationCreated);
  });

  userUpdatedSubscription();
  userCreatedSubscription();
  taskUpdatedSubscription();
  notificationEventCreatedSubscription().onResult(result => {
    console.log('result', result.data?.NotificationEventCreated.code);
    if (result.data?.NotificationEventCreated.code === 'PING') {
      // notify({
      //   type: NOTIFICATION_TYPES.SUCCESS,
      //   title: 'Sandbox created succesfully!',
      // });
      console.log('Sandbox created');
    }
  });

  taskCreatedSubscription().onResult(result => {
    if (!result.data?.TaskCreated) return;
    if (
      !addNewTaskToCache(apolloClient.cache, result?.data?.TaskCreated, {
        updateAssignedToMe: result.data?.TaskCreated?.assignedTo?.some(el => el.id === user.value?.id!),
      })
    )
      return;

    if (result.data.TaskCreated.assignedTo?.[0].id === user.value?.id) {
      notify({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: `Task assigned to you in the brand ${result.data.TaskCreated.brand?.name}`,
        message: `The task ${result.data.TaskCreated.title} has been assigned to you.`,
      });
    }
  });

  taskDeletedSubscription().onResult(result => {
    if (!result.data?.TaskDeleted) return;

    const newTaskId = result.data.TaskDeleted.id;
    if (!tasks.value.find(task => task.id === newTaskId)) return;
    deleteTaskFromCache(apolloClient.cache, result?.data?.TaskDeleted, {
      updateAssignedToMe: result.data?.TaskDeleted?.assignedTo?.some(el => el.id === user.value?.id!),
    });
  });
};
