import { useCloneTask } from '@/services/queries/task.query';
import { useBrandWithTasks } from './useBrandWithTasks';

export const usePresetTasks = () => {
  const { tasks } = useBrandWithTasks('_preset');
  const { mutate: cloneTask } = useCloneTask();

  const makeTaskPreset = async (cloneTaskId: string) => {
    await cloneTask({
      cloneTaskId,
      instanceId: 'kpreset',
    });
  };
  return { tasks, makeTaskPreset };
};
