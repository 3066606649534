<script setup lang="ts">
import ModalTooltipButton from '@/components/modal-tooltip-button/ModalTooltipButton.vue';
import { useRefreshInstances } from '@/hooks/instance/useRefreshInstances';
import { useServerInstances } from '@/hooks/instance/useServerInstances';
import { useUser } from '@/hooks/user/useUser';
import { ArrowPathIcon, InformationCircleIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { Button } from '@kleecks/ui-lib';

interface BrandDetailDashboardHeaderProps {
  name: string;
  url: string;
  showSkeleton?: boolean;
  helpText?: string;
  cta?: {
    onClick: () => void;
    label: string;
  };
  // viewType?: 'grid' | 'table';
  // onSearch?: (value: string) => void;
  // searchValue?: string;
}

const props = defineProps<BrandDetailDashboardHeaderProps>();
const { isSuperAdmin } = useUser();
const { refreshInstances, loading } = useRefreshInstances();
const { refetch: refetchInstances } = useServerInstances();

// const emits = defineEmits(['change-view-type']);
</script>

<template>
  <div>
    <div class="flex w-full items-start pt-4">
      <div
        v-if="!props.showSkeleton"
        class="flex-1">
        <div class="flex justify-between">
          <div class="flex items-center gap-2 text-2xl font-medium text-primary">
            {{ props.name }}
            <div v-if="props.helpText">
              <ModalTooltipButton
                :modalContent="props.helpText"
                modalTitle="About">
                <InformationCircleIcon class="size-6" />
              </ModalTooltipButton>
            </div>
          </div>
          <div class="flex gap-4">
            <Button
              v-if="isSuperAdmin"
              variant="blue"
              size="xs"
              @click="
                async () => {
                  await refreshInstances();
                  refetchInstances();
                }
              ">
              <ArrowPathIcon
                class="size-4 transition-transform duration-500 ease-in-out"
                :class="{ 'animate-spin': loading }" />
              Refresh Instances
            </Button>
            <Button
              v-if="props.cta"
              variant="blue"
              size="xs"
              @click="props.cta?.onClick">
              <PlusIcon class="size-4 transition-transform duration-500 ease-in-out" />
              {{ props.cta.label }}
            </Button>
          </div>
        </div>
        <a
          :href="props.url"
          target="_blank"
          class="flex cursor-pointer items-center gap-1 text-xs text-kl-green-300 underline hover:underline"
          >{{ props.url }}
        </a>
      </div>
      <div v-else>
        <div class="h-8 w-96 animate-pulse rounded-lg bg-kl-gray-300" />
        <div class="mt-2 h-4 w-72 animate-pulse rounded-lg bg-kl-gray-300" />
      </div>
    </div>
    <!-- <div
      v-if="props.onSearch !== undefined"
      class="mt-4 flex w-full items-center">
      <div class="relative flex grow items-stretch focus-within:z-10">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" />
        </div>
        <input
          id="searchbar"
          :value="props.searchValue"
          type="text"
          name="searchbar"
          class="block w-full rounded-md border border-gray-secondary py-2 pl-10 text-primary outline-none transition-colors duration-300 focus:border-kl-green-200"
          :placeholder="'Search'"
          @input="(event: any) => props.onSearch?.(event?.target?.value as string)" />
      </div>
      <div class="flex items-center divide-x">
        <div
          v-if="props.viewType"
          class="flex pl-4">
          <Squares2X2Icon
            :class="['h-6 cursor-pointer', viewType === 'grid' ? 'text-active' : 'text-gray-400 hover:text-primary']"
            aria-hidden="true"
            @click="() => emits('change-view-type', 'grid')" />
          <ListBulletIcon
            :class="[
              'ml-4 h-6 cursor-pointer',
              viewType === 'table' ? 'text-active' : 'text-gray-400 hover:text-primary',
            ]"
            aria-hidden="true"
            @click="() => emits('change-view-type', 'table')" />
        </div>
      </div>
    </div> -->
  </div>
</template>
