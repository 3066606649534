<script setup lang="ts">
import { MODULES_IDS } from '@/constants/modules.constants';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrand } from '@/hooks/brand/useBrand';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useUser } from '@/hooks/user/useUser';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { getCookie } from '@kleecks/code-lib';
import { MessagePanel } from '@kleecks/ui-lib';
import { computed, onMounted, onUnmounted, ref } from 'vue';

const { user } = useUser();
const brandId = useRouteParam('brandId');
const { notify } = useNotificationStore();
const { brand } = useBrand(brandId);
const { navigateToSandbox } = useSandboxNavigate();

const loading = ref(true);

const userStore = useUserStore();
const handleMessage = (
  event: MessageEvent<{
    type: string;
    payload: {
      url: string;
    };
  }>
) => {
  if (event.data.type === 'loaded') {
    loading.value = false;
  }
  if (event.data.type === 'navigateUrlInRainbow') {
    if (userStore.selectedTask?.id) {
      navigateToSandbox({
        module: MODULES_IDS.SEO_TOOL,
        url: event.data.payload.url,
      });
    } else {
      notify(DEFAULT_NOTIFICATIONS.NO_TASK_SELECTED);
    }
  }
};
const url = computed(() => {
  if (!user.value) return undefined;
  if (!brand.value?.projects?.[0]?.label) return undefined;

  const ftls = getCookie('ftls');

  if (!ftls) return undefined;

  return `https://zesty-melba-148a5e.netlify.app/#/initialize?username=${
    user?.value?.login
  }&project=${encodeURIComponent(brand.value.projects[0].label)}&ftls=${ftls}&projectId=${brand.value.projects[0].id}`;
});

onMounted(() => {
  window.addEventListener('message', handleMessage);
});

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
});
</script>

<template>
  <div class="relative h-[calc(100vh-7rem)] w-screen">
    <transition
      name="fade"
      :duration="1000">
      <div
        v-if="loading"
        class="absolute z-[2] h-full w-full bg-kl-gray-100 p-4">
        <MessagePanel
          class="h-full w-full"
          :messages="[
            {
              text: 'Loading strategy...',
              icon: ArrowPathIcon,
              iconCss: 'animate-spin',
              visibility: true,
            },
          ]" />
      </div>
    </transition>

    <iframe
      v-if="url"
      :src="url"
      class="h-full w-full"></iframe>
  </div>
</template>
