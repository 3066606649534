/* eslint-disable no-console */
import { TOPICS } from '@kleecks/code-lib';
import { GetBrandTasks, GetBrand } from '@/services/graphql/brand.graphql';
import { InstanceFragment, TaskFragment } from '@/services/graphql/fragments.graphql';
import type { Brand, BrandConnector } from '@/types/brand.types';
import type { Instance } from '@/types/instance.types';
import type { Task, TaskActivity } from '@/types/task.types';
import type { ApolloCache } from '@apollo/client';
import { GetTaskActivities } from '@/services/graphql/task.graphql';
import { GetModuleProperties } from '@/services/graphql/module-properties.graphql';
import { GetNotificationCountsQuery, GetNotificationsQuery } from '@/services/graphql/notification.graphql';
import { GOOGLE_CONNECTORS, type GoogleConnectorsType } from '@/types/connectors.types';

const countStatusMap: {
  [key: string]: 'countBySeo' | 'countByStrategy' | 'countByUx' | 'countByIssues' | 'countBySettings';
} = {
  [TOPICS.SEO]: 'countBySeo',
  [TOPICS.STRATEGY]: 'countByStrategy',
  [TOPICS.UX]: 'countByUx',
  [TOPICS.ISSUES]: 'countByIssues',
  [TOPICS.SETTINGS]: 'countBySettings',
};

export const addConnectorToBrand = (
  type: GoogleConnectorsType,
  brandName: string,
  connector: BrandConnector,
  cache: ApolloCache<any>
) => {
  console.log({
    type,
    brandName,
    connector,
  });
  const getBrandQuery = cache.readQuery<{ brand: Brand }>({
    query: GetBrand,
    variables: { name: brandName },
  });

  console.log(getBrandQuery);
  if (getBrandQuery) {
    cache.writeQuery({
      query: GetBrand,
      variables: { name: brandName },
      data: {
        brand: {
          ...getBrandQuery.brand,
          connectors: {
            ...getBrandQuery.brand.connectors,
            ...(type === GOOGLE_CONNECTORS.GOOGLE_ADS && { Google_ads: connector }),
            ...(type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE && { Google_search_console: connector }),
          },
        },
      },
    });
  }
};

export const removeConnectorFromBrand = (type: GoogleConnectorsType, brandName: string, cache: ApolloCache<any>) => {
  const getBrandQuery = cache.readQuery<{ brand: Brand }>({
    query: GetBrand,
    variables: { name: brandName },
  });

  console.log(getBrandQuery, type, brandName);

  if (getBrandQuery) {
    cache.writeQuery({
      query: GetBrand,
      variables: { name: brandName },
      data: {
        brand: {
          ...getBrandQuery.brand,
          connectors: {
            ...getBrandQuery.brand.connectors,
            ...(type === GOOGLE_CONNECTORS.GOOGLE_ADS && { Google_ads: null }),
            ...(type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE && { Google_search_console: null }),
          },
        },
      },
    });
  }
};

export const viewAllNotificationsInCache = (cache: ApolloCache<any>) => {
  const getNotificationsQuery = cache.readQuery<{ notifications: any[] }>({
    query: GetNotificationsQuery,
    variables: { take: 20, skip: 0 },
  });

  const getNotificationCountsQuery = cache.readQuery<{
    notificationCounts: {
      total: number;
      unviewed: number;
    };
  }>({
    query: GetNotificationCountsQuery,
  });

  cache.writeQuery({
    query: GetNotificationCountsQuery,
    data: {
      notificationCounts: {
        ...(getNotificationCountsQuery?.notificationCounts || {}),
        total: getNotificationCountsQuery?.notificationCounts.total || 0,
        unviewed: 0,
      },
    },
  });

  cache.writeQuery({
    query: GetNotificationsQuery,
    variables: { take: 20, skip: 0 },
    data: {
      notifications: getNotificationsQuery?.notifications?.map(el => ({ ...el, viewed: true })) || [],
    },
  });
};

export const addNewNotificationToCache = (cache: ApolloCache<any>, notification: any) => {
  const getNotificationsQuery = cache.readQuery<{ notifications: any[] }>({
    query: GetNotificationsQuery,
    variables: { take: 20, skip: 0 },
  });

  cache.writeQuery({
    query: GetNotificationsQuery,
    variables: { take: 20, skip: 0 },
    data: {
      notifications: [notification, ...(getNotificationsQuery?.notifications || [])],
    },
  });

  const getNotificationCountsQuery = cache.readQuery<{
    notificationCounts: {
      total: number;
      unviewed: number;
    };
  }>({
    query: GetNotificationCountsQuery,
  });

  cache.writeQuery({
    query: GetNotificationCountsQuery,
    data: {
      notificationCounts: {
        ...(getNotificationCountsQuery?.notificationCounts || {}),
        total: getNotificationCountsQuery?.notificationCounts.total || 0,
        unviewed: (getNotificationCountsQuery?.notificationCounts.unviewed || 0) + 1,
      },
    },
  });
};

export const updateTaskActivityCountInCache = (
  cache: ApolloCache<any>,
  activity: Partial<TaskActivity>,
  taskId: string
) => {
  cache.updateFragment(
    {
      id: cache.identify({ id: taskId, __typename: 'Task' }),
      fragment: TaskFragment,
      fragmentName: 'TaskFragment',
    },
    taskData => {
      const res = {
        ...taskData,
        activitiesObject: {
          ...(taskData.activitiesObject || {}),
          count: (taskData.activitiesObject?.count || 0) + 1,
          countNotMilestones: (taskData.activitiesObject?.countNotMilestones || 0) + 1,
        },
      };

      return res;
    }
  );
};

export const addActivityToTaskCache = (cache: ApolloCache<any>, newActivity: Partial<TaskActivity>, taskId: string) => {
  cache.updateFragment(
    {
      id: cache.identify({ id: taskId, __typename: 'Task' }),
      fragment: TaskFragment,
      fragmentName: 'TaskFragment',
    },
    taskData => {
      const shouldIncreaseCount = !taskData.activitiesObject?.moduleProperties?.find(
        (el: TaskActivity) => !el.milestone && el.name === newActivity.name
      );

      const res = {
        ...taskData,
        activitiesObject: {
          ...(taskData.activitiesObject || {}),
          ...(shouldIncreaseCount && {
            count: (taskData.activitiesObject?.count || 0) + 1,
            countNotMilestones: (taskData.activitiesObject?.countNotMilestones || 0) + 1,
          }),
          moduleProperties: [...(taskData.activitiesObject?.moduleProperties || []), newActivity],
        },
      };

      const taskActivitiesQuery = cache.readQuery<{ getTask: Task }>({
        query: GetTaskActivities,
        variables: { taskId },
      });
      cache.writeQuery({
        query: GetTaskActivities,
        variables: { taskId },
        data: {
          getTask: {
            ...taskActivitiesQuery?.getTask,
            activitiesObject: {
              countNotMilestones: (taskData.activitiesObject?.countNotMilestones || 0) + 1,
              ...(taskActivitiesQuery?.getTask.activitiesObject || {}),
              ...(shouldIncreaseCount && {
                count: (taskData.activitiesObject?.count || 0) + 1,
              }),
              moduleProperties: [
                ...(taskActivitiesQuery?.getTask.activitiesObject?.moduleProperties || []),
                newActivity,
              ],
            },
          },
        },
      });
      return res;
    }
  );
  const modulePropertiesQuery = cache.readQuery<{ moduleProperties: TaskActivity[] }>({
    query: GetModuleProperties,
    variables: { taskId, name: newActivity.name },
  });

  cache.writeQuery({
    query: GetModuleProperties,
    variables: { task: taskId, name: newActivity.name },
    data: {
      moduleProperty: [...(modulePropertiesQuery?.moduleProperties || []), newActivity],
    },
  });
};

export const deleteTaskFromCache = (
  cache: ApolloCache<any>,
  task: Partial<Task>,
  options?: {
    updateAssignedToMe?: boolean;
  }
): boolean => {
  const getBrandTasksQuery = cache.readQuery<{ brand: Brand }>({
    query: GetBrandTasks,
    variables: { name: task.brand?.name },
  });

  if (!getBrandTasksQuery?.brand.taskObject?.tasks?.find(({ id }) => id === task.id)) return false;

  if (getBrandTasksQuery)
    cache.writeQuery({
      query: GetBrandTasks,
      variables: { name: task.brand?.name },
      data: {
        brand: {
          ...getBrandTasksQuery?.brand,
          taskObject: {
            ...(getBrandTasksQuery?.brand.taskObject || {}),
            totalCount: (getBrandTasksQuery?.brand?.taskObject?.totalCount || 0) - 1,
            tasks:
              getBrandTasksQuery?.brand?.taskObject?.tasks?.filter(({ id }) => id !== task.id) ||
              getBrandTasksQuery?.brand?.taskObject?.tasks ||
              [],
          },
        },
      },
    });

  const instanceId = task.instance?.instanceId;
  const instanceRef = cache.readFragment<Instance>({
    id: cache.identify({ instanceId, __typename: 'Instance' }),
    fragment: InstanceFragment,
    fragmentName: 'InstanceFragment',
  });

  if (instanceRef) {
    cache.writeFragment({
      id: cache.identify({ instanceId, __typename: 'Instance' }),
      fragment: InstanceFragment,
      fragmentName: 'InstanceFragment',
      data: {
        ...instanceRef,
        taskAggregatedCount: {
          ...instanceRef.taskAggregatedCount,
          // @ts-ignore
          [countStatusMap[task.topic as TOPICS]]:
            // @ts-ignore
            (instanceRef.taskAggregatedCount?.[countStatusMap[task.topic as TOPICS]] || 0) - 1,
          countByAll: (instanceRef.taskAggregatedCount?.countByAll || 0) - 1,
          ...(options?.updateAssignedToMe && {
            countByAssignedToMe: {
              ...instanceRef.taskAggregatedCount?.countByAssignedToMe,
              [countStatusMap[task.topic!]]:
                (instanceRef.taskAggregatedCount?.countByAssignedToMe?.[countStatusMap[task.topic!]] || 0) - 1,
            },
          }),
        },
      },
    });
  }

  const normalizedTaskId = cache.identify({ id: task.id, __typename: 'Task' });
  cache.evict({ id: normalizedTaskId });

  return true;
};

export const addNewTaskToCache = (
  cache: ApolloCache<any>,
  task: Partial<Task>,
  options?: {
    updateAssignedToMe?: boolean;
  }
): boolean => {
  const brandName = task.brand?.name;

  const instanceId = task.instance?.instanceId;
  const instanceRef = cache.readFragment<Instance>({
    id: cache.identify({ instanceId, __typename: 'Instance' }),
    fragment: InstanceFragment,
    fragmentName: 'InstanceFragment',
  });

  const getBrandTasksQuery = cache.readQuery<{ brand: Brand }>({
    query: GetBrandTasks,
    variables: { name: brandName },
  });

  if (getBrandTasksQuery?.brand.taskObject?.tasks?.find(({ id }) => id === task.id)) return false;

  try {
    if (getBrandTasksQuery)
      cache.writeQuery({
        query: GetBrandTasks,
        variables: { name: brandName },
        data: {
          brand: {
            ...getBrandTasksQuery?.brand,
            taskObject: {
              ...getBrandTasksQuery?.brand?.taskObject,
              totalCount: (getBrandTasksQuery?.brand?.taskObject?.totalCount || 0) + 1,
              tasks: (getBrandTasksQuery?.brand?.taskObject?.tasks || []).concat(task as Task),
            },
          },
        },
      });
  } catch (e) {
    console.error(e);
  }

  try {
    if (instanceRef) {
      cache.writeFragment({
        id: cache.identify({ instanceId, __typename: 'Instance' }),
        fragment: InstanceFragment,
        fragmentName: 'InstanceFragment',
        data: {
          ...instanceRef,
          taskAggregatedCount: {
            ...instanceRef.taskAggregatedCount,
            [countStatusMap[task.topic!]]: (instanceRef.taskAggregatedCount?.[countStatusMap[task.topic!]] || 0) + 1,
            countByAll: (instanceRef.taskAggregatedCount?.countByAll || 0) + 1,
            ...(options?.updateAssignedToMe && {
              countByAssignedToMe: {
                ...instanceRef.taskAggregatedCount?.countByAssignedToMe,
                [countStatusMap[task.topic!]]:
                  (instanceRef.taskAggregatedCount?.countByAssignedToMe?.[countStatusMap[task.topic!]] || 0) + 1,
              },
            }),
          },
        },
      });
    }
  } catch (e) {
    console.error(e);
  }

  return true;
};
