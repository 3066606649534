<script setup lang="ts">
import type { TaskAction, Task } from '@/types/task.types';

import { computed, ref } from 'vue';
import { useUserStore } from '@/stores/useUserStore';
import TasksListItem from '../tasks-list-item/TasksListItem.vue';
import TasksListItemCondensed from '../tasks-list-item-condensed/TasksListItemCondensed.vue';
import TaskEmpty from '../tasks-empty/TaskEmpty.vue';

interface TaskListProps {
  tasks: Task[];
  isPublishEnabled: boolean;
  isScheduleEnabled: boolean;
  isDraggable?: boolean;
  condensed?: boolean;
  isCloneEnabled?: boolean;
  topic?: string;
  onTaskAction: (taskId: string, action: TaskAction) => void;
}

const props = defineProps<TaskListProps>();
const userStore = useUserStore();

const currentTaskId = computed(() => userStore.selectedTask?.id);
const expandedId = ref<string>();

const toggleExpand = (taskId: string) => {
  if (expandedId.value !== taskId) {
    expandedId.value = taskId;
    return;
  }

  expandedId.value = '';
};
// This ref is used to get the root element of the list to manage useIntersectionObserver in TasksListItem
const root = ref(null);
</script>

<template>
  <ul
    ref="root"
    data-testid="tasks-list"
    class="h-full overflow-y-auto"
    :class="{
      'divide-y divide-kl-gray-200': props.condensed,
    }">
    <template v-if="props.tasks.length === 0">
      <div class="p-3">
        <TaskEmpty
          height="h-12"
          :topic="topic" />
      </div>
    </template>
    <template v-else-if="!props.condensed">
      <TasksListItem
        v-for="task in props.tasks"
        ref="target"
        :key="task.id"
        :root="root"
        :is-publish-enabled="props.isPublishEnabled"
        :data-cy="`task-item-${task.id}`"
        :toggle-expand="() => toggleExpand(task.id)"
        :is-schedule-enabled="props.isScheduleEnabled"
        :is-expanded="expandedId === task.id"
        :is-draggable="props.isDraggable"
        :is-clone-enabled="props.isCloneEnabled"
        :on-action="props.onTaskAction"
        :task="task" />
    </template>
    <template v-else>
      <TasksListItemCondensed
        v-for="task in props.tasks"
        :key="task.id"
        :data-cy="`task-item-${task.id}`"
        :is-publish-enabled="props.isPublishEnabled"
        :is-schedule-enabled="props.isScheduleEnabled"
        :is-draggable="props.isDraggable"
        :is-clone-enabled="props.isCloneEnabled"
        :is-current="currentTaskId === task.id"
        :on-action="props.onTaskAction"
        :task="task" />
    </template>
  </ul>
</template>
