import { createBrandMutation } from '@/services/queries/brand.query';
import type { Brand } from '@/types/brand.types';

export const useCreateBrand = () => {
  const { mutate, loading, error } = createBrandMutation();

  const createBrand = async (brand: Partial<Brand>) => {
    const res = await mutate({ brand });
    return res?.data?.createBrand;
  };

  return {
    createBrand,
    loading,
    error,
  };
};
