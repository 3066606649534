import { useSubscription } from '@vue/apollo-composable';
import { NotificationEventCreated } from '@/services/graphql/subscriptions.graphql';

export const notificationEventCreatedSubscription = () =>
  useSubscription<{
    NotificationEventCreated: {
      message: {
        ping: string;
      };
      code: string;
    };
  }>(NotificationEventCreated);
