<script setup lang="ts">
import type { Task, TaskAction, TaskFilters } from '@/types/task.types';
import { filterTasks, thereAreFilters, getCountOfFilterApplied } from '@/utils/tasks.utils';
import { DEFAULT_TASK_FILTERS } from '@/constants/task.constants';
import { type FunctionalComponent, type Component, ref, watch, computed } from 'vue';

import draggable from 'vuedraggable';
import rfdc from 'rfdc';
import deepEqual from 'fast-deep-equal';

import TasksContainerBoxLayout from '@/components/tasks/tasks-container-box/layout/TasksContainerBoxLayout.vue';
import TasksContainerBoxFiltersMenu from '@/components/tasks/tasks-container-box/tasks-container-box-filters-menu/TaskContainerBoxFiltersMenu.vue';
import TasksListItem from '@/components/tasks/tasks-list-item/TasksListItem.vue';
import DragAndDropOverlay from '@/components/atoms/drag-and-drop-overlay/DragAndDropOverlay.vue';

interface TasksContainerBoxProps {
  boxTitle: string;
  boxId: string;
  boxIcon: FunctionalComponent | Component | string;
  disabled?: boolean;
  variant: 'gray' | 'pink' | 'violet';
  websiteVisitHref?: string;
  dragStatus: boolean;
  dropOptions?: {
    isDropAgree: boolean;
    dropAgreeMessage: string;
    onDrop: () => void;
  };
  dragOptions: {
    group: { name: string; pull: string; put: boolean };
    isPublishEnabled: boolean;
    isScheduleEnabled: boolean;
    isCloneEnabled: boolean;
    isDraggable: boolean;
    isPreset: boolean;
    hideInstance: boolean;
    onCloneTask: (obj: Task) => void;
  };
  addTaskShortcut?: () => void;
  onSendSignalForManageGeneralFilters: (boxId: string, internalFiltersMenuStatus: boolean) => void;
  onUpdateDragStatus: (status: boolean) => void;
  onSendTaskAction: (taskId: string, action: TaskAction) => void;
}
const clone = rfdc();
const tasks = defineModel<Task[]>('tasks', { required: true, default: [] });
const props = withDefaults(defineProps<TasksContainerBoxProps>(), {
  disabled: false,
  websiteVisitHref: undefined,
  dropOptions: undefined,
  addTaskShortcut: undefined,
});

const tasksCopy = ref<Task[]>(clone(tasks.value));

const expandedTaskId = ref<string>();

const toggleExpandTask = (taskId: string) => {
  expandedTaskId.value = expandedTaskId.value === taskId ? '' : taskId;
};

// Feature: Drag and Drop from one box to another

const handleDrop = () => {
  if (props.dropOptions !== undefined) {
    props.dropOptions.onDrop();
  }
};

// Feature Manage Filters of Filter Box
const filterObject = ref<TaskFilters>(clone(DEFAULT_TASK_FILTERS));

const getAssignersOptions = computed<{ label: string; value: string; image?: string }[]>(() => {
  if (!tasks.value) return [];
  const assignees = tasks.value
    .flatMap(el => el.assignedTo)
    .filter((el, i, arr) => arr.findIndex(e => e.id === el.id) === i);
  const res = [...(assignees.map(el => ({ label: `${el.nome} ${el.cognome}`, value: el.id!, image: el.image })) || [])];

  return res;
});
const getCountOfFilters = computed(() => getCountOfFilterApplied(filterObject.value));

const applyFiltersForTasks = (newFiltersSettings: TaskFilters, type: 'order' | 'general') => {
  if (type === 'order') {
    filterObject.value.orderBy = newFiltersSettings.orderBy;
  } else {
    filterObject.value.searchKey = newFiltersSettings.searchKey;
    filterObject.value.filterByAssignees = newFiltersSettings.filterByAssignees;
    filterObject.value.filterByStatus = newFiltersSettings.filterByStatus;
    filterObject.value.filterByTopics = newFiltersSettings.filterByTopics;
  }
  if (thereAreFilters(filterObject.value)) {
    props.onSendSignalForManageGeneralFilters(props.boxId, true);
    tasksCopy.value = filterTasks(tasks.value, filterObject.value);
  } else {
    props.onSendSignalForManageGeneralFilters(props.boxId, false);
    tasksCopy.value = clone(tasks.value);
  }
};

watch(
  () => tasks.value,
  newTask => {
    if (!deepEqual(newTask, tasksCopy.value)) {
      tasksCopy.value = clone(newTask);
      if (thereAreFilters(filterObject.value)) {
        tasksCopy.value = filterTasks(tasksCopy.value, filterObject.value);
      }
    }
  },
  { deep: true }
);
</script>

<template>
  <TasksContainerBoxLayout
    v-bind="$attrs"
    :header-variant="props.variant"
    :header-icon="props.boxIcon"
    :header-title="props.boxTitle"
    :disabled="props.disabled"
    :count-of-filters="getCountOfFilters"
    :website-visit-href="props.websiteVisitHref"
    :on-add-new-task="props.addTaskShortcut ? () => props.addTaskShortcut?.() : undefined">
    <template #filters-menu-content="{ filtersMenuIsOpen, currentFiltersMenuType, closeFiltersMenu }">
      <TasksContainerBoxFiltersMenu
        :filters-object="filterObject"
        :is-open="filtersMenuIsOpen"
        :assigners-options="getAssignersOptions"
        :filter-type="currentFiltersMenuType ? currentFiltersMenuType : 'order'"
        :on-apply-some-filters="(filters, fromFilterType) => applyFiltersForTasks(filters, fromFilterType)"
        @close="closeFiltersMenu()" />
    </template>
    <template #body-content>
      <DragAndDropOverlay
        v-if="!props.disabled && props.dropOptions"
        :label="props.dropOptions.dropAgreeMessage"
        :isVisible="props.dragStatus"
        :isDropDisabled="!props.dropOptions.isDropAgree"
        :onDrop="() => handleDrop()" />
      <slot
        name="extra-body-content"
        :taskFiltered="tasksCopy.length" />
      <draggable
        v-model="tasksCopy"
        :sort="false"
        :onStart="() => props.onUpdateDragStatus(true)"
        :onEnd="() => props.onUpdateDragStatus(false)"
        :group="{ name: 'tasks', pull: 'clone', put: false }"
        tag="ul"
        :clone="(obj:any) => props.dragOptions.onCloneTask(obj)"
        :class="{
          'pointer-events-none': props.disabled,
        }"
        class="cursor-grab"
        item-key="id">
        <template #item="{ element }">
          <TasksListItem
            :key="element.id"
            :is-publish-enabled="props.dragOptions.isPublishEnabled"
            :is-schedule-enabled="props.dragOptions.isScheduleEnabled"
            :is-clone-enabled="props.dragOptions.isCloneEnabled"
            :hide-instance="props.dragOptions.hideInstance"
            :is-draggable="props.dragOptions.isDraggable"
            :is-preset="props.dragOptions.isPreset"
            :is-expanded="expandedTaskId === element.id"
            :toggle-expand="() => toggleExpandTask(element.id)"
            :on-action="(taskId, action) => props.onSendTaskAction(taskId, action)"
            :task="element" />
        </template>
      </draggable>
    </template>
  </TasksContainerBoxLayout>
</template>
