<script setup lang="ts">
import GetAuthCodeForm from '@/components/forms/get-auth-code-form/GetAuthCodeForm.vue';
import { APP_VERSION } from '@/constants/settings.constants';
import { useQueryParam } from '@/hooks/common/useQueryParam';
import { useLogin } from '@/services/queries/authentication';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { COOKIES, setCookie } from '@kleecks/code-lib';
import { BaseTextInput, Button, logoMain, DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref } from 'vue';

interface MessageDialog {
  isOpen: boolean;
  headerTitle: string;
  content: string;
}

const pin = ref<string>('');
const email = ref('');
const password = ref('');

const MESSAGE_DIALOGS_MAP = ref<Record<string, MessageDialog>>({
  forgotPassword: {
    isOpen: false,
    headerTitle: 'Forgot Password?',
    content: 'Please contact your team admin to change or receive a new password.',
  },
  notValidIP: {
    isOpen: false,
    headerTitle: 'Not Valid IP',
    content:
      'Your IP was not recognized by the system, it is not possible to access the platform. Please contact your admin.',
  },
});
const isPinSetDialogOpen = ref(false);

const redirect = useQueryParam('redirect');
const { load: login, refetch: loginRefetch, onResult: onLogin, onError: onLoginError, loading } = useLogin();
const errorPinMessage = ref<string>();

const loadOrRefetch = (payload: any) => {
  // eslint-disable-next-line no-unused-expressions
  login(null, payload) || loginRefetch(payload);
};

const handleLogin = async () => {
  if (!pin.value) return;
  let pinInt = parseInt(pin.value, 10);
  if (Number.isNaN(pinInt)) {
    pinInt = -1;
  }

  loadOrRefetch({ username: email.value?.toLowerCase(), password: password.value, pin: pinInt });
};

onLogin(result => {
  if (result.data?.login?.jwt) {
    errorPinMessage.value = undefined;
    // Store JWT token
    setCookie(COOKIES.TOKEN_KEY, result.data.login.jwt);
    setCookie('ftls', btoa(password.value));
    window.location.href = redirect.value ? decodeURIComponent(redirect.value) : '/';
  }
});

onLoginError(error => {
  if (error.message) {
    const pinInt = parseInt(pin.value, 10);
    if (Number.isNaN(pinInt)) {
      errorPinMessage.value = 'The code format is invalid. Please enter only numbers (e.g., 1234).';
    } else {
      errorPinMessage.value = 'The code is incorrect. Please check your email and try again.';
    }
  }
});

const handlePinReceived = async (obj: { username: string; password: string }) => {
  email.value = obj.username;
  password.value = obj.password;
  isPinSetDialogOpen.value = true;
};
</script>

<template>
  <!--Forgot your password modal OR Not Valid IP-->
  <DialogContainer
    v-for="k in Object.keys(MESSAGE_DIALOGS_MAP)"
    :key="k"
    :is-open="MESSAGE_DIALOGS_MAP[k].isOpen"
    :full-screen="false"
    :on-close="() => (MESSAGE_DIALOGS_MAP[k].isOpen = false)">
    <Modal
      hide-header
      wrapper-class="w-80 rounded-lg ">
      <div class="flex flex-col">
        <div class="relative flex">
          <div class="flex-1 overflow-x-hidden pr-6">
            <p class="truncate text-kl-base font-semibold text-kl-gray-500">
              {{ MESSAGE_DIALOGS_MAP[k].headerTitle }}
            </p>
          </div>
          <XMarkIcon
            class="absolute -top-1.5 right-0 size-5 shrink-0 cursor-pointer text-kl-icon hover:text-kl-green-200"
            @click="() => (MESSAGE_DIALOGS_MAP[k].isOpen = false)" />
        </div>
        <span class="mt-6 text-kl-sm text-kl-gray-400">
          {{ MESSAGE_DIALOGS_MAP[k].content }}
        </span>
        <div class="mt-8 flex items-center gap-x-4">
          <div class="flex-1">
            <span
              class="cursor-pointer text-kl-xs text-kl-gray-400 underline hover:text-kl-gray-600"
              @click="() => (MESSAGE_DIALOGS_MAP[k].isOpen = false)"
              >Back to login page</span
            >
          </div>
        </div>
      </div>
    </Modal>
  </DialogContainer>

  <!--Pin set modal-->
  <DialogContainer
    :is-open="isPinSetDialogOpen"
    :full-screen="false"
    :on-close="() => (isPinSetDialogOpen = false)">
    <Modal
      hide-header
      :on-close="() => (isPinSetDialogOpen = false)"
      wrapper-class="w-80 rounded-lg">
      <div class="flex flex-col">
        <div class="relative flex">
          <div class="flex-1 overflow-x-hidden pr-6">
            <p class="truncate text-kl-base font-semibold text-kl-gray-500">Autenticate Your Account</p>
          </div>
          <XMarkIcon
            class="absolute -top-1.5 right-0 size-5 shrink-0 cursor-pointer text-kl-icon hover:text-kl-green-200"
            @click="() => (isPinSetDialogOpen = false)" />
        </div>
        <span class="mt-1 text-kl-xs text-kl-gray-400">
          Please insert the authorization code we sent to your email.
        </span>
        <form
          class="mt-4 flex flex-col justify-between"
          @submit.prevent="handleLogin">
          <BaseTextInput
            id="pin"
            v-model="pin"
            name="pin"
            data-testid="pin-input"
            placeholder="Enter your code here"
            :autofocus="true"
            type="text"
            label="Your code"
            :status="{ valid: !errorPinMessage, message: errorPinMessage }" />
          <div class="mt-8 flex items-center gap-x-4">
            <div class="flex-1">
              <span
                class="cursor-pointer text-kl-xs text-kl-gray-400 underline hover:text-kl-gray-600"
                @click="() => (isPinSetDialogOpen = false)"
                >Not received your code?</span
              >
            </div>
            <Button
              variant="blue"
              size="sm"
              :disabled="!pin"
              data-testid="login-button"
              type="submit"
              :isLoading="loading"
              @click="handleLogin">
              Login
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  </DialogContainer>

  <!--Login page-->
  <div class="flex h-screen w-screen items-center justify-center bg-kl-gray-100">
    <div class="flex w-80 flex-col rounded-lg border border-sec bg-white p-6 pb-6 pt-4 shadow-custom">
      <img
        :src="logoMain"
        class="mx-auto w-44" />
      <GetAuthCodeForm
        v-if="
          !isPinSetDialogOpen && !MESSAGE_DIALOGS_MAP.forgotPassword.isOpen && !MESSAGE_DIALOGS_MAP.notValidIP.isOpen
        "
        @forgot-password="() => (MESSAGE_DIALOGS_MAP.forgotPassword.isOpen = true)"
        @invalid-ip="() => (MESSAGE_DIALOGS_MAP.notValidIP.isOpen = true)"
        @pin-received="obj => handlePinReceived(obj)" />
    </div>
    <p class="absolute bottom-4 text-center text-2xs font-light text-kl-gray-400">
      © Kleecks Ltd 2024 - {{ APP_VERSION }}
    </p>
  </div>
</template>
