import { ModulesDictionaryQuery, CreateOrUpdateModulesDictionary } from '@/services/graphql/modules-dictionary.graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import type { MaybeRef } from 'vue';
import { unref } from 'vue';

export const modulesDictionaryQuery = (lang: MaybeRef<string>) =>
  useQuery<
    {
      modulesDictionary: any;
    },
    {
      lang: string;
    }
  >(
    ModulesDictionaryQuery,
    () => ({
      lang: unref(lang),
    }),
    () => ({
      enabled: !!unref(lang),
    })
  );

export const createOrUpdateModulesDictionaryMutation = () =>
  useMutation<
    {
      createOrUpdateModulesDictionary: any;
    },
    {
      lang: string;
      data: any;
    }
  >(CreateOrUpdateModulesDictionary, {
    update: (cache, { data }, { variables }) => {
      const newData = data?.createOrUpdateModulesDictionary;

      if (newData) {
        cache.writeQuery({
          query: ModulesDictionaryQuery,
          data: {
            modulesDictionary: newData,
          },
          variables: {
            lang: variables?.lang,
          },
        });
      }
    },
  });
