<script setup lang="ts">
import type { Task, TaskActivity } from '@/types/task.types';
import { computed } from 'vue';
import ActivityWrapper from '@/components/activities/activity-wrapper/ActivityWrapper.vue';
import { ListBulletIcon } from '@heroicons/vue/24/outline';
import { useTaskActivities } from '@/hooks/tasks/useTaskActivities';

interface ActivitiesListProps {
  task: Task;
  showTaskTitle?: boolean;
  showNoActivitiesMessage?: boolean;
}

const props = defineProps<ActivitiesListProps>();
const { activities, loading } = useTaskActivities(props.task.id);
const sortedActivitiesWithoutMilestones = computed<TaskActivity[]>(() =>
  [...activities.value]
    .sort((a, b) => new Date(b.when).getTime() - new Date(a.when).getTime())
    .filter(({ milestone, who }) => !milestone && !!who)
);
</script>
<template>
  <div v-if="loading">
    <div class="flex animate-pulse flex-col gap-1">
      <div class="h-6 rounded bg-slate-200"></div>
    </div>
  </div>
  <div v-else-if="sortedActivitiesWithoutMilestones.length">
    <div class="flex items-center gap-1">
      <ListBulletIcon class="h-4 w-4 text-kl-icon" />
      <span class="text-xs text-secondary">Activities</span>
      <span
        v-if="props.showTaskTitle"
        class="truncate text-xs text-primary"
        >for <b>{{ props.task.title }}</b></span
      >
    </div>
    <ul
      role="list"
      class="ml-2 mt-2 border-l border-gray-secondary">
      <li
        v-for="activity in sortedActivitiesWithoutMilestones"
        :key="activity.id"
        class="py-2">
        <ActivityWrapper
          :user="activity.who"
          :activity="activity"
          :task="props.task" />
      </li>
    </ul>
  </div>
  <div
    v-else-if="props.showNoActivitiesMessage"
    class="text-xs text-kl-gray-400">
    No activities to display
  </div>
</template>
