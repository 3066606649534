export const GOOGLE_CONNECTORS = {
  GOOGLE_ADS: 'GOOGLE_ADS',
  GOOGLE_SEARCH_CONSOLE: 'GOOGLE_SEARCH_CONSOLE',
} as const;

export const CONNECTORS_LABELS = {
  [GOOGLE_CONNECTORS.GOOGLE_ADS]: 'Google Ads',
  [GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE]: 'Google Search Console',
} as const;

export type GoogleConnectorsType = (typeof GOOGLE_CONNECTORS)[keyof typeof GOOGLE_CONNECTORS];
