<script setup lang="ts">
import { useBrand } from '@/hooks/brand/useBrand';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import {
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  ChevronRightIcon,
  CodeBracketSquareIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/vue/24/outline';
import type { FunctionalComponent } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { instance, loading } = useInstance(brandId, instanceId);
const { brand } = useBrand(brandId);
const router = useRouter();

const links = computed<
  {
    name: string;
    routeName: string;
    description: string;
    icon: FunctionalComponent;
  }[]
>(() => {
  const res = [];

  res.push({
    name: 'Brands',
    routeName: ROUTE_NAMES.BRANDS,
    description: 'Explore all brands',
    icon: BuildingOffice2Icon,
  });

  if (brand.value) {
    res.push({
      name: 'Instances',
      routeName: ROUTE_NAMES.BRAND,
      description: `Explore all instances for ${brandId.value}`,
      icon: BuildingStorefrontIcon,
    });
  }

  if (instance.value) {
    res.push({
      name: 'Dashboards',
      routeName: ROUTE_NAMES.DASHBOARDS_LIBRARY,
      description: `Explore all dashboards for ${instanceId.value}`,
      icon: CodeBracketSquareIcon,
    });
  }

  return res;
});
</script>

<template>
  <div
    v-if="!loading"
    class="flex h-[calc(100vh-7rem)] w-full flex-col bg-sec">
    <main class="mx-auto flex max-w-7xl flex-auto flex-col justify-center p-6">
      <p class="text-center text-base font-semibold leading-8 text-kl-violet-200">404</p>
      <h1 class="mt-4 text-center text-3xl font-medium tracking-tight text-kl-gray-600 sm:text-5xl">Page not found</h1>
      <p class="mt-6 text-center text-base leading-7 text-kl-gray-400">
        Sorry, the page you’re looking for doesn’t exist.
      </p>
      <div
        v-if="links.length > 0"
        class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <h2 class="sr-only">Popular pages</h2>
        <ul
          role="list"
          class="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
          <li
            v-for="(link, linkIdx) in links"
            :key="linkIdx"
            class="relative flex gap-x-6 py-3">
            <div
              class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
              <component
                :is="link?.icon"
                class="h-6 w-6 text-indigo-600"
                aria-hidden="true" />
            </div>
            <div class="flex-auto">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                <a @click="router.push({ name: link.routeName })">
                  <span
                    class="absolute inset-0"
                    aria-hidden="true" />
                  {{ link?.name }}
                </a>
              </h3>
              <p class="mt-0 text-sm leading-6 text-gray-600">{{ link?.description }}</p>
            </div>
            <div class="flex-none self-center">
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-10">
        <!-- should we include a "history.go(-1)" button? -->
        <div
          class="flex cursor-pointer items-center justify-center space-x-2 text-sm font-semibold leading-7 text-kl-violet-200 hover:text-kl-green-300 active:text-kl-green-200"
          @click="
            () => {
              router.push('/');
            }
          ">
          <ArrowUturnLeftIcon class="size-4" />
          <span aria-hidden="true"> Back to home</span>
        </div>
      </div>
    </main>
  </div>
</template>
