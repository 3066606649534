import { INSTANCE_MAP } from '@/constants/instance.constants';
import { getBrandQuery } from '@/services/queries/brand.query';
import type { Instance } from '@/types/instance.types';
import type { MaybeRef } from 'vue';
import { computed } from 'vue';

export const useInstances = (brandId: MaybeRef<string | undefined>) => {
  const query = getBrandQuery(brandId);

  const brand = computed(() => query.result.value?.brand);

  const instances = computed<Instance[]>(() => {
    if (!brand.value) return [];
    if (!brand.value.instances) return [];
    const arr: Instance[] = [];
    Object.keys(INSTANCE_MAP).forEach(key => {
      const instance = brand.value?.instances?.[key as keyof typeof INSTANCE_MAP];
      if (instance) {
        arr.push(instance);
      }
    });
    return arr;
  });

  const instancesNames = computed<{ [key: string]: string }>(() => {
    if (!brand.value) return {};
    if (!brand.value.instances) return {};

    const names = Object.keys(brand.value.instances).reduce((acc, key) => {
      const instance = brand.value?.instances?.[key as keyof typeof INSTANCE_MAP];
      if (instance) {
        acc[instance?.instanceId] = INSTANCE_MAP[key as keyof typeof INSTANCE_MAP];
      }
      return acc;
    }, {} as { [key: string]: string });

    return names;
  });

  const instancesEnable = computed<{ [key: string]: boolean }>(() => {
    if (!brand.value) return {};
    if (!brand.value.instances) return {};

    const names = Object.keys(brand.value.instances).reduce((acc, key) => {
      const instance = brand.value?.instances?.[key as keyof typeof INSTANCE_MAP];
      if (instance) {
        acc[instance?.instanceId] = instance?.enabled;
      }
      return acc;
    }, {} as { [key: string]: boolean });

    return names;
  });

  return {
    instancesNames,
    instancesEnable,
    instances,
    loading: query.loading,
  };
};
