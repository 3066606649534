export const TOPICS = {
    SEO: 'seo',
    ISSUES: 'issues',
    STRATEGY: 'strategy',
    UX: 'ux',
    SETTINGS: 'settings',
};
export const TOPIC_DISPLAY_NAME = {
    [TOPICS.SEO]: 'SEO',
    [TOPICS.ISSUES]: 'Issues',
    [TOPICS.STRATEGY]: 'Strategy',
    [TOPICS.UX]: 'UX',
    [TOPICS.SETTINGS]: 'Settings',
};
export const TOPIC_COLORS = {
    [TOPICS.SEO]: 'bg-kl-yellow-200 text-white',
    [TOPICS.ISSUES]: 'bg-[#E56553] text-white',
    [TOPICS.STRATEGY]: 'bg-kl-cyan text-white',
    [TOPICS.UX]: 'bg-[#98288B] text-white',
    [TOPICS.SETTINGS]: 'bg-[#564BA2] text-white',
};
export const TOPIC_OPTIONS = [
    {
        value: 'seo',
        label: 'SEO',
    },
    {
        value: 'issues',
        label: 'Issues',
    },
    {
        value: 'strategy',
        label: 'Strategy',
    },
    {
        value: 'ux',
        label: 'UX',
    },
    {
        value: 'settings',
        label: 'Settings',
    },
];
