import { TASK_STATUS } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useCurrentUserInstanceTask } from '@/hooks/tasks/useCurrentUserInstanceTask';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useUser } from '@/hooks/user/useUser';
import { COOKIES, getCookie, setCookie } from '@kleecks/code-lib';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useUserStore = defineStore('user', () => {
  const selectedTaskId = ref<string | undefined>(getCookie(COOKIES.SELECTED_TASK_ID));
  const instanceId = useRouteParam('instanceId');
  const brandId = useRouteParam('brandId');
  const { instanceTasks } = useInstanceTasks(brandId, instanceId);
  const { mutate: updateTask } = useUpdateTask();
  const { user, onResult: onResultUser } = useUser();

  const { task: selectedTask } = useCurrentUserInstanceTask(selectedTaskId);

  watch(selectedTask, () => {
    if (selectedTask.value?.status === TASK_STATUS.NEW) {
      updateTask({
        task: {
          id: selectedTask.value.id,
          status: TASK_STATUS.DRAFT,
        },
      });
    }
  });

  onResultUser(result => {
    const { user: newUser } = result.data;
    Sentry.setUser({
      id: newUser?.id,
      username: `${newUser.nome} ${newUser.cognome}`,
      email: newUser.login,
    });
  });

  watch(
    () => instanceTasks.value,
    () => {
      if (!selectedTask.value) {
        const cookieTask = getCookie(COOKIES.SELECTED_TASK_ID);
        if (cookieTask) {
          const taskId = instanceTasks.value
            .filter(el => el.status !== TASK_STATUS.PUBLISHED && el.assignedTo?.find(u => u.id === user.value?.id))
            ?.find(task => task.id === cookieTask)?.id;

          if (taskId) {
            selectedTaskId.value = taskId;
          }
        }
      }
    }
  );

  const setSelectedTaskId = (id: string | undefined) => {
    selectedTaskId.value = id;
    setCookie(COOKIES.SELECTED_TASK_ID, id!);
  };

  return {
    selectedTask,
    setSelectedTaskId,
  };
});
