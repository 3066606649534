import type { Task } from '@/types/task.types';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { TASK_STATUS } from '@/constants/task.constants';
import { useRouteParam } from '../common/useRouteParam';
import { useInstanceTasks } from '../instance/useInstanceTasks';
import { useUser } from '../user/useUser';

export const useCurrentUserInstanceTask = (taskId: MaybeRef<string | undefined>) => {
  const instanceId = useRouteParam('instanceId');
  const brandId = useRouteParam('brandId');
  const { instanceTasks, loading } = useInstanceTasks(brandId, instanceId);

  const { user } = useUser();

  const tasks = computed<Task[]>(() => {
    if (!user.value?.id) return [];
    const res =
      instanceTasks.value?.filter(
        el => el.assignedTo?.find(u => u.id === user.value?.id) && el.status !== TASK_STATUS.PUBLISHED
      ) || [];

    return res;
  });

  const task = computed<Task | undefined>(() => tasks.value.find(el => el?.id === unref(taskId)));
  return { task, loading };
};
