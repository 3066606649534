import { updateDashboardMutation } from '@/services/queries/dashboard.query';

export const useUpdateDashboard = () => {
  const { mutate, loading } = updateDashboardMutation();

  return {
    updateDashboard: mutate,
    loading,
  };
};
