<script setup lang="ts">
import DashboardWidgetContainer from '@/components/dashboards/dashboard-widget-container/DashboardWidgetContainer.vue';
import type { NewDashboardObject } from '@/components/dashboards/new-dashboard-panel/NewDashboardPanel.vue';
import NewDashboardPanel from '@/components/dashboards/new-dashboard-panel/NewDashboardPanel.vue';
import SelectModuleDialog from '@/components/dialogs/SelectModuleDialog.vue';
import type { MODULES } from '@/constants/modules.constants';
import {
  MODULES_DASHBOARD_CONFIGURATION,
  MODULES_NAMES,
  STANDALONE_MODULES,
  WIDGET_MODULES,
} from '@/constants/modules.constants';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { WidgetMap, type WidgetPackage } from '@/helpers/widget-factory';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useModules } from '@/hooks/modules/useModules';
import { useUser } from '@/hooks/user/useUser';
import { useWidgets } from '@/hooks/widgets/useWidgets';
import { createDashboardMutation } from '@/services/queries/dashboard.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import type {
  CreateDashboardPayload,
  Dashboard,
  DashboardConfiguration,
  DashboardVisibility,
} from '@/types/dashboard.types';
import draggable from 'vuedraggable';

import { generateUuid } from '@kleecks/code-lib';
import { AddWidgetRow, DialogContainer, StandardContainer } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const isSelectModuleDialogOpen = ref(false);
const selectedModule = ref<MODULES>();
const { notify } = useNotificationStore();
const { user, isSuperAdmin } = useUser();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { mutate: createDashboard, loading: isSavingDashboard } = createDashboardMutation();
const userStore = useUserStore();

const dashboardConfiguration = ref<Partial<Dashboard> & { data: DashboardConfiguration }>({
  data: {
    objects: [],
  },
});
const isStandaloneModuleDashboard = computed(() =>
  dashboardConfiguration.value?.data?.objects?.some(obj => STANDALONE_MODULES?.includes(obj.moduleId as MODULES))
);
const { modules } = useModules();

const { widgetsMap, widgetsProps } = useWidgets();
const isPublicEnabled = computed(
  () =>
    // The dashboard can be public only if includes modules, and not widgets
    !dashboardConfiguration.value.data?.objects.some(obj => obj.type === 'widgets')
);
const closeDialogModule = () => {
  selectedModule.value = undefined;
  isSelectModuleDialogOpen.value = false;
};

const saveDashboard = async (obj: NewDashboardObject, visibility: DashboardVisibility) => {
  const dashboardObjToSave: CreateDashboardPayload = {
    dashboard: {
      name: obj.name,
      description: obj.description,
      topic: obj.topic,
      data: dashboardConfiguration.value.data!,
    },
  };

  if (visibility === 'private') {
    dashboardObjToSave.dashboard.brandId = brandId.value;
    dashboardObjToSave.dashboard.userLogin = user.value?.login;
  }

  if (visibility === 'global') {
    dashboardObjToSave.dashboard.userLogin = user.value?.login;
  }

  if (visibility === 'team_universal') {
    dashboardObjToSave.dashboard.teamId = user.value?.team?.id;
  }

  const result = await createDashboard(dashboardObjToSave);

  notify(DEFAULT_NOTIFICATIONS.DASHBOARD_CREATED);
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: result?.data?.createDashboard?.id } });
};

const addWidget = (widgetPackage: WidgetPackage, values: Record<string, unknown>) => {
  dashboardConfiguration.value.data.objects.push({
    id: generateUuid(),
    widgetPackage,
    type: 'widgets',
    values,
  });

  closeDialogModule();
};

const selectModule = (module: MODULES) => {
  if (STANDALONE_MODULES.includes(module)) {
    dashboardConfiguration.value?.data?.objects?.push({
      id: generateUuid(),
      moduleId: module,
      type: 'modules',
    });

    isSelectModuleDialogOpen.value = false;

    return;
  }
  if (!WIDGET_MODULES.includes(module)) {
    selectedModule.value = module;
    isSelectModuleDialogOpen.value = false;
    return;
  }

  dashboardConfiguration.value.data?.objects.push({
    id: generateUuid(),
    moduleId: module,
    type: 'modules',
  });
  isSelectModuleDialogOpen.value = false;
};

const removeWidget = (index: number) => {
  dashboardConfiguration.value.data?.objects.splice(index, 1);
};

const widgetModulesInDashboardIds = computed<MODULES[]>(
  () =>
    dashboardConfiguration.value?.data?.objects.reduce((acc, curr) => {
      if (curr.type === 'modules') {
        acc.push(curr.moduleId!);
      }
      return acc;
    }, [] as MODULES[]) ?? []
);
</script>

<template>
  <div class="dashboard-gradient">
    <StandardContainer>
      <div class="flex items-start bg-sec">
        <div class="max-h-[calc(100vh-7rem)] flex-1 overflow-y-auto pr-4">
          <component
            :is="modules[dashboardConfiguration?.data?.objects[0]!.moduleId as MODULES]"
            v-if="isStandaloneModuleDashboard && dashboardConfiguration?.data?.objects[0]"
            ref="widgetComponent"
            :key="userStore.selectedTask?.id || 'default'"
            v-bind="{
              taskId: userStore.selectedTask?.id,
              instanceId,
            }" />
          <draggable
            v-else
            v-model="dashboardConfiguration.data.objects"
            group="objects"
            class="grid grid-cols-1 gap-8 pt-8 lg:grid-cols-2"
            as="div"
            handle=".handle"
            :animation="200"
            item-key="id">
            <template #item="{ element, index }">
              <DashboardWidgetContainer
                v-if="element.widgetPackage"
                :key="element.widgetPackage"
                :module-id="element.moduleId"
                :width-configuration="MODULES_DASHBOARD_CONFIGURATION[element.moduleId as MODULES]?.configuration"
                :name="WidgetMap[element.widgetPackage as WidgetPackage].widgetName"
                :has-permission="widgetsMap[element.widgetPackage] !== undefined"
                :can-grab="true"
                :on-remove="() => removeWidget(index)">
                <component
                  :is="widgetsMap[element.widgetPackage]"
                  v-bind="{
                    ...(widgetsProps[element.widgetPackage] || {}),
                    ...(element.values || {}),
                  }" />
              </DashboardWidgetContainer>
              <DashboardWidgetContainer
                v-else-if="element.moduleId"
                v-slot="{ afterHeaderId }"
                :key="element.moduleId"
                :module-id="element.moduleId"
                :width-configuration="MODULES_DASHBOARD_CONFIGURATION[element.moduleId as MODULES]?.configuration"
                :name="MODULES_NAMES[element.moduleId as MODULES]"
                :has-permission="modules[element.moduleId as MODULES] !== undefined"
                :can-grab="true"
                :on-remove="() => removeWidget(index)">
                <component
                  :is="modules[element.moduleId as MODULES]"
                  v-bind="{
                    taskId: userStore.selectedTask?.id,
                    instanceId,
                    customSectionHeaderId: afterHeaderId,
                    isReadOnly: true,
                  }" />
              </DashboardWidgetContainer>
            </template>
          </draggable>
          <AddWidgetRow
            class="my-8"
            :on-add-row="() => (isSelectModuleDialogOpen = true)" />
        </div>

        <div class="sticky top-0 h-[calc(100vh-7rem)] w-1/3 items-start">
          <NewDashboardPanel
            :is-public-enabled="isPublicEnabled"
            :is-loading="isSavingDashboard"
            :is-admin="user?.admin"
            :is-super-admin="isSuperAdmin"
            :on-save="(obj, visibility) => saveDashboard(obj, visibility)" />
        </div>
      </div>
    </StandardContainer>
  </div>
  <DialogContainer
    v-if="selectedModule !== undefined"
    :is-open="selectedModule !== undefined"
    :on-close="closeDialogModule">
    <component
      :is="modules[selectedModule]"
      v-if="modules[selectedModule]"
      :on-close="closeDialogModule"
      :task-id="userStore.selectedTask?.id"
      :on-select-widget="addWidget" />
  </DialogContainer>

  <SelectModuleDialog
    :already-added-widget-modules="widgetModulesInDashboardIds"
    :on-select-module="selectModule"
    :is-open="isSelectModuleDialogOpen"
    :on-close="closeDialogModule" />
</template>
