import {
  CreateDashboardMutation,
  DashboardUpdatedSubscription,
  DeleteDashboardMutation,
  GetDashboardsQuery,
  UpdateDashboardMutation,
} from '@/services/graphql/dashboard.graphql';
import type { Brand } from '@/types/brand.types';
import type { Dashboard } from '@/types/dashboard.types';

import type { Instance } from '@/types/instance.types';
import type { User } from '@/types/user.types';
import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable';

interface CreateDashboardPayload {
  name: string;
  userLogin?: string;
  topic?: string;
  instanceId?: string;
  thumb?: string;
  description?: string;
  data: Record<string, any>;
  brandId?: string;
  teamId?: string;
}
export const getAllDashboardsQuery = () =>
  useQuery<{
    dashboards: Dashboard[];
  }>(GetDashboardsQuery);

export const createDashboardMutation = () =>
  useMutation<{ createDashboard: Dashboard }, { dashboard: CreateDashboardPayload }>(CreateDashboardMutation, {
    update: (cache, { data }) => {
      const oldDashboards = cache.readQuery<{ dashboards: Dashboard[] }>({ query: GetDashboardsQuery });

      cache.writeQuery({
        query: GetDashboardsQuery,
        data: {
          dashboards: [
            ...(oldDashboards?.dashboards || []),
            {
              ...(data?.createDashboard || {}),
            },
          ],
        },
      });
    },
  });

export const updateDashboardMutation = () =>
  useMutation<{ updateDashboard: Dashboard }, { dashboard: Partial<Dashboard> }>(UpdateDashboardMutation);

export const deleteDashboardMutation = () =>
  useMutation<
    {
      deleteDashboard: {
        id: string;
        name: string;
        instance: Partial<Instance>;
        user: Partial<User>;
        brand: Partial<Brand>;
      };
    },
    { id: string }
  >(DeleteDashboardMutation, {
    update: (cache, { data }) => {
      if (!data) return;

      cache.evict({ id: cache.identify(data.deleteDashboard) });
    },
  });

export const dashboardUpdatedSubscription = () =>
  useSubscription<{ DashboardUpdated: Dashboard }>(DashboardUpdatedSubscription);
