<script setup lang="ts">
import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';
import { type Dashboard } from '@/types/dashboard.types';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { HeadlessTable, WithTooltip, type Column } from '@kleecks/ui-lib';
import { h } from 'vue';
import { useDeleteDashboard } from '@/hooks/dashboard/useDeleteDashboard';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import {
  TrashIcon,
  // DocumentDuplicateIcon,
  PencilSquareIcon,
  ArrowTopRightOnSquareIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';
import { useUser } from '@/hooks/user/useUser';
import { formattedDateMonthDDYYYY } from '@/utils/date.utils';
import { isDashbordInReadOnlyMode } from '@/utils/dashboard.utils';
import TypeCell from './TypeCell.vue';

interface DashboardsTableProps {
  dashboards: Dashboard[];
  brandName?: string;
  onSendDashboardToEdit: (dashboard: Dashboard) => void;
}

const props = defineProps<DashboardsTableProps>();
const deleteAlertStore = useDeleteAlertStore();
const { deleteDashboard } = useDeleteDashboard();

const { user, isSuperAdmin } = useUser();

const router = useRouter();

const navigateToDashboard = (id: string) => {
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: id } });
};

const columns: Column<Dashboard>[] = [
  {
    accessorKey: 'name',
    accessorFn: (value: Dashboard) =>
      h('div', { class: 'flex align-center gap-2' }, [
        h('div', { class: 'text-medium text-sm' }, value.name),
        h(LockClosedIcon, {
          class: [
            { hidden: user.value && !isDashbordInReadOnlyMode(value, user.value, isSuperAdmin.value) },
            'size-5 text-kl-icon',
          ],
        }),
      ]),
    header: 'Name',
  },
  {
    header: 'Topic',
    accessorFn: (value: Dashboard) => value.topic && h(TopicChip, { topic: value.topic }),
  },
  {
    header: 'Type',
    accessorFn: (value: Dashboard) => h(TypeCell, { dashboard: value, brandName: props.brandName }),
  },
  {
    header: 'Widgets',
    accessorFn: (value: Dashboard) => h('div', { class: 'pl-6' }, value.data?.objects?.length || 0),
  },
  {
    header: 'Created on',
    accessorFn: (value: Dashboard) => formattedDateMonthDDYYYY(value.createdAt?.toString() ?? ''),
  },
  {
    header: 'Options',
    cellClass: 'w-36',
    accessorFn: (dashboard: Dashboard) => {
      const children = [];
      children.push(
        h(
          WithTooltip,
          { label: 'Delete Dashboard' },
          {
            default: () =>
              h(TrashIcon, {
                class:
                  user.value && !isDashbordInReadOnlyMode(dashboard, user.value, isSuperAdmin.value)
                    ? 'w-5 text-kl-icon hover:text-kl-red-300 cursor-pointer'
                    : 'pointer-events-none w-5 text-kl-icon opacity-30',
                onClick: event => {
                  event.preventDefault();
                  event.stopPropagation();
                  deleteAlertStore.showDeleteAlert(`Are you sure to delete the dashboard ${dashboard.name}?`, () =>
                    deleteDashboard(dashboard.id)
                  );
                },
              }),
          }
        )
      );

      children.push(
        h(
          WithTooltip,
          { label: 'Edit Dashboard' },
          {
            default: () =>
              h(PencilSquareIcon, {
                class:
                  user.value && !isDashbordInReadOnlyMode(dashboard, user.value, isSuperAdmin.value)
                    ? 'w-5 text-kl-icon hover:text-kl-green-200 cursor-pointer'
                    : 'pointer-events-none w-5 text-kl-icon opacity-30',
                onClick: event => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.onSendDashboardToEdit(dashboard);
                },
              }),
          }
        )
      );
      // h(DocumentDuplicateIcon, {
      // class: 'w-5 text-kl-icon hover:text-active cursor-pointer',
      // onClick: event => {
      // event.preventDefault();
      // event.stopPropagation();
      // },
      // }),

      children.push(
        h(
          WithTooltip,
          { label: 'View Dashboard' },
          {
            default: () =>
              h(ArrowTopRightOnSquareIcon, {
                class: 'w-5 text-kl-icon hover:text-kl-green-300 cursor-pointer',
                onClick: event => {
                  event.preventDefault();
                  event.stopPropagation();
                  navigateToDashboard(dashboard.id);
                },
              }),
          }
        )
      );

      return h('div', { class: 'flex items-center justify-end gap-3' }, children);
    },
  },
];
</script>

<template>
  <HeadlessTable
    font-size="xs"
    :elements="props.dashboards"
    :columns="columns" />
</template>
