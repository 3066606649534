<script setup lang="ts">
import type { Brand } from '@/types/brand.types';
import { computed } from 'vue';

interface BrandTableInstancesCellProps {
  brand: Brand;
}

const props = defineProps<BrandTableInstancesCellProps>();

const instances = computed(() => {
  if (!props.brand.instances) return [];
  return Object.keys(props.brand.instances)
    .map(key => ({
      name: key,
      id: props.brand.instances![key as keyof typeof props.brand.instances]?.instanceId,
    }))
    ?.filter(el => !!el.id);
});
</script>

<template>
  <div class="flex flex-col gap-2">
    <div
      v-for="instance in instances"
      :key="instance.name"
      class="flex w-fit items-center gap-2 rounded-full bg-kl-violet-200/60 px-2 text-white">
      <b>{{ instance.name }}</b>
      <span>{{ instance.id }}</span>
    </div>
  </div>
</template>
