export const getLabelFromDictionary = (dictionary: any, key: string, hideFallback?: boolean) => {
  if (!dictionary) return key;

  const keys = key.split('.');

  try {
    return keys.reduce((o, i) => o[i], dictionary) || (!hideFallback ? key : undefined);
  } catch {
    return !hideFallback ? key : undefined;
  }
};
