<script setup lang="ts">
import { DialogContainer, Modal, BaseToggle, logoMain, Button } from '@kleecks/ui-lib';
import { ref } from 'vue';
import { vIntersectionObserver } from '@vueuse/components';
import { useUpdateUser } from '@/hooks/user/useUpdateUser';
import { useUser } from '@/hooks/user/useUser';

interface DisclaimerDialogProps {
  isOpen: boolean;
  onSubscribe: () => void;
  onDismiss: () => void;
}

const props = defineProps<DisclaimerDialogProps>();
const areTermsReaded = ref(false);
const isSubscribing = ref(false);
const isAllTextReaded = ref(false);
const updateUser = useUpdateUser();
const { user } = useUser();

const getIpAddress = async () => {
  const response = await fetch('https://checkip.amazonaws.com/"');
  const data = await response.text();
  return data?.trim();
};

const root = ref(null);

const onIntersectionObserver = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isAllTextReaded.value) return;
  isAllTextReaded.value = isIntersecting;
};

const handleSubscribe = async () => {
  try {
    isSubscribing.value = true;
    if (!user.value?.login) return;
    const ipAddress = await getIpAddress();
    updateUser.mutate({
      user: { acceptanceClauses: true, acceptanceClausesIp: ipAddress },
      login: user.value.login,
    });
    props.onSubscribe();
  } finally {
    isSubscribing.value = false;
  }
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="() => {}">
    <Modal
      :on-close="() => {}"
      hideHeader>
      <div class="flex h-[640px] max-h-[calc(100vh-100px)] w-[1024px] max-w-[calc(100vw-100px)] flex-col gap-y-6">
        <div class="shrink-0">
          <img
            :src="logoMain"
            class="mx-auto w-40" />
        </div>
        <div
          ref="root"
          class="h-full flex-1 overflow-y-auto rounded-sm border border-kl-gray-200 py-2 pl-2 pr-3.5">
          <div class="whitespace-normal text-justify text-kl-base text-kl-gray-400">
            <div class="space-y-4 p-4">
              <h1 class="mb-4 text-3xl font-bold">Terms of Use</h1>

              <!-- Recitals Section -->
              <section class="mb-8">
                <h2 class="mb-3 text-2xl font-semibold">Recitals</h2>
                <p class="mb-4">
                  Vivian S.r.l. (with registered offices in Milan, Viale Abruzzi 66, fiscal code 09232590969; “Vivian”)
                  has created the software KLEECKS, a Software as a Service powered by AI and machine learning with
                  search engine logics to maximise web optimisation performance and to improve overall web experience
                  for end users. (the “Kleecks Software”);
                </p>
                <p class="mb-4">
                  On the basis of the Kleecks Software characteristics and features, Vivian also created and developed a
                  bundle of services (the “Kleecks Services”), which can be directly or indirectly provided to Vivian’s
                  customers that (a) have an internet domain and a published website directly managed or run with
                  administration rights of the Content Management System (CMS) and of the intermediate Domain Name
                  System/s (DNS); and (b) directly or through intermediaries, are willing to benefit from one or more
                  Kleecks Services.
                </p>
                <p class="mb-4">
                  These Terms of Use apply to govern the use of Kleecks software by You (also, the “User”), by the mere
                  fact that the Kleecks Services have been activated for the Vivian’s customers’ website, for which you
                  declare to have a legitimate right to access being it understood that You are identified as an
                  “Authorised Personnel”.
                </p>
                <p class="mb-4">
                  Use of the Kleecks Software or the Kleecks Service shall be granted to the User up and until a valid
                  agreement between Vivian and its customer is valid and in full force and effect. In case of any
                  termination of such agreement, the User may not be granted access to such service without any prior
                  notice.
                </p>
              </section>

              <!-- Intellectual Property Section -->
              <section class="mb-8">
                <h2 class="mb-3 text-2xl font-semibold">Intellectual Property, copyright and prohibitions</h2>
                <p class="mb-4">
                  The User expressly acknowledges that the Kleecks Software is a software resulting from Vivian’s
                  creative activity, original pursuant to article 2, paragraph 1, no. 8, of Law No 633/1941.
                </p>
                <p class="mb-4">
                  Therefore, the User declares and acknowledges that the software, as well as all the trademarks, names,
                  copyrights, patents, know-how, and any other industrial and intellectual property used, connected, or
                  otherwise related to the Kleecks Software, including any and all future developments, updates,
                  customisations, releases, translations or modifications, including those implemented following
                  specific suggestions from any third party, including the User (the “Intellectual Property”), are the
                  exclusive property of Vivian.
                </p>
                <p class="mb-4">The User is not authorised to:</p>
                <ul class="mb-4 ml-8 list-disc">
                  <li>
                    Circumvent or avoid any technological protection measures present in the software or in the services
                    or related to them;
                  </li>
                  <li>Disassemble, decompile, decipher, modify, emulate any feature of the software;</li>
                  <li>Separate components of the software or the services;</li>
                  <li>
                    Publish, duplicate the software or the services, lease them, borrow them or rent them, sell them,
                    export them, import them or distribute them;
                  </li>
                  <li>Transfer the software, any software licence or any rights of access or use thereof;</li>
                  <li>
                    Use the services in unauthorised ways which may interfere with the use by others or the access to
                    services, data, accounts or network;
                  </li>
                  <li>Allow access to the services through unauthorised applications of third parties.</li>
                </ul>
              </section>

              <!-- Use of the Kleecks Software Section -->
              <section class="mb-8">
                <h2 class="mb-3 text-2xl font-semibold">Use of the Kleecks Software</h2>
                <p class="mb-4">
                  The Kleecks Software’s features shall be provided exclusively through Vivian’s servers. The User
                  undertakes to keep the Authentication Parameters strictly confidential, not to disclose them to third
                  parties and/or not to advertise them.
                </p>
                <p class="mb-4">
                  The User acknowledges that use of the Kleecks Software and the Kleecks Services does not entail any
                  transfer of the rights of use or their ownership nor the related Intellectual Property. It is
                  therefore expressly prohibited to the User to sell, sub-license to third parties, distribute to the
                  public and/or allow the use to third parties of, whether for free or upon consideration, the Kleecks
                  Software and/or any Kleecks Services and/or the related Intellectual Property.
                </p>
                <p class="mb-4">In Particular, the User:</p>
                <ul class="mb-4 ml-8 list-disc">
                  <li>
                    shall not transfer to third parties the Kleecks Software and/or any of the Kleecks Services and/or
                    the related Intellectual Property (including translations, compilations, whole or partial copies of
                    the software as part of modifications, derivative works, updates, etc.), without Vivian’s prior
                    written consent.
                  </li>
                  <li>
                    shall not make available to third parties the Kleecks Software and/or any of the Kleecks Services
                    and/or the related Intellectual Property, except those personnel with authorised access for the
                    purpose of carrying out activities exclusively, and eventually remotely, in their favour for the
                    purposes strictly related to its authorised use;
                  </li>
                  <li>
                    shall not modify the software used for the provision of the Kleecks Services (including the Kleecks
                    Software), nor incorporate it in whole or in part in other programmes nor decompile, copy or emulate
                    any component of the Kleecks Software.
                  </li>
                </ul>
                <p class="mb-4">
                  Any behaviour, act or omission committed in breach of these obligations - including the unauthorised
                  reproduction of the Kleecks Software and the unauthorised acquisition and/or disposal, even for free,
                  of a copy or copies of the Kleecks Software – may also be prosecuted as a criminal offence. Any
                  customisation of the Kleecks Software and/or of any Kleecks Services provided by Vivian (or other
                  partner of Vivian) under specific suggestions and/or requests by the User shall remain exclusive
                  property of Vivian, which shall have the right of transfer to third parties, inserting them into
                  and/or using them for the creation of other products and/or services, without the provision of
                  compensation to the User. In providing the Kleecks Software and/or any Kleecks Services, Vivian may
                  use, at its own discretion and expense, products and services offered, whether for free or upon
                  consideration, by other parties, deemed to be useful to improve the performance of the Kleecks
                  Software and/or the Kleecks Services.
                </p>
              </section>

              <!-- Confidentiality Section -->
              <section class="mb-8">
                <h2 class="mb-3 text-2xl font-semibold">Confidentiality</h2>
                <p class="mb-4">
                  The User undertakes to keep secret and strictly confidential all information related to the Kleecks
                  Software and/or to any Kleecks Services and its functions, as well as any other information it may be
                  aware of or that has been communicated (the “Confidential Information”).
                </p>
                <p class="mb-4">
                  The confidentiality obligation referred to in this clause continues to be effective and remains in
                  force even after the cessation, for any reason, of the use of the Kleecks Software and/or of the
                  Kleecks Services, and, in any event, until the Confidential Information enters into public domain.
                </p>
                <p class="mb-4">
                  The User undertakes to identify the Kleecks Software, the Kleecks Services, and the related
                  Intellectual Property as Vivian’s exclusive property also in any dispute with third parties. Vivian
                  shall recognize and identify the User’s trademarks, copyrights, designs, trade-dress, domain names and
                  other intellectual property as being the exclusive property of User.
                </p>
              </section>

              <section class="mb-8">
                <h2 class="mb-3 text-2xl font-semibold">Infrastructure of the Kleecks Software and data processing</h2>
                <p class="mb-4">
                  Infrastructure of the Kleecks Software and data processing In order to optimise the websites’
                  contents, the Kleecks Software operates as an intermediary (the so-called man in the middle) between
                  Vivian’s client and the visitor(s) of the User’s website(s) (the “Visitors”): it receives requests
                  from the Visitors, makes them in turn to the Vivian’s client’s server, receives the response,
                  optimises the responses received and sends them to the Vivian’s client’s server. To do so it must be
                  allowed to read the information exchanged between the Vivian’s client’s server and the Visitors. Even
                  the encrypted communications over HTTPS protocol are decrypted, optimised and then rewritten using the
                  SSL certificate provided to Vivian and installed on the servers on which Vivian operates (as a
                  Software as a Service) the Kleecks Software and consequently from which the Kleecks Services are
                  provided.
                </p>

                <p class="mb-4">
                  The Kleecks Software intervenes only on the data provided by the CMS of the Vivian’s client s website
                  which are in the public domain; the Kleecks Software cannot make connections autonomously to
                  restricted areas, to invoke web services or to directly access the data contained in the application
                  databases.
                </p>
                <p class="mb-4">
                  The User acknowledges that, in the performance of the Agreement, Vivian shall not process Visitors’
                  personal data previously collected by the User and on its behalf, with the exception of the Visitors’
                  IP addresses and only for activity log purposes. In this regard, Vivian hereby warrants that it will
                  obscure the last two digits of each Visitor's IP address.
                </p>

                <section class="mb-8">
                  <h2 class="mb-3 text-2xl font-semibold">Exemption from liability</h2>

                  <ul class="mb-4 ml-8 list-disc">
                    <li>
                      the User exempts Vivian from any liability for damages occurred as a consequence of hacker’s
                      attacks, thieves, cyber criminals, cracker, virus in its servers (CMS) or potential malfunctions
                      of the Kleecks Software caused by technical problems on machinery, servers, routers, telephone
                      lines, telematic networks of third parties to provide own and ancillary services in respect of the
                      Kleecks Services;
                    </li>
                    <li>
                      the User exempts Vivian from any liability for any malfunctioning of the Kleecks Services due to
                      non-compliance and/or obsolescence of the equipment with which the User is equipped;
                    </li>
                    <li>
                      the User exempts Vivian from any liability for any malfunctions, disservices, interruptions of the
                      Vivian’s client’s website/s that would result from the interruption of the Kleecks Services, if
                      the same interruption is not due to causes attributable to Vivian by way of fraud or gross
                      negligence;
                    </li>
                    <li>
                      Vivian is exempted from any liability for potential damages or malfunctions resulting from changes
                      or alterations of any kind made by the User, by Authorized Personnel and/or by third parties
                      unauthorized to the services/products governed by these Terms of Use (including but not limited
                      to: changes to codes, folders and/or file layout, their names).;
                    </li>
                    <li>
                      With the exception of wilful misconduct or gross negligence, Vivian’s liability shall not exceed
                      (i) the amounts received by Vivian in the execution of the agreement with its client or – in case
                      there is no direct agreement between Vivian and the User – (ii) the amounts received by Vivian in
                      the execution of the agreement with the Vivian’s partner that provided the Kleecks Software and
                      Kleecks Solutions to the User. Vivian shall act and represents that its services are and shall
                      continue to remain in compliance with the GDPR as well as the U.S. data process and privacy laws
                      eventually identified pursuant to para. E. In the event of a proven breach of such data process
                      and privacy laws by Vivian, the latter shall indeminify User for all costs and attorney’s fees
                      incurred should any claim be brought against User.
                    </li>
                  </ul>
                </section>
                <!-- Governing Law Section -->
                <section class="mb-8">
                  <h2 class="mb-3 text-2xl font-semibold">Governing law and jurisdiction</h2>
                  <p class="mb-4">
                    These Terms of Use shall be regulated by Italian Law and any controversies arising hereunder shall
                    be submitted to the exclusive jurisdiction of the Court of Milan (save for any mandatory venue
                    provided for by D.Lgs 207/2005).
                  </p>
                </section>

                <!-- Footer -->
                <footer class="mt-8 text-center">
                  <p class="text-gray-600">&copy; 2024 Vivian S.r.l. All rights reserved.</p>
                </footer>
              </section>
            </div>
          </div>
          <div v-intersection-observer="[onIntersectionObserver, { root, threshold: 1.0 }]"></div>
        </div>
        <BaseToggle
          v-model="areTermsReaded"
          label="I have read and accept to the terms and conditions"
          :disabled="!isAllTextReaded" />
        <div class="flex shrink-0 justify-end gap-x-2 text-white">
          <Button
            class="w-24"
            variant="red"
            @click="props.onDismiss">
            Logout
          </Button>
          <Button
            :disabled="!areTermsReaded"
            class="w-24"
            :is-loading="isSubscribing"
            :variant="areTermsReaded ? 'primary' : 'gray'"
            @click="handleSubscribe">
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
