<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';
import { createTeamMutation } from '@/services/queries/team.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { BaseTextInput, Button, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, watch } from 'vue';
import * as yup from 'yup';

interface CreateTeamDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const props = defineProps<CreateTeamDialogProps>();
const { notify } = useNotificationStore();

const yupSchema = yup.object().shape({
  name: yup.string().required('Team name is required'),
});

const { validateSchema, errors } = useSchemaValidation(yupSchema);
const teamValues = ref<{
  name: string;
}>({
  name: '',
});

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen)
      teamValues.value = {
        name: '',
      };
  }
);

const { mutate: createTeam } = createTeamMutation();

const handleClose = () => {
  props.onClose();
};

const handleCreateTeam = async () => {
  if (!(await validateSchema(teamValues.value))) return;
  const payload = {
    name: teamValues.value.name,
  };
  await createTeam({ team: payload });
  notify(DEFAULT_NOTIFICATIONS.TEAM_CREATED(teamValues.value.name));
  handleClose();
};

const isSaveEnabled = computed(() => teamValues.value.name !== '');
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-enter-key-press="handleCreateTeam"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      title="Create team"
      sub-title="Add a new team into the platform">
      <div
        class="flex w-96 flex-col gap-4"
        @submit.prevent="() => isSaveEnabled && handleCreateTeam()">
        <BaseTextInput
          id="team_name"
          v-model="teamValues.name"
          :status="{
            message: errors.name,
            valid: !errors.name,
          }"
          label="Name*"
          name="team_name" />
        <div class="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            :disabled="!isSaveEnabled"
            @click="handleCreateTeam">
            Create
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
