<script setup lang="ts">
import { LightBulbIcon } from '@heroicons/vue/20/solid';
import { PaperAirplaneIcon } from '@heroicons/vue/24/outline';
import { StandardContainer } from '@kleecks/ui-lib';
import { ref, watch } from 'vue';
import logoKleecky from '@/assets/logo_kleecky_gray.gif';
import { useChatbotStore } from '@/stores/useChatbotStore';
import { useChatbot } from '@/hooks/chatbot/useChatbot';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { chatbotCleanMemoryMutation } from '@/services/queries/chatbot.query';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import type { MODULES } from '@/constants/modules.constants';
import AiFeedList from '../ai-feed-list/AiFeedList.vue';

const chatbot = useChatbot();
const chatbotStore = useChatbotStore();

const instanceId = useRouteParam('instanceId');
const cleanMemoryMutation = chatbotCleanMemoryMutation();

watch(instanceId, () => {
  cleanMemoryMutation.mutate();
});

const searchedValue = ref('');
const showGif = ref(true);

const handleClose = () => {
  chatbotStore.closeChatbot();
  cleanMemoryMutation.mutate();
};

const handleSelectModule = (moduleId: MODULES, messageId: string) => {
  chatbot.selectModule(moduleId, messageId);
};

const handleSearch = () => {
  if (!searchedValue.value) return;
  chatbotStore.openChatbot();
  chatbot.sendMessage(searchedValue.value).then(() => {
    chatbotStore.setCountChatbotMessages(chatbot.aiMessages.value.filter(mex => mex.type === 'user').length);
  });
  searchedValue.value = '';
};
</script>

<template>
  <div class="relative">
    <div
      class="fixed left-0 w-full bg-kl-gray-100 transition-all duration-200"
      :class="{
        'top-0': !chatbotStore.isShowInPage,
        'top-[7rem]': chatbotStore.isShowInPage,
        'z-0': !chatbotStore.isOpen,
        'z-[99]': chatbotStore.isOpen,
      }">
      <div
        key="ai_feed_list"
        class="scrollbar-1 w-full grow overflow-y-auto transition-all duration-300"
        :class="{
          'h-0': !chatbotStore.isOpen,
          'h-[calc(100vh-(7rem+92px))]': chatbotStore.isOpen,
        }">
        <StandardContainer>
          <AiFeedList
            v-if="chatbotStore.isOpen"
            :messages="chatbot.aiMessages.value"
            :withUseRag="false"
            :on-select-module="handleSelectModule" />
        </StandardContainer>
      </div>
      <div class="flex h-[92px] w-full items-center justify-center bg-kl-violet-200 py-4">
        <StandardContainer>
          <div class="relative flex items-center gap-x-8">
            <div class="relative h-16 flex-1 overflow-hidden rounded-full">
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">
                <img
                  v-if="showGif"
                  :src="logoKleecky"
                  class="absolute left-3 h-16" />
                <PaperAirplaneIcon
                  v-else
                  class="absolute right-4 top-[22px] size-5 cursor-pointer text-kl-violet-400/50"
                  @click="handleSearch" />
              </transition>
              <input
                v-model="searchedValue"
                class="h-16 w-full cursor-pointer truncate border-none bg-kl-gray-100 py-5 pr-12 text-kl-base italic text-kl-gray-500 transition-all duration-200 ease-in-out placeholder:text-secondary focus:not-italic focus-visible:border-kl-blue-100 focus-visible:ring-0"
                :class="[
                  {
                    'pl-20': showGif,
                  },
                  {
                    'pl-5': !showGif,
                  },
                ]"
                type="text"
                placeholder="Hi! Ask me something…"
                @focusin="showGif = false"
                @focusout="showGif = true"
                @keyup.enter="handleSearch" />
            </div>
            <div class="flex w-[410px] justify-between">
              <div class="flex flex-col">
                <div class="flex items-center text-kl-xs font-medium text-white">
                  <LightBulbIcon class="relative left-[-2px] mb-1 size-5 text-white" />
                  Suggestions
                </div>

                <div class="flex items-center pl-1 text-xs italic text-white">
                  <div class="mr-2 size-2 rounded-full border border-white" />
                  How can I rewrite a meta title?
                </div>
                <div class="flex items-center pl-1 text-xs italic text-white">
                  <div class="mr-2 size-2 rounded-full border border-white" />
                  How can I submit a structured data?
                </div>
                <div class="flex items-center pl-1 text-xs italic text-white">
                  <div class="mr-2 size-2 rounded-full border border-white" />
                  ...
                </div>
              </div>
              <XMarkIcon
                class="cursor-pointer text-white"
                :class="{
                  'size-8': chatbotStore.isOpen,
                  'size-4': !chatbotStore.isOpen,
                }"
                @click="handleClose" />
            </div>
          </div>
        </StandardContainer>
      </div>
    </div>
  </div>
</template>
