<script setup lang="ts">
import { TASK_STATUS } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useUser } from '@/hooks/user/useUser';
import { useUserStore } from '@/stores/useUserStore';
import type { TaskAction, Task } from '@/types/task.types';
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  ArrowsRightLeftIcon,
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { WithTooltip } from '@kleecks/ui-lib';
import { computed } from 'vue';

interface TasksTableActionProps {
  task: Task;
  onAction: (taskId: string, action: TaskAction) => void;
}

const props = defineProps<TasksTableActionProps>();
const instanceId = useRouteParam('instanceId');
const userStore = useUserStore();
const { user } = useUser();
const isMyTask = computed(() => props.task.assignedTo?.find(el => el.login === user.value?.login));
const isFollowedUser = computed(
  () => !isMyTask.value && user.value?.follows?.find(u1 => props.task?.assignedTo?.find(u2 => u2.id === u1.id))
);

const isAdmin = computed(() => user.value?.admin);

const showWorkOnThisTask = computed(
  () =>
    props.task.instance?.instanceId === instanceId.value &&
    isMyTask.value &&
    props.task.status !== TASK_STATUS.PUBLISHED &&
    userStore.selectedTask?.id !== props.task.id
);

const showRejectTask = computed(
  () =>
    props.task.status !== TASK_STATUS.PUBLISHED &&
    isAdmin.value &&
    (props.task.status === TASK_STATUS.APPROVED || props.task.status === TASK_STATUS.IN_REVIEW)
);
const showApproveTask = computed(
  () =>
    props.task.status !== TASK_STATUS.PUBLISHED &&
    isAdmin.value &&
    (props.task.status === TASK_STATUS.DRAFT ||
      props.task.status === TASK_STATUS.IN_REVIEW ||
      props.task.status === TASK_STATUS.REJECTED)
);

const showReopenTask = computed(() => props.task.status === TASK_STATUS.APPROVED && isAdmin.value);

const showEditTask = computed(() => props.task.status !== TASK_STATUS.PUBLISHED && isAdmin.value);
const showDeleteTask = computed(() => props.task.status !== TASK_STATUS.PUBLISHED && isAdmin.value);
</script>

<template>
  <div class="flex items-center justify-end gap-2">
    <WithTooltip
      v-if="showReopenTask"
      label="Reopen task"
      @click="
        e => {
          e.stopPropagation();
          props.onAction(props.task.id, 'reopen');
        }
      ">
      <div class="size-5 cursor-pointer text-kl-icon hover:text-active">
        <ArrowLeftIcon />
      </div>
    </WithTooltip>
    <WithTooltip
      v-if="showApproveTask"
      label="Approve task"
      @click="
        e => {
          e.stopPropagation();
          props.onAction(props.task.id, 'approve');
        }
      ">
      <div class="size-5 cursor-pointer text-kl-icon hover:text-active">
        <CheckCircleIcon />
      </div>
    </WithTooltip>
    <WithTooltip
      v-if="showRejectTask"
      label="Reject task"
      @click="
        e => {
          e.stopPropagation();
          props.onAction(props.task.id, 'reject');
        }
      ">
      <div class="size-5 cursor-pointer text-kl-icon hover:text-active">
        <XMarkIcon />
      </div>
    </WithTooltip>
    <WithTooltip
      v-if="isFollowedUser || user?.admin || isMyTask"
      label="View preview"
      @click="
        e => {
          e.stopPropagation();
          props.onAction(props.task.id, 'view-sandbox');
        }
      ">
      <div class="size-5 cursor-pointer text-kl-icon hover:text-active">
        <ArrowTopRightOnSquareIcon />
      </div>
    </WithTooltip>

    <WithTooltip
      v-if="showWorkOnThisTask"
      label="Work on this task"
      @click="
        e => {
          e.stopPropagation();
          props.onAction(props.task.id, 'switch');
        }
      ">
      <div class="size-5 cursor-pointer text-kl-icon hover:text-active">
        <ArrowsRightLeftIcon />
      </div>
    </WithTooltip>
    <WithTooltip
      v-if="showEditTask"
      label="Edit task">
      <div
        class="size-5 cursor-pointer text-kl-icon hover:text-active"
        @click="
          e => {
            e.stopPropagation();
            props.onAction(props.task.id, 'edit');
          }
        ">
        <PencilIcon />
      </div>
    </WithTooltip>

    <WithTooltip
      v-if="showDeleteTask"
      label="Delete task">
      <div
        class="size-5 cursor-pointer text-kl-icon hover:text-active"
        @click="
          e => {
            e.stopPropagation();
            props.onAction(props.task.id, 'delete');
          }
        ">
        <TrashIcon />
      </div>
    </WithTooltip>
  </div>
</template>
