<script setup lang="ts">
import { UserProfileImage, DrawerContainer, WithTooltip } from '@kleecks/ui-lib';
import { useLogout } from '@/hooks/user/useLogout';
import { useUser } from '@/hooks/user/useUser';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ref } from 'vue';
import Profile from '@/components/profile/Profile.vue';
import { StarIcon } from '@heroicons/vue/24/solid';
// import { StarIcon } from '@heroicons/vue/24/outline';

const { logout } = useLogout();
const { user } = useUser();
const showDrawer = ref<boolean>(false);

const closeDrawer = () => {
  showDrawer.value = false;
};
</script>

<template>
  <Menu
    as="div"
    class="relative">
    <MenuButton class="flex rounded-full text-sm focus:shadow-custom">
      <span class="sr-only">Open user menu</span>
      <div class="relative">
        <WithTooltip :label="`${user?.nome} ${user?.cognome}`">
          <UserProfileImage
            v-if="user"
            :user="user"
            size="md" />
        </WithTooltip>
        <StarIcon
          v-if="user?.admin"
          class="absolute bottom-[-2px] right-[-4px] size-4 text-yellow-400" />
      </div>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="absolute right-0 top-10 z-10 min-w-80 origin-top-right divide-y divide-kl-gray-200 rounded-md bg-white shadow-xl ring-1 ring-kl-gray-200 focus:outline-none">
        <div class="flex items-start p-4">
          <UserProfileImage
            v-if="user"
            :user="user"
            size="md" />
          <div class="ml-2">
            <p class="text-sm font-medium text-primary">
              {{ user?.nome }}
              {{ user?.cognome }}
            </p>
            <p class="text-xs font-light text-secondary">{{ user?.team?.name }}</p>
            <p class="text-xs font-light text-secondary">
              {{ user?.login }}
            </p>
          </div>
        </div>
        <MenuItem
          v-slot="{ active }"
          class="py-2">
          <div
            :class="[active ? 'bg-gray-100' : '', 'block cursor-pointer px-4 py-1 text-sm text-gray-700']"
            @click="showDrawer = true">
            Your Profile
          </div>
        </MenuItem>
        <MenuItem
          v-slot="{ active }"
          class="py-2">
          <div
            :class="[active ? 'bg-gray-100' : '', 'block cursor-pointer px-4 py-1 text-sm text-gray-700']"
            @click="logout">
            Logout
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
  <DrawerContainer
    :isShowing="showDrawer"
    sub-title="Manage your profile settings"
    title="Your Profile"
    drawer-class="w-[100%] sm:w-[60%] max-w-[1200px]"
    :on-close="closeDrawer">
    <div class="mt-2 flex min-h-0 flex-1 flex-col border-t border-kl-gray-200 pt-8">
      <Profile :on-close="closeDrawer" />
    </div>
  </DrawerContainer>
</template>
