<script setup lang="ts">
import type { MODULES } from '@/constants/modules.constants';
import { MODULES_DESCRIPTIONS, MODULES_NAMES, STANDALONE_MODULES, WIDGET_MODULES } from '@/constants/modules.constants';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useDashboards } from '@/hooks/dashboard/useDashboards';
import { useUser } from '@/hooks/user/useUser';
import { updateDashboardMutation } from '@/services/queries/dashboard.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { generateUuid } from '@kleecks/code-lib';
import { BaseTextArea, BaseTextInput, Button, Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

interface AssignModuleToDashboardProps {
  moduleId: MODULES;
}
const props = defineProps<AssignModuleToDashboardProps>();

const { mutate: updateDashboard, loading } = updateDashboardMutation();
const { notify } = useNotificationStore();
const router = useRouter();
const dashboardId = ref('');
const brandId = useRouteParam('brandId');
const { user } = useUser();
const { privateDashboards } = useDashboards(brandId, user);
const dashboard = computed(() => privateDashboards.value?.find(d => d.id === dashboardId.value));

const filteredDashboards = computed(() => {
  if (STANDALONE_MODULES.includes(props.moduleId)) {
    return privateDashboards.value?.filter(d => !d.data?.objects?.length);
  }

  if (!WIDGET_MODULES.includes(props.moduleId)) {
    return privateDashboards.value;
  }
  // Show only dashboards where the module is not already added
  return privateDashboards.value?.filter(d => !d.data?.objects?.some(o => o.moduleId === props.moduleId));
});

const dashboardsOptions = computed(
  () =>
    filteredDashboards.value
      ?.filter(d => d.user?.id === user.value?.id || user.value?.admin)
      ?.map(x => ({ label: x.name, value: x.id })) ?? []
);
const emits = defineEmits<{
  (e: 'close'): void;
}>();

const handleClose = () => {
  emits('close');
};

const handleAssignModule = async () => {
  if (!WIDGET_MODULES.includes(props.moduleId) && !STANDALONE_MODULES.includes(props.moduleId)) {
    router.push({
      name: ROUTE_NAMES.DASHBOARD,
      params: { dashboardId: dashboardId.value },
      query: { moduleId: props.moduleId },
    });
    return;
  }

  await updateDashboard({
    dashboard: {
      id: dashboardId.value,
      data: {
        ...(dashboard.value?.data || {}),
        objects: [
          ...(dashboard.value?.data?.objects || []),
          {
            id: generateUuid(),
            moduleId: props.moduleId,
            type: 'modules',
          },
        ],
      },
    },
  });
  notify(DEFAULT_NOTIFICATIONS.DASHBOARD_MODULE_ADDED);
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: dashboardId.value } });
};
</script>

<template>
  <DialogContainer
    :full-screen="false"
    :is-open="true"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      title="Add module to dashboard"
      :sub-title="`Add the ${MODULES_NAMES[props.moduleId]} module to an existing dashboard.`">
      <form>
        <div class="flex w-[500px] gap-4">
          <div class="flex flex-1 flex-col gap-2">
            <BaseTextInput
              id="module_name"
              v-model="MODULES_NAMES[props.moduleId]"
              type="text"
              label="Module name"
              name="module_name"
              :disabled="true" />
            <BaseTextArea
              id="module_description"
              v-model="MODULES_DESCRIPTIONS[props.moduleId]"
              label="Module Description"
              name="module_description"
              :disabled="true" />
          </div>
          <div class="flex flex-1 flex-col gap-4 border-l border-sec pl-4">
            <div class="flex-1">
              <Combobox
                id="dashboard"
                v-model="dashboardId"
                label="Add to Dashboard*"
                value-expr="value"
                display-expr="label"
                name="dashboard"
                :items="dashboardsOptions"
                :disabled="!dashboardsOptions.length" />

              <p
                v-if="!dashboardsOptions.length"
                class="mt-4 text-xs text-kl-red-200">
                There are not available dashboards where you can insert this module.
              </p>
            </div>
            <div class="mt-8 flex justify-end">
              <Button
                variant="primary"
                size="md"
                :disabled="loading || !dashboardId"
                @click="handleAssignModule">
                Add
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </DialogContainer>
</template>
