import { getTaskActivities } from '@/services/queries/task.query';
import type { TaskActivity } from '@/types/task.types';
import type { MaybeRef } from 'vue';
import { computed } from 'vue';

export const useTaskActivities = (taskId: MaybeRef<string | undefined>) => {
  const query = getTaskActivities(taskId);

  const activities = computed<TaskActivity[]>(
    () => query.result.value?.getTask.activitiesObject?.moduleProperties || []
  );

  return {
    activities,
    ...query,
  };
};
