<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { DialogContainer, MessageModal } from '@kleecks/ui-lib';
import { type VNode } from 'vue';

export type DeleteAlertProps = {
  isOpen: boolean;
  onDelete: () => void;
  message?: string;
  messageComponent?: VNode;
  onClose: () => void;
};

const props = withDefaults(defineProps<DeleteAlertProps>(), {
  message: undefined,
  messageComponent: undefined,
});

const handleClose = () => {
  props.onClose();
};

const handleDelete = () => {
  props.onDelete();
  handleClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    z-index-class="z-[12]"
    :on-close="props.onClose">
    <MessageModal
      data-testid="delete-alert-modal"
      :on-close="handleClose"
      :on-confirm="handleDelete"
      confirm-variant="red"
      :message="props.message"
      :is-open="props.isOpen"
      :icon="ExclamationTriangleIcon"
      title="Confirm delete?">
      <template #default>
        <component :is="props.messageComponent" />
      </template>
    </MessageModal>
  </DialogContainer>
</template>
