<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { SERVER_BASE_URL } from '@/constants/settings.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useUpdateBrand } from '@/hooks/brand/useUpdateBrand';
import { useDisableInstance } from '@/hooks/instance/useDisableInstance';
import { useEnableInstance } from '@/hooks/instance/useEnableInstance';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useServerInstances } from '@/hooks/instance/useServerInstances';
import { useRefreshInstances } from '@/hooks/instance/useRefreshInstances';
import type { Brand } from '@/types/brand.types';
import { getCookie, COOKIES } from '@kleecks/code-lib';
import { BaseTextInput, BaseToggle, Button, DialogContainer, Modal, FileUploader, Combobox } from '@kleecks/ui-lib';
import { computed, ref, toRef, watch } from 'vue';
import * as yup from 'yup';
import { useBrand } from '@/hooks/brand/useBrand';

interface EditBrandDialogProps {
  isOpen: boolean;
  onClose: () => void;
  brand: Brand;
}

const props = defineProps<EditBrandDialogProps>();
const { mutate: updateBrand } = useUpdateBrand();
const { notify } = useNotificationStore();
const { enableInstance } = useEnableInstance();
const { disableInstance } = useDisableInstance();
const { refetch: refetchBrands } = useBrands();
const brandIdRef = toRef(() => props.brand.id);
const { refetch: refetchBrand } = useBrand(brandIdRef);
const { instances, refetch: refetchInstances } = useServerInstances();
const { refreshInstances, loading } = useRefreshInstances();

const uploadedFile = ref<File | null>(null);
const isSaving = ref(false);
const yupSchema = yup.object().shape({
  name: yup.string().required('Brand name is required'),
  label: yup.string().required('Brand label is required'),
  mainUrl: yup.string().nullable(),
  PREPROD: yup.string().nullable(),
  PROD: yup.string().nullable(),
  STAGING: yup.string().nullable(),
});

const { validateSchema, errors } = useSchemaValidation(yupSchema);

const brandValues = ref<{
  name: string;
  label: string;
  mainUrl?: string;
  PREPROD?: string;
  PROD?: string;
  STAGING?: string;
  preprodEnabled: boolean;
  prodEnabled: boolean;
  stagingEnabled: boolean;
}>({
  name: props.brand.name,
  label: props.brand.label,
  mainUrl: props.brand.mainUrl,
  preprodEnabled: props.brand.instances?.preprod?.enabled!,
  prodEnabled: props.brand.instances?.prod?.enabled!,
  stagingEnabled: props.brand.instances?.staging?.enabled!,
  PREPROD: props.brand.instances?.preprod?.instanceId,
  PROD: props.brand.instances?.prod?.instanceId,
  STAGING: props.brand.instances?.staging?.instanceId,
});

const handleClose = () => {
  props.onClose();
};

const uploadPhoto = async () => {
  const url = `${SERVER_BASE_URL}/images/brand`;

  const formData = new FormData();
  formData.append('brand', props.brand.name);
  formData.append('icon', 'false');
  formData.append('image', uploadedFile.value!);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${getCookie(COOKIES.TOKEN_KEY)}`);

  const method = props.brand.image ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    redirect: 'follow',
    headers,
    body: formData,
  });

  return response;
};

const handleSaveBrand = async () => {
  try {
    isSaving.value = true;
    if (!(await validateSchema(brandValues.value))) return;

    if (uploadedFile.value) {
      await uploadPhoto();
    }
    await updateBrand({
      brand: {
        name: props.brand.name,
        label: brandValues.value.label,
        instances: {
          PREPROD: brandValues.value.PREPROD,
          PROD: brandValues.value.PROD,
          STAGING: brandValues.value.STAGING,
        },
      },
    });

    if (brandValues.value.preprodEnabled !== props.brand.instances?.preprod?.enabled) {
      if (brandValues.value.preprodEnabled) {
        await enableInstance(brandValues.value.PREPROD!);
      } else {
        await disableInstance(brandValues.value.PREPROD!);
      }
    }

    if (brandValues.value.prodEnabled !== props.brand.instances?.prod?.enabled) {
      if (brandValues.value.prodEnabled) {
        await enableInstance(brandValues.value.PROD!);
      } else {
        await disableInstance(brandValues.value.PROD!);
      }
    }

    if (brandValues.value.stagingEnabled !== props.brand.instances?.staging?.enabled) {
      if (brandValues.value.stagingEnabled) {
        await enableInstance(brandValues.value.STAGING!);
      } else {
        await disableInstance(brandValues.value.STAGING!);
      }
    }

    await refetchBrand();
    await refetchBrands();
    notify(DEFAULT_NOTIFICATIONS.BRAND_UPDATED(brandValues.value.name!));
    handleClose();
  } finally {
    isSaving.value = false;
  }
};

const isSaveEnabled = computed(() => brandValues.value.name !== '');

watch(
  () => props.isOpen,
  () => {
    if (!props.isOpen) return;
    brandValues.value = {
      name: props.brand?.name,
      label: props.brand?.label,
      mainUrl: props.brand?.mainUrl,
      PREPROD: props.brand?.instances?.preprod?.instanceId,
      PROD: props.brand?.instances?.prod?.instanceId,
      STAGING: props.brand?.instances?.staging?.instanceId,
      preprodEnabled: props.brand?.instances?.preprod?.enabled!,
      prodEnabled: props.brand?.instances?.prod?.enabled!,
      stagingEnabled: props.brand?.instances?.staging?.enabled!,
    };
  }
);
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-enter-key-press="handleSaveBrand"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      title="Edit brand"
      :sub-title="`Edit the brand ${props.brand?.name}`">
      <div class="flex w-[600px] flex-col gap-8">
        <div class="flex items-start gap-4 divide-x divide-kl-gray-100">
          <div class="flex flex-1 flex-col gap-4">
            <BaseTextInput
              id="brand_id"
              v-model="brandValues.name"
              placeholder="Brand id"
              label="Id"
              name="brand_id"
              :disabled="true"
              :status="{
                message: errors.name,
                valid: !errors.name,
              }" />

            <BaseTextInput
              id="brand_name"
              v-model="brandValues.label"
              placeholder="Brand name"
              label="Name*"
              name="brand_name"
              :status="{
                message: errors.label,
                valid: !errors.label,
              }" />

            <BaseTextInput
              id="brand_main_url"
              v-model="brandValues.mainUrl"
              placeholder="Main URL"
              label="Main URL"
              :status="{
                message: errors.mainUrl,
                valid: !errors.mainUrl,
              }"
              name="brand_main_url" />
          </div>
          <div class="flex flex-1 flex-col gap-4 pl-4">
            <Combobox
              id="brand_preprod"
              v-model="brandValues.PREPROD"
              :items="instances"
              placeholder="Preprod instance ID"
              :status="{
                message: errors.PREPROD,
                valid: !errors.PREPROD,
              }"
              name="brand_preprod">
              <template #forToggle>
                <BaseToggle
                  v-model="brandValues.preprodEnabled"
                  label="Preprod"
                  size="sm"
                  name="brand_preprod" />
              </template>
            </Combobox>
            <Combobox
              id="brand_staging"
              v-model="brandValues.STAGING"
              :items="instances"
              placeholder="Staging instance ID"
              :status="{
                message: errors.STAGING,
                valid: !errors.STAGING,
              }"
              name="brand_staging">
              <template #forToggle>
                <BaseToggle
                  v-model="brandValues.stagingEnabled"
                  label="Staging"
                  size="sm"
                  name="brand_staging" />
              </template>
            </Combobox>
            <Combobox
              id="brand_prod"
              v-model="brandValues.PROD"
              :items="instances"
              placeholder="Prod instance ID"
              :status="{
                message: errors.PROD,
                valid: !errors.PROD,
              }"
              name="brand_prod">
              <template #forToggle>
                <BaseToggle
                  v-model="brandValues.prodEnabled"
                  label="Production"
                  size="sm"
                  name="brand_prod" />
              </template>
            </Combobox>
            <div class="self-end">
              <Button
                variant="primary"
                size="sm"
                :is-loading="loading"
                @click="
                  async () => {
                    await refreshInstances();
                    refetchInstances();
                  }
                ">
                Refresh Instances
              </Button>
            </div>
          </div>
        </div>
        <div>
          <label class="text-xs text-primary">Brand image</label>
          <div class="p-2">
            <FileUploader
              v-model="uploadedFile"
              :image="props.brand.image" />
          </div>
        </div>
        <div class="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            :is-loading="isSaving"
            :disabled="!isSaveEnabled"
            @click="handleSaveBrand">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
