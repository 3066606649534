import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCreateTaskDialog = defineStore('createTaskDialog', () => {
  const isOpenValue = ref(false);

  const showCreateTaskDialog = () => {
    isOpenValue.value = true;
  };

  const closeCreateTaskDialog = () => {
    isOpenValue.value = false;
  };

  return {
    isOpenValue,
    showCreateTaskDialog,
    closeCreateTaskDialog,
  };
});
