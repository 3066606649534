<script setup lang="ts">
import ActivitiesList from '@/components/activities/activities-list/ActivitiesList.vue';
import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';
import { useInstance } from '@/hooks/instance/useInstance';
import type { Task } from '@/types/task.types';
import { formatDate } from '@/utils/date.utils';
import { Tabs, DrawerContainer, TaskStatusChip, Button, WithTooltip } from '@kleecks/ui-lib';
import { computed, ref, toRef } from 'vue';

import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useUserStore } from '@/stores/useUserStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useUser } from '@/hooks/user/useUser';

import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import TasksListItemCondensed from '@/components/tasks/tasks-list-item-condensed/TasksListItemCondensed.vue';
import { useRouter } from 'vue-router';
import { ROUTE_NAMES } from '@/constants/routes.constants';

interface TasksDetailsDrawerProps {
  task?: Task;
  onClose(): void;
  drawerClass?: string;
  isShowing: boolean;
}

const selectedTabId = ref('details');
const props = defineProps<TasksDetailsDrawerProps>();
const { mutate: updateTask, loading: updatingTask } = useUpdateTask();
const userStore = useUserStore();
const notificationStore = useNotificationStore();
const { navigateToSandbox } = useSandboxNavigate();
const { user } = useUser();
const isTaskAssignedToUser = computed(() => props.task?.assignedTo?.some(u => u.id === user?.value?.id));
const instanceIdRef = toRef(() => props.task?.instance?.instanceId);
const brandIdRef = toRef(() => props.task?.brand?.name);
const { instanceName } = useInstance(brandIdRef, instanceIdRef);
const { instanceTasks } = useInstanceTasks(brandIdRef, instanceIdRef);
const userTasks = computed(() => instanceTasks.value.filter(t => t.assignedTo.some(u => u.id === user?.value?.id)));
const router = useRouter();

const canSeePreview = computed(
  () =>
    user.value?.admin ||
    user.value?.id === props.task?.assignedTo?.[0]?.id ||
    user.value?.follows?.some(
      u =>
        u.id === props.task?.assignedTo?.[0]?.id ||
        user.value?.admin ||
        props.task?.assignedTo?.[0]?.id === user.value?.id
    )
);

const tabs = computed(() => {
  const res: {
    name: string;
    id: string;
    count?: number;
  }[] = [{ name: 'Details', id: 'details' }];

  if (
    props.task?.assignedTo?.find(x => x.id === user.value?.id) ||
    user.value?.admin ||
    user.value?.follows?.find(x => x.id === props.task?.assignedTo?.[0]?.id)
  ) {
    res.push({ name: 'Activities', id: 'activities', count: props.task?.activitiesObject?.countNotMilestones ?? 0 });
  }

  if (props.task?.assignedTo?.find(x => x.id === user.value?.id)) {
    res.push({ name: 'My Tasks', id: 'my-tasks', count: userTasks.value.length });
  }

  if (user.value?.admin) {
    res.push({ name: 'All Tasks', id: 'all-tasks', count: instanceTasks?.value?.length });
  }
  return res;
});

const handleChangeTab = (tabId: string) => {
  if (tabId === 'all-tasks') {
    router.push({ name: ROUTE_NAMES.TASKS_MANAGEMENT });
    props.onClose();
    return;
  }

  selectedTabId.value = tabId;
};
const workOnThisTask = () => {
  userStore.setSelectedTaskId(props.task!.id!);
  notificationStore.notify({
    title: 'Task changed',
    message: `Now you're working on ${userStore.selectedTask?.title}`,
    type: 'info',
  });
};

const sendInReview = async () => {
  if (!userStore.selectedTask) throw new Error('No task selected');
  await updateTask({
    task: {
      id: userStore.selectedTask.id,
      status: TASK_STATUS.IN_REVIEW,
    },
  });
  notificationStore.notify(DEFAULT_NOTIFICATIONS.TASK_SENT_IN_REVIEW(userStore.selectedTask.title));
};

const reopenTask = async () => {
  if (userStore.selectedTask) {
    await updateTask({
      task: {
        id: userStore.selectedTask.id,
        status: TASK_STATUS.DRAFT,
      },
    });
    notificationStore.notify(DEFAULT_NOTIFICATIONS.TASK_REOPENED(userStore.selectedTask.title));
  }
};

const viewPreview = () => {
  navigateToSandbox({
    userLogin: props.task?.assignedTo?.[0]?.login,
    taskId: props.task?.id,
    url: `https://${props.task?.instance?.domains?.[0]?.domain}`,
  });
};
</script>

<template>
  <DrawerContainer
    :title="task?.title"
    :isShowing="props.isShowing"
    :drawer-class="props.drawerClass"
    :on-close="props.onClose">
    <template #afterTitle>
      <TaskStatusChip
        v-if="task?.status"
        :task="task"
        class="ml-2" />
    </template>
    <template #header>
      <div
        tabindex="0"
        @keydown.enter="
          () => {
            navigateToSandbox({
              taskId: props.task?.id,
              url: `https://${props.task?.instance?.domains?.[0]?.domain}`,
            });
          }
        "
        @click="
          () => {
            navigateToSandbox({
              taskId: props.task?.id,
              url: `https://${props.task?.instance?.domains?.[0]?.domain}`,
            });
          }
        ">
        <WithTooltip
          v-if="
            props.task?.status !== TASK_STATUS.PUBLISHED && props.task?.status !== TASK_STATUS.CREATING && canSeePreview
          "
          label="View Preview"
          @click="viewPreview">
          <ArrowTopRightOnSquareIcon class="size-5 cursor-pointer text-kl-icon hover:text-active" />
        </WithTooltip>
      </div>
    </template>
    <template v-if="props.isShowing">
      <Tabs
        :currentTabId="selectedTabId"
        tab-size-class="w-1/3"
        tab-header-size="w-full"
        :tabs="tabs"
        :on-change="handleChangeTab"
        size="md"
        :selected-tab="0"
        class="mt-4" />

      <div class="min-h-0 flex-1 overflow-y-auto">
        <div
          v-if="selectedTabId === 'activities'"
          class="overflow-y-auto p-2 pt-6">
          <ActivitiesList
            v-if="props.task"
            :task="props.task" />
        </div>
        <div
          v-if="selectedTabId === 'details'"
          class="flex flex-col divide-gray-secondary px-2 py-2">
          <div
            v-if="task?.description"
            class="border-b border-kl-gray-200 py-6">
            <p class="text-sm font-light text-kl-gray-400">{{ task?.description }}</p>
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-sm text-kl-gray-400">Status</span>
            </div>
            <TaskStatusChip
              v-if="task?.status"
              :task="task" />
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-xs text-kl-gray-400">Topic</span>
            </div>
            <TopicChip
              v-if="task?.topic"
              :topic="task?.topic" />
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-xs text-kl-gray-400">Brand</span>
            </div>
            <span class="text-sm font-medium text-kl-gray-500">{{ task?.brand?.label }}</span>
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-xs text-kl-gray-400">Project</span>
            </div>
            <span class="text-sm font-medium text-kl-gray-500">{{ instanceName }}</span>
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-xs text-kl-gray-400">Assigned To</span>
            </div>
            <span class="text-sm font-medium text-kl-gray-500"
              >{{ task?.assignedTo?.[0]?.nome }}
              {{ task?.assignedTo?.[0]?.cognome }}
            </span>
          </div>
          <div class="flex items-center border-b border-sec py-2">
            <div class="flex-1 items-center">
              <span class="text-xs text-kl-gray-400">Created On</span>
            </div>
            <span class="text-sm font-medium text-kl-gray-500">{{
              task?.createdAt && formatDate(task.createdAt)
            }}</span>
          </div>
          <span class="text-sm font-medium text-kl-gray-500"></span>
        </div>
        <ul
          v-if="selectedTabId === 'my-tasks'"
          class="divide-y divide-kl-gray-200 overflow-y-auto p-2">
          <TasksListItemCondensed
            v-for="t in userTasks"
            :key="t.id"
            :data-cy="`task-item-${t.id}`"
            auto-expand
            hide-options
            :on-click="() => (selectedTabId = 'details')"
            :is-current="userStore.selectedTask?.id === t.id"
            :task="t"
            :on-action="() => {}" />
        </ul>
      </div>
      <div class="flex items-center pt-4">
        <div class="flex-1">
          <div
            v-if="
              props.task?.status !== TASK_STATUS.PUBLISHED &&
              props.task?.status !== TASK_STATUS.CREATING &&
              canSeePreview
            "
            class="group ml-4 flex cursor-pointer items-center gap-2 text-kl-sm text-secondary hover:underline"
            @click="viewPreview">
            <ArrowTopRightOnSquareIcon class="size-5 cursor-pointer text-kl-icon group-hover:text-active" />
            View Preview
          </div>
        </div>
        <div v-if="props.task?.id === userStore.selectedTask?.id && !!props.task?.id">
          <Button
            v-if="props.task?.status === TASK_STATUS.IN_REVIEW || props.task?.status === TASK_STATUS.APPROVED"
            variant="red"
            size="xs"
            :is-loading="updatingTask"
            @click="reopenTask">
            Re-open task
          </Button>
          <Button
            v-else-if="props.task.status === TASK_STATUS.DRAFT || props.task.status === TASK_STATUS.REJECTED"
            variant="primary"
            size="xs"
            :is-loading="updatingTask"
            @click="sendInReview">
            Send for Review
          </Button>
        </div>
        <div
          v-if="
            isTaskAssignedToUser &&
            props.task?.id !== userStore.selectedTask?.id &&
            props.task?.status !== TASK_STATUS.PUBLISHED &&
            props.task?.status !== TASK_STATUS.CREATING
          ">
          <Button
            variant="blue"
            @click="workOnThisTask">
            Work this task
          </Button>
        </div>
      </div>
    </template>
  </DrawerContainer>
</template>
