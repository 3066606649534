<script setup lang="ts">
import { COOKIES, getCookie } from '@kleecks/code-lib';
import { computed } from 'vue';

const authenticationUrl = computed(() => `https://kwiki.kleecks.com/setAuth?kToken=${getCookie(COOKIES.TOKEN_KEY)}`);
</script>

<template>
  <iframe
    :src="authenticationUrl"
    class="h-screen w-screen" />
</template>
