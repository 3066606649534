import type { Topic } from '@kleecks/code-lib';

export const INSTANCE_MAP = {
  staging: 'Staging',
  preprod: 'PreProduction',
  prod: 'Production',
};

export const INSTANCE_PANEL_COLORS_MAP: {
  [key in Topic]?: string;
} = {
  settings: 'text-kl-violet-200 border-kl-violet-200',
  strategy: 'text-kl-cyan border-kl-cyan',
  seo: 'text-kl-gold border-kl-gold',
  issues: 'text-[#E56554] border-[#E56554]',
  ux: 'text-kl-violet-100 border-kl-violet-100',
};

export const INSTANCES_PANEL_BG_COLORS_MAP: {
  [key in Topic]?: string;
} = {
  settings: 'bg-kl-violet-200',
  strategy: 'bg-kl-cyan',
  seo: 'bg-kl-yellow-200',
  issues: 'bg-[#E56554]',
  ux: 'bg-kl-violet-100',
};

export const INSTANCES_PANEL_BG_COLORS_ASCII_MAP: {
  [key in Topic]?: string;
} = {
  settings: '#564AA2',
  strategy: '#53B6E5',
  seo: '#FED717',
  issues: '#FF655E',
  ux: '#98288B',
};

export const INSTANCE_PANEL_TITLE_MAP: {
  [key in Topic]?: string;
} = {
  settings: 'Settings',
  strategy: 'Strategy',
  seo: 'SEO',
  issues: 'Issues',
  ux: 'UX',
};
