import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { useBrandWithTasks } from '../tasks/useBrandWithTasks';

export const useInstanceTasks = (brandId: MaybeRef<string | undefined>, instanceId: MaybeRef<string | undefined>) => {
  const { loading, tasks } = useBrandWithTasks(brandId);

  const instanceTasks = computed(() => {
    const res = tasks.value.filter(({ instance }) => instance?.instanceId === unref(instanceId));
    return res;
  });

  return {
    instanceTasks,
    loading,
  };
};
