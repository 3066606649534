<script setup lang="ts">
// import { ref, watch } from 'vue';

interface TasksCounterProps {
  total: number;
  assigned: number;
  label: string;
  size?: 'sm' | 'md' | 'lg';
  color: 'red' | 'green' | 'aqua' | 'gray' | 'yellow' | 'purple' | 'pink' | 'violet' | 'darkviolet';
}

const props = defineProps<TasksCounterProps>();
// const animatePing = ref(false);

// watch(
//   () => props.value,
//   (newValue, oldValue) => {
//     if (newValue > oldValue) {
//       animatePing.value = true;
//       setTimeout(() => {
//         animatePing.value = false;
//       }, 2000);
//     }
//   }
// );
</script>

<template>
  <div
    :class="[
      'flex  cursor-pointer flex-col justify-between rounded shadow-custom transition-colors duration-500 hover:shadow-lg',
      {
        'size-[60px]': !props.size || props.size === 'lg',
        'size-[52px]': props.size === 'md',
        'size-[40px]': props.size === 'sm',
        'bg-kl-red-200 text-kl-gray-600/50': props.color === 'red',
        'bg-kl-green-200 text-white/50': props.color === 'green',
        'bg-kl-cyan text-kl-gray-600/50': props.color === 'aqua',
        'bg-kl-gray-300 text-white/50': props.color === 'gray',
        'bg-kl-yellow-200 text-kl-gray-600/50': props.color === 'yellow',
        'bg-kl-violet-100 text-white/50': props.color === 'purple',
        'bg-kl-pink-100 text-white/50': props.color === 'pink',
        'bg-kl-violet-200 text-white/50': props.color === 'violet',
        'bg-kl-violet-500 text-white/50': props.color === 'darkviolet',
      },
    ]">
    <div class="flex flex-1 px-2 text-left">
      <span
        class="block self-end font-medium leading-normal text-white"
        :class="{
          'text-2xl': !props.size || props.size === 'lg',
          'text-xl': props.size === 'md',
          'text-lg': props.size === 'sm',
        }"
        data-testid="project-counter-value"
        >{{ props.assigned }}</span
      >
    </div>
    <div class="flex h-[20px] items-center justify-center rounded-b-sm bg-[#0000002e] text-center text-xs text-white">
      {{ props.label }}
    </div>
  </div>
</template>
