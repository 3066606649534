import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useUser } from '@/hooks/user/useUser';
import {
  CloneTaskMutation,
  CreateTaskMutation,
  DeleteTaskMutation,
  PublishTaskMutation,
  TaskUpdatedSubscription,
  TaskDeletedSubscription,
  TaskCreatedSubscription,
  UpdateTaskMutation,
  GetTaskActivities,
} from '@/services/graphql/task.graphql';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Task, TaskStatus } from '@/types/task.types';
import { addNewTaskToCache, deleteTaskFromCache } from '@/utils/cache.utils';
import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable';
import { unref, type MaybeRef } from 'vue';

export type TaskCreateInput = {
  assignedBy: string;
  assignedTo: string[];
  brand: string;
  description: string;
  endTime: Date;
  priority: string;
  progressH?: number;
  startTime: Date;
  title: string;
  totH: number;
  topic: string;
  status: TaskStatus;
  instanceId: string;
  includeApproved: boolean;
};

export type TaskUpdateInput = {
  id: string;
} & Partial<Omit<TaskCreateInput, 'brand' | 'includeApproved'>>;

interface PublishTaskInput {
  publishTaskId: string;
  instanceId: string;
}

export const updateTaskMutation = () =>
  useMutation<
    {
      updateTask: Partial<Task>;
    },
    { task: TaskUpdateInput }
  >(UpdateTaskMutation);

export const useCreateTask = () => {
  const { notify } = useNotificationStore();
  const { user } = useUser();
  return useMutation<
    {
      createTask: Partial<Task>;
    },
    { task: TaskCreateInput }
  >(CreateTaskMutation, {
    update: (cache, { data }) => {
      if (!data?.createTask) return;
      if (
        !addNewTaskToCache(cache, data?.createTask, {
          updateAssignedToMe: data?.createTask.assignedTo?.some(el => el.id === user.value!.id),
        })
      )
        return;
      notify(DEFAULT_NOTIFICATIONS.TASK_CREATED(data.createTask?.title!));
    },
  });
};

export const useDeleteTask = () => {
  const { notify } = useNotificationStore();
  const { user } = useUser();

  return useMutation<
    {
      deleteTask: Partial<Task>;
    },
    {
      taskId: string;
    }
  >(DeleteTaskMutation, {
    update: (cache, { data }) => {
      notify(DEFAULT_NOTIFICATIONS.TASK_DELETED(data?.deleteTask?.title!));
      if (!data?.deleteTask) return;
      deleteTaskFromCache(cache, data?.deleteTask, {
        updateAssignedToMe: data?.deleteTask.assignedTo?.some(el => el.id === user.value!.id),
      });
    },
  });
};

export const useCloneTask = () =>
  useMutation<{ cloneTask: Task }, { cloneTaskId: string; instanceId: string }>(CloneTaskMutation, {
    update: (cache, { data }) => {
      if (!data?.cloneTask) return;
      addNewTaskToCache(cache, data?.cloneTask);
    },
  });

export const usePublishTask = () =>
  useMutation<
    {
      publishTask: Partial<Task>;
    },
    PublishTaskInput
  >(PublishTaskMutation);

export const getTaskActivities = (taskId: MaybeRef<string | undefined>) =>
  useQuery<
    { getTask: Partial<Task> },
    {
      taskId: string;
    }
  >(
    GetTaskActivities,
    () => ({
      taskId: unref(taskId)!,
    }),
    () => ({
      enabled: !!unref(taskId),
    })
  );

export const taskUpdatedSubscription = () => useSubscription<{ TaskUpdated: Partial<Task> }>(TaskUpdatedSubscription);
export const taskDeletedSubscription = () => useSubscription<{ TaskDeleted: Partial<Task> }>(TaskDeletedSubscription);
export const taskCreatedSubscription = () => useSubscription<{ TaskCreated: Partial<Task> }>(TaskCreatedSubscription);
