import { enableInstanceMutation } from '@/services/queries/instance.query';

export const useEnableInstance = () => {
  const mutation = enableInstanceMutation();

  const enableInstance = async (instanceId: string): Promise<boolean> => {
    const res = await mutation.mutate({
      instanceId,
    });

    return !!res?.data?.enableInstance;
  };

  return {
    ...mutation,
    enableInstance,
  };
};
