<script setup lang="ts">
import { DropdownMenu, TaskStatusChip, UserProfileImage, WithTooltip } from '@kleecks/ui-lib';

import { TASK_PRIORITY_COLOR, TASK_PRIORITY_LABEL } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useTaskActions } from '@/hooks/tasks/useTasksActions';
import { useCurrentTaskDrawerStore } from '@/stores/useCurrentTaskDrawerStore';
import type { Task, TaskAction } from '@/types/task.types';
import { formatDate } from '@/utils/date.utils';
import { useIntersectionObserver } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';
import { useUser } from '@/hooks/user/useUser';

interface TasksListItemCondensedProps {
  task: Task;
  isPublishEnabled?: boolean;
  isScheduleEnabled?: boolean;
  isDraggable?: boolean;
  isCloneEnabled?: boolean;
  hideOptions?: boolean;
  root?: HTMLElement | null;
  isCurrent?: boolean;
  autoExpand?: boolean;
  onAction: (taskId: string, action: TaskAction) => void;
  onClick?: () => void;
}

const props = defineProps<TasksListItemCondensedProps>();
const instanceId = useRouteParam('instanceId');
const brandId = useRouteParam('brandId');
const { instanceName } = useInstance(brandId, props.task.instance?.instanceId);

const taskDrawerStore = useCurrentTaskDrawerStore();
const taskRef = toRef(() => props.task);
const { user } = useUser();
const isMyTask = computed(() => props.task.assignedTo?.find(el => el.login === user.value?.login));

const isFollowedUser = computed(
  () => !isMyTask.value && user.value?.follows?.find(u1 => props.task?.assignedTo?.find(u2 => u2.id === u1.id))
);
const { actions } = useTaskActions(brandId, instanceId, taskRef, {
  isPublishEnabled: props.isPublishEnabled,
  isCloneEnabled: props.isCloneEnabled,
  isScheduleEnabled: props.isScheduleEnabled,
});

// FEATURE 2: Manage tooltip visibility
// In this case we want to close the tooltip when the TaskListItem scroll out of the viewport
const target = ref(null);
const isVisible = ref(false);
const dropDownMenuRef = ref<typeof DropdownMenu>();

useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
  },
  { root: props.root }
);
</script>

<template>
  <li
    :data-taskid="props.task.id"
    data-testid="tasks-list-item"
    @click="
      () => {
        if (props.onClick) props.onClick();
        taskDrawerStore.openDrawer(props.task?.id);
      }
    ">
    <div
      ref="target"
      class="group relative bg-white p-2 hover:bg-kl-gray-100/50 active:border-kl-green-200"
      :class="{
        'cursor-grab': props.isDraggable,
        'cursor-pointer': !props.isDraggable,
      }">
      <div class="flex items-center">
        <div class="flex flex-1 items-center gap-2 text-sm text-secondary">
          <TaskStatusChip
            size="h-3.5 text-2xs w-[60px] justify-center"
            :task="props.task" />
          <div
            v-if="isFollowedUser"
            class="inline-flex h-4 items-center rounded-xl border border-kl-cyan bg-white px-4 py-[.15rem] text-2xs text-secondary">
            Followed
          </div>
        </div>
        <div class="inline-flex items-center justify-end gap-x-1">
          <span :class="[TASK_PRIORITY_COLOR[props.task.priority]]">
            {{ TASK_PRIORITY_LABEL[props.task.priority] }}
          </span>
          <DropdownMenu
            v-if="(actions?.[0]?.length > 0 || actions?.[1]?.length > 0) && !props.hideOptions"
            ref="dropDownMenuRef"
            data-testid="task-dropdown-menu"
            :menu-options="actions"
            :on-send-option-operation="(operation: string) => props.onAction(task.id, operation as TaskAction)" />
        </div>
      </div>

      <p
        class="text-sm text-kl-gray-500"
        data-testid="task-title">
        {{ props.task.title }}
      </p>

      <div
        class="relative overflow-hidden transition-all duration-300"
        :class="{
          ' h-0 group-hover:h-8': !props.autoExpand,
          'h-8': props.autoExpand,
        }">
        <div class="flex items-end gap-2 pt-2">
          <div class="flex flex-1 items-center">
            <div class="text-xs text-secondary">{{ instanceName }}</div>
            <div
              v-if="props.isCurrent"
              class="ml-2 border-l border-kl-gray-300 pl-2">
              <div class="rounded-full border border-kl-pink-100 px-1 text-2xs text-kl-pink-100">Current</div>
            </div>
          </div>

          <div
            v-if="task.createdAt"
            class="text-xs text-secondary">
            Created At: {{ formatDate(task.createdAt) }}
          </div>
          <WithTooltip
            v-if="task.assignedTo?.[0]"
            :label="`${task.assignedTo[0]?.nome} ${task.assignedTo[0]?.cognome}`">
            <UserProfileImage
              :user="task.assignedTo?.[0]"
              size="sm" />
          </WithTooltip>
        </div>
      </div>
    </div>
  </li>
</template>
