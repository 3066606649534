import type { Component, FunctionalComponent } from 'vue';
import { defineAsyncComponent } from 'vue';
import {
  ArrowPathRoundedSquareIcon,
  GlobeAltIcon,
  VariableIcon,
  LinkIcon,
  WrenchIcon,
  CodeBracketIcon,
} from '@heroicons/vue/24/outline';
import ABTestCustomersJourney from '@/assets/icons-modules/AB-Test-Customers-Journey.svg';
// import Accessibility from '@/assets/icons-modules/Accessibility.svg';
import AiSeoAutomation from '@/assets/icons-modules/Ai-Seo-Automation.svg';
import BotAccessConfigurator from '@/assets/icons-modules/Bot-Access-Configurator.svg';
import BreadcrumbsConfigurator from '@/assets/icons-modules/Breadcrumbs-Configurator.svg';
import CDNConfigurator from '@/assets/icons-modules/CDN-Configurator.svg';
import CacheControl from '@/assets/icons-modules/Cache-Control.svg';
// import CheckResources from '@/assets/icons-modules/Check-Resources.svg';
import CookiesConfigurator from '@/assets/icons-modules/Cookies-Configurator.svg';
import ControlCenterIcon from '@/assets/icons-modules/Control-Center.svg';
import CountryLocaleConfigurator from '@/assets/icons-modules/Country-Locale-Configurator.svg';
import CrawlerMonitoring from '@/assets/icons-modules/Crawler-Monitoring.svg';
// import CriticalCss from '@/assets/icons-modules/Critical-Css.svg';
import CSSSpeedOptimizer from '@/assets/icons-modules/CSS-Speed-Optimizer.svg';
import FileManager from '@/assets/icons-modules/File-Manager.svg';
import GlobalPageBuilderEditors from '@/assets/icons-modules/Global-Page-Builder-Editors.svg';
import GlobalVariablesSettings from '@/assets/icons-modules/Global-Variables-Settings.svg';
import GoogleTagManagerConfigurator from '@/assets/icons-modules/Google-Tag-Manager-Configurator.svg';
import HTMLSpeedOptimizer from '@/assets/icons-modules/HTML-Speed-Optimizer.svg';
import HTTPHeadersConfigurator from '@/assets/icons-modules/HTTP-Headers-Configurator.svg';
import ImagesOptimizer from '@/assets/icons-modules/Images-Optimizer.svg';
// import JSPReRenderingConfigurator from '@/assets/icons-modules/JS-Pre-rendering-Configurator.svg';
import JSSpeedOptimizer from '@/assets/icons-modules/JS-Speed-Optimizer.svg';
import KleecksCacheManagement from '@/assets/icons-modules/Kleecks-Cache-Management.svg';
import KleecksExclusionRules from '@/assets/icons-modules/Kleecks-Exclusion-Rules.svg';
import KleecksGeneralSettings from '@/assets/icons-modules/Kleecks-General-Settings.svg';
import KleecksServices from '@/assets/icons-modules/Kleecks-Services.svg';
import PageBuilderandEditor from '@/assets/icons-modules/Page-Builder-and-Editor.svg';
import PageDataImport from '@/assets/icons-modules/Page-Data-Import.svg';
import PageSpeedAutomator from '@/assets/icons-modules/Page-Speed-Automator.svg';
import PageTypesIdentifier from '@/assets/icons-modules/Page-Types-Identifier.svg';
import ResourcesPrioritization from '@/assets/icons-modules/Resources-Prioritization.svg';
import Performances from '@/assets/icons-modules/Performances.svg';
// import Reports from '@/assets/icons-modules/Reports.svg';
import RobotsConfigurator from '@/assets/icons-modules/Robots-Configurator.svg';
import SchemaMarkupConfigurator from '@/assets/icons-modules/Schema-Markup-Configurator.svg';
import SecuritySettings from '@/assets/icons-modules/Security-Settings.svg';
import SeoEditor from '@/assets/icons-modules/Seo-Editor.svg';
import SeoSemOptimizer from '@/assets/icons-modules/Seo-Sem-Optimizer.svg';
import SitemapConfigurator from '@/assets/icons-modules/Sitemap-Configurator.svg';
import StatusCodeConfigurator from '@/assets/icons-modules/Status-Code-Configurator.svg';
import UrlExplorer from '@/assets/icons-modules/Url-Explorer.svg';
import UrlRedirectConfigurator from '@/assets/icons-modules/Url-Redirect-Configurator.svg';
import UrlRedirectImport from '@/assets/icons-modules/Url-Redirect-Import.svg';
import UrlRewriteManager from '@/assets/icons-modules/Url-Rewrite-Manager.svg';

import AiContentGeneration from '@/assets/icons-modules/Ai-Content-Generation.svg';

export type ModuleTabsId = 'tab-all' | 'tab-project' | 'tab-suggested' | 'tab-popular' | 'tab-newest';

export const WIDGET_SIZE_CONFIGURATION = {
  FULL_SCREEN: 'full-screen',
  ONE_COLUMN: 'one-column',
  TWO_COLUMNS: 'two-columns',
  THREE_COLUMNS: 'three-columns',
  FOUR_COLUMNS: 'four-columns',
} as const;

export type WidgetSizeConfiguration = (typeof WIDGET_SIZE_CONFIGURATION)[keyof typeof WIDGET_SIZE_CONFIGURATION];

export const MODULES_CATEGORY = {
  MAIN: 'General settings',
  DATA_AND_MONITORING: 'Data And monitoring',
  SEO: 'SEO',
  PAGE_SPEED: 'Page Speed',
  CRO: 'CRO',
} as const;

type MODULES_CATEGORY_IDS = (typeof MODULES_CATEGORY)[keyof typeof MODULES_CATEGORY];
export const MODULES_CATEGORY_COLOR: {
  [key in MODULES_CATEGORY_IDS]?: string;
} = {
  [MODULES_CATEGORY.MAIN]: 'kl-violet-100',
  [MODULES_CATEGORY.SEO]: 'kl-violet-500',
  [MODULES_CATEGORY.DATA_AND_MONITORING]: 'kl-violet-100',
  [MODULES_CATEGORY.PAGE_SPEED]: 'kl-gold',
  [MODULES_CATEGORY.CRO]: 'kl-green',
};

export const MODULES_CATEGORY_COLOR_ASCII: {
  [key in MODULES_CATEGORY_IDS]: string;
} = {
  [MODULES_CATEGORY.MAIN]: '#8773FF',
  [MODULES_CATEGORY.DATA_AND_MONITORING]: '#8773FF',
  [MODULES_CATEGORY.SEO]: '#98288B',
  [MODULES_CATEGORY.PAGE_SPEED]: '#FFC107',
  [MODULES_CATEGORY.CRO]: '#4CAF50',
};

export type ModuleCategory = (typeof MODULES_CATEGORY)[keyof typeof MODULES_CATEGORY];

export const MODULES_TABS: {
  name: string;
  id: ModuleCategory | 'all';
}[] = [
  {
    name: 'All Modules',
    id: 'all',
  },
  {
    name: 'General Settings',
    id: MODULES_CATEGORY.MAIN,
  },
  {
    name: 'Data and monitoring',
    id: MODULES_CATEGORY.DATA_AND_MONITORING,
  },
  {
    name: 'SEO',
    id: MODULES_CATEGORY.SEO,
  },
  {
    name: 'Page Speed',
    id: MODULES_CATEGORY.PAGE_SPEED,
  },
  {
    name: 'CRO',
    id: MODULES_CATEGORY.CRO,
  },
];

//* *******************************************
// 1. MODULES CONSTANTS
//* *******************************************

export const MODULES_IDS = {
  AI_CONTENT: 'M_AI_CONTENT',
  ALTERNATES: 'M_ALTERNATES',
  BREADCRUMBS_SETTINGS: 'M_BREADCRUMBS_SETTINGS',
  BYPASS_RULES: 'M_BYPASS_RULES',
  CACHE_CONTROL: 'M_CACHE_CONTROL',
  CACHE_MANAGEMENT: 'M_CACHE_MANAGEMENT',
  CANONICAL: 'M_CANONICAL',
  CDN: 'M_CDN',
  COOKIES: 'M_COOKIES',
  CRAWLER: 'M_CRAWLER',
  CUSTOMER_JOURNEY: 'M_CUSTOMER_JOURNEY',
  FILE_MANAGER: 'M_FILE_MANAGER',
  GENERAL_SETTINGS: 'M_GENERAL_SETTINGS',
  GET_PARAMETERS: 'M_GET_PARAMETERS',
  HTML_SPEED_OPTIMIZER: 'M_HTML_SPEED_OPTIMIZER',
  HTTP_HEADERS: 'M_HTTP_HEADERS',
  INDEXABILITY: 'M_INDEXABILITY',
  INTERNATIONAL: 'M_INTERNATIONAL',
  MONITORING: 'M_MONITORING',
  PAGE_DATA_IMPORT: 'M_PAGE_DATA_IMPORT',
  PAGE_TYPE_ADVANCED: 'M_PAGE_TYPE_ADVANCED',
  PAGE_TYPE_CLUSTER: 'M_PAGE_TYPE_CLUSTER',
  PERFORMANCE_CSS: 'M_PERFORMANCE_CSS',
  IMAGES_OPTIMIZER: 'M_IMAGES_OPTIMIZER',
  PERFORMANCE_JS: 'M_PERFORMANCE_JS',
  PREDICTIONS: 'M_PREDICTIONS',
  PRERENDER: 'M_PRERENDER',
  REDIRECTS: 'M_REDIRECTS',
  ROBOTS: 'M_ROBOTS',
  SECURITY: 'M_SECURITY',
  SEO_AUTOMATION: 'M_SEO_AUTOMATION',
  SEO_SEM: 'M_SEO_SEM',
  SEO_TOOL: 'M_SEO_TOOL',
  SEO_URLS: 'M_SEO_URLS',
  SERVICES_TOOLS: 'M_SERVICES_TOOLS',
  SITEMAP: 'M_SITEMAP',
  SOURCE_EDITOR: 'M_SOURCE_EDITOR',
  SOURCE_EDITORS: 'M_SOURCE_EDITORS',
  STATUS: 'M_STATUS',
  STATUS_CODE: 'M_STATUS_CODE',
  URL_EXPLORER_ANALYTIC: 'M_URL_EXPLORER_ANALYTIC',
  URL_EXPLORER_CRAWL: 'M_URL_EXPLORER_CRAWL',
  URL_FIX: 'M_URL_FIX',
  URL_PAGINATION: 'M_URL_PAGINATION',
  URL_REDIRECT: 'M_URL_REDIRECT',
  URL_REWRITE: 'M_URL_REWRITE',
  VARIABLES_SELECTORS: 'M_VARIABLES_SELECTORS',
  RESOURCES_PRIORITIZATION: 'M_RESOURCES_PRIORITIZATION',
  SCHEMA_MARKUP_CONFIGURATOR: 'M_SCHEMA_MARKUP_CONFIGURATOR',
  CONTROL_CENTER: 'M_CONTROL_CENTER',
} as const;

//* *******************************************
// 2. MODULES TYPES
//* *******************************************

export type MODULES = (typeof MODULES_IDS)[keyof typeof MODULES_IDS];
export type ACTIVITY_MODULES_ID = 'M_ALTERNATES' | 'M_REPORT' | 'M_SOURCE_EDITOR';

/**
 * Internal modules are modules that are not displayed in the modules list
 * and don't need permissions.
 */
export const INTERNAL_MODULES = [MODULES_IDS.SEO_TOOL] as const;

export const KLEECKS_MODULES = [MODULES_IDS.RESOURCES_PRIORITIZATION] as const;
export const STANDALONE_MODULES: MODULES[] = [
  MODULES_IDS.AI_CONTENT,
  MODULES_IDS.URL_EXPLORER_ANALYTIC,
  MODULES_IDS.URL_EXPLORER_CRAWL,
  MODULES_IDS.MONITORING,
] as const;

export type InternalModules = (typeof INTERNAL_MODULES)[number];
export type StandaloneModules = (typeof STANDALONE_MODULES)[number];
export type ExternalModules = Exclude<MODULES, InternalModules>;

//* *******************************************
// 3. MODULES COMPONENTS
//* *******************************************

const LazyMonitoring = defineAsyncComponent(() => import('@/modules/monitoring/MonitoringImpl.vue'));
const LazyAIContent = defineAsyncComponent(() => import('@/modules/ai-content/AiContentImpl.vue'));
const LazyAlternates = defineAsyncComponent(() => import('@/modules/alternates/AlternatesImpl.vue'));
const LazyBreadcrumbsSettings = defineAsyncComponent(
  () => import('@/modules/breadcrumbs-settings/BreadcrumbSettingsImpl.vue')
);
const LazyKleecksExclusionRules = defineAsyncComponent(
  () => import('@/modules/kleecks-exclusion-rules/KleecksExclusionRulesImpl.vue')
);
const LazyCacheControl = defineAsyncComponent(() => import('@/modules/cache-control/CacheControlImpl.vue'));
const LazyCacheManagement = defineAsyncComponent(() => import('@/modules/cache-management/CacheManagementImpl.vue'));
const LazyCanonical = defineAsyncComponent(() => import('@/modules/canonical/CanonicalImpl.vue'));
const LazyCDN = defineAsyncComponent(() => import('@/modules/cdn/CdnImpl.vue'));
const LazyCookies = defineAsyncComponent(() => import('@/modules/cookies/CookiesImpl.vue'));
const LazyCrawler = defineAsyncComponent(() => import('@/modules/crawler/CrawlerImpl.vue'));
const LazyCustomerJourney = defineAsyncComponent(() => import('@/modules/customer-journey/CustomerJourneyImpl.vue'));
const LazyFileManager = defineAsyncComponent(() => import('@/modules/file-manager/FileManagerImpl.vue'));
const LazyGeneralSettings = defineAsyncComponent(() => import('@/modules/general-settings/GeneralSettingsImpl.vue'));
const LazyGetParameters = defineAsyncComponent(() => import('@/modules/get-parameters/GetParametersImpl.vue'));
const LazyHtmlSpeedOptimizer = defineAsyncComponent(
  () => import('@/modules/html-speed-optimizer/HtmlSpeedOptimizerImpl.vue')
);
const LazyHttpHeaders = defineAsyncComponent(() => import('@/modules/http-headers/HttpHeadersImpl.vue'));
const LazyIndexability = defineAsyncComponent(() => import('@/modules/indexability/IndexabilityImpl.vue'));
const LazyInternational = defineAsyncComponent(() => import('@/modules/international/InternationalImpl.vue'));
const LazyPageDataImport = defineAsyncComponent(() => import('@/modules/page-data-import/PageDataImportImpl.vue'));
const LazyPageTypeAdvanced = defineAsyncComponent(
  () => import('@/modules/page-type-advanced/PageTypeAdvancedImpl.vue')
);
const LazyPageTypeCluster = defineAsyncComponent(() => import('@/modules/page-type-cluster/PageTypeClusterImpl.vue'));
const LazyPerformanceCSs = defineAsyncComponent(() => import('@/modules/performance-css/PerformanceCssImpl.vue'));
const LazyImagesOptimizer = defineAsyncComponent(() => import('@/modules/images-optimizer/ImagesOptimizerImpl.vue'));
const LazyPerformanceJs = defineAsyncComponent(() => import('@/modules/performance-js/PerformanceJsImpl.vue'));
const LazyPredictions = defineAsyncComponent(() => import('@/modules/predictions/PredictionsImpl.vue'));
const LazyPrerender = defineAsyncComponent(() => import('@/modules/prerender/PrerenderImpl.vue'));
const LazyRedirects = defineAsyncComponent(() => import('@/modules/redirects/RedirectsImpl.vue'));
const LazyRobots = defineAsyncComponent(() => import('@/modules/robots/RobotsImpl.vue'));
const LazySecurity = defineAsyncComponent(() => import('@/modules/security/SecurityImpl.vue'));
const LazySeoAutomation = defineAsyncComponent(() => import('@/modules/seo-automation/SeoAutomationImpl.vue'));
const LazySeoSem = defineAsyncComponent(() => import('@/modules/seo-sem/SeoSemImpl.vue'));
const LazySeoUrls = defineAsyncComponent(() => import('@/modules/seo-urls/SeoUrlsImpl.vue'));
const LazyServicesTools = defineAsyncComponent(() => import('@/modules/services-tools/ServicesToolsImpl.vue'));
const LazySitemap = defineAsyncComponent(() => import('@/modules/sitemap/SitemapImpl.vue'));
const LazySourceEditor = defineAsyncComponent(() => import('@/modules/source-editor/SourceEditorImpl.vue'));
const LazySourceEditors = defineAsyncComponent(() => import('@/modules/source-editors/SourceEditorsImpl.vue'));
const LazyStatus = defineAsyncComponent(() => import('@/modules/status/StatusImpl.vue'));
const LazyStatusCode = defineAsyncComponent(() => import('@/modules/status-code/StatusCodeImpl.vue'));
const LazyUrlExplorerAnalytic = defineAsyncComponent(() => import('@/modules/url-explorer-analytic/Impl.vue'));
const LazyUrlExplorerCrawl = defineAsyncComponent(() => import('@/modules/url-explorer-crawl/Impl.vue'));
const LazyUrlFix = defineAsyncComponent(() => import('@/modules/url-fix/UrlFixImpl.vue'));
const LazyUrlPagination = defineAsyncComponent(() => import('@/modules/url-pagination/UrlPaginationImpl.vue'));
const LazyUrlRedirect = defineAsyncComponent(() => import('@/modules/url-redirect/UrlRedirectImpl.vue'));
const LazyUrlRewrite = defineAsyncComponent(() => import('@/modules/url-rewrite/UrlRewriteImpl.vue'));
const LazyVariablesAndSelectors = defineAsyncComponent(
  () => import('@/modules/variables-and-selectors/VariablesAndSelectorsImpl.vue')
);
const LazyResourcesPrioritization = defineAsyncComponent(
  () => import('@/modules/resources-prioritization/ResourcesPrioritizationImpl.vue')
);

const LazySchemaMarkupConfigurator = defineAsyncComponent(
  () => import('@/modules/schema-markup-configurator/SchemaMarkupConfiguratorImpl.vue')
);

const LazyControlCenter = defineAsyncComponent(() => import('@/modules/control-center/ControlCenterImpl.vue'));

export const MODULES_LAZY_MAP: {
  // Exclude internal modules
  [key in ExternalModules]: Component;
} = {
  [MODULES_IDS.MONITORING]: LazyMonitoring,
  [MODULES_IDS.AI_CONTENT]: LazyAIContent,
  [MODULES_IDS.ALTERNATES]: LazyAlternates,
  [MODULES_IDS.BREADCRUMBS_SETTINGS]: LazyBreadcrumbsSettings,
  [MODULES_IDS.BYPASS_RULES]: LazyKleecksExclusionRules,
  [MODULES_IDS.CACHE_CONTROL]: LazyCacheControl,
  [MODULES_IDS.CACHE_MANAGEMENT]: LazyCacheManagement,
  [MODULES_IDS.CANONICAL]: LazyCanonical,
  [MODULES_IDS.CDN]: LazyCDN,
  [MODULES_IDS.COOKIES]: LazyCookies,
  [MODULES_IDS.CRAWLER]: LazyCrawler,
  [MODULES_IDS.CUSTOMER_JOURNEY]: LazyCustomerJourney,
  [MODULES_IDS.FILE_MANAGER]: LazyFileManager,
  [MODULES_IDS.GENERAL_SETTINGS]: LazyGeneralSettings,
  [MODULES_IDS.GET_PARAMETERS]: LazyGetParameters,
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: LazyHtmlSpeedOptimizer,
  [MODULES_IDS.HTTP_HEADERS]: LazyHttpHeaders,
  [MODULES_IDS.INDEXABILITY]: LazyIndexability,
  [MODULES_IDS.INTERNATIONAL]: LazyInternational,
  [MODULES_IDS.PAGE_DATA_IMPORT]: LazyPageDataImport,
  [MODULES_IDS.PAGE_TYPE_ADVANCED]: LazyPageTypeAdvanced,
  [MODULES_IDS.PAGE_TYPE_CLUSTER]: LazyPageTypeCluster,
  [MODULES_IDS.PERFORMANCE_CSS]: LazyPerformanceCSs,
  [MODULES_IDS.IMAGES_OPTIMIZER]: LazyImagesOptimizer,
  [MODULES_IDS.PERFORMANCE_JS]: LazyPerformanceJs,
  [MODULES_IDS.PREDICTIONS]: LazyPredictions,
  [MODULES_IDS.PRERENDER]: LazyPrerender,
  [MODULES_IDS.REDIRECTS]: LazyRedirects,
  [MODULES_IDS.ROBOTS]: LazyRobots,
  [MODULES_IDS.SECURITY]: LazySecurity,
  [MODULES_IDS.SEO_AUTOMATION]: LazySeoAutomation,
  [MODULES_IDS.SEO_SEM]: LazySeoSem,
  [MODULES_IDS.SEO_URLS]: LazySeoUrls,
  [MODULES_IDS.SERVICES_TOOLS]: LazyServicesTools,
  [MODULES_IDS.SITEMAP]: LazySitemap,
  [MODULES_IDS.SOURCE_EDITOR]: LazySourceEditor,
  [MODULES_IDS.SOURCE_EDITORS]: LazySourceEditors,
  [MODULES_IDS.STATUS]: LazyStatus,
  [MODULES_IDS.STATUS_CODE]: LazyStatusCode,
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]: LazySchemaMarkupConfigurator,
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]: LazyUrlExplorerAnalytic,
  [MODULES_IDS.URL_EXPLORER_CRAWL]: LazyUrlExplorerCrawl,
  [MODULES_IDS.URL_FIX]: LazyUrlFix,
  [MODULES_IDS.URL_PAGINATION]: LazyUrlPagination,
  [MODULES_IDS.URL_REDIRECT]: LazyUrlRedirect,
  [MODULES_IDS.URL_REWRITE]: LazyUrlRewrite,
  [MODULES_IDS.VARIABLES_SELECTORS]: LazyVariablesAndSelectors,
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: LazyResourcesPrioritization,
  [MODULES_IDS.CONTROL_CENTER]: LazyControlCenter,
};

//* *******************************************
// 4. MODULES CONFIGURATION GROUP
//* *******************************************

// Modules library is the list of modules that are displayed in the modules list without internal and url rewrites  modules
export const MODULES_LIBRARY: MODULES[] = [
  MODULES_IDS.MONITORING,
  MODULES_IDS.AI_CONTENT,
  MODULES_IDS.BREADCRUMBS_SETTINGS,
  MODULES_IDS.BYPASS_RULES,
  MODULES_IDS.CACHE_CONTROL,
  MODULES_IDS.CACHE_MANAGEMENT,
  MODULES_IDS.CDN,
  MODULES_IDS.COOKIES,
  MODULES_IDS.CRAWLER,
  MODULES_IDS.CUSTOMER_JOURNEY,
  MODULES_IDS.FILE_MANAGER,
  MODULES_IDS.GENERAL_SETTINGS,
  MODULES_IDS.HTML_SPEED_OPTIMIZER,
  MODULES_IDS.HTTP_HEADERS,
  MODULES_IDS.INDEXABILITY,
  MODULES_IDS.INTERNATIONAL,
  MODULES_IDS.PAGE_DATA_IMPORT,
  MODULES_IDS.PAGE_TYPE_CLUSTER,
  MODULES_IDS.PERFORMANCE_CSS,
  MODULES_IDS.IMAGES_OPTIMIZER,
  MODULES_IDS.PERFORMANCE_JS,
  MODULES_IDS.PREDICTIONS,
  MODULES_IDS.PRERENDER,
  MODULES_IDS.REDIRECTS,
  MODULES_IDS.ROBOTS,
  MODULES_IDS.SECURITY,
  MODULES_IDS.SEO_AUTOMATION,
  MODULES_IDS.SEO_SEM,
  MODULES_IDS.SERVICES_TOOLS,
  MODULES_IDS.SITEMAP,
  MODULES_IDS.SOURCE_EDITOR,
  MODULES_IDS.SOURCE_EDITORS,
  MODULES_IDS.STATUS,
  MODULES_IDS.STATUS_CODE,
  MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR,
  MODULES_IDS.URL_EXPLORER_ANALYTIC, // TODO: Re-enable later
  MODULES_IDS.URL_EXPLORER_CRAWL,
  MODULES_IDS.URL_REDIRECT,
  MODULES_IDS.URL_REWRITE,
  MODULES_IDS.VARIABLES_SELECTORS,
  MODULES_IDS.CONTROL_CENTER,
] as const;

// MODULES THAT ARE WIDGETS
export const WIDGET_MODULES: MODULES[] = [
  MODULES_IDS.MONITORING,
  MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR,
  MODULES_IDS.ALTERNATES,
  MODULES_IDS.BREADCRUMBS_SETTINGS,
  MODULES_IDS.BYPASS_RULES,
  MODULES_IDS.CACHE_CONTROL,
  MODULES_IDS.CACHE_MANAGEMENT,
  MODULES_IDS.CANONICAL,
  MODULES_IDS.CDN,
  MODULES_IDS.COOKIES,
  MODULES_IDS.CRAWLER,
  MODULES_IDS.CUSTOMER_JOURNEY,
  MODULES_IDS.FILE_MANAGER,
  MODULES_IDS.GET_PARAMETERS,
  MODULES_IDS.GENERAL_SETTINGS,
  MODULES_IDS.HTML_SPEED_OPTIMIZER,
  MODULES_IDS.HTTP_HEADERS,
  MODULES_IDS.INDEXABILITY,
  MODULES_IDS.INTERNATIONAL,
  MODULES_IDS.PAGE_DATA_IMPORT,
  MODULES_IDS.PAGE_TYPE_CLUSTER,
  MODULES_IDS.PERFORMANCE_CSS,
  MODULES_IDS.IMAGES_OPTIMIZER,
  MODULES_IDS.PERFORMANCE_JS,
  MODULES_IDS.PREDICTIONS,
  MODULES_IDS.PRERENDER,
  MODULES_IDS.REDIRECTS,
  MODULES_IDS.ROBOTS,
  MODULES_IDS.SECURITY,
  MODULES_IDS.SEO_AUTOMATION,
  MODULES_IDS.SEO_SEM,
  MODULES_IDS.SEO_URLS,
  MODULES_IDS.SERVICES_TOOLS,
  MODULES_IDS.SITEMAP,
  MODULES_IDS.SOURCE_EDITORS,
  MODULES_IDS.STATUS,
  MODULES_IDS.STATUS_CODE,
  MODULES_IDS.URL_EXPLORER_ANALYTIC,
  MODULES_IDS.URL_EXPLORER_CRAWL,
  MODULES_IDS.URL_FIX,
  MODULES_IDS.URL_PAGINATION,
  MODULES_IDS.URL_REDIRECT,
  MODULES_IDS.URL_REWRITE,
  MODULES_IDS.VARIABLES_SELECTORS,
  MODULES_IDS.RESOURCES_PRIORITIZATION,
  MODULES_IDS.CONTROL_CENTER,
] as const;

//* *******************************************
// 5. MODULES CONFIGURATION UI
//* *******************************************
export const MODULES_NAMES: {
  [key in MODULES]: string;
} = {
  [MODULES_IDS.MONITORING]: 'CWV Monitoring',
  [MODULES_IDS.AI_CONTENT]: 'AI Content Generator',
  [MODULES_IDS.ALTERNATES]: 'Alternates',
  [MODULES_IDS.BREADCRUMBS_SETTINGS]: 'Breadcrumbs Configurations',
  [MODULES_IDS.BYPASS_RULES]: 'Kleecks Exclusion Rules Configurations',
  [MODULES_IDS.CDN]: 'CDN Configurations',
  [MODULES_IDS.CACHE_CONTROL]: 'Cache Configurations',
  [MODULES_IDS.CACHE_MANAGEMENT]: 'Kleecks Cache Management',
  [MODULES_IDS.CANONICAL]: 'Canonical',
  [MODULES_IDS.COOKIES]: 'Cookies Configurations',
  [MODULES_IDS.CRAWLER]: 'Crawl Management',
  [MODULES_IDS.CUSTOMER_JOURNEY]: 'A/B Test - Customers Journey',
  [MODULES_IDS.FILE_MANAGER]: 'File Manager',
  [MODULES_IDS.GENERAL_SETTINGS]: 'Kleecks General Configurations',
  [MODULES_IDS.GET_PARAMETERS]: 'Get Params',
  [MODULES_IDS.HTTP_HEADERS]: 'HTTP Headers Configurations',
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: 'Html Speed Optimizer',
  [MODULES_IDS.INDEXABILITY]: 'Bots Access Configurations',
  [MODULES_IDS.INTERNATIONAL]: 'International SEO Configurations',
  [MODULES_IDS.PAGE_DATA_IMPORT]: 'Page Data Import (Xls)',
  [MODULES_IDS.PAGE_TYPE_ADVANCED]: 'Page Type Advanced',
  [MODULES_IDS.PAGE_TYPE_CLUSTER]: 'Page Types Identifier',
  [MODULES_IDS.PERFORMANCE_CSS]: 'CSS Speed Optimizer',
  [MODULES_IDS.IMAGES_OPTIMIZER]: 'Images Optimizer',
  [MODULES_IDS.PERFORMANCE_JS]: 'JS Speed Optimizer',
  [MODULES_IDS.PREDICTIONS]: 'Page Speed Automator',
  [MODULES_IDS.PRERENDER]: 'Prerender',
  [MODULES_IDS.REDIRECTS]: 'Url Redirect Configurations',
  [MODULES_IDS.ROBOTS]: 'Robots Configurations',
  [MODULES_IDS.SEO_AUTOMATION]: 'Ai Seo Automation',
  [MODULES_IDS.SEO_TOOL]: 'Seo Editor',
  [MODULES_IDS.SEO_URLS]: 'SEO URLs',
  [MODULES_IDS.SECURITY]: 'Security Settings',
  [MODULES_IDS.SEO_SEM]: 'Seo/Sem Optimizer',
  [MODULES_IDS.SERVICES_TOOLS]: 'Force Refresh RUles Engine',
  [MODULES_IDS.SITEMAP]: 'Sitemap Configurations',
  [MODULES_IDS.SOURCE_EDITOR]: 'Page Builder and Editor',
  [MODULES_IDS.SOURCE_EDITORS]: 'Source Editor History',
  [MODULES_IDS.STATUS]: 'Google Tag Manager Configurations',
  [MODULES_IDS.STATUS_CODE]: 'Status Code Configurations',
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]: 'Schema Markup Configurations',
  [MODULES_IDS.URL_FIX]: 'URL Fix',
  [MODULES_IDS.URL_PAGINATION]: 'URL Pagination',
  [MODULES_IDS.URL_REDIRECT]: 'Url Redirect Import',
  [MODULES_IDS.URL_REWRITE]: 'Url Rewrite Configurations',
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]: 'Url Explorer Analytic',
  [MODULES_IDS.URL_EXPLORER_CRAWL]: 'Url Explorer',
  [MODULES_IDS.VARIABLES_SELECTORS]: 'Global Variables Configurations',
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: 'Resources Prioritization',
  [MODULES_IDS.CONTROL_CENTER]: 'Tasks Control Center',
};

export const MODULES_DESCRIPTIONS: {
  [key in MODULES]: string;
} = {
  [MODULES_IDS.MONITORING]: '',
  [MODULES_IDS.AI_CONTENT]:
    'This module allows you to effortlessly generate, manage, and translate content for your pages by seamlessly integrating an AI engine.',
  [MODULES_IDS.ALTERNATES]: '',
  [MODULES_IDS.BREADCRUMBS_SETTINGS]:
    'This module allows you to create and modify breadcrumbs for your website. It offers the ability to manage content, links, formatting, display, and exclusion rules for the homepage and other pages.',
  [MODULES_IDS.BYPASS_RULES]:
    'This module allows you to specify URLs that should be excluded and not processed by Kleecks. You can define exclusion rules by listing individual URLs or URL patterns using regular expressions.',
  [MODULES_IDS.CDN]:
    "This module allows you to customise the resources and parameters of S-Maxage for caching on the CDN. The 's-maxage' directive is designed for shared caches within CDNs, determining the duration for which these shared caches can continue serving the resource from the cache.",
  [MODULES_IDS.CACHE_CONTROL]:
    'This module enables you to precisely configure the Max-age parameters for caching. This directive specifies the time-to-live (TTL), representing the maximum duration that content can remain cached in the browser cache.',
  [MODULES_IDS.CACHE_MANAGEMENT]: '',
  [MODULES_IDS.CANONICAL]: '',
  [MODULES_IDS.COOKIES]:
    'This module enables you to activate cookie processing via Kleecks and allows for potential customisation through injected rules or path rewriting.',
  [MODULES_IDS.CRAWLER]:
    'This module offers an overview of crawl activities, facilitates configuration management, and enables the creation of various profiles.',
  [MODULES_IDS.CUSTOMER_JOURNEY]:
    'This module enables you to create and manage themes or different versions of the same website or some of its elements.',
  [MODULES_IDS.FILE_MANAGER]:
    'This module provides a comprehensive set of tools for file management, enabling users to browse, organise, and efficiently handle files within the platform.',
  [MODULES_IDS.GENERAL_SETTINGS]: '',
  [MODULES_IDS.GET_PARAMETERS]: '',
  [MODULES_IDS.HTTP_HEADERS]:
    'This module offers comprehensive management capabilities for configuring HTTP headers and setting rules for request HTTP headers.',
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: 'Html Speed Optimizer description',
  [MODULES_IDS.PERFORMANCE_CSS]:
    'This module enables you to optimise CSS performance by configuring the options provided by Kleecks.',
  [MODULES_IDS.IMAGES_OPTIMIZER]:
    'This module enables you to optimise image performance by configuring the options provided by Kleecks.',
  [MODULES_IDS.PERFORMANCE_JS]:
    'This module enables you to optimise JS performance by configuring the options provided by Kleecks.',
  [MODULES_IDS.INTERNATIONAL]:
    'This module enables you to effortlessly configure HTML data locale, rules, and other essential parameters crucial for internationalising your project.',
  [MODULES_IDS.INDEXABILITY]:
    'This module enables you to modify the rules for crawling and indexing pages by search engines.',
  [MODULES_IDS.PAGE_DATA_IMPORT]: '',
  [MODULES_IDS.PAGE_TYPE_ADVANCED]: '',
  [MODULES_IDS.PAGE_TYPE_CLUSTER]:
    "This module allows you to designate page types (such as Homepage, Product Listing Page, Product Detail Page, etc.) for subsequent rule application and feature integration. Utilising AI scanning, Kleecks recommends optimal page classifications tailored to your site's content.",
  [MODULES_IDS.PREDICTIONS]: '',
  [MODULES_IDS.PRERENDER]:
    'By effectively utilizing prerendering services, you can significantly improve your website’s SEO by enhancing page load times, improving user experience, and boosting your search engine rankings.',
  [MODULES_IDS.REDIRECTS]:
    'This module empowers you to establish comprehensive rules for enabling or disabling URL redirects, enabling effective project management.',
  [MODULES_IDS.ROBOTS]:
    "This module facilitates the creation and management of your site's robots.txt file. It offers the flexibility to manually set rules or import and fine-tune the file using an advanced text editor for greater customisation.",
  [MODULES_IDS.SEO_AUTOMATION]: '',
  [MODULES_IDS.SEO_TOOL]:
    'This module enables you to call Kleecks services to optimise and refresh pages and update sitemaps.',
  [MODULES_IDS.SEO_URLS]: '',
  [MODULES_IDS.SEO_SEM]:
    'This module enables you to manage and execute your Google Ads campaigns, comparing organic and paid metrics.',
  [MODULES_IDS.SECURITY]:
    'This module provides several options for overseeing various aspects of site security, such as managing SQL injection and reverse tabnabbing. Additionally, it offers advanced features for configuring bans based on user agents or IPs.',
  [MODULES_IDS.SERVICES_TOOLS]: '',
  [MODULES_IDS.SITEMAP]: 'This module enables you to manage and configure the sitemap.xml.',
  [MODULES_IDS.SOURCE_EDITOR]:
    'This module empowers you to enhance the UX/UI of your site by seamlessly enriching the source code of pages or templates with new HTML elements. Leveraging the user-friendly visual editor, you can effortlessly modify these elements with just a few clicks.',
  [MODULES_IDS.SOURCE_EDITORS]:
    'This module lists the history of variations generated through the Source Editor (Page Builder and Editor) and manages advanced settings of the latter.',
  [MODULES_IDS.STATUS_CODE]:
    'This module enables you to manage HTTP status codes by establishing personalised rules and offers customisation options for the HTML associated with 4xx and 5xx errors.',
  [MODULES_IDS.STATUS]:
    'This module enables you to manage and configure the Google Tag Manager script in the project, with the ability to define exclusion rules for certain pages of the site.',
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]:
    "This module empowers you to establish the markup schema for structured data on every page type of your website. Leveraging the AI engine, you'll receive suggestions for the optimal structure, which you can then personalise using the visual editor.",
  [MODULES_IDS.URL_FIX]: '',
  [MODULES_IDS.URL_PAGINATION]: '',
  [MODULES_IDS.URL_REDIRECT]:
    'This module simplifies the configuration of URL redirects, allowing you to import instructions from an Excel file or input each redirection manually.',
  [MODULES_IDS.URL_REWRITE]:
    'This module enables you to input custom URL rewriting rules based on a predefined set of parameters.',
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]:
    'A straightforward and comprehensive method to explore and gain a complete overview of all the pages managed by Kleecks.',
  [MODULES_IDS.URL_EXPLORER_CRAWL]:
    'This module provides a simple way to explore and have a full overview of crawled pages.',
  [MODULES_IDS.VARIABLES_SELECTORS]:
    'This module allows you to define custom variables that can be easily called and reused throughout the entire application.',
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: '',
  [MODULES_IDS.CONTROL_CENTER]: '',
};
export const MODULES_CATEGORIES: {
  [key in MODULES]?: ModuleCategory[];
} = {
  [MODULES_IDS.MONITORING]: [MODULES_CATEGORY.DATA_AND_MONITORING],
  [MODULES_IDS.BREADCRUMBS_SETTINGS]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.BYPASS_RULES]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.COOKIES]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.CRAWLER]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.GENERAL_SETTINGS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.HTTP_HEADERS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.INDEXABILITY]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.INTERNATIONAL]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.PAGE_DATA_IMPORT]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.PAGE_TYPE_CLUSTER]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.REDIRECTS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.ROBOTS]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SECURITY]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.SEO_AUTOMATION]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SEO_TOOL]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SERVICES_TOOLS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.SITEMAP]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SOURCE_EDITOR]: [MODULES_CATEGORY.CRO],
  [MODULES_IDS.SOURCE_EDITORS]: [MODULES_CATEGORY.DATA_AND_MONITORING],
  [MODULES_IDS.STATUS]: [MODULES_CATEGORY.DATA_AND_MONITORING],
  [MODULES_IDS.STATUS_CODE]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.URL_EXPLORER_CRAWL]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.SEO_URLS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.URL_FIX]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.GET_PARAMETERS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.CANONICAL]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.ALTERNATES]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.URL_PAGINATION]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.VARIABLES_SELECTORS]: [MODULES_CATEGORY.MAIN],
  [MODULES_IDS.CUSTOMER_JOURNEY]: [MODULES_CATEGORY.CRO],
  [MODULES_IDS.FILE_MANAGER]: [MODULES_CATEGORY.CRO],
  [MODULES_IDS.AI_CONTENT]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.CACHE_MANAGEMENT]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.CACHE_CONTROL]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.CDN]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.PERFORMANCE_JS]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.PERFORMANCE_CSS]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.IMAGES_OPTIMIZER]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.PREDICTIONS]: [MODULES_CATEGORY.PAGE_SPEED],
  [MODULES_IDS.PRERENDER]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.SEO_SEM]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.URL_REWRITE]: [MODULES_CATEGORY.SEO],
  [MODULES_IDS.CONTROL_CENTER]: [MODULES_CATEGORY.DATA_AND_MONITORING],
  [MODULES_IDS.URL_REDIRECT]: [MODULES_CATEGORY.SEO],
};

export const MODULES_ICON: {
  [key in MODULES]?: string | FunctionalComponent;
} = {
  [MODULES_IDS.MONITORING]: Performances,
  [MODULES_IDS.AI_CONTENT]: AiContentGeneration,
  [MODULES_IDS.ALTERNATES]: ArrowPathRoundedSquareIcon,
  [MODULES_IDS.BREADCRUMBS_SETTINGS]: BreadcrumbsConfigurator,
  [MODULES_IDS.BYPASS_RULES]: KleecksExclusionRules,
  [MODULES_IDS.CACHE_CONTROL]: CacheControl,
  [MODULES_IDS.CANONICAL]: GlobeAltIcon,
  [MODULES_IDS.CDN]: CDNConfigurator,
  [MODULES_IDS.COOKIES]: CookiesConfigurator,
  [MODULES_IDS.CONTROL_CENTER]: ControlCenterIcon,
  [MODULES_IDS.CRAWLER]: CrawlerMonitoring,
  [MODULES_IDS.CUSTOMER_JOURNEY]: ABTestCustomersJourney,
  [MODULES_IDS.FILE_MANAGER]: FileManager,
  [MODULES_IDS.GENERAL_SETTINGS]: KleecksGeneralSettings,
  [MODULES_IDS.GET_PARAMETERS]: VariableIcon,
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: HTMLSpeedOptimizer,
  [MODULES_IDS.HTTP_HEADERS]: HTTPHeadersConfigurator,
  [MODULES_IDS.INDEXABILITY]: BotAccessConfigurator,
  [MODULES_IDS.CACHE_MANAGEMENT]: KleecksCacheManagement,
  [MODULES_IDS.INTERNATIONAL]: CountryLocaleConfigurator,
  [MODULES_IDS.PAGE_DATA_IMPORT]: PageDataImport,
  [MODULES_IDS.PAGE_TYPE_CLUSTER]: PageTypesIdentifier,
  [MODULES_IDS.PERFORMANCE_CSS]: CSSSpeedOptimizer,
  [MODULES_IDS.IMAGES_OPTIMIZER]: ImagesOptimizer,
  [MODULES_IDS.PERFORMANCE_JS]: JSSpeedOptimizer,
  [MODULES_IDS.PREDICTIONS]: PageSpeedAutomator,
  [MODULES_IDS.PRERENDER]: PageSpeedAutomator,
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: ResourcesPrioritization,
  [MODULES_IDS.REDIRECTS]: UrlRedirectConfigurator,
  [MODULES_IDS.ROBOTS]: RobotsConfigurator,
  [MODULES_IDS.SECURITY]: SecuritySettings,
  [MODULES_IDS.SEO_AUTOMATION]: AiSeoAutomation,
  [MODULES_IDS.SEO_SEM]: SeoSemOptimizer,
  [MODULES_IDS.SEO_TOOL]: SeoEditor,
  [MODULES_IDS.SEO_URLS]: LinkIcon,
  [MODULES_IDS.SERVICES_TOOLS]: KleecksServices,
  [MODULES_IDS.SITEMAP]: SitemapConfigurator,
  [MODULES_IDS.SOURCE_EDITOR]: PageBuilderandEditor,
  [MODULES_IDS.SOURCE_EDITORS]: GlobalPageBuilderEditors,
  [MODULES_IDS.STATUS]: GoogleTagManagerConfigurator,
  [MODULES_IDS.STATUS_CODE]: StatusCodeConfigurator,
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]: SchemaMarkupConfigurator,
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]: UrlExplorer,
  [MODULES_IDS.URL_EXPLORER_CRAWL]: UrlExplorer,
  [MODULES_IDS.URL_FIX]: WrenchIcon,
  [MODULES_IDS.URL_PAGINATION]: CodeBracketIcon,
  [MODULES_IDS.URL_REDIRECT]: UrlRedirectImport,
  [MODULES_IDS.URL_REWRITE]: UrlRewriteManager,
  [MODULES_IDS.VARIABLES_SELECTORS]: GlobalVariablesSettings,
} as const;

export const MODULES_DASHBOARD_CONFIGURATION: {
  [key in MODULES]?: {
    configuration: WidgetSizeConfiguration;
  };
} = {
  [MODULES_IDS.MONITORING]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.CRAWLER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.HTML_SPEED_OPTIMIZER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.INDEXABILITY]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.BREADCRUMBS_SETTINGS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SEO_SEM]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.SEO_AUTOMATION]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SEO_TOOL]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SEO_URLS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SITEMAP]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.INTERNATIONAL]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.CDN]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.COOKIES]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.CACHE_CONTROL]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.CACHE_MANAGEMENT]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.PERFORMANCE_CSS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.IMAGES_OPTIMIZER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.PERFORMANCE_JS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.PREDICTIONS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.PRERENDER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.REDIRECTS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.ROBOTS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SECURITY]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },

  [MODULES_IDS.SOURCE_EDITOR]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.SOURCE_EDITORS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.STATUS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.STATUS_CODE]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.SCHEMA_MARKUP_CONFIGURATOR]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.URL_EXPLORER_ANALYTIC]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.URL_EXPLORER_CRAWL]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.URL_FIX]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.URL_PAGINATION]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.URL_REDIRECT]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.URL_REWRITE]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.VARIABLES_SELECTORS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.CONTROL_CENTER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.PAGE_TYPE_CLUSTER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.PAGE_TYPE_ADVANCED]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.PAGE_DATA_IMPORT]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.FILE_MANAGER]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.GET_PARAMETERS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.BYPASS_RULES]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.CUSTOMER_JOURNEY]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },

  [MODULES_IDS.GENERAL_SETTINGS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.HTTP_HEADERS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
  },
  [MODULES_IDS.SERVICES_TOOLS]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
  [MODULES_IDS.RESOURCES_PRIORITIZATION]: {
    configuration: WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
  },
};
