import type { GoogleConnectorsType } from '@/types/connectors.types';
import { ConnectGoogleServiceMutation, DisconnectGoogleServiceMutation } from '@/services/graphql/connectors.graphql';
import { useMutation } from '@vue/apollo-composable';
import { addConnectorToBrand, removeConnectorFromBrand } from '@/utils/cache.utils';
import type { BrandConnector } from '@/types/brand.types';

export const connectGoogleServiceMutation = () =>
  useMutation<
    {
      connectGoogleService: BrandConnector;
    },
    {
      brand: string;
      code: string;
      type: GoogleConnectorsType;
    }
  >(ConnectGoogleServiceMutation, {
    update: (cache, { data }, { variables }) => {
      if (data?.connectGoogleService) {
        addConnectorToBrand(variables?.type!, variables?.brand!, data.connectGoogleService, cache);
      }
    },
  });

export const disconnectGoogleServiceMutation = () =>
  useMutation<
    { disconnectGoogleService: boolean },
    {
      brand: string;
      type: GoogleConnectorsType;
    }
  >(DisconnectGoogleServiceMutation, {
    update: (cache, { data }, { variables }) => {
      console.log(data, variables);
      if (data?.disconnectGoogleService) {
        removeConnectorFromBrand(variables?.type!, variables?.brand!, cache);
      }
    },
  });
