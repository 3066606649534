<script setup lang="ts">
import type { MODULES, ModuleCategory } from '@/constants/modules.constants';
import {
  MODULES_CATEGORIES,
  MODULES_DESCRIPTIONS,
  MODULES_ICON,
  MODULES_NAMES,
  MODULES_TABS,
  STANDALONE_MODULES,
} from '@/constants/modules.constants';
import { useModules } from '@/hooks/modules/useModules';
import { MODULE_PROPERTY_RULES } from '@kleecks/modules-lib';
import { BaseTextInput, DialogContainer, Modal, Tabs } from '@kleecks/ui-lib';
import type { FunctionalComponent } from 'vue';
import { computed, ref, watch } from 'vue';
import ModuleCard from '@/components/cards/module-card/ModuleCard.vue';
import { useUser } from '@/hooks/user/useUser';

interface SelectModuleDialogProps {
  isOpen: boolean;
  alreadyAddedWidgetModules?: MODULES[];
  disabled?: boolean;
  onClose: () => void;
  onSelectModule: (id: MODULES) => void;
}

interface IModuleCard {
  id: MODULES;
  name: string;
  description: string;
  icon?: string | FunctionalComponent;
}

const { modulesLibrary } = useModules();

const { user } = useUser();

const tabs = computed(() =>
  user.value?.admin
    ? MODULES_TABS
    : MODULES_TABS.filter(tab => {
        if (tab.id === 'all') return true;
        return modulesLibrary.value?.some(module =>
          MODULES_CATEGORIES[module as MODULES]?.includes(tab.id as ModuleCategory)
        );
      })
);
const currentTabId = ref<string>(tabs.value[0].id);
const props = defineProps<SelectModuleDialogProps>();
const searchValue = ref('');

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      searchValue.value = '';
    }
  }
);

const modulesCard = computed<IModuleCard[]>(() =>
  modulesLibrary.value

    ?.filter(
      el =>
        currentTabId.value === 'all' ||
        MODULES_CATEGORIES[el as MODULES]?.includes(currentTabId.value as ModuleCategory)
    )
    .reduce<IModuleCard[]>((acc, module) => {
      if (
        !searchValue.value ||
        MODULES_NAMES[module as MODULES].toLowerCase().includes(searchValue.value.toLowerCase())
      )
        return [
          ...acc,
          {
            id: module as MODULES,
            name: MODULES_NAMES[module as MODULES],
            description: MODULES_DESCRIPTIONS[module as MODULES],
            icon: MODULES_ICON[module as MODULES],
          },
        ];

      if (MODULE_PROPERTY_RULES[module]?.some(rule => rule.toLowerCase().includes(searchValue.value.toLowerCase())))
        return [
          ...acc,
          {
            id: module as MODULES,
            name: MODULES_NAMES[module as MODULES],
            description: MODULES_DESCRIPTIONS[module as MODULES],
            icon: MODULES_ICON[module as MODULES],
          },
        ];

      return acc;
    }, [])
    ?.toSorted((a, b) => a.name.localeCompare(b.name))
);
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :on-close="props.onClose">
    <Modal
      :on-close="props.onClose"
      title="Select Widget"
      sub-title="Select a widget from the library and add to the dashboard">
      <div class="flex h-[30rem] min-h-0 w-[50rem] flex-col gap-4">
        <Tabs
          size="xs"
          tab-header-size="w-full"
          tab-size-class="flex-1"
          :tabs="tabs"
          :on-change="tabId => (currentTabId = tabId)"
          :current-tab-id="currentTabId" />

        <BaseTextInput
          v-model="searchValue"
          placeholder="Search widgets"
          type="search"
          input-classes="bg-white" />
        <div class="flex-1 overflow-y-auto py-2 pe-1">
          <div class="grid grid-cols-2 gap-8">
            <ModuleCard
              v-for="module in modulesCard"
              :id="module.id"
              :key="module.id"
              class="py-2"
              :is-disabled="
                (props.alreadyAddedWidgetModules?.length && STANDALONE_MODULES.includes(module.id)) ||
                props.alreadyAddedWidgetModules?.includes(module.id) ||
                props.disabled
              "
              :name="module.name"
              :icon="module.icon"
              :hide-assign-button="true"
              :description="module.description"
              :on-click="() => props.onSelectModule(module.id)" />
          </div>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
