<script setup lang="ts">
import ModalTooltipButton from '@/components/modal-tooltip-button/ModalTooltipButton.vue';
import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';
import {
  InformationCircleIcon,
  ListBulletIcon,
  ViewColumnsIcon,
  PlusIcon,
  Squares2X2Icon,
} from '@heroicons/vue/24/outline';
import { type Topic, TOPIC_OPTIONS, type VIEW_TYPE } from '@kleecks/code-lib';
import { BaseTextInput, BaseToggle, Button, FilterBox } from '@kleecks/ui-lib';
import type { Component, FunctionalComponent } from 'vue';
import { computed, h, useSlots } from 'vue';

interface DashboardHeaderProps {
  viewType?: VIEW_TYPE;
  viewTypeOptions?: Set<VIEW_TYPE>;
  title: string;
  subTitle?: string;
  cta?: {
    onClick: () => void;
    label: string;
    variant?: 'primary' | 'secondary' | 'blue' | 'gray' | 'pink' | 'red' | 'violet' | 'yellow';
  };
  helpText?: string;
  // eslint-disable-next-line no-unused-vars
  onChangeViewType?: (value: VIEW_TYPE) => void;
  searchPlaceholder?: string;
  topicValue?: string[];
  counterForTopic?: Record<string, number>;
  dashboardTypeValue?: string;
  dashboardTypeEnable?: boolean;
  disabledFilters?: boolean;
  onChangeTopic?: (value: string[]) => void;
  onChangeDashboardType?: (value: string) => void;
  onChangeDashboardToggle?: (value: boolean) => void;
}

const props = defineProps<DashboardHeaderProps>();
const slot = useSlots();

const searchValue = defineModel<string | undefined>('searchValue', { required: false });
const topicModel = computed<string[]>({
  get() {
    return props.topicValue!;
  },
  set(val: string[]) {
    props.onChangeTopic!(val!);
  },
});

const dashboardToggleModel = computed({
  get() {
    return props.dashboardTypeEnable;
  },
  set(val: any) {
    props.onChangeDashboardToggle!(val);
  },
});

const ICON_MAP: Record<VIEW_TYPE, FunctionalComponent | Component | string> = {
  grid: Squares2X2Icon,
  list: ListBulletIcon,
  columns: ViewColumnsIcon,
};

const getViewTypeOptions = computed(() => {
  let options = props.viewTypeOptions?.size ? Array.from(props.viewTypeOptions) : (['grid', 'list'] as VIEW_TYPE[]);

  if (props.viewType && !options.includes(props.viewType)) {
    options = [props.viewType, ...options];
  }

  return options.map(viewType => ({
    viewType,
    icon: ICON_MAP[viewType],
  }));
});
</script>

<template>
  <div class="w-full">
    <div class="flex w-full items-start">
      <div class="flex-1">
        <div class="flex items-center gap-2 text-2xl font-medium text-primary">
          {{ props.title }}
          <div v-if="props.helpText">
            <ModalTooltipButton
              :modalContent="props.helpText"
              modalTitle="About">
              <InformationCircleIcon class="size-6" />
            </ModalTooltipButton>
          </div>
        </div>
        <span
          v-if="props.subTitle"
          class="text-xs text-secondary"
          >{{ props.subTitle }}
        </span>
      </div>
      <div class="inline-flex items-center gap-x-4">
        <BaseToggle
          v-if="onChangeDashboardToggle"
          v-model="dashboardToggleModel"
          :label="dashboardTypeValue" />

        <Button
          v-if="props.cta"
          :variant="props.cta.variant || 'blue'"
          size="xs"
          @click="props.cta?.onClick">
          <PlusIcon class="size-4 transition-transform duration-500 ease-in-out" />
          {{ props.cta.label }}
        </Button>
      </div>
    </div>
    <slot name="extra-header-content" />
    <div class="mt-4 flex w-full items-center gap-4">
      <div class="flex w-full gap-4 rounded-sm">
        <div
          v-if="searchValue !== undefined"
          class="mr-4 w-96 flex-1">
          <BaseTextInput
            v-model="searchValue"
            input-classes="bg-white"
            :class="props.disabledFilters ? 'opacity-50 pointer-events-none' : ''"
            data-testid="dashboard-header-search-bar"
            :placeholder="props.searchPlaceholder ?? 'search'"
            type="search" />
        </div>
        <slot name="extra-header-filters" />
        <div
          v-if="onChangeTopic"
          :class="slot['extra-header-filters'] ? 'w-80 flex-1' : 'w-80'">
          <FilterBox
            v-model="topicModel"
            :class="props.disabledFilters ? 'opacity-50 pointer-events-none' : ''"
            bgClass="bg-white"
            :items="TOPIC_OPTIONS"
            label="Filter by topic"
            :render-fn="
              ({value}: any) => h('div', { class: 'flex items-center gap-2 py-1 text-secondary' }, [
                h(TopicChip, { topic: value as Topic }),
                h('span', { class: 'text-xs' }, `${props.counterForTopic ? `(${props.counterForTopic[value as Topic] || 0})`: ''}`),
              ])
            "
            filter-placeholder="Insert here the topic you want to filter by"
            placeholder="Filter by topic" />
          <!-- <Combobox
            v-model="topicModel"
            placeholder="Select Topic"
            class="bg-white"
            :items="[{ label: 'All Topics', value: '' }, ...TOPIC_OPTIONS]" /> -->
        </div>
      </div>

      <div
        v-if="props.viewType && props.onChangeViewType !== undefined"
        class="flex gap-2">
        <component
          :is="option.icon"
          v-for="option in getViewTypeOptions"
          :key="option.viewType"
          :class="[
            'h-6 w-auto cursor-pointer hover:text-active',
            {
              'text-active': props.viewType === option.viewType,
              'text-gray-400': props.viewType !== option.viewType,
            },
          ]"
          aria-hidden="true"
          @click="() => props.onChangeViewType!(option.viewType)" />
      </div>
    </div>
  </div>
</template>
