<script setup lang="ts">
import {
  MODULES_CATEGORIES,
  MODULES_CATEGORY_COLOR,
  type MODULES,
  type ModuleCategory,
} from '@/constants/modules.constants';
import { h } from 'vue';
import type { Column } from '@kleecks/ui-lib';
import { HeadlessTable } from '@kleecks/ui-lib';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';

interface ModulesLibraryTableProps {
  modules: {
    icon: string;
    id: MODULES;
    name: string;
    description: string;
  }[];
}

const props = defineProps<ModulesLibraryTableProps>();

const emits = defineEmits<{
  (e: 'assign-module', obj: MODULES): void;
}>();

const handleAssignToDashboard = (id: MODULES) => {
  emits('assign-module', id);
};

const groupsCell = (listCategory: ModuleCategory[]) => {
  if (!listCategory) return [];
  return listCategory.map(category =>
    h(
      'div',
      {
        class: `inline-flex w-fit whitespace-nowrap h-4 items-center self-end justify-center rounded-xl border border-${MODULES_CATEGORY_COLOR[category]} px-2 py-[.15rem] text-2xs text-${MODULES_CATEGORY_COLOR[category]} font-medium`,
      },
      category
    )
  );
};

const columns: Column<{
  icon: string;
  id: MODULES;
  name: string;
  description: string;
}>[] = [
  {
    actions: true,
    component: row =>
      h(
        'div',
        { class: 'flex justify-center items-center w-12 h-12 border rounded' },
        h('img', { src: row.icon, class: 'w-8' })
      ),
  },
  {
    accessorKey: 'name',
    header: 'Module Name and Details',
    accessorFn: row => [
      h('div', { class: 'text-sm text-primary font-medium' }, row.name),
      h('div', { class: 'text-2xs text-secondary' }, row.description),
    ],
    sortable: true,
  },
  {
    header: 'Groups',
    accessorFn: row =>
      h('div', { class: 'flex flex-col gap-2' }, groupsCell(MODULES_CATEGORIES[row.id as MODULES] as ModuleCategory[])),
  },
  {
    actions: true,
    component: row =>
      h(PlusCircleIcon, {
        class: 'w-5 text-kl-icon hover:text-active cursor-pointer',
        onClick: () => {
          handleAssignToDashboard(row.id);
        },
      }),
  },
];
</script>

<template>
  <HeadlessTable
    font-size="xs"
    :elements="props.modules"
    :columns="columns" />
</template>
