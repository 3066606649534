import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable';
import {
  GetNotificationsQuery,
  ViewNotificationsMutation,
  GetNotificationCountsQuery,
  NotificationCreatedSubscription,
  ReadAllNotifications,
} from '@/services/graphql/notification.graphql';
import type { Notification } from '@/types/notification.types';
import { viewAllNotificationsInCache } from '@/utils/cache.utils';

export const getNotificationsQuery = () =>
  useQuery<{ notifications: Notification[] }>(GetNotificationsQuery, {
    take: 20,
    skip: 0,
  });

export const viewNotificationsMutation = () =>
  useMutation<
    boolean,
    {
      ids: string[];
    }
  >(ViewNotificationsMutation, {
    update: cache => {
      viewAllNotificationsInCache(cache);
    },
  });

export const readAllNotificationsMutation = () =>
  useMutation<boolean>(ReadAllNotifications, {
    update: cache => {
      viewAllNotificationsInCache(cache);
    },
  });

export const getNotificationCountsQuery = () =>
  useQuery<{
    notificationCounts: {
      total: number;
      unviewed: number;
    };
  }>(GetNotificationCountsQuery);

export const notificationCreatedSubscription = () =>
  useSubscription<{
    NotificationCreated: Notification;
  }>(NotificationCreatedSubscription);
