import { useMutation, useQuery } from '@vue/apollo-composable';
import { GetTeamsQuery, CreateTeamMutation, DeleteTeamMutation } from '@/services/graphql/team.graphql';
import type { Team } from '@/types/team.types';

export const getTeamsQuery = () => useQuery<{ teams: Team[] }>(GetTeamsQuery);
export const createTeamMutation = () =>
  useMutation<{ createTeam: Team }, { team: { name: string } }>(CreateTeamMutation, {
    update: (cache, { data }) => {
      const { teams } = cache.readQuery<{ teams: Team[] }>({ query: GetTeamsQuery }) || { teams: [] };
      cache.writeQuery({
        query: GetTeamsQuery,
        data: {
          teams: [...teams, data?.createTeam],
        },
      });
    },
  });

export const deleteTeamMutation = () =>
  useMutation<{ deleteTeam: Team }, { id: string }>(DeleteTeamMutation, {
    update: (cache, { data }) => {
      const { teams } = cache.readQuery<{ teams: Team[] }>({ query: GetTeamsQuery }) || { teams: [] };
      cache.writeQuery({
        query: GetTeamsQuery,
        data: {
          teams: teams.filter(team => team.id !== data?.deleteTeam.id),
        },
      });
    },
  });
