import type { Dashboard, DashboardVisibility } from '@/types/dashboard.types';
import type { User } from '@/types/user.types';

interface BadgeSettings {
  label: string;
  color: 'gray' | 'green-light' | 'violet';
}

export const getDashboardVisibility = (dashboard: Dashboard): DashboardVisibility | undefined => {
  if (dashboard.user?.id && dashboard.brand?.id) return 'private';
  if (dashboard.user?.id && !dashboard.brand?.id) return 'global';
  if (dashboard.team?.id && !dashboard.user?.id && !dashboard.brand?.id) return 'team_universal';
  if (!dashboard.user?.id && !dashboard.brand?.id && !dashboard.team?.id) return 'universal';
  return undefined;
};

export const getDashboardBadge = (dashboard: Dashboard, brandName?: string): BadgeSettings | undefined => {
  const dashType = getDashboardVisibility(dashboard);
  if (dashType === 'private' || dashType === 'global') {
    return { label: `${brandName || 'barnd'}`, color: 'gray' };
  }
  if (dashType === 'team_universal') {
    return { label: 'Team', color: 'green-light' };
  }
  if (dashType === 'universal') {
    return { label: 'System', color: 'violet' };
  }
  return undefined;
};

export const isDashbordInReadOnlyMode = (dashboard: Dashboard, user: User, isSuperAdmin: boolean): boolean => {
  const dashType = getDashboardVisibility(dashboard);

  if ((dashType === 'private' || dashType === 'global') && user.id !== dashboard.user?.id) {
    return true;
  }

  if (dashType === 'team_universal' && (!user.admin || user.team?.id !== dashboard.team?.id)) {
    return true;
  }

  if (dashType === 'universal' && !isSuperAdmin) {
    return true;
  }
  return false;
};
