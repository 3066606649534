<script setup lang="ts">
import { BaseTextArea, Button } from '@kleecks/ui-lib';
import type { KeyToEdit } from '../modules-dictionary/modules-dictionary.constants';

interface DictionaryFormProps {
  keysToEdit: KeyToEdit[];
  openKeyPreview: (keyId: string) => void;
  onSave: (data: any) => void;
  onClose: () => void;
}

const props = defineProps<DictionaryFormProps>();
const jsonToEdit = defineModel<any>();
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col gap-4 divide-y divide-kl-gray-200">
    <div class="min-h-0 flex-1 space-y-4 overflow-y-auto">
      <div
        v-for="key in props.keysToEdit"
        :key="key.id"
        class="flex flex-col gap-4 rounded-md border border-dashed border-kl-gray-200 bg-kl-gray-100/50 p-4">
        <div class="flex items-center gap-4">
          <span class="text-sm font-medium text-primary">{{ key.id }}</span>
        </div>
        <div class="ml-2 space-y-4 border-l border-kl-gray-200 pl-4">
          <template
            v-for="field in key.fields"
            :key="field">
            <BaseTextArea
              v-if="typeof field === 'string'"
              :key="field"
              v-model="jsonToEdit[key.id][field]"
              :name="field"
              :label="field" />

            <div v-else>
              <span class="text-sm font-medium">
                {{ field.id }}
              </span>
              <div class="ml-2 flex flex-col gap-4 border-l border-kl-gray-200 pl-4 pt-4">
                <BaseTextArea
                  v-for="subField in field.fields"
                  :key="subField"
                  v-model="jsonToEdit[key.id][field.id][subField]"
                  :name="field"
                  :label="subField" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center justify-end gap-4 self-end pt-4">
      <Button
        variant="secondary"
        @click="props.onClose">
        Close
      </Button>
      <Button
        variant="primary"
        @click="onSave(jsonToEdit)">
        Save
      </Button>
    </div>
  </div>
</template>
