<script setup lang="ts">
import ModuleCard from '@/components/cards/module-card/ModuleCard.vue';
import AssignModuleToDashboardDialog from '@/components/dialogs/assign-module-to-dashboard/AssignModuleToDashboardDialog.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import SidePanel from '@/components/panels/side-panel/SidePanel.vue';
import ModulesLibraryTable from '@/components/tables/modules-library-table/ModulesLibraryTable.vue';
import type { ModuleCategory, MODULES } from '@/constants/modules.constants';
import {
  MODULES_CATEGORIES,
  MODULES_DESCRIPTIONS,
  MODULES_ICON,
  MODULES_NAMES,
  MODULES_TABS,
} from '@/constants/modules.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useModules } from '@/hooks/modules/useModules';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/vue/24/outline';
import { type VIEW_TYPE } from '@kleecks/code-lib';
import { MODULE_PROPERTY_RULES } from '@kleecks/modules-lib';
import { BaseTextInput, StandardContainer, Tabs, Button } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useUser } from '@/hooks/user/useUser';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';
import { useChatbotStore } from '@/stores/useChatbotStore';
import NotFound from '../not-found/NotFound.vue';

const chatbotStore = useChatbotStore();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const viewType = ref<VIEW_TYPE>('grid');
const { instance, loading } = useInstance(brandId, instanceId);
const { getLabel } = useApplicationLabels();

interface IModuleCard {
  id: MODULES;
  name: string;
  description: string;
  icon: any;
}
const changeViewType = (newValue: VIEW_TYPE) => {
  viewType.value = newValue;
};

const { modulesLibrary } = useModules();
const searchValue = ref('');
const { user } = useUser();
const moduleIdToAssign = ref<MODULES>();
const tabs = computed(() =>
  user.value?.admin
    ? MODULES_TABS
    : MODULES_TABS.filter(tab => {
        if (tab.id === 'all') return true;
        return modulesLibrary.value?.some(module =>
          MODULES_CATEGORIES[module as MODULES]?.includes(tab.id as ModuleCategory)
        );
      })
);

const currentTabId = ref<string>(tabs.value[0].id);

const availableModulesCount = computed(
  () =>
    modulesLibrary.value?.filter(
      el =>
        currentTabId.value === 'all' ||
        MODULES_CATEGORIES[el as MODULES]?.includes(currentTabId.value as ModuleCategory)
    )?.length
);

const modulesCard = computed<IModuleCard[]>(() =>
  modulesLibrary.value
    ?.filter(
      el =>
        currentTabId.value === 'all' ||
        MODULES_CATEGORIES[el as MODULES]?.includes(currentTabId.value as ModuleCategory)
    )
    .reduce<IModuleCard[]>((acc, module) => {
      if (
        !searchValue.value ||
        MODULES_NAMES[module as MODULES].toLowerCase().includes(searchValue.value.toLowerCase())
      )
        return [
          ...acc,
          {
            id: module as MODULES,
            name: MODULES_NAMES[module as MODULES],
            description: MODULES_DESCRIPTIONS[module as MODULES],
            icon: MODULES_ICON[module as MODULES],
          },
        ];

      if (MODULE_PROPERTY_RULES[module]?.some(rule => rule.toLowerCase().includes(searchValue.value.toLowerCase())))
        return [
          ...acc,
          {
            id: module as MODULES,
            name: MODULES_NAMES[module as MODULES],
            description: MODULES_DESCRIPTIONS[module as MODULES],
            icon: MODULES_ICON[module as MODULES],
          },
        ];
      return acc;
    }, [])
    .toSorted((a, b) => a.name.localeCompare(b.name))
);

const assignModuleToDashboardShow = (id: MODULES) => {
  moduleIdToAssign.value = id;
};
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('MODULES_LIBRARY.hero.help')"
    modal-title="Modules Library info" />
  <AssignModuleToDashboardDialog
    v-if="moduleIdToAssign"
    :module-id="moduleIdToAssign"
    @close="moduleIdToAssign = undefined" />
  <NotFound v-if="!loading && !instance" />
  <StandardContainer v-if="instance">
    <div
      class="flex items-start gap-8 py-8 transition-all duration-100"
      :class="{
        'container-height-chatbot': chatbotStore.isShowInPage,
        'container-height-no-chatbot delay-100': !chatbotStore.isShowInPage,
      }">
      <div class="flex h-full flex-1 flex-col">
        <div class="flex min-h-0 grow flex-col gap-4">
          <DashboardHeader
            title="Modules library"
            sub-title="The library of your active modules that you can use in this project" />
          <Tabs
            size="sm"
            tab-size-class="flex-1"
            :tabs="tabs"
            :on-change="tabId => (currentTabId = tabId)"
            :current-tab-id="currentTabId" />
          <div class="flex items-center">
            <BaseTextInput
              v-model="searchValue"
              input-classes="bg-white"
              placeholder="Search Modules..."
              type="search" />
            <div class="flex pl-2">
              <Squares2X2Icon
                :class="[
                  'mx-2 h-6 w-auto cursor-pointer hover:text-active',
                  {
                    'text-active': viewType === 'grid',
                    'text-gray-400': viewType !== 'grid',
                  },
                ]"
                aria-hidden="true"
                @click="() => changeViewType('grid')" />
              <ListBulletIcon
                :class="[
                  'mx-2 h-6 w-auto cursor-pointer hover:text-active',
                  {
                    'text-active': viewType === 'list',
                    'text-gray-400': viewType !== 'list',
                  },
                ]"
                aria-hidden="true"
                @click="() => changeViewType('list')" />
            </div>
          </div>
          <div
            v-if="availableModulesCount === 0"
            class="flex flex-col items-center justify-center rounded-lg border border-kl-gray-200 bg-white p-6">
            <h1 class="text-center text-kl-base font-medium text-kl-gray-500">
              This module is not currently available with your plan.
            </h1>
            <p class="text-center text-kl-sm text-kl-gray-400">
              Please contact us if you would like more information or to schedule a demo.
            </p>
            <Button
              class="mt-4"
              variant="blue"
              disabled>
              Contact Us
            </Button>
          </div>
          <div
            v-if="viewType === 'grid' && modulesCard.length > 0"
            class="scrollbar-1 grid grid-cols-2 gap-8 overflow-y-auto py-4 2xl:grid-cols-3">
            <ModuleCard
              v-for="module in modulesCard"
              :id="module.id"
              :key="module.id"
              class="py-2"
              :name="module.name"
              :icon="module.icon"
              :description="module.description"
              :on-click="
                () => {
                  assignModuleToDashboardShow(module.id);
                }
              " />
          </div>
          <div
            v-else-if="viewType === 'list' && modulesCard.length > 0"
            class="flex min-h-0 flex-col">
            <ModulesLibraryTable
              :modules="modulesCard"
              @assign-module="assignModuleToDashboardShow" />
          </div>
        </div>
      </div>
      <div class="flex min-h-0 w-[410px] flex-col items-start self-stretch">
        <SidePanel />
      </div>
    </div>
  </StandardContainer>
</template>
