<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useInstances } from '@/hooks/instance/useInstances';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Task } from '@/types/task.types';
import { BaseTextInput, Button, DialogContainer, KleecksInput, Modal } from '@kleecks/ui-lib';
import { computed, ref, toRefs } from 'vue';

interface ScheduleTaskDialogProps {
  isOpen: boolean;
  brandId?: string;
  task: Task;
  onClose: () => void;
}
const props = defineProps<ScheduleTaskDialogProps>();

const { title, brand } = toRefs(props.task);
const { mutate: updateTask, loading } = useUpdateTask();
const { notify } = useNotificationStore();
const brandId = ref(props.brandId);
const scheduleTime = ref('');

const { instances } = useInstances(brandId);

const instancesOptions = computed(() =>
  instances.value?.map(el => ({
    label: el.instanceId,
    value: el.instanceId,
  }))
);

const handleScheduleTask = async () => {
  await updateTask({
    task: {
      id: props.task.id,
      status: 'Scheduled',
      // scheduledTime: scheduleTime.value,
    },
  });
  notify(DEFAULT_NOTIFICATIONS.TASK_PUBLISHED);
  props.onClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="props.onClose">
    <Modal
      :on-close="props.onClose"
      class="w-80"
      title="Schedule task"
      sub-title="Select the time when you want schedule the task">
      <div class="flex flex-col gap-4">
        <BaseTextInput
          id="task_name"
          v-model="title"
          type="text"
          label="Task Name*"
          name="task_name"
          :disabled="true" />

        <BaseTextInput
          id="task_brand"
          v-model="brand.label"
          label="Brand*"
          name="task_brand"
          type="text"
          :disabled="true" />

        <KleecksInput
          id="schedule_time"
          type="datetime"
          label="Time*"
          name="schedule_time"
          :options="instancesOptions"
          :on-change="value => (scheduleTime = value)"
          :value="scheduleTime" />

        <Button
          class="mt-4 self-end"
          type="button"
          variant="primary"
          :disabled="loading"
          :is-loading="loading"
          @click="handleScheduleTask">
          Publish
        </Button>
      </div>
    </Modal>
  </DialogContainer>
</template>
