<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { TASK_PRIORITY_MAP } from '@/constants/task.constants';
import { TOPIC_OPTIONS } from '@kleecks/code-lib';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useUsers } from '@/hooks/user/useUsers';
import { getUserQuery } from '@/services/queries/user.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Task, TaskPriority } from '@/types/task.types';
import { Combobox, BaseTextArea, BaseTextInput, BaseToggle, Button, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, toRef, watch, watchEffect } from 'vue';
import { useInstances } from '@/hooks/instance/useInstances';
import { useRouteParam } from '@/hooks/common/useRouteParam';

import * as yup from 'yup';

import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';

interface EditTaskDialogProps {
  isOpen: boolean;
  task?: Task;
  isPreset?: boolean;
}

const props = defineProps<EditTaskDialogProps>();
const userQuery = getUserQuery();
const { users } = useUsers();
const { notify } = useNotificationStore();
const brandId = useRouteParam('brandId');
const { instancesNames } = useInstances(brandId);
const usersOptions = computed(() =>
  users.value
    .map(user => ({
      label: `${user.nome} ${user.cognome}`,
      value: user.login,
    }))
    .toSorted((a, b) => a.label.localeCompare(b.label))
);
const userLogin = computed(() => {
  const user = userQuery.result?.value?.user;
  if (!user) return '';
  return user.login;
});

const priorityOptions = Object.keys(TASK_PRIORITY_MAP).map(key => ({
  label: TASK_PRIORITY_MAP[key as TaskPriority],
  value: key,
}));

const yupSchema = toRef(() =>
  props.isPreset
    ? yup.object().shape({
        title: yup.string().required('The title of the task is required.').nonNullable(),
        topic: yup.string().required('The topic is required.'),
      })
    : yup.object().shape({
        title: yup.string().required('The title of the task is required.').nonNullable(),
        brand: yup.string().required('The brand is required.'),
        instanceId: yup.string().required('The instance is required.'),
        assignee: yup.string().required('The assignee is required.'),
        priority: yup.string().required('The priority is required.'),
        topic: yup.string().required('The topic is required.'),
      })
);

const { validateSchema, errors } = useSchemaValidation(yupSchema);

const taskValues = ref({
  title: props.task?.title,
  description: props.task?.description,
  instanceId: '',
  assignee: props.task?.assignedTo?.[0]?.login,
  brand: props.task?.brand.label || props.task?.brand.name,
  assignToMe: false,
  priority: props.task?.priority as string,
  topic: props.task?.topic,
});

watchEffect(() => {
  if (!props.task) return;
  if (instancesNames.value) {
    taskValues.value.instanceId =
      instancesNames.value[props.task.instance?.instanceId || ''] || props.task?.instance?.instanceId!;
  }
});

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen)
      taskValues.value = {
        title: props.task?.title,
        description: props.task?.description,
        instanceId: props.task?.instance?.instanceId || '',
        brand: props.task?.brand.label || props.task?.brand.name,
        assignee: props.task?.assignedTo?.[0]?.login,
        assignToMe: false,
        priority: props.task?.priority as string,
        topic: props.task?.topic,
      };
  }
);

watch(
  () => taskValues.value.assignToMe,
  () => {
    if (taskValues.value.assignToMe) taskValues.value.assignee = userLogin.value;
  }
);

const { mutate: updateTask, loading: isTaskUpdating } = useUpdateTask();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const handleClose = () => {
  emits('close');
};

const handleUpdateTask = async () => {
  if (!(await validateSchema(taskValues.value))) return;
  const assignedTo = taskValues.value.assignToMe ? [userLogin.value] : [taskValues.value.assignee!];

  if (!props.task) return;

  const payload = {
    id: props.task.id!,
    assignedBy: userLogin.value!,
    assignedTo,
    description: taskValues.value.description!,
    endTime: new Date(),
    owner: ['test owner'],
    priority: taskValues.value.priority!,
    startTime: new Date(),
    topic: taskValues.value.topic!,
    title: taskValues.value.title!,
    totH: 10,
    viewer: ['test viewer'],
  };
  await updateTask({
    task: payload,
  });
  notify(DEFAULT_NOTIFICATIONS.TASK_UPDATED(taskValues.value.title!));
  handleClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="handleClose">
    <Modal
      v-if="props.task"
      :on-close="handleClose"
      title="Edit Task"
      :sub-title="`Edit the task ${props.task.title} and the save your changes`">
      <div class="flex w-[40rem] gap-4">
        <div class="flex flex-1 flex-col gap-4">
          <BaseTextInput
            id="task_title"
            v-model="taskValues.title"
            type="text"
            :status="{
              message: errors.title,
              valid: !errors.title,
            }"
            label="Task name*"
            name="task_title" />

          <BaseTextInput
            v-if="!props.isPreset"
            id="task_brand"
            v-model="taskValues.brand"
            label="Brand*"
            :status="{
              message: errors.brand,
              valid: !errors.brand,
            }"
            name="task_brand"
            :disabled="true" />

          <BaseTextInput
            v-if="!props.isPreset"
            id="task_instance"
            v-model="taskValues.instanceId"
            label="Project*"
            :status="{
              message: errors.instanceId,
              valid: !errors.instanceId,
            }"
            name="task_instance"
            :disabled="true" />

          <Combobox
            v-if="!props.isPreset"
            v-model="taskValues.assignee"
            :items="usersOptions"
            :disabled="taskValues.assignToMe"
            label="Assign to*"
            :status="{
              message: errors.assignee,
              valid: !errors.assignee,
            }"
            placeholder="Assign to an user..."
            data-cy="locale" />

          <BaseToggle
            v-if="!props.isPreset"
            v-model="taskValues.assignToMe"
            class="mt-2"
            label="Assign to me" />
        </div>
        <div class="flex flex-1 flex-col gap-4 border-l border-sec pl-4">
          <BaseTextArea
            id="task_description"
            v-model="taskValues.description"
            label="Task Description"
            name="task_description" />

          <Combobox
            id="task_priority"
            v-model="taskValues.priority"
            label="Priority"
            :status="{
              message: errors.priority,
              valid: !errors.priority,
            }"
            name="task_priority"
            :items="priorityOptions" />

          <Combobox
            id="task_topic"
            v-model="taskValues.topic"
            label="Topic"
            name="task_topic"
            :items="TOPIC_OPTIONS" />
        </div>
      </div>
      <div class="mt-8 flex justify-end">
        <Button
          variant="primary"
          size="sm"
          :is-loading="isTaskUpdating"
          :disabled="isTaskUpdating"
          @click="handleUpdateTask">
          Update
        </Button>
      </div>
    </Modal>
  </DialogContainer>
</template>
