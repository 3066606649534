import { deleteBrandMutation } from '@/services/queries/brand.query';

export const useDeleteBrand = () => {
  const { mutate, loading } = deleteBrandMutation();

  const deleteBrand = async (name: string) => {
    const res = await mutate({ name });
    return res?.data?.deleteBrand;
  };

  return {
    deleteBrand,
    loading,
  };
};
