<script setup lang="ts">
import { useUser } from '@/hooks/user/useUser';
import { useUpdateUser } from '@/hooks/user/useUpdateUser';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { BaseTextInput, Button, FileUploader } from '@kleecks/ui-lib';
import { ref } from 'vue';
import { SERVER_BASE_URL } from '@/constants/settings.constants';
import { COOKIES, getCookie } from '@kleecks/code-lib';
import * as yup from 'yup';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';

interface ProfileProps {
  onClose?: () => void;
}

const props = defineProps<ProfileProps>();
const fileInputRef = ref<HTMLInputElement | null>(null);

const uploadedFile = ref<File | null>(null);

const { user } = useUser();
const { mutate: updateUser } = useUpdateUser();
const isUpdatingUser = ref(false);
const { notify } = useNotificationStore();

const onInputFileChanged = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const filesList = target.files;
  if (!filesList) return;
  uploadedFile.value = filesList.item(0);
  fileInputRef.value!.files = null;
};

const yupSchema = yup.object().shape({
  nome: yup.string().required('First name is required'),
  cognome: yup.string().required('Last name is required'),
  team: yup.string().required('Team is required'),
  login: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string(),
  confirmPassword: yup.string().when('password', (password: string[]) => {
    if (password?.[0]) return yup.string().oneOf([yup.ref('password')], 'Passwords must match');
    return yup.string();
  }),
});

const { errors, validateSchema } = useSchemaValidation(yupSchema);
interface UserProfile {
  nome: string;
  cognome: string;
  password: string;
  confirmPassword: string;
  team: string;
  login: string;
}

const userValues = ref<UserProfile>({
  nome: user?.value?.nome || '',
  cognome: user?.value?.cognome || '',
  password: '',
  confirmPassword: '',
  team: user?.value?.team?.id || '',
  login: user?.value?.login || '',
});

const uploadPhoto = async () => {
  const url = `${SERVER_BASE_URL}/images/user`;

  const formData = new FormData();
  formData.append('user', user.value?.id!);
  formData.append('icon', 'false');
  formData.append('image', uploadedFile.value!);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${getCookie(COOKIES.TOKEN_KEY)}`);

  const method = 'POST';

  const response = await fetch(url, {
    method,
    redirect: 'follow',
    headers,
    body: formData,
  });

  return response;
};

const saveProfile = async () => {
  try {
    isUpdatingUser.value = true;
    if (!(await validateSchema(userValues.value))) return;
    const payload = {
      nome: userValues.value.nome,
      cognome: userValues.value.cognome,
    };

    if (userValues.value.password) {
      (payload as any).password = userValues.value.password;
    }

    if (uploadedFile.value) await uploadPhoto();

    await updateUser({ user: payload, login: user?.value?.login! });
    notify(DEFAULT_NOTIFICATIONS.USER_UPDATED(userValues.value.nome));
    props?.onClose?.();
  } finally {
    isUpdatingUser.value = false;
  }
};
</script>

<template>
  <div class="flex h-full min-h-0 flex-col gap-4">
    <div class="flex min-h-0 flex-1 items-stretch gap-4 divide-x divide-kl-gray-200">
      <input
        ref="fileInputRef"
        type="file"
        multiple
        hidden
        :accept="['.png', 'jpg', '.jpeg'].join(', ')"
        @change="onInputFileChanged" />
      <div
        v-if="user?.image && !uploadedFile"
        class="text-center">
        <div class="relative size-[200px] overflow-hidden rounded-full border border-kl-gray-200 shadow-sm">
          <img
            class="size-full object-cover"
            :src="user.image" />
        </div>
        <div class="mt-4">
          <span
            class="cursor-pointer text-sm text-kl-red-200 hover:underline"
            @click="fileInputRef?.click()">
            Change
          </span>
        </div>
      </div>
      <div v-else>
        <div class="size-[200px] overflow-hidden rounded-full border border-kl-gray-200 shadow-sm">
          <FileUploader v-model="uploadedFile" />
        </div>

        <div
          v-if="uploadedFile"
          class="mt-4 flex flex-col text-center">
          <span
            class="cursor-pointer text-sm text-kl-red-200 hover:underline"
            @click="uploadedFile = null"
            >Remove</span
          >
        </div>
      </div>
      <form
        class="flex min-h-0 flex-1 flex-col gap-4 overflow-y-auto pl-4"
        @submit.prevent="saveProfile">
        <span class="w-fit text-sm font-medium text-kl-gray-400">Basic Info</span>
        <div class="flex flex-col gap-4 rounded-lg border border-kl-gray-200 p-4">
          <div class="flex gap-4">
            <BaseTextInput
              :id="'user-name'"
              v-model="userValues.nome"
              :status="{
                valid: !errors.nome,
                message: errors.nome,
              }"
              label="First name"
              :name="`user-name`" />

            <BaseTextInput
              :id="'user-last-name'"
              v-model="userValues.cognome"
              label="Last name"
              :status="{
                valid: !errors.cognome,
                message: errors.cognome,
              }"
              :name="`user-last-name`" />
          </div>
          <div class="flex gap-4">
            <BaseTextInput
              :id="'user-team'"
              v-model="userValues.team"
              label="Team"
              :status="{
                valid: !errors.team,
                message: errors.team,
              }"
              :name="`user-team`"
              disabled />

            <BaseTextInput
              :id="'user-login'"
              v-model="userValues.login"
              label="Email"
              :status="{
                valid: !errors.login,
                message: errors.login,
              }"
              :name="`user-login`"
              disabled />
          </div>
        </div>
        <span class="w-fit text-sm font-medium text-kl-gray-400">Change Password</span>

        <div class="flex flex-col gap-4 rounded-lg border border-kl-gray-200 p-4">
          <BaseTextInput
            :id="'user-password'"
            v-model="userValues.password"
            label="New Password"
            type="password"
            :name="`user-password`"
            autocomplete="one-time-code"
            :status="{
              valid: !errors.password,
              message: errors.password,
            }" />

          <BaseTextInput
            :id="'user-confirm-password'"
            v-model="userValues.confirmPassword"
            label="Confirm Password"
            type="password"
            :name="`user-confirm-password`"
            autocomplete="one-time-code"
            :status="{
              valid: !errors.confirmPassword,
              message: errors.confirmPassword,
            }" />
        </div>
      </form>
    </div>

    <div class="text-right">
      <Button
        variant="primary"
        size="md"
        :is-loading="isUpdatingUser"
        :disabled="isUpdatingUser"
        @click="saveProfile">
        Update
      </Button>
    </div>
  </div>
</template>
