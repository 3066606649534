import { GetPaginatedBrands } from '@/services/graphql/brand.graphql';
import { type Brand } from '@/types/brand.types';
import { useQuery } from '@vue/apollo-composable';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

export const BRANDS_LIMIT = 20;

export const usePaginatedBrands = (search: Ref<string | undefined>) => {
  // eslint-disable-next-line no-undef
  const timeoutRef = ref<NodeJS.Timeout | null>(null);
  const deferredSearch = ref<string | undefined>(search.value);

  watch(search, value => {
    if (timeoutRef.value) clearTimeout(timeoutRef.value);

    timeoutRef.value = setTimeout(() => {
      deferredSearch.value = value;
    }, 500);
  });

  const query = useQuery<{
    brands: Brand[];
  }>(GetPaginatedBrands, () => ({
    orderBy: {
      label: 'asc',
    },
    take: BRANDS_LIMIT,
    skip: 0,
    ...(deferredSearch.value?.length
      ? {
          where: {
            row: {
              contains: deferredSearch.value,
            },
          },
        }
      : {}),
  }));

  const brands = computed(() => query.result?.value?.brands?.filter(x => x.name !== '_preset') || []);
  const hasMore = computed(() => brands.value.length > 0 && brands.value.length % BRANDS_LIMIT === 0);

  const fetchMore = () => {
    query.fetchMore({
      variables: {
        take: BRANDS_LIMIT,
        skip: brands.value.length,
        ...(search?.value
          ? {
              where: {
                nome: {
                  contains: search.value,
                },
              },
            }
          : {}),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          brands: [...previousResult.brands, ...fetchMoreResult.brands],
        };
      },
    });
  };

  return {
    ...query,
    brands,
    fetchMore,
    hasMore,
  };
};
