<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useCreateBrand } from '@/hooks/brand/useCreateBrand';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { BaseTextInput, Button, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, watch } from 'vue';
import * as yup from 'yup';

interface CreateBrandDialogProps {
  isOpen: boolean;
  onClose: (newBrandId?: string) => void;
}

const props = defineProps<CreateBrandDialogProps>();
const { createBrand, loading } = useCreateBrand();
const { notify } = useNotificationStore();

const yupSchema = yup.object().shape({
  label: yup.string().required('Brand name is required'),
  mainUrl: yup.string().url('Main url must be a valid url (e.g. https://example.com)'),
});

const { validateSchema, errors } = useSchemaValidation(yupSchema);
const brandValues = ref<{
  label: string;
  mainUrl: string;
}>({
  label: '',
  mainUrl: '',
});

const handleClose = (newBrandId?: string) => {
  props.onClose(newBrandId);
};

const handleCreateBrand = async () => {
  if (!(await validateSchema(brandValues.value))) return;
  const brandId = brandValues.value.label?.trim().toLowerCase().replaceAll(' ', '');
  const payload = {
    name: brandId,
    label: brandValues.value.label,
    mainUrl: brandValues.value.mainUrl,
  };
  await createBrand(payload);
  notify(DEFAULT_NOTIFICATIONS.BRAND_CREATED(brandValues.value.label));
  handleClose(brandId);
};

const isSaveEnabled = computed(() => brandValues.value.label !== '');

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen)
      brandValues.value = {
        label: '',
        mainUrl: '',
      };
  }
);
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-enter-key-press="handleCreateBrand"
    :on-close="
      () => {
        handleClose();
      }
    ">
    <Modal
      :on-close="
        () => {
          handleClose();
        }
      "
      title="Create brand"
      sub-title="Add a new brand into the platform">
      <div class="flex w-96 flex-col gap-4">
        <BaseTextInput
          id="brand_name"
          v-model="brandValues.label"
          placeholder="Brand name"
          label="Name*"
          name="brand_name"
          :status="{
            message: errors.label,
            valid: !errors.label,
          }" />

        <BaseTextInput
          id="brand_url"
          v-model="brandValues.mainUrl"
          placeholder="Brand main url"
          :status="{
            message: errors.mainUrl,
            valid: !errors.mainUrl,
          }"
          label="Main url"
          name="brand_url" />
        <div class="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            :is-loading="loading"
            :disabled="!isSaveEnabled"
            @click="handleCreateBrand">
            Create
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
