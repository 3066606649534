<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination } from 'swiper/modules';
import type { Dashboard } from '@/types/dashboard.types';
import HomeDashboardCard from '@/components/cards/home-dashboard-card/HomeDashboardCard.vue';

interface DashboardSliderProps {
  dashboards: Dashboard[];
  navigateToDashboard: (dashboard: Dashboard) => void;
}

const props = defineProps<DashboardSliderProps>();
</script>

<template>
  <div class="relative h-fit">
    <swiper
      :modules="[Navigation, Pagination]"
      slides-per-view="auto"
      :height="140"
      class="relative"
      :style="{
        '--swiper-navigation-size': '18px',
        '--swiper-pagination-color': '#CCCCCC',
        '--swiper-pagination-bullet-inactive-color': '#E5E7EB',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-size': '24px',
        '--swiper-pagination-bullet-horizontal-gap': '4px',
        '--swiper-pagination-top': '140px',
        overflow: 'inherit',
      }"
      :space-between="10"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'hidden',
      }"
      :pagination="{
        clickable: false,
        renderBullet(index, className) {
          return `<span class='${className} rounded-none w-6 h-[.3rem]'></span>`;
        },
      }">
      <swiper-slide
        v-for="dashboard in props.dashboards"
        :key="dashboard.id"
        :modules="[Navigation, Pagination]"
        class="h-40 w-fit"
        :pagination="{ clickable: true }">
        <HomeDashboardCard
          :dashboard="dashboard"
          @click="navigateToDashboard(dashboard)" />
      </swiper-slide>
      <div
        class="swiper-button-prev absolute inset-y-0 left-0 flex h-32 w-8 cursor-pointer items-center justify-center bg-kl-gray-100/60 font-bold text-kl-gray-400 transition-all hover:bg-kl-gray-100 hover:text-kl-gray-600"></div>
      <div
        class="swiper-button-next absolute inset-y-0 -right-1 flex h-32 w-8 cursor-pointer items-center justify-center bg-kl-gray-100/60 font-bold text-kl-gray-400 transition-all hover:bg-kl-gray-100 hover:text-kl-gray-600"></div>
    </swiper>
  </div>
</template>
