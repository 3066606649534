import { TASK_STATUS } from '@/constants/task.constants';
import type { Task, TaskFilters } from '@/types/task.types';
import { compareAsc, parseISO, isValid } from 'date-fns';

// Map for status ordering
const STATUS_ORDER = {
  [TASK_STATUS.CREATING]: 0,
  [TASK_STATUS.NEW]: 1,
  [TASK_STATUS.DRAFT]: 2,
  [TASK_STATUS.IN_REVIEW]: 3,
  [TASK_STATUS.APPROVED]: 4,
  [TASK_STATUS.REJECTED]: 5,
  [TASK_STATUS.PUBLISHED]: 6,
  [TASK_STATUS.SCHEDULED]: 7,
};

// Helper function to parse a valid date or return null
const parseValidDate = (dateString: string | undefined): Date | null => {
  if (!dateString) return null; // Return null if date is undefined or empty

  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : null; // Return null if the date is invalid (e.g., 'pippo')
};

// Apply the correct ordering direction
const applyOrderDirection = (tasks: Task[], direction: 'asc' | 'desc') => {
  if (direction === 'desc') {
    return tasks.reverse();
  }
  return tasks;
};

// Helper function to sort by status
const sortByStatus = (tasks: Task[], direction: 'asc' | 'desc') => {
  const sortedTasks = [...tasks].sort((a, b) => {
    const statusA = STATUS_ORDER[a.status] ?? -1;
    const statusB = STATUS_ORDER[b.status] ?? -1;
    return statusA - statusB;
  });

  return applyOrderDirection(sortedTasks, direction);
};

// Helper function to sort by last modification date
const sortByLastModification = (tasks: Task[], direction: 'asc' | 'desc') => {
  const sortedTasks = [...tasks].sort((a, b) => {
    const dateA = parseValidDate(a.updatedAt); // Use updatedAt for last modification
    const dateB = parseValidDate(b.updatedAt);

    if (dateA && dateB) {
      return compareAsc(dateA, dateB); // Default to ascending order
    }
    if (dateA) {
      return -1;
    }
    if (dateB) {
      return 1;
    }
    return 0;
  });

  return applyOrderDirection(sortedTasks, direction);
};

// Main function to filter tasks
export const filterTasks = (tasksToFilter: Task[], filtersSettings: TaskFilters): Task[] => {
  let filteredTasks = [...tasksToFilter]; // Create a copy of the tasks array

  if (filtersSettings.orderBy) {
    const { by, direction } = filtersSettings.orderBy;
    const orderDirection = direction === 'desc' ? 'desc' : 'asc'; // Default to ascending

    if (by === 'status') {
      filteredTasks = sortByStatus(filteredTasks, orderDirection);
    }

    if (by === 'lastModification') {
      filteredTasks = sortByLastModification(filteredTasks, orderDirection);
    }
  }

  if (filtersSettings.filterByTopics.length > 0) {
    filteredTasks = filteredTasks.filter(task => filtersSettings.filterByTopics.includes(task.topic));
  }

  if (filtersSettings.filterByStatus.length > 0) {
    filteredTasks = filteredTasks.filter(task => filtersSettings.filterByStatus.includes(task.status));
  }

  if (filtersSettings.filterByAssignees.length > 0) {
    filteredTasks = filteredTasks.filter(task =>
      task.assignedTo.some(assignee => filtersSettings.filterByAssignees.includes(assignee.id))
    );
  }

  if (filtersSettings.searchKey) {
    const searchKey = filtersSettings.searchKey.toLowerCase();
    filteredTasks = filteredTasks.filter(task => {
      const title = task.title.toLowerCase() || '';
      const description = task.description?.toLowerCase() || '';
      return title.includes(searchKey) || description.includes(searchKey);
    });
  }

  return filteredTasks;
};

export const thereAreFilters = (filters: TaskFilters): boolean => {
  const { orderBy, searchKey, filterByTopics, filterByStatus, filterByAssignees } = filters;

  const checks = [
    orderBy.by !== undefined && orderBy.by !== '',
    orderBy.direction !== undefined && orderBy.direction !== '',
    searchKey !== '',
    filterByTopics.length > 0,
    filterByStatus.length > 0,
    filterByAssignees.length > 0,
  ];

  return checks.some(Boolean);
};

export const getCountOfFilterApplied = (filters: TaskFilters): { general: number; order: number } => {
  const { orderBy, searchKey, filterByTopics, filterByStatus, filterByAssignees } = filters;

  const generalChecks = [
    searchKey !== '',
    filterByTopics.length > 0,
    filterByStatus.length > 0,
    filterByAssignees.length > 0,
  ];

  const orderChecks = [orderBy.by !== undefined && orderBy.by !== ''];

  return {
    general: generalChecks.filter(Boolean).length,
    order: orderChecks.filter(Boolean).length,
  };
};
