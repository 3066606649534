<script setup lang="ts">
import DashboardCard from '@/components/cards/dashboard-card/DashboardCard.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import DashboardsTable from '@/components/tables/dashboards-table/DashboardsTable.vue';
import EditDashboardDialog from '@/components/dialogs/edit-dashboard-dialog/EditDashboardDialog.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useUser } from '@/hooks/user/useUser';
import { useBrand } from '@/hooks/brand/useBrand';

import type { Dashboard } from '@/types/dashboard.types';
import { type VIEW_TYPE } from '@kleecks/code-lib';
import { StandardContainer, Tabs, MessagePanel } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowPathIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import SidePanel from '@/components/panels/side-panel/SidePanel.vue';
import { useDashboards } from '@/hooks/dashboard/useDashboards';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';

import { useChatbotStore } from '@/stores/useChatbotStore';
import Fuse from 'fuse.js';
import NotFound from '../not-found/NotFound.vue';

const brandId = useRouteParam('brandId');
const { brand } = useBrand(brandId);
const { user, isSuperAdmin } = useUser();
const instanceId = useRouteParam('instanceId');
const chatbotStore = useChatbotStore();

const { instance, loading } = useInstance(brandId, instanceId);
const {
  allDashboards,
  privateDashboards,
  teamDashboards,
  kleecksDashboards,
  loading: dashboardLoading,
} = useDashboards(brandId.value, user.value);
const router = useRouter();
const { getLabel } = useApplicationLabels();

const searchValue = ref('');
const topicValue = ref<string[]>([]);

const selectedDashboardTab = ref('kleecksDashboard');

const getDashboardTabs = computed(() => [
  { id: 'kleecksDashboard', name: 'System Dashboards' },
  { id: 'teamDashboards', name: 'Team Dashboards' },
  { id: `brandDashboards`, name: `My Dashboards` },
  { id: 'allDashboards', name: 'All Dashboards' },
]);

const handleChangeDashboardTab = (tabId: string) => {
  if (tabId === selectedDashboardTab.value) return;
  selectedDashboardTab.value = tabId;
};

const filteredDashboards = computed(() => {
  // Step 1: Extract the Dashboard data by tabId
  let dashboardsByTabId = [] as Dashboard[];

  switch (selectedDashboardTab.value) {
    case 'brandDashboards':
      dashboardsByTabId = privateDashboards.value;
      break;
    case 'teamDashboards':
      dashboardsByTabId = teamDashboards.value;
      break;
    case 'kleecksDashboard':
      dashboardsByTabId = kleecksDashboards.value;
      break;
    default:
      // dashboardsByTabId = [...privateDashboards.value, ...teamDashboards.value, ...kleecksDashboards.value];
      dashboardsByTabId = allDashboards.value;
  }

  // Step 2: Apply Fuse.js search for text-based filtering
  const fuse = new Fuse(dashboardsByTabId, {
    keys: ['name', 'description'], // Search by name and description
    shouldSort: true, // Sort the results based on relevance
  });

  let searchResults = searchValue.value ? fuse.search(searchValue.value).map(result => result.item) : dashboardsByTabId;

  // Step 3: Apply topic-based filtering if topicValue is set
  if (topicValue.value.length > 0) {
    searchResults = searchResults.filter(dashboard => topicValue.value.some(topic => dashboard.topic === topic));
  }

  // Step 4: Return the final filtered dashboards
  return searchResults;
});

const viewType = ref<VIEW_TYPE>('grid');

const handleChangeViewType = (newValue: VIEW_TYPE) => {
  viewType.value = newValue;
};
const navigateToNewDashboard = () => {
  router.push({ name: ROUTE_NAMES.NEW_DASHBOARD });
};

const navigateToDashboard = (dashboard: Dashboard) => {
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: dashboard.id } });
};
const topic = (value: string[]) => {
  topicValue.value = value;
};

// Feature 3: Edit Dashboard Modal
const isOpenEditDashboardModal = ref(false);
const dashboardToEdit = ref<Dashboard | undefined>();
const getUserSettings = computed(() => ({
  teamId: user.value?.team?.id,
  login: user.value?.login,
  isAdmin: user.value?.admin ?? false,
  isSuperAdmin: isSuperAdmin.value ?? false,
}));
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('DASHBOARDS_LIBRARY.hero.help')!"
    modal-title="Dashboards Library info" />
  <NotFound v-if="!loading && !instance" />
  <StandardContainer v-if="instance">
    <div
      class="flex items-start gap-8 py-8 transition-all duration-100"
      :class="{
        'container-height-chatbot': chatbotStore.isShowInPage,
        'container-height-no-chatbot delay-100': !chatbotStore.isShowInPage,
      }">
      <div class="flex h-full flex-1 flex-col">
        <div class="flex min-h-0 w-full grow flex-col gap-8">
          <DashboardHeader
            v-model:search-value="searchValue"
            title="Dashboards"
            :sub-title="`The library of the dashboard for this instance`"
            :cta="{
              onClick: navigateToNewDashboard,
              label: 'Create New',
            }"
            :view-type="viewType"
            search-placeholder="Search dashboards"
            :topic-value="topicValue"
            :on-change-topic="value => topic(value)"
            :on-change-view-type="handleChangeViewType">
            <template #extra-header-content>
              <div class="mt-4 w-full">
                <Tabs
                  tab-size-class="px-4"
                  tab-header-size="w-full"
                  :current-tab-id="selectedDashboardTab"
                  :tabs="getDashboardTabs"
                  :on-change="tabId => handleChangeDashboardTab(tabId)" />
              </div>
            </template>
          </DashboardHeader>
          <transition-group name="fadein">
            <div
              v-if="!dashboardLoading && viewType === 'grid' && filteredDashboards.length > 0"
              class="scrollbar-1 grid grid-cols-2 gap-8 overflow-y-auto py-1 2xl:grid-cols-3">
              <DashboardCard
                v-for="dashboard in filteredDashboards"
                :key="dashboard.id"
                class="h-fit"
                :brand-name="brand?.label"
                :dashboard="dashboard"
                :on-send-dashboard-to-edit="
                  dashboard => {
                    dashboardToEdit = dashboard;
                    isOpenEditDashboardModal = true;
                  }
                "
                @click="navigateToDashboard(dashboard)" />
            </div>
            <div
              v-else-if="!dashboardLoading && viewType !== 'grid' && filteredDashboards.length > 0"
              class="flex h-full min-h-0 flex-col border">
              <DashboardsTable
                :dashboards="filteredDashboards"
                :brand-name="brand?.label"
                :on-send-dashboard-to-edit="
                  dashboard => {
                    dashboardToEdit = dashboard;
                    isOpenEditDashboardModal = true;
                  }
                " />
              />
            </div>
            <MessagePanel
              v-else
              class="h-full min-h-0 w-full"
              :messages="[
                {
                  text: `You haven't dashboards to show`,
                  icon: ExclamationCircleIcon,
                  visibility: !dashboardLoading && filteredDashboards.length === 0,
                },
                {
                  text: 'Loading dashboards',
                  icon: ArrowPathIcon,
                  iconCss: 'animate-spin',
                  visibility: dashboardLoading,
                },
              ]" />
          </transition-group>
        </div>
      </div>

      <div class="flex min-h-0 w-[410px] flex-col items-start self-stretch">
        <SidePanel />
      </div>
    </div>
  </StandardContainer>
  <EditDashboardDialog
    :is-open="isOpenEditDashboardModal"
    :dashboard="dashboardToEdit"
    :brand-id="brandId"
    :user-settings="getUserSettings"
    @close="isOpenEditDashboardModal = false" />
</template>
