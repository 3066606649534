<script setup lang="ts">
import type { Notification } from '@/types/notification.types';
import { formatDate } from '@/utils/date.utils';
import { formatNotificationComponent, formatNotificationLabelInHTML } from '@/utils/notification.utils';
import { BellIcon } from '@heroicons/vue/24/outline';
import { computed, ref, watch } from 'vue';

interface NotificationItemProps {
  notification: Notification;
}

const props = defineProps<NotificationItemProps>();

const htmlNotificationLabel = computed(() => formatNotificationLabelInHTML(props.notification));

const notificationComponent = computed(() => formatNotificationComponent(props.notification));

const showNotificationComponent = ref(false);

watch(notificationComponent, newValue => {
  showNotificationComponent.value = newValue !== null;
});
</script>

<template>
  <div
    class="flex w-full gap-2"
    data-testid="notification-item">
    <div
      class="size-8 rounded p-1"
      data-testid="notification-icon"
      :class="{
        'bg-[#FF978B]': !props.notification.viewed,
        'bg-[#E5E7EB]': props.notification.viewed,
      }">
      <BellIcon class="text-white" />
    </div>
    <div class="flex flex-1 flex-col overflow-hidden">
      <div class="inline-flex w-full items-center">
        <span
          class="flex-1 text-xs text-secondary"
          data-testid="when"
          >{{ formatDate(props.notification.when) }}</span
        >
      </div>
      <span
        class="mb-4 flex-auto whitespace-normal text-kl-sm text-primary"
        data-testid="label"
        v-html="htmlNotificationLabel" />

      <component
        :is="notificationComponent"
        v-if="notificationComponent" />
    </div>
  </div>
</template>
