<script setup lang="ts">
// import GlobalChip from '@/components/atoms/global-chip/GlobalChip.vue';
import InstanceActivitiesPanel from '@/components/panels/instance-activities-panel/InstanceActivitiesPanel.vue';
import TasksPanel from '@/components/panels/tasks-panel/TasksPanel.vue';
import StatsBalls from '@/components/stats/StatsBalls.vue';
import TaskEmpty from '@/components/tasks/tasks-empty/TaskEmpty.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useQueryParam } from '@/hooks/common/useQueryParam';

import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useDashboards } from '@/hooks/dashboard/useDashboards';
import { useInstance } from '@/hooks/instance/useInstance';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useCurrentInstanceTask } from '@/hooks/tasks/useCurrentInstanceTask';
import { useCreateTaskDialog } from '@/stores/useCreateTaskDialogStore';
import { useCurrentTaskDrawerStore } from '@/stores/useCurrentTaskDrawerStore';
import { useUserStore } from '@/stores/useUserStore';
import { formatDate } from '@/utils/date.utils';

import { INSTANCE_PANEL_TITLE_MAP, INSTANCES_PANEL_BG_COLORS_ASCII_MAP } from '@/constants/instance.constants';

import { useUser } from '@/hooks/user/useUser';
import type { Dashboard } from '@/types/dashboard.types';
import type { TaskAction } from '@/types/task.types';
import type { Topic } from '@kleecks/code-lib';
import { TaskStatusChip, UserProfileImage, WithTooltip } from '@kleecks/ui-lib';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import DashboardsSlider from './DashboardsSlider.vue';

interface InstanceDashboardLeftPanel {
  onTaskAction: (taskId: string, action: TaskAction) => void;
}

const props = defineProps<InstanceDashboardLeftPanel>();
const createTaskDialog = useCreateTaskDialog();
const router = useRouter();
const panel = useQueryParam('panel');
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { user } = useUser();

const { privateDashboards, kleecksDashboards } = useDashboards(brandId, user);
const { instance } = useInstance(brandId, instanceId);
const { instanceTasks } = useInstanceTasks(brandId, instanceId);

const tasksCount = computed(() => ({
  strategy: instance.value?.taskAggregatedCount.countByStrategy || 0,
  seo: instance.value?.taskAggregatedCount.countBySeo || 0,
  ux: instance.value?.taskAggregatedCount.countByUx || 0,
  issues: instance.value?.taskAggregatedCount.countByIssues || 0,
  settings: instance.value?.taskAggregatedCount.countBySettings || 0,
}));
const tasksColor = computed(() => ({
  strategy: INSTANCES_PANEL_BG_COLORS_ASCII_MAP.strategy || '',
  seo: INSTANCES_PANEL_BG_COLORS_ASCII_MAP.seo || '',
  ux: INSTANCES_PANEL_BG_COLORS_ASCII_MAP.ux || '',
  issues: INSTANCES_PANEL_BG_COLORS_ASCII_MAP.issues || '',
  settings: INSTANCES_PANEL_BG_COLORS_ASCII_MAP.settings || '',
}));

const userStore = useUserStore();
const { task } = useCurrentInstanceTask(userStore.selectedTask?.id);

const taskDrawerStore = useCurrentTaskDrawerStore();

const navigateToDashboard = (dashboard: Dashboard) => {
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId: dashboard.id } });
};

const navigateToAllTasks = () => {
  router.push({ name: ROUTE_NAMES.TASKS_MANAGEMENT });
};

const navigateToDashboardsLibrary = () => {
  router.push({ name: ROUTE_NAMES.DASHBOARDS_LIBRARY });
};

const addNewDashboard = () => {
  router.push({
    name: ROUTE_NAMES.NEW_DASHBOARD,
    params: {
      brandId: router.currentRoute.value.params.brandId,
      instance: router.currentRoute.value.params.instance,
    },
  });
};
</script>

<template>
  <div class="flex max-h-full min-h-0 flex-1 flex-col gap-y-8">
    <!--Dashboard Container-->
    <div
      v-if="panel === 'overview'"
      class="scrollbar-1 flex min-h-0 flex-1 flex-col justify-stretch gap-y-6 overflow-y-auto overflow-x-hidden pe-1">
      <div
        v-if="user?.admin"
        id="task_management"
        class="self-stretch">
        <div class="flex items-center gap-4 pb-3">
          <p class="flex-1 text-sm font-medium text-kl-gray-400">Task Management</p>
          <div
            class="cursor-pointer text-xs text-secondary underline hover:text-active"
            @click="navigateToAllTasks">
            Manage All Task
          </div>
          <div
            class="cursor-pointer text-xs text-secondary underline hover:text-active"
            @click="createTaskDialog.showCreateTaskDialog">
            Create Task
          </div>
        </div>
        <div class="flex justify-between gap-4 rounded border border-kl-gray-200 bg-white pb-0 pt-4">
          <StatsBalls
            class="w-1/2 px-2"
            :values="tasksCount"
            :colors="tasksColor"
            :labels="INSTANCE_PANEL_TITLE_MAP"
            class-label="text-xs"
            class-counter="text-xl"
            :max-size="75"
            :min-size="30" />
          <div class="mb-4 flex max-h-[120px] w-1/2 flex-col overflow-y-auto px-4">
            <TaskEmpty v-if="instanceTasks.length === 0" />
            <div
              v-for="taskItem in instanceTasks"
              v-else
              :key="taskItem.id"
              class="flex cursor-pointer items-center gap-2 border-b border-kl-gray-200 p-1 py-1 hover:bg-kl-gray-100 active:bg-kl-gray-200"
              @click="taskDrawerStore.openDrawer(taskItem.id)">
              <TaskStatusChip
                size="h-3.5 text-2xs w-[60px] justify-center"
                :task="taskItem" />
              <div class="flex-1 truncate text-xs text-primary">{{ taskItem.title }}</div>
              <div class="text-2xs text-secondary">
                {{ formatDate(taskItem.createdAt) }}
              </div>
              <div class="">
                <WithTooltip
                  v-if="taskItem.assignedTo?.[0]"
                  :label="`${taskItem.assignedTo[0]?.nome} ${taskItem.assignedTo[0]?.cognome}`">
                  <UserProfileImage
                    :user="taskItem.assignedTo?.[0]"
                    size="xs" />
                </WithTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="global-dashboards"
        class="self-stretch">
        <div class="flex items-center gap-4 pb-3">
          <p class="flex flex-1 items-center gap-2 text-sm font-medium text-kl-gray-400">
            System Dashboards
            <!-- <GlobalChip /> -->
          </p>
          <div
            class="cursor-pointer text-xs text-secondary underline hover:text-active"
            @click="navigateToDashboardsLibrary">
            View All Dashboards
          </div>
        </div>

        <DashboardsSlider
          v-if="kleecksDashboards.length"
          :dashboards="kleecksDashboards"
          :navigateToDashboard="navigateToDashboard" />
      </div>

      <div
        v-if="privateDashboards.length"
        id="your-dashboards"
        class="self-stretch">
        <div class="flex items-center gap-4 pb-3">
          <p class="flex-1 text-sm font-medium text-kl-gray-400">Your Dashboards</p>
          <div
            class="cursor-pointer text-xs text-secondary underline hover:text-active"
            @click="navigateToDashboardsLibrary">
            View All Dashboards
          </div>
          <div
            class="cursor-pointer text-xs text-secondary underline hover:text-active"
            @click="addNewDashboard">
            Create Dashboard
          </div>
        </div>

        <DashboardsSlider
          v-if="privateDashboards.length"
          :dashboards="privateDashboards"
          :navigateToDashboard="navigateToDashboard" />
      </div>
    </div>
    <template v-if="panel !== 'tasks' && panel !== 'overview'">
      <InstanceActivitiesPanel
        key="panel"
        data-testid="instance-activities-panel"
        :on-task-action="props.onTaskAction"
        :topic="(panel as Topic)" />
    </template>

    <div
      v-else-if="panel === 'tasks'"
      key="panel-tasks"
      class="flex min-h-0 flex-1 flex-col">
      <TasksPanel
        data-testid="instance-tasks-panel"
        :task="task" />
    </div>
  </div>

  <!-- TODO: enable this when AI hints are ready -->
  <!-- <transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-1000">
        <ul
            v-if="panel === 'ai' && chatbot.aiMessages.value.length === 0"
            role="list"
            class="grid grid-cols-3 gap-4">
            <li
                v-for="hint in hintsList"
                :key="hint.title"
                class="mb-4 flex rounded-md bg-kl-gray-200 px-6 py-4 shadow-custom">
                <div class="flex-1">
                    <div class="flex items-baseline">
                        <div class="mr-2 h-6 w-6 rounded-md border border-[#E56554] p-1 text-[#E56554]">
                            <ExclamationTriangleIcon class="h-full w-full" />
                        </div>
                        <div class="text-sm text-primary">{{ hint.title }}</div>
                    </div>
                    <p class="mt-2 text-xs font-light text-secondary">{{ hint.label }}</p>
                </div>
                <PlusCircleIcon class="h-6 w-6 text-kl-icon" />
            </li>
        </ul>
    </transition> -->
</template>
