<script setup lang="ts">
import { TASK_ERROR, TASK_PRIORITY_MAP, TASK_STATUS } from '@/constants/task.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useInstances } from '@/hooks/instance/useInstances';
import { useUsers } from '@/hooks/user/useUsers';
import { useCreateTask } from '@/services/queries/task.query';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { getUserQuery } from '@/services/queries/user.query';
import type { TaskPriority } from '@/types/task.types';
import type { Topic } from '@kleecks/code-lib';
import { TOPIC_OPTIONS } from '@kleecks/code-lib';
import { BaseTextArea, BaseTextInput, BaseToggle, Button, Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, watch, watchEffect } from 'vue';

import * as yup from 'yup';
import { ValidationError } from 'yup';

interface CreateTaskDialogProps {
  isOpen: boolean;
  instanceId?: string;
  topic?: Topic;
  isInstanceFixed?: boolean;
}

const { mutate: createTask, loading: isTaskCreating, error: graphQLError } = useCreateTask();
const props = defineProps<CreateTaskDialogProps>();
const { brands } = useBrands();
const userQuery = getUserQuery();

const brandId = ref(useRouteParam('brandId'));
const { users } = useUsers();
const { instances, instancesNames } = useInstances(brandId);

const errors = ref<{
  [key: string]: string;
}>({});

watch(
  () => graphQLError.value,
  () => {
    console.log(graphQLError.value);
    if (graphQLError.value?.graphQLErrors[0]?.extensions.code === TASK_ERROR) {
      errors.value.title = graphQLError.value?.graphQLErrors[0].message || '';
    }
  }
);

const yupSchema = yup.object().shape({
  title: yup.string().required('The title of the task is required.').nonNullable(),
  brand: yup.string().required('The brand is required.'),
  instanceId: yup.string().required('The instance is required.'),
  assignee: yup.string().required('The assignee is required.'),
  priority: yup.string().required('The priority is required.'),
  topic: yup.string().required('The topic is required.'),
});

const userLogin = computed(() => {
  const user = userQuery.result?.value?.user;
  if (!user) return '';
  return user.login;
});

const usersOptions = computed(() =>
  users.value
    .map(user => ({
      label: `${user.nome} ${user.cognome}`,
      value: user.login,
    }))
    .toSorted((a, b) => a.label.localeCompare(b.label))
);

const brandsOptions = computed(() =>
  brands.value.map(brand => ({
    label: brand.label || brand.name,
    value: brand.name,
  }))
);

const taskValues = ref<{
  title: string;
  brand: string;
  instanceId: string;
  description: string;
  assignee: string;
  assignToMe: boolean;
  priority: string;
  topic: string;
  includeApproved: boolean;
}>({
  title: '',
  brand: brandId.value || brandsOptions?.value[0]?.value,
  instanceId: props.instanceId || '',
  description: '',
  assignee: '',
  assignToMe: false,
  priority: 'low',
  topic: props.topic || TOPIC_OPTIONS[0].value,
  includeApproved: false,
});

const instancesOptions = computed(() =>
  instances.value?.map(el => ({
    label: instancesNames.value[el.instanceId] || el.instanceId,
    value: el.instanceId,
  }))
);

const validateForm = async () => {
  try {
    await yupSchema.validate(taskValues.value, { abortEarly: false });
    errors.value = {};
    return true;
  } catch (err) {
    if (err instanceof ValidationError) {
      errors.value = err.inner.reduce<{
        [key: string]: string;
      }>((acc, error) => {
        if (error.path) acc[error.path] = error.message;
        return acc;
      }, {});
    }
  }
  return false;
};

const priorityOptions = Object.keys(TASK_PRIORITY_MAP).map(key => ({
  label: TASK_PRIORITY_MAP[key as TaskPriority],
  value: key,
}));
const emits = defineEmits<{
  (e: 'close'): void;
}>();

const handleClose = () => {
  emits('close');
};

const handleCreateTask = async () => {
  if (!(await validateForm())) return;
  const assignedTo = taskValues.value.assignToMe ? [userLogin.value] : [taskValues.value.assignee];
  const payload = {
    assignedBy: userLogin.value,
    assignedTo,
    brand: taskValues.value.brand,
    instanceId: taskValues.value.instanceId,
    description: taskValues.value.description,
    endTime: new Date(),
    owner: [userLogin.value],
    priority: taskValues.value.priority,
    startTime: new Date(),
    title: taskValues.value.title,
    totH: 10,
    status: TASK_STATUS.NEW,
    topic: taskValues.value.topic,
    viewer: ['test viewer'],
    includeApproved: taskValues.value.includeApproved,
  };

  try {
    await createTask({
      task: payload,
    });
  } finally {
    if (!graphQLError.value?.message) {
      handleClose();
    }
  }
};

watch(
  () => taskValues.value.assignToMe,
  () => {
    if (taskValues.value.assignToMe) taskValues.value.assignee = userLogin.value;
  }
);

watch(
  () => props.isOpen,
  newIsOpenProp => {
    if (newIsOpenProp) {
      taskValues.value = {
        title: '',
        brand: brandId.value || brandsOptions?.value[0]?.value,
        instanceId: props.instanceId || '',
        description: '',
        assignee: '',
        assignToMe: false,
        priority: 'low',
        topic: props.topic || TOPIC_OPTIONS[0].value,
        includeApproved: false,
      };

      errors.value = {};
    }
  }
);
watchEffect(() => {
  taskValues.value.instanceId = props.instanceId || instancesOptions.value[0]?.value;
});
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="handleClose">
    <Modal
      :on-close="handleClose"
      title="Create a task"
      data-testid="create-task-modal"
      sub-title="Create a task and assign it to an user of your team">
      <div class="flex w-[40rem] gap-4">
        <div class="flex flex-1 flex-col gap-4">
          <BaseTextInput
            id="task_title"
            v-model="taskValues.title"
            type="text"
            :status="{
              message: errors.title,
              valid: !errors.title,
            }"
            label="Task name*"
            name="task_title" />

          <Combobox
            id="task_brand"
            v-model="taskValues.brand"
            label="Brand*"
            name="task_brand"
            :items="brandsOptions"
            data-testid="task_brand"
            :disabled="brandId !== undefined" />

          <Combobox
            id="task_instance"
            v-model="taskValues.instanceId"
            label="Project*"
            :disabled="props.isInstanceFixed"
            data-testid="task_instance"
            :items="instancesOptions" />

          <Combobox
            v-model="taskValues.assignee"
            :items="usersOptions"
            :disabled="taskValues.assignToMe"
            data-testid="task_assignee"
            label="Assign to*"
            :status="{
              message: errors.assignee,
              valid: !errors.assignee,
            }"
            placeholder="Assign to an user..."
            data-cy="locale" />

          <BaseToggle
            v-model="taskValues.assignToMe"
            data-testid="task_assign_to_me"
            class="mt-2"
            label="Assign to me" />
        </div>
        <div class="flex w-1/2 flex-1 flex-col gap-4 border-l border-sec pl-4">
          <BaseTextArea
            id="task_description"
            v-model="taskValues.description"
            label="Task Description"
            :rows="4"
            data-testid="task_description"
            name="task_description" />

          <Combobox
            id="task_priority"
            v-model="taskValues.priority"
            :status="{
              message: errors.priority,
              valid: !errors.priority,
            }"
            label="Priority"
            name="task_priority"
            :items="priorityOptions" />

          <Combobox
            id="task_topic"
            v-model="taskValues.topic"
            label="Topic"
            name="task_topic"
            data-testid="task_topic"
            :items="TOPIC_OPTIONS" />

          <div class="mt-2 break-all">
            <BaseToggle
              v-model="taskValues.includeApproved"
              label="Include previously approved tasks" />
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-end">
        <Button
          variant="primary"
          size="sm"
          type="button"
          data-testid="create-task-button"
          :is-loading="isTaskCreating"
          :disabled="isTaskCreating"
          @click="handleCreateTask">
          Create
        </Button>
      </div>
    </Modal>
  </DialogContainer>
</template>
