<script setup lang="ts">
import ActivitiesList from '@/components/activities/activities-list/ActivitiesList.vue';
import TasksList from '@/components/tasks/tasks-list/TasksList.vue';
import {
  INSTANCES_PANEL_BG_COLORS_MAP,
  INSTANCE_PANEL_COLORS_MAP,
  INSTANCE_PANEL_TITLE_MAP,
} from '@/constants/instance.constants';
import { TOPIC_ICONS_MAP } from '@/constants/topic-icons.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useUser } from '@/hooks/user/useUser';
import { useCreateTaskDialog } from '@/stores/useCreateTaskDialogStore';

import InstanceWidgetContainer from '@/components/widgets/instance-widget-container/InstanceWidgetContainer.vue';
import type { Task, TaskAction } from '@/types/task.types';
import type { Topic } from '@kleecks/code-lib';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { Button } from '@kleecks/ui-lib';
import { computed } from 'vue';
// const hintsList = [
//     {
//         title: 'Second level updated',
//         label: 'Lorem ipsum dolor sit amet and kleecks is the best',
//     },
//     {
//         title: 'Second level updateds',
//         label: 'Lorem ipsum dolor sit amet and kleecks is the best',
//     },
//     {
//         title: 'Second level updated',
//         label: 'Lorem ipsum dolor sit amet and kleecks is the best',
//     },
// ];

interface InstanceActivitiesPanelProps {
  topic: Topic;
  onTaskAction: (taskId: string, action: TaskAction) => void;
}
const props = defineProps<InstanceActivitiesPanelProps>();
const { user } = useUser();

const createTaskDialog = useCreateTaskDialog();

const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { instanceTasks, loading } = useInstanceTasks(brandId, instanceId);

const filteredTasksPerTopic = computed<Task[]>(() => instanceTasks.value.filter(task => task.topic === props.topic));
</script>

<template>
  <div class="flex min-h-0 grow flex-col gap-8">
    <div class="flex w-full items-start">
      <div :class="['mr-2 h-12 w-12 rounded-md border-2 p-2', INSTANCE_PANEL_COLORS_MAP[props.topic]]">
        <component
          :is="TOPIC_ICONS_MAP[props.topic]"
          class="h-full w-full" />
      </div>
      <div class="flex-1">
        <h1
          class="text-2xl font-medium text-primary"
          data-testid="instance-panel-title">
          {{ INSTANCE_PANEL_TITLE_MAP[props.topic] }}
        </h1>
        <p
          class="text-xs font-light text-secondary"
          data-testid="instance-panel-subtitle">
          Tasks and Activities
        </p>
      </div>
      <Button
        v-if="user?.admin"
        data-testid="new-task-button"
        variant="pink"
        @click="createTaskDialog.showCreateTaskDialog">
        <div class="flex items-center gap-2">
          <PlusIcon class="h-5 w-5 transition-transform duration-500 ease-in-out" />
          <span>New Task</span>
        </div>
      </Button>
    </div>

    <div class="flex min-h-0 w-full grow gap-8">
      <InstanceWidgetContainer
        :loading="loading"
        data-testid="tasks-left-panel"
        class="w-full pb-4 lg:w-1/2"
        :border-color="INSTANCES_PANEL_BG_COLORS_MAP[props.topic]"
        :title="`All ${INSTANCE_PANEL_TITLE_MAP[props.topic]} Tasks`">
        <TasksList
          :tasks="filteredTasksPerTopic"
          :is-schedule-enabled="false"
          :is-draggable="false"
          :is-publish-enabled="false"
          :on-task-action="props.onTaskAction" />
      </InstanceWidgetContainer>

      <InstanceWidgetContainer
        :loading="loading"
        data-testid="activities-left-panel"
        class="hidden w-1/2 pb-4 lg:flex"
        :border-color="INSTANCES_PANEL_BG_COLORS_MAP[props.topic]"
        :title="`All ${INSTANCE_PANEL_TITLE_MAP[props.topic]} Activities`">
        <ul
          data-testid="activities-list"
          role="list"
          class="flex h-full flex-col gap-4 overflow-y-auto px-4 pt-4">
          <template v-for="task in filteredTasksPerTopic">
            <ActivitiesList
              v-if="task.activitiesObject?.count! > 0"
              :key="task.id"
              :show-task-title="true"
              :task="task" />
          </template>
        </ul>
      </InstanceWidgetContainer>

      <!-- <div class="w-1/2 pl-8">
                <ul role="list">
                    <li
                        v-for="hint in hintsList"
                        :key="hint.title"
                        class="mb-4 flex rounded-md bg-kl-gray-200 px-6 py-4 shadow-custom">
                        <div class="flex-1">
                            <div class="flex items-baseline">
                                <div class="mr-2 h-6 w-6 rounded-md border border-kl-cyan p-1 text-kl-cyan">
                                    <LightBulbIcon class="h-full w-full" />
                                </div>
                                <div class="text-base text-primary">{{ hint.title }}</div>
                            </div>
                            <p class="text-xs font-light text-secondary">{{ hint.label }}</p>
                        </div>
                        <PlusCircleIcon class="h-6 w-6 text-kl-icon" />
                    </li>
                </ul>
            </div> -->
    </div>
  </div>
</template>
