import type { NotificationToast } from '@/types/notification.types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { generateUuid } from '@kleecks/code-lib';

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<(NotificationToast & { id: string })[]>([]);

  const removeNotification = (id: string) => {
    notifications.value = notifications.value.filter(n => n.id !== id);
  };

  const notify = (notification: NotificationToast) => {
    const id = generateUuid();
    notifications.value.unshift({ ...notification, id });
  };

  return {
    notifications,
    notify,
    removeNotification,
  };
});
