import { GetBrands } from '@/services/graphql/brand.graphql';
import { type Brand } from '@/types/brand.types';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

export const useBrands = () => {
  const query = useQuery<{
    brands: Brand[];
  }>(GetBrands, {
    orderBy: {
      label: 'asc',
    },
  });
  const brands = computed(
    () =>
      query.result?.value?.brands
        ?.toSorted((a, b) => a.name?.localeCompare(b.name))
        ?.filter(x => x.name !== '_preset') || []
  );

  return {
    brands,
    ...query,
  };
};
