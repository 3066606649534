<script setup lang="ts">
import { TOPIC_COLORS, TOPIC_DISPLAY_NAME } from '@kleecks/code-lib';
import type { Topic } from '@kleecks/code-lib';

interface TopicChipProps {
  topic: Topic;
  mini?: boolean;
}

const props = defineProps<TopicChipProps>();
</script>

<template>
  <div
    v-if="props.topic"
    data-testid="topic-chip"
    :class="[
      'inline-flex items-center rounded-xl px-4 py-[.15rem] text-xs',
      TOPIC_COLORS[props.topic],
      props.mini ? ' h-3' : ' h-4',
    ]">
    {{ TOPIC_DISPLAY_NAME[props.topic] }}
  </div>
</template>
