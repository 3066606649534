export const getCookie = (cName) => {
    let c = '';
    document.cookie.split(';').forEach(cookie => {
        const decodedCookie = decodeURIComponent(cookie.trim());
        if (decodedCookie.startsWith(`${cName}=`)) {
            const [, rest] = decodedCookie.split('=');
            c = rest;
        }
    });
    return c;
};
