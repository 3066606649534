export const getLabelFromDictionary = (dictionary, key, hideFallback) => {
    if (!dictionary)
        return key;
    const keys = key.split('.');
    try {
        return keys.reduce((o, i) => o[i], dictionary) || (!hideFallback ? key : undefined);
    }
    catch (_a) {
        return !hideFallback ? key : undefined;
    }
};
