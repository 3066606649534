<script setup lang="ts">
import type { ConnectorsIds } from '@/constants/connectors.constants';
import { AVAILABLE_CONNECTORS } from '@/constants/connectors.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useGoogleConnectors } from '@/hooks/connectors/useGoogleConnectors';
import type { BrandConnector } from '@/types/brand.types';
import { GOOGLE_CONNECTORS } from '@/types/connectors.types';
import { formatDate } from '@/utils/date.utils';
import { Button, UserProfileImage } from '@kleecks/ui-lib';
import { computed } from 'vue';

const brandId = useRouteParam('brandId');
const googleConnectors = useGoogleConnectors(brandId);

const handleConnect = (id: ConnectorsIds) => {
  if (id === 'google_ads') {
    googleConnectors.triggerLogin(GOOGLE_CONNECTORS.GOOGLE_ADS);
  }

  if (id === 'google_gsc') {
    googleConnectors.triggerLogin(GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE);
  }
};

const handleDisconnect = (id: ConnectorsIds) => {
  if (id === 'google_ads') {
    googleConnectors.disconnectAccount(GOOGLE_CONNECTORS.GOOGLE_ADS);
  }

  if (id === 'google_gsc') {
    googleConnectors.disconnectAccount(GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE);
  }
};

const loadingStates = computed(() => ({
  google_ads: googleConnectors.loadingGoogleAds.value,
  google_gsc: googleConnectors.loadingGoogleSearchConsole.value,
}));

const connectorsInfo = computed<{
  [key in 'google_ads' | 'google_gsc']?: BrandConnector | undefined;
}>(() => ({
  google_ads: googleConnectors.adsConnector.value,
  google_gsc: googleConnectors.searchConsoleConnector.value,
}));
</script>

<template>
  <ul class="flex flex-col gap-8">
    <li
      v-for="connector in AVAILABLE_CONNECTORS"
      :key="connector.id"
      class="flex w-full items-center border border-kl-gray-200 bg-white p-4">
      <img
        v-if="typeof connector.icon === 'string'"
        :src="connector.icon"
        class="mr-2 h-8 w-8" />
      <component
        :is="connector.icon"
        v-else
        class="mr-2 h-8 w-8" />
      <div class="w-72">
        <h3 class="text-base text-primary">{{ connector.label }}</h3>
        <template v-if="connectorsInfo[connector.id]">
          <p class="text-xs font-light text-secondary">
            Connected on {{ formatDate(connectorsInfo[connector.id]!.date) }}
          </p>
          <div
            v-if="connectorsInfo[connector.id]!.user?.nome"
            class="flex items-center gap-2">
            <p class="text-xs font-light text-secondary">
              Connected by: {{ connectorsInfo[connector.id]!.user?.nome }}
              {{ connectorsInfo[connector.id]!.user?.cognome }}
            </p>
            <UserProfileImage
              size="sm"
              :user="connectorsInfo[connector.id]!.user" />
          </div>
        </template>
      </div>

      <div class="flex-1">
        <template v-if="connectorsInfo[connector.id]">
          <span class="text-sm font-light text-secondary">Account:</span>
          <p class="text-sm text-primary">{{ connectorsInfo[connector.id]?.email }}</p>
        </template>
      </div>

      <Button
        v-if="!connectorsInfo[connector.id]"
        variant="primary"
        size="xl"
        :disabled="loadingStates[connector.id]"
        :is-loading="loadingStates[connector.id]"
        @click="handleConnect(connector.id)">
        Connect
      </Button>

      <Button
        v-else
        variant="red"
        size="xl"
        :disabled="loadingStates[connector.id]"
        :is-loading="loadingStates[connector.id]"
        @click="handleDisconnect(connector.id)">
        Disconnect
      </Button>
    </li>
  </ul>
</template>
