export const COOKIES = {
    TOKEN_KEY: 'kToken',
    LAST_PANEL: 'kl_last_panel',
    SELECTED_TASK_ID: 'kl_selected_task_id',
    BRAND_ID: 'kl_brand_id',
    INSTANCE_ID: 'kl_instance_id',
    DOMAIN_ID: 'kl_domain_id',
    TASK_ID: 'kl_task_id',
    INSTANCE_NAME: 'kl_instance_name',
    PAGE_TYPE: 'kl_page_type',
    PAGE_SUB_TYPE: 'kl_page_sub_type',
    DATA_TYPE: 'kl_data_type',
    MODULE: 'kl_module',
    SINGLE_URL_EDIT: 'kl_single_url_edit',
    IS_DEV_MODE: 'kl_is_dev_mode',
    SCHEMA_ID: 'kl_schema_id',
    LAST_VARIATION_ID: 'kl_last_variation_id',
    USER_LOGIN: 'kl_user_login',
};
