import { getMainDefinition } from '@apollo/client/utilities';
// import * as Sentry from '@sentry/vue';
import { createFragmentRegistry } from '@apollo/client/cache';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import {
  BrandFragment,
  DashboardFragment,
  DomainFragment,
  InstanceFragment,
  TaskFragment,
  TaskUserFragment,
  ConnectorFragment,
} from '@/services/graphql/fragments.graphql';
import { ApolloClient, InMemoryCache, createHttpLink, from, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'; // <-- This one uses graphql-ws
import { COOKIES, getCookie } from '@kleecks/code-lib';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createClient } from 'graphql-ws';
import type { App } from 'vue';
import * as Sentry from '@sentry/vue';

import { APP_VERSION, ENVIRONMENT, GRAPHQL_URL, WS_GRAPHQL_URL } from './constants/settings.constants';

const cache = new InMemoryCache({
  fragments: createFragmentRegistry(
    BrandFragment,
    DomainFragment,
    DashboardFragment,
    TaskUserFragment,
    TaskFragment,
    InstanceFragment,
    ConnectorFragment
  ),
  typePolicies: {
    Domain: {
      keyFields: ['id', 'domain'],
    },
    Instance: {
      keyFields: ['instanceId'],
      fields: {
        tasks: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Brand: {
      fields: {
        taskObject: {
          merge: true,
        },
        tasks: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        Postgres: {
          keyArgs: false,
          merge: (existing, incoming, { args }) => {
            const offset = args?.skip || 0;
            return !offset ? incoming : [...(existing || []), ...incoming];
          },
        },
      },
    },
  },
});
if (ENVIRONMENT !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

// Create a GraphQLWsLink link:
const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_GRAPHQL_URL,
    retryAttempts: 5,
    keepAlive: 10000,
    connectionParams: {
      'X-Access-Token': getCookie(COOKIES.TOKEN_KEY),
    },
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      if (error.message === 'Unauthorized') {
        // eslint-disable-next-line no-console
        console.error('Unauthorized');
        return;
      }

      if (process.env.NODE_ENV !== 'production')
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${error.message}, Path: ${error.path}`, error);

      Sentry.captureException(error);
    });

  // eslint-disable-next-line no-console
  if (networkError && process.env.NODE_ENV !== 'production') console.error(`[Network error]: ${networkError}`);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // Get JWT token from cookies
  const token = getCookie(COOKIES.TOKEN_KEY);
  return {
    headers: {
      ...headers,
      'X-Access-Token': token,
    },
  };
});

export const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authLink, link]),
  connectToDevTools: APP_VERSION === 'development',
});

export const initGraphql = (app: App) => {
  app.provide(DefaultApolloClient, apolloClient);
};
