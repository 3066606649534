import type { Task } from '@/types/task.types';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { useRouteParam } from '../common/useRouteParam';
import { useInstanceTasks } from '../instance/useInstanceTasks';

export const useCurrentInstanceTask = (taskId: MaybeRef<string | undefined>) => {
  const instanceId = useRouteParam('instanceId');
  const brandId = useRouteParam('brandId');
  const { instanceTasks, loading } = useInstanceTasks(brandId, instanceId);
  const task = computed<Task | undefined>(() => instanceTasks.value?.find(el => el?.id === unref(taskId)));
  return { task, loading };
};
