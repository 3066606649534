<script setup lang="ts">
import KleecksSpinner from '@/assets/kleecks-spinner.gif';
import AssignModuleToDashboardDialog from '@/components/dialogs/assign-module-to-dashboard/AssignModuleToDashboardDialog.vue';
import ModuleAiContainer from '@/components/modules/module-ai-container/ModuleAiContainer.vue';
import type { MODULES } from '@/constants/modules.constants';
import { MODULES_DESCRIPTIONS, MODULES_LIBRARY, MODULES_NAMES } from '@/constants/modules.constants';
import { useModules } from '@/hooks/modules/useModules';
import { useUser } from '@/hooks/user/useUser';
import { useUserStore } from '@/stores/useUserStore';
import type { AiMessageType } from '@/types/chatbot.types';
import { ArrowPathIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/20/solid';
import { logoIcon, UserProfileImage } from '@kleecks/ui-lib';
import * as marked from 'marked';
import { ref, watchEffect } from 'vue';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import AiActivityCard from '../ai-activity-card/AiActivityCard.vue';

interface AiFeedListProps {
  messages: AiMessageType[];
  withUseRag: boolean;
  onSelectModule: (moduleId: MODULES, messageId: string) => void;
}

const props = defineProps<AiFeedListProps>();
const moduleIdToAssign = ref<MODULES>();
const { modules } = useModules();
const userStore = useUserStore();
const { user } = useUser();
const messageElements = ref<HTMLElement[]>([]);
const instanceId = useRouteParam('instanceId');
const brandId = useRouteParam('brandId');
const scrollToBottom = () => {
  if (messageElements.value.length > 0) {
    const lastMessage = messageElements.value[messageElements.value.length - 1];
    lastMessage.scrollIntoView();
  }
};

watchEffect(() => {
  scrollToBottom();
});
</script>

<template>
  <AssignModuleToDashboardDialog
    v-if="moduleIdToAssign"
    :module-id="moduleIdToAssign"
    @close="moduleIdToAssign = undefined" />
  <div class="flow-root pt-8">
    <ul role="list">
      <li>
        <div class="relative pb-8">
          <span
            class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true" />
          <div class="relative flex items-start space-x-3">
            <div class="relative">
              <img
                class="flex h-10 w-10 items-center justify-center rounded-full border border-kl-gray-100 bg-kl-gray-200"
                :src="logoIcon"
                alt="" />
            </div>
            <div class="mt-2 flex-1">
              <div class="text-sm">
                <div class="text-secondary">Hi, ask me something</div>
                <div class="flex flex-wrap items-center gap-2 pt-2"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="(message, messageIdx) in props.messages"
        ref="messageElements"
        :key="message.id">
        <div class="relative pb-8">
          <span
            v-if="messageIdx !== props.messages.length - 1"
            class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true" />
          <div class="relative flex items-start space-x-3">
            <template v-if="message.type === 'ai'">
              <div
                :class="[
                  'relative',
                  {
                    'self-start': message.questions?.length,
                  },
                ]">
                <img
                  v-if="!message.loading"
                  class="flex h-10 w-10 items-center justify-center rounded-full border border-kl-gray-100 bg-kl-gray-200"
                  :src="logoIcon"
                  alt="" />
                <img
                  v-if="message.loading"
                  class="flex h-10 w-10 items-center justify-center rounded-full border border-kl-gray-100 bg-kl-gray-200"
                  :src="KleecksSpinner"
                  alt="" />
              </div>
              <div class="mt-2 flex-1">
                <div class="text-sm">
                  <div
                    v-if="message.availableModules"
                    class="flex flex-wrap items-center gap-2 pt-2">
                    <template v-for="moduleId in message.availableModules">
                      <div
                        v-if="MODULES_NAMES[moduleId]"
                        :key="moduleId"
                        class="cursor-pointer rounded-2xl border border-gray-200 bg-white px-4 py-1 text-xs text-gray-500 hover:bg-kl-green-200 hover:text-white"
                        @click="() => props.onSelectModule(moduleId, message.id)">
                        {{ MODULES_NAMES[moduleId] }}
                      </div>
                    </template>
                  </div>
                  <div
                    v-if="!message.loading"
                    class="chatbot-output-section text-secondary"
                    v-html="marked.parse(message.label || '')" />

                  <span v-if="message.isStreaming">
                    <span class="animate-pulse text-secondary">...</span>
                  </span>

                  <div
                    v-if="message.availableActivities"
                    class="grid grid-cols-2 gap-8 pt-2">
                    <AiActivityCard
                      v-for="activity in message.availableActivities"
                      :key="activity.id"
                      :task-id="activity.id" />
                  </div>

                  <div
                    v-if="message.loading"
                    class="text-secondary">
                    <span
                      v-if="props.withUseRag"
                      class="loading-ellipsis" />
                    <span v-else>I'm Working for you...</span>
                  </div>
                  <div
                    v-if="message.questions"
                    class="flex gap-4 pt-4">
                    <div
                      v-for="question in message.questions"
                      :key="question.value"
                      :class="[
                        'cursor-pointer rounded-2xl border border-gray-secondary px-8 py-1 text-2xs ',
                        {
                          'bg-white text-secondary': !question.selected,
                        },
                        {
                          'hover:bg-kl-green-200 hover:text-white': question.selected === false,
                        },
                        {
                          'bg-kl-green-200 text-white': question.selected,
                        },
                      ]">
                      {{ question.label }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="message.type === 'user'">
              <div class="relative">
                <UserProfileImage
                  class="flex h-10 w-10 items-center justify-center rounded-full bg-kl-gray-200"
                  :user="user!"
                  size="lg" />

                <span class="absolute -bottom-0.5 -right-1 px-0.5">
                  <ChatBubbleLeftEllipsisIcon
                    class="size-5 rounded-xl bg-white p-1 text-secondary"
                    aria-hidden="true" />
                </span>
              </div>
              <div class="mt-2 flex-1">
                <div class="text-sm">
                  <div class="text-secondary">
                    {{ message.label }}
                  </div>
                </div>
              </div>
            </template>
            <template v-if="message.moduleId">
              <div class="relative self-start">
                <img
                  v-if="!message.loading"
                  class="flex h-10 w-10 items-center justify-center rounded-full bg-kl-gray-200"
                  :src="logoIcon"
                  alt="" />

                <ArrowPathIcon
                  v-if="message.loading"
                  class="flex h-10 w-10 animate-spin items-center justify-center rounded-full bg-kl-gray-200 p-2 text-secondary"
                  aria-hidden="true" />
              </div>

              <div class="mt-2 flex flex-1 flex-col gap-4">
                <div class="text-sm">
                  <div
                    class="text-secondary"
                    v-html="marked.parse(message.label || '')"></div>
                </div>

                <ModuleAiContainer
                  v-if="message.moduleId"
                  :is-add-button-enabled="modules[message.moduleId as MODULES] !== undefined && MODULES_LIBRARY.includes(message.moduleId as MODULES)"
                  :on-add-module-to-dashboard="() => (moduleIdToAssign = message.moduleId as MODULES)"
                  :module-name="MODULES_NAMES[message.moduleId as MODULES]"
                  :module-description="MODULES_DESCRIPTIONS[message.moduleId as MODULES]"
                  :module-id="(message.moduleId as MODULES)">
                  <component
                    v-bind="{
                      isReadOnly: undefined,
                      instanceId,
                      brandId,
                      taskId: userStore.selectedTask?.id,
                    }"
                    :is="modules[message.moduleId as MODULES]"
                    v-if="modules[message.moduleId as MODULES]" />

                  <div
                    v-else
                    class="h-56 w-full p-2 text-center">
                    <div
                      class="flex h-full items-center justify-center rounded-md border-2 border-dashed border-gray-200 bg-neutral-50">
                      <span class="text-sm text-gray-500">
                        You don't have permissions to view this module. Ask to your administrator
                      </span>
                    </div>
                  </div>
                </ModuleAiContainer>
              </div>
            </template>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
