<script setup lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/24/solid';
import { DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref } from 'vue';

interface ModalTooltipButtonProps {
  modalContent: string;
  modalTitle: string;
}

const props = defineProps<ModalTooltipButtonProps>();

const isShown = ref(false);

const showModal = () => {
  isShown.value = true;
};

const hideModal = () => {
  isShown.value = false;
};
</script>

<template>
  <div
    class="relative w-fit cursor-pointer text-secondary hover:text-active"
    @click="showModal">
    <slot />
    <DialogContainer
      v-if="isShown"
      :is-open="isShown"
      :on-close="hideModal">
      <Modal
        :title="props.modalTitle"
        :icon="InformationCircleIcon"
        class="p-8"
        :on-close="hideModal">
        <div
          class="max-h-[80vh] w-[800px] overflow-y-auto text-base leading-7 text-primary"
          v-html="props.modalContent"></div>
      </Modal>
    </DialogContainer>
  </div>
</template>
