<script setup lang="ts">
import TasksCounter from '@/components/tasks-counter-section/tasks-counter/TasksCounter.vue';
import { INSTANCE_MAP } from '@/constants/instance.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useDisableInstance } from '@/hooks/instance/useDisableInstance';
import { useEnableInstance } from '@/hooks/instance/useEnableInstance';
import type { InstanceAggregatedTasksCount, InstancePanelType } from '@/types/instance.types';
import { ArrowTopRightOnSquareIcon, FolderIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { type CRAWL_STATUS } from '@kleecks/code-lib';
import { BaseToggle } from '@kleecks/ui-lib';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface ProjectCardProps {
  brandId: string;
  name: string;
  disabled: boolean;
  crawlsCount: number;
  brandName: string;
  crawlDate?: string;
  showDelete: boolean;
  showEdit: boolean;
  showId: boolean;
  crawlStatus?: CRAWL_STATUS;
  url: string;
  aggregatedTasksCount: InstanceAggregatedTasksCount;
  instanceId: string;
  openEditProjectDialog: (env: string) => void;
}

const props = defineProps<ProjectCardProps>();

const { enableInstance } = useEnableInstance();
const { disableInstance } = useDisableInstance();

const totalAssignedTasks = computed(
  () =>
    (props.aggregatedTasksCount.countByAssignedToMe?.countByStrategy || 0) +
    (props.aggregatedTasksCount.countByAssignedToMe?.countBySeo || 0) +
    (props.aggregatedTasksCount.countByAssignedToMe?.countByUx || 0) +
    (props.aggregatedTasksCount.countByAssignedToMe?.countByIssues || 0) +
    (props.aggregatedTasksCount.countByAssignedToMe?.countBySettings || 0)
);

const enabledRef = ref(!props.disabled);

const router = useRouter();
// const { user } = useUser();

const navigateToDashboardPanel = (panel: InstancePanelType) => {
  if (!enabledRef.value) return;
  router.push({ name: ROUTE_NAMES.INSTANCE, query: { panel }, params: { instanceId: props.instanceId } });
};

const projectId = computed(
  () => Object.keys(INSTANCE_MAP).find(key => INSTANCE_MAP[key as keyof typeof INSTANCE_MAP] === props.name) || ''
);

watch(
  () => enabledRef.value,
  val => {
    if (val) {
      enableInstance(props.instanceId);
    } else {
      disableInstance(props.instanceId);
    }
  }
);

// const navigateToTasksPanel = () => {
//   if (user?.value?.admin) {
//     router.push({
//       name: ROUTE_NAMES.TASKS_MANAGEMENT,
//       params: { instanceId: props.instanceId },
//     });
//     return;
//   }
//   navigateToDashboardPanel('tasks');
// };
</script>

<template>
  <div
    :class="[
      'block h-fit cursor-pointer rounded-lg border border-sec bg-white p-4 shadow-custom ring-kl-green-300 transition duration-300 ease-in-out ',
      { 'opacity-60': !enabledRef },
      { 'hover:border-kl-green-200 focus:shadow-lg focus:outline-0 focus:ring-1 active:ring-1': enabledRef },
    ]"
    @click="
      e => {
        if (!enabledRef) e.stopPropagation();
      }
    ">
    <div class="flex items-start">
      <div class="flex-1">
        <div class="flex items-center gap-2">
          <div class="flex flex-1 items-center">
            <FolderIcon class="size-6 text-kl-icon" />
            <span class="ml-2 whitespace-nowrap text-xs font-medium text-secondary">{{ props.brandName }}</span>
          </div>
          <BaseToggle
            v-if="props.showEdit"
            v-model="enabledRef"
            size="sm" />
        </div>
        <div class="mt-4 text-lg font-medium leading-5 text-primary">
          {{ props.name }}
        </div>
        <a
          v-if="props.url"
          class="text-xs text-kl-green-300 underline"
          :href="props.url"
          target="_blank"
          @click="e => e.stopPropagation()"
          >{{ props.url }}
        </a>
        <div
          v-if="props.crawlDate"
          class="mt-2 flex items-center font-light">
          <span class="text-xs text-secondary">Last Crawl on {{ props.crawlDate }}</span>
          <span class="ml-2 rounded-xl border border-kl-green-200 px-4 text-xs text-kl-green-300">{{
            props.crawlStatus
          }}</span>
        </div>
      </div>
    </div>
    <div class="mt-4 flex w-full gap-3">
      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countByStrategy"
          :assigned="props.aggregatedTasksCount.countByAssignedToMe?.countByStrategy"
          label="Strategy"
          size="md"
          color="aqua"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('strategy');
            }
          " />
      </div>

      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countBySeo"
          :assigned="props.aggregatedTasksCount.countByAssignedToMe?.countBySeo"
          label="Seo"
          color="yellow"
          size="md"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('seo');
            }
          " />
      </div>
      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countByUx"
          :assigned="props.aggregatedTasksCount.countByAssignedToMe?.countByUx"
          :new="0"
          label="Ux"
          size="md"
          color="purple"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('ux');
            }
          " />
      </div>
      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countByIssues"
          :assigned="props.aggregatedTasksCount.countByAssignedToMe?.countByIssues"
          label="Issues"
          size="md"
          color="red"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('issues');
            }
          " />
      </div>
      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countBySettings"
          :assigned="props.aggregatedTasksCount.countByAssignedToMe?.countBySettings"
          label="Settings"
          size="md"
          color="violet"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('settings');
            }
          " />
      </div>
      <div class="flex flex-1 justify-center">
        <TasksCounter
          :total="props.aggregatedTasksCount.countByAll"
          :assigned="totalAssignedTasks"
          label="All tasks"
          size="md"
          color="pink"
          @click="
            e => {
              e.stopPropagation();
              navigateToDashboardPanel('settings');
            }
          " />
      </div>
    </div>
    <div class="mt-6 flex items-center">
      <div
        v-if="showId"
        class="text-xs text-[#4F5B62]">
        ID: {{ props.instanceId }}
      </div>
      <div class="flex-1" />
      <div class="flex items-center gap-3">
        <TrashIcon
          v-if="showDelete"
          class="size-4 text-kl-icon hover:text-active" />
        <PencilSquareIcon
          v-if="showEdit"
          class="size-4 text-kl-icon hover:text-active"
          @click="
            event => {
              event.stopPropagation();
              props.openEditProjectDialog(projectId);
            }
          " />
        <ArrowTopRightOnSquareIcon class="size-4 text-kl-icon hover:text-active" />
      </div>
    </div>
  </div>
</template>
