<script setup lang="ts">
// import GlobalChip from '@/components/atoms/global-chip/GlobalChip.vue';
import DashboardWidgetContainer from '@/components/dashboards/dashboard-widget-container/DashboardWidgetContainer.vue';
import SelectModuleDialog from '@/components/dialogs/SelectModuleDialog.vue';
import EditDashboardDialog from '@/components/dialogs/edit-dashboard-dialog/EditDashboardDialog.vue';
import EditDashboardWidgetDialog from '@/components/dialogs/edit-dashboard-widget/EditDashboardWidgetDialog.vue';
import type { MODULES } from '@/constants/modules.constants';
import {
  MODULES_DASHBOARD_CONFIGURATION,
  MODULES_NAMES,
  STANDALONE_MODULES,
  MODULES_DESCRIPTIONS,
  WIDGET_MODULES,
} from '@/constants/modules.constants';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TOPIC_ICONS_MAP } from '@/constants/topic-icons.constants';
import { WidgetMap, type WidgetPackage } from '@/helpers/widget-factory';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useDashboard } from '@/hooks/dashboard/useDashboard';
import { useDeleteDashboard } from '@/hooks/dashboard/useDeleteDashboard';
import { useModules } from '@/hooks/modules/useModules';
import { useBrandWithTasks } from '@/hooks/tasks/useBrandWithTasks';
import { useUser } from '@/hooks/user/useUser';
import { useWidgets } from '@/hooks/widgets/useWidgets';
import { updateDashboardMutation } from '@/services/queries/dashboard.query';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import type { Dashboard } from '@/types/dashboard.types';
import { PencilIcon, PlusIcon, TrashIcon, GlobeAltIcon } from '@heroicons/vue/24/outline';
import { TOPIC_COLORS, generateUuid } from '@kleecks/code-lib';
import {
  AddWidgetRow,
  Button,
  DialogContainer,
  DropdownMenu,
  StandardContainer,
  WithTooltip,
  Badge,
} from '@kleecks/ui-lib';
import { computed, onMounted, ref, watchEffect, h, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import draggable from 'vuedraggable';
import { getDashboardBadge, getDashboardVisibility, isDashbordInReadOnlyMode } from '@/utils/dashboard.utils';
import { useBrand } from '@/hooks/brand/useBrand';
import NotFound from '../not-found/NotFound.vue';

interface DashboardProps {
  moduleIdToOpen?: MODULES;
}

const props = defineProps<DashboardProps>();
const userStore = useUserStore();
const { user, isSuperAdmin } = useUser();
const { modules } = useModules();
const router = useRouter();
const dashboardId = useRouteParam('dashboardId');
const { notify } = useNotificationStore();
const { mutate: updateDashboard, loading: isUpdatingDashboard } = updateDashboardMutation();
const { deleteDashboard } = useDeleteDashboard();
const instanceId = useRouteParam('instanceId');
const brandId = useRouteParam('brandId');
const { brand, loading: loadingBrand } = useBrand(brandId);
const { dashboard, loading: loadingDashboard } = useDashboard(dashboardId, brandId);
const dashboardDeepRef = ref<Dashboard | undefined>(undefined);
const objectToEditIndex = ref<number | undefined>();
const { loading: loadingTasks } = useBrandWithTasks(brandId);

const getUserSettings = computed(() => ({
  teamId: user.value?.team?.id,
  login: user.value?.login,
  isAdmin: user.value?.admin ?? false,
  isSuperAdmin: isSuperAdmin.value ?? false,
}));

const isStandaloneModuleDashboard = computed(() =>
  dashboard.value?.data?.objects?.some(obj => STANDALONE_MODULES?.includes(obj.moduleId as MODULES))
);
const isLoading = computed(() => loadingDashboard.value || loadingTasks.value);

watchEffect(() => {
  if (dashboard.value) dashboardDeepRef.value = JSON.parse(JSON.stringify(dashboard.value));
});

const deleteAlertStore = useDeleteAlertStore();
const isSelectModuleDialogOpen = ref(false);
const selectedModule = ref<MODULES | undefined>();

onMounted(() => {
  if (props.moduleIdToOpen) {
    // Wait for next tick otherwise the dialog will not be open
    setTimeout(() => {
      selectedModule.value = props.moduleIdToOpen;
      router.replace({ query: { moduleIdToOpen: undefined } });
    });
  }
});
const isEditDashboardDialogOpen = ref(false);
const widgetComponent = ref(null);

const { widgetsMap, widgetsProps } = useWidgets();

// const isReadOnly = computed(() => {
//   if (user.value?.admin) {
//     if (!dashboard.value?.brand?.name) return user.value.team?.id !== 'kleecks';
//     return false;
//   }
//   if (!dashboard.value?.brand?.name) return true;
//   return dashboard.value.user?.login !== user.value?.login;
// });

const isReadOnly = computed(() =>
  user.value && dashboard.value ? isDashbordInReadOnlyMode(dashboard.value, user.value, isSuperAdmin.value) : true
);

const getBadgeSettings = computed(() =>
  dashboard.value ? getDashboardBadge(dashboard.value, brand.value?.name) : undefined
);

const menuOptions = computed(() => {
  if (isReadOnly.value) return [];
  return [
    {
      label: 'Edit',
      operation: 'edit',
      icon: PencilIcon,
      disabled: isReadOnly.value,
    },
    {
      label: 'Delete',
      operation: 'delete',
      icon: TrashIcon,
      disabled: isReadOnly.value,
    },
  ];
});

const closeDialogModule = () => {
  selectedModule.value = undefined;
  isSelectModuleDialogOpen.value = false;
};

const selectWidget = async (widgetPackage: WidgetPackage, values: Record<string, unknown>) => {
  if (!dashboardDeepRef.value?.data) {
    dashboardDeepRef.value = {
      ...dashboardDeepRef.value,
      data: {
        objects: [],
      },
    } as Dashboard;
  }
  dashboardDeepRef.value?.data?.objects?.push({
    id: generateUuid(),
    widgetPackage,
    type: 'widgets',
    values,
  });

  await updateDashboard({
    dashboard: {
      id: dashboardId.value,
      data: dashboardDeepRef.value?.data,
    },
  });

  notify(DEFAULT_NOTIFICATIONS.DASHBOARD_WIDGET_ADDED);
  closeDialogModule();
};

const selectModule = async (module: MODULES) => {
  if (!dashboardDeepRef.value?.data) {
    dashboardDeepRef.value = {
      ...dashboardDeepRef.value,
      data: {
        objects: [],
      },
    } as Dashboard;
  }
  if (STANDALONE_MODULES.includes(module)) {
    dashboardDeepRef.value.data!.objects = [
      {
        id: generateUuid(),
        moduleId: module,
        type: 'modules',
      },
    ];

    isSelectModuleDialogOpen.value = false;
    notify(DEFAULT_NOTIFICATIONS.DASHBOARD_MODULE_ADDED);

    await updateDashboard({
      dashboard: {
        id: dashboardId.value,
        data: dashboardDeepRef.value?.data,
      },
    });

    return;
  }

  if (!WIDGET_MODULES.includes(module)) {
    selectedModule.value = module;
    isSelectModuleDialogOpen.value = false;
    return;
  }

  dashboardDeepRef.value?.data?.objects?.push({
    id: generateUuid(),
    moduleId: module,
    type: 'modules',
  });

  await updateDashboard({
    dashboard: {
      id: dashboardId.value,
      data: dashboardDeepRef.value?.data,
    },
  });

  notify(DEFAULT_NOTIFICATIONS.DASHBOARD_MODULE_ADDED);
  isSelectModuleDialogOpen.value = false;
};

const removeWidget = async (index: number, name: string) => {
  const customBody = defineComponent({
    render() {
      return h('div', { class: 'whitespace-normal font-roboto-flex text-kl-gray-400' }, [
        h('span', { class: 'text-kl-sm' }, [
          'Do you want to delete widget: ',
          h('span', { class: 'font-bold text-kl-sm' }, name),
          ' from this Dashboard?',
        ]),
        h(
          'span',
          { class: 'block text-kl-xs text-kl-red-200 mt-2.5' },
          '(This action cannot be undone and all widget progress will be lost).'
        ),
      ]);
    },
  });
  deleteAlertStore.showDeleteAlert(h(customBody), async () => {
    dashboardDeepRef.value?.data?.objects?.splice(index, 1);

    await updateDashboard({
      dashboard: {
        id: dashboardId.value,
        data: dashboardDeepRef.value?.data,
      },
    });

    notify(DEFAULT_NOTIFICATIONS.DASHBOARD_WIDGET_REMOVED);
  });
};

const handleDeleteDashboard = () => {
  deleteAlertStore.showDeleteAlert(`Are you sure you want to delete dashboard ${dashboard.value?.name}?`, async () => {
    await deleteDashboard(dashboardId.value);
    await router.push({
      name: ROUTE_NAMES.DASHBOARDS_LIBRARY,
    });
  });
};

const openEditDialog = () => {
  isEditDashboardDialogOpen.value = true;
};

const openSelectModuleDialog = () => {
  isSelectModuleDialogOpen.value = true;
};

const editDashboardObject = (index: number) => {
  objectToEditIndex.value = index;
};

const handleMenuAction = (action: string) => {
  if (action === 'edit') {
    openEditDialog();
    return;
  }
  if (action === 'delete') {
    handleDeleteDashboard();
    return;
  }

  throw new Error(`Unknown action ${action}`);
};

const editObject = (index: number, payload: { description?: string }) => {
  if (dashboardDeepRef.value?.data?.objects?.[index])
    dashboardDeepRef.value.data.objects[index].description = payload.description;

  updateDashboard({
    dashboard: {
      id: dashboardId.value,
      data: dashboardDeepRef.value?.data,
    },
  });

  objectToEditIndex.value = undefined;
};

const handleExecuteOperationForStandaloneModule = (operation: string, module?: MODULES) => {
  if (module && operation === 'delete') {
    removeWidget(0, MODULES_NAMES[module]);
  }

  if (operation === 'edit') {
    editDashboardObject(0);
  }
};

const getStandAloneModuleObj = computed(() => {
  if (!isStandaloneModuleDashboard.value) return undefined;
  return dashboardDeepRef.value?.data?.objects?.filter(el => STANDALONE_MODULES.includes(el.moduleId as MODULES))[0];
});

const widgetModulesInDashboardIds = computed<MODULES[]>(
  () =>
    dashboard.value?.data?.objects?.reduce((acc, curr) => {
      if (curr.type === 'modules') {
        acc.push(curr.moduleId!);
      }
      return acc;
    }, [] as MODULES[]) ?? []
);

const showNoModulesMessage = computed(() => {
  if (user.value?.admin) return false;

  if (!isReadOnly.value) return false;

  return !dashboardDeepRef.value?.data?.objects?.some(obj => {
    if (obj.type === 'modules') return modules.value[obj.moduleId as MODULES] !== undefined;
    return WidgetMap[obj.widgetPackage as WidgetPackage] !== undefined;
  });
});
</script>

<template>
  <NotFound v-if="!isLoading && !dashboard" />
  <EditDashboardWidgetDialog
    v-if="dashboard && objectToEditIndex !== undefined"
    :is-open="objectToEditIndex !== undefined"
    :name="WidgetMap[dashboard.data?.objects?.[objectToEditIndex!].widgetPackage as WidgetPackage]?.widgetName ?? MODULES_NAMES[dashboard.data?.objects?.[objectToEditIndex!]?.moduleId as MODULES]"
    :description="dashboard.data?.objects?.[objectToEditIndex ?? 0]?.description"
    :on-confirm="payload => editObject(objectToEditIndex ?? 0, payload)"
    :on-close="() => (objectToEditIndex = undefined)" />

  <transition name="fade">
    <div
      v-if="dashboard && !loadingTasks && !loadingBrand"
      class="bg-sec">
      <EditDashboardDialog
        v-if="dashboard"
        :is-open="isEditDashboardDialogOpen"
        :dashboard="dashboard"
        :brand-id="brandId"
        :user-settings="getUserSettings"
        @close="isEditDashboardDialogOpen = false" />
      <StandardContainer>
        <div
          v-if="!loadingDashboard"
          data-testid="dashboard-container"
          class="bg-sec py-8">
          <div
            v-if="dashboard"
            key="dashboard-header"
            class="flex items-center gap-4">
            <div :class="[TOPIC_COLORS[dashboard.topic!], 'size-16 items-center rounded p-2']">
              <component :is="TOPIC_ICONS_MAP[dashboard.topic!]" />
            </div>
            <div class="flex flex-1 flex-col items-start">
              <!-- <GlobalChip v-if="!dashboard.brand?.name" /> -->

              <div class="flex items-center gap-2">
                <Badge
                  v-if="getBadgeSettings"
                  class="h-fit w-fit !bg-white"
                  :label="getBadgeSettings.label"
                  type="soft"
                  :color="getBadgeSettings.color" />
              </div>
              <div class="flex items-center gap-2 truncate text-2xl font-medium leading-7 text-secondary">
                {{ dashboard?.name }}

                <WithTooltip label="Private Dashboard for all your brands">
                  <GlobeAltIcon
                    v-if="getDashboardVisibility(dashboard) === 'global'"
                    class="size-5 text-kl-icon" />
                </WithTooltip>
              </div>

              <WithTooltip :label="dashboard?.description!">
                <span class="line-clamp-1 min-w-0 pr-16 text-sm text-secondary">
                  {{ dashboard?.description }}
                </span>
              </WithTooltip>
            </div>
            <Button
              v-if="!isReadOnly && !isStandaloneModuleDashboard"
              variant="blue"
              @click="openSelectModuleDialog">
              Add Widget
              <PlusIcon class="ml-2 h-4 w-4" />
            </Button>
            <div class="flex items-center">
              <WithTooltip label="Dashboard settings">
                <DropdownMenu
                  v-if="menuOptions.length > 0"
                  :menu-options="menuOptions"
                  :on-send-option-operation="(operation: string) => handleMenuAction(operation as string)"
                  @click="(e: any) => e.stopPropagation()" />
              </WithTooltip>
            </div>
          </div>

          <div
            v-if="showNoModulesMessage"
            class="h-[calc(100vh-15rem)] w-full flex-1 pt-8 text-center">
            <div class="flex h-full items-center justify-center rounded-md border border-gray-300">
              <span class="text-base text-gray-500"> You don't have available modules in this dashboard. </span>
            </div>
          </div>

          <div
            v-if="dashboard?.data && !isLoading && isStandaloneModuleDashboard && getStandAloneModuleObj?.moduleId"
            :key="userStore.selectedTask?.id || 'default'"
            class="mt-8 flex h-[calc(100vh-17rem)] min-h-0 flex-col rounded-lg border border-gray-secondary bg-white p-6 hover:shadow-md">
            <div class="mb-4 flex items-center justify-between">
              <div class="flex flex-1 flex-col gap-y-1">
                <p
                  v-if="MODULES_NAMES[getStandAloneModuleObj.moduleId as MODULES]"
                  class="flex-1 font-medium text-primary"
                  data-testid="name">
                  {{ MODULES_NAMES[getStandAloneModuleObj.moduleId as MODULES] }}
                </p>
                <p
                  v-if="getStandAloneModuleObj.description || MODULES_DESCRIPTIONS[getStandAloneModuleObj.moduleId as MODULES]"
                  class="max-w-[90%] text-sm text-kl-gray-400">
                  {{
                    getStandAloneModuleObj.description ||
                    MODULES_DESCRIPTIONS[getStandAloneModuleObj.moduleId as MODULES]
                  }}
                </p>
              </div>
              <WithTooltip label="Widget settings">
                <DropdownMenu
                  v-if="menuOptions.length > 0"
                  :menu-options="menuOptions"
                  :on-send-option-operation="(operation) => handleExecuteOperationForStandaloneModule(operation, getStandAloneModuleObj?.moduleId as MODULES)"
                  @click="(e: any) => e.stopPropagation()" />
              </WithTooltip>
            </div>
            <component
              :is="modules[getStandAloneModuleObj.moduleId]"
              ref="widgetComponent"
              v-bind="{
                taskId: userStore.selectedTask?.id,
                brandId,
                instanceId,
                isReadOnly: undefined,
              }" />
          </div>
          <draggable
            v-else-if="dashboardDeepRef?.data && !isLoading && !isStandaloneModuleDashboard && !showNoModulesMessage"
            v-model="dashboardDeepRef.data.objects"
            group="objects"
            class="flex flex-col gap-8 pt-8 md:grid md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-8"
            draggable=".isDraggable"
            handle=".handle"
            :onEnd="
              () => {
                updateDashboard({
                  dashboard: {
                    id: dashboardId,
                    data: dashboardDeepRef?.data,
                  },
                });
              }
            "
            as="div"
            :animation="200"
            item-key="id">
            <template #item="{ element, index }">
              <DashboardWidgetContainer
                v-if="element.widgetPackage && WidgetMap[element.widgetPackage as WidgetPackage]"
                v-slot="{ afterHeaderId }"
                :key="element.widgetPackage"
                :description="element.description"
                :module-id="element.widgetPackage"
                :data-testid="element.widgetPackage"
                :on-edit="() => editDashboardObject(index)"
                :can-edit="!isReadOnly"
                :width-configuration="WidgetMap[element.widgetPackage as WidgetPackage].widgetConfiguration?.display"
                :name="WidgetMap[element.widgetPackage as WidgetPackage].widgetName"
                :has-permission="widgetsMap[element.widgetPackage] !== undefined"
                :on-remove="() => removeWidget(index, WidgetMap[element.widgetPackage as WidgetPackage].widgetName)"
                :task-status="userStore.selectedTask?.status"
                :class="{ isDraggable: !isReadOnly }">
                <component
                  :is="widgetsMap[element.widgetPackage]"
                  ref="widgetComponent"
                  :key="userStore.selectedTask?.id || 'default'"
                  v-bind="{
                    ...(widgetsProps[element.widgetPackage] || {}),
                    ...(element.values || {}),
                    isReadOnly: undefined,
                    customSectionHeaderId: afterHeaderId,
                    instanceId,
                    brandId,
                    taskId: userStore.selectedTask?.id,
                  }" />
              </DashboardWidgetContainer>
              <DashboardWidgetContainer
                v-else-if="element.moduleId && MODULES_NAMES[element.moduleId as MODULES] && (user?.admin || modules[element.moduleId as MODULES])"
                v-slot="{ afterHeaderId }"
                :key="element.moduleId"
                :task-status="userStore.selectedTask?.status"
                :data-testid="element.moduleId"
                :module-id="element.moduleId"
                :description="element.description || MODULES_DESCRIPTIONS[element.moduleId as MODULES] "
                :on-edit="() => editDashboardObject(index)"
                :can-edit="!isReadOnly"
                :width-configuration="MODULES_DASHBOARD_CONFIGURATION[element.moduleId as MODULES]?.configuration"
                :name="MODULES_NAMES[element.moduleId as MODULES]"
                :has-permission="modules[element.moduleId as MODULES] !== undefined"
                :on-remove="() => removeWidget(index, MODULES_NAMES[element.moduleId as MODULES])"
                :class="{ isDraggable: !isReadOnly }">
                <component
                  :is="modules[element.moduleId as MODULES]"
                  :key="userStore.selectedTask?.id || 'default'"
                  v-bind="{
                    taskId: userStore.selectedTask?.id,
                    instanceId,
                    brandId,
                    isReadOnly: undefined,
                    customSectionHeaderId: afterHeaderId,
                  }" />
              </DashboardWidgetContainer>
            </template>
          </draggable>

          <AddWidgetRow
            v-if="!isReadOnly && !isLoading && !isStandaloneModuleDashboard"
            class="my-8"
            :on-add-row="openSelectModuleDialog" />
        </div>
      </StandardContainer>

      <DialogContainer
        :is-open="selectedModule !== undefined"
        :on-close="closeDialogModule">
        <component
          :is="modules[selectedModule]"
          v-if="selectedModule && modules[selectedModule]"
          :task-id="userStore.selectedTask?.id"
          :on-close="closeDialogModule"
          :on-select-widget="selectWidget" />
      </DialogContainer>

      <SelectModuleDialog
        :already-added-widget-modules="widgetModulesInDashboardIds"
        :disabled="isUpdatingDashboard"
        :on-select-module="selectModule"
        :is-open="isSelectModuleDialogOpen"
        :on-close="closeDialogModule" />
    </div>
  </transition>
</template>
