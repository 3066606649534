<script setup lang="ts">
import { type Brand } from '@/types/brand.types';
import { computed, reactive, ref, watch } from 'vue';
import { CameraIcon, PencilSquareIcon } from '@heroicons/vue/24/outline';
import { BaseTextInput, Button } from '@kleecks/ui-lib';
import { useUpdateBrand } from '@/hooks/brand/useUpdateBrand';
import { useUser } from '@/hooks/user/useUser';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useRoute } from 'vue-router';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { SERVER_BASE_URL } from '@/constants/settings.constants';
import { COOKIES, getCookie } from '@kleecks/code-lib';

interface BrandDetailsDrawerProps {
  brand: Brand;
}

const props = defineProps<BrandDetailsDrawerProps>();
const route = useRoute();
const isEditing = ref(route.query?.editMode === 'true');
const { mutate: updateBrand, loading } = useUpdateBrand();
const { user } = useUser();
const { notify } = useNotificationStore();

const fileInputRef = ref<HTMLInputElement | null>(null);
const uploadedFile = ref<File | null>(null);
const isDragging = ref(false);
const uploadedFileUrl = computed(() => (uploadedFile.value ? URL.createObjectURL(uploadedFile.value) : ''));
const onInputFileChanged = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const filesList = target.files;
  if (!filesList) return;
  uploadedFile.value = filesList.item(0);
};
const fields: Partial<{
  [key in keyof Brand]: string;
}> = {
  name: 'Id',
  label: 'Label',
  vat: 'VAT',
  country: 'Country',
  address: 'Address',
};

const brandValues = reactive<any>({
  name: props.brand.name,
  label: props.brand.label,
  vat: props.brand.vat,
  country: props.brand.country,
  address: props.brand.address,
});

const onDropHandler = (event: DragEvent) => {
  event.preventDefault();
  const filesList = event.dataTransfer?.files;
  if (!filesList) return;
  uploadedFile.value = filesList.item(0);
};

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = true;
};

const onDragLeave = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
};

const uploadPhoto = async () => {
  const url = `${SERVER_BASE_URL}/images/brand`;

  const formData = new FormData();
  formData.append('brand', props.brand.name);
  formData.append('icon', 'false');
  formData.append('image', uploadedFile.value!);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${getCookie(COOKIES.TOKEN_KEY)}`);

  const method = props.brand.image ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    redirect: 'follow',
    headers,
    body: formData,
  });

  return response;
};

const saveBrand = async () => {
  await updateBrand({
    brand: {
      ...brandValues,
    },
  });

  if (uploadedFile.value) {
    await uploadPhoto();
  }
  notify(DEFAULT_NOTIFICATIONS.BRAND_UPDATED(props.brand.name));
  isEditing.value = false;
};

watch(isEditing, value => {
  if (!value) {
    uploadedFile.value = null;
    fileInputRef.value!.value = '';
  }
});
</script>

<template>
  <div class="flex h-full w-full flex-col bg-white py-4 pl-8">
    <div class="flex-1">
      <div class="flex">
        <span class="flex-1 text-base font-medium text-primary">Details</span>

        <!-- <AdjustmentsHorizontalIcon
                    class="mr-2 h-5 w-5 cursor-pointer text-kl-icon hover:text-active"
                    aria-hidden="true" /> -->
        <PencilSquareIcon
          v-if="user?.admin"
          :class="`ml-2 h-5 w-5 cursor-pointer text-${isEditing ? 'active' : 'kl-icon'} hover:text-active`"
          aria-hidden="true"
          @click="() => (isEditing = !isEditing)" />
      </div>

      <div
        v-if="uploadedFileUrl || props.brand.image"
        class="relative my-8 h-52 w-full rounded-md border border-sec">
        <img
          :src="uploadedFileUrl || props.brand.image"
          class="size-full object-cover" />
        <div
          v-if="isEditing"
          class="absolute -bottom-2 -right-2 flex size-8 cursor-pointer items-center justify-center rounded-full bg-kl-gray-400 p-1 text-white hover:bg-kl-gray-400 hover:shadow active:shadow-lg"
          @click="
            () => {
              fileInputRef?.click();
            }
          ">
          <CameraIcon class="size-full" />
        </div>
      </div>
      <div
        v-else-if="isEditing"
        class="my-8 flex h-52 w-full cursor-pointer flex-col rounded-md border-dashed border-kl-green-200 bg-gray-100 hover:border"
        :class="{
          'border border-dashed border-kl-green-200': isDragging,
        }"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDropHandler"
        @click="
          () => {
            fileInputRef?.click();
          }
        ">
        <div class="flex h-full flex-col items-center justify-center text-sm">
          <CameraIcon class="h-10 w-10" />
          <p class="text-center text-primary">
            Click or drag and drop to upload an image
            <br />
            <span class="text-2xs text-secondary">PNG, JPG, JPEG (Max. 5MB)</span>
          </p>
        </div>
      </div>
      <div
        v-else
        class="my-8 flex h-52 w-full flex-col rounded-md bg-gray-100">
        <div class="flex h-full flex-col items-center justify-center text-sm">
          <CameraIcon class="h-10 w-10" />
          <p class="text-center text-primary">No image added.</p>
        </div>
      </div>

      <input
        ref="fileInputRef"
        type="file"
        multiple
        hidden
        :accept="['.png', 'jpg', '.jpeg'].join(', ')"
        @change="onInputFileChanged" />
      <div
        v-for="key in Object.keys(fields)"
        :key="key"
        class="flex items-center border-b border-gray-100 py-2">
        <span class="flex-1 text-xs font-light text-secondary">{{ fields[key as keyof Brand] }}</span>

        <div class="flex-1 text-right">
          <BaseTextInput
            v-if="isEditing"
            :id="`brand-${key}`"
            v-model="brandValues[key]"
            :data-cy="`brand-${key}`"
            type="text"
            :disabled="key === 'name'"
            :placeholder="`${fields[key as keyof Brand]}...`"
            :name="`brand-${key}`" />

          <span
            v-if="!isEditing"
            :data-cy="`brand-${key}`"
            class="text-right text-sm font-medium text-kl-gray-400"
            >{{ props.brand[key as keyof Brand] }}</span
          >
        </div>
      </div>
    </div>

    <div
      v-if="isEditing"
      class="mt-4 flex items-center justify-end gap-2 text-right">
      <Button
        variant="secondary"
        size="md"
        @click="() => (isEditing = false)">
        Cancel
      </Button>
      <Button
        variant="primary"
        size="md"
        :disabled="loading"
        :is-loading="loading"
        @click="saveBrand">
        Save Changes
      </Button>
    </div>
  </div>
</template>
