import type { TaskFilters, TaskPriority } from '@/types/task.types';

export const TASK_STATUS = {
  CREATING: 'Creating',
  NEW: 'New',
  DRAFT: 'Draft',
  IN_REVIEW: 'In Review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PUBLISHED: 'Published',
  SCHEDULED: 'Scheduled',
} as const;

export const TASK_PRIORITY_MAP: {
  [key in TaskPriority]: string;
} = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const TASK_PRIORITY_COLOR: {
  [key in TaskPriority]: string;
} = {
  high: 'text-kl-red-200',
  medium: 'text-kl-yellow-200',
  low: 'text-green-500',
};

export const TASK_PRIORITY_LABEL: {
  [key in TaskPriority]: string;
} = {
  low: '!',
  medium: '!!',
  high: '!!!',
};

export const TASK_PRIORITY_BORDER: {
  [key in TaskPriority]: string;
} = {
  high: 'border-kl-red-200',
  medium: 'border-kl-yellow-200',
  low: 'border-green-500',
};

export const TASK_STATUS_COLORS = {
  [TASK_STATUS.APPROVED]: 'bg-kl-green-200',
  [TASK_STATUS.PUBLISHED]: 'bg-kl-green-300',
  [TASK_STATUS.SCHEDULED]: 'bg-kl-yellow-200',
  [TASK_STATUS.IN_REVIEW]: 'bg-kl-red-200',
  [TASK_STATUS.REJECTED]: 'bg-kl-red-300',
  [TASK_STATUS.DRAFT]: 'bg-[#4290B5]',
  [TASK_STATUS.NEW]: 'bg-kl-violet-200',
};

export const TASK_ERROR = 'TASK_ERROR';

export const DEFAULT_TASK_FILTERS: TaskFilters = {
  orderBy: {
    by: '',
    direction: '',
  },
  searchKey: '',
  filterByTopics: [],
  filterByStatus: [],
  filterByAssignees: [],
};
