import { getTeamsQuery } from '@/services/queries/team.query';
import type { Team } from '@/types/team.types';
import { computed } from 'vue';

export const useTeams = () => {
  const query = getTeamsQuery();

  const teams = computed<Team[]>(() => query.result.value?.teams ?? []);

  return {
    teams,
    ...query,
  };
};
