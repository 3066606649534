import { MODULES_IDS, WIDGET_SIZE_CONFIGURATION } from '@/constants/modules.constants';
import { type PermissionWidget } from '@/types/dashboard.types';
import { defineAsyncComponent } from 'vue';

const LazySourceEditorWidget = defineAsyncComponent(() =>
  import(`@/modules/source-editor/widgets`).then(m => m.SourceEditorWidget)
);

export type WidgetPackage = 'SourceEditorWidget';

export const WidgetMap: {
  [key in WidgetPackage]: PermissionWidget;
} = {
  SourceEditorWidget: {
    moduleName: MODULES_IDS.SOURCE_EDITOR,
    modulePackage: 'source-editor',
    widgetName: 'Source Editor',
    widgetPackage: 'SourceEditorWidget',
    widgetConfiguration: {
      display: WIDGET_SIZE_CONFIGURATION.TWO_COLUMNS,
    },
    propsToForward: [],
  },
};

const WIDGETS_MAP: {
  [key in keyof typeof WidgetMap]: any;
} = {
  SourceEditorWidget: LazySourceEditorWidget,
};

export const widgetFactory = (data: PermissionWidget): any => {
  const widget = WIDGETS_MAP[data.widgetPackage];
  return widget;
};
