<script setup lang="ts">
import { BaseTextArea, BaseTextInput, Button, DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref, watch } from 'vue';

interface EditDashboardWidgetDialogProps {
  isOpen: boolean;
  name: string;
  description?: string;
  onConfirm: (payload: { description?: string }) => void;
  onClose: () => void;
}

const props = defineProps<EditDashboardWidgetDialogProps>();

const description = ref(props.description);
const name = ref(props.name);

watch(
  () => props.isOpen,
  () => {
    description.value = props.description;
    name.value = props.name;
  }
);

const handleClose = () => {
  props.onClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="handleClose">
    <Modal
      class="flex w-[640px] flex-col"
      :on-close="handleClose"
      title="Edit widget"
      sub-title="Edit the info displayed on the dashboard">
      <form
        class="flex flex-col gap-6"
        @submit.prevent="() => props.onConfirm({ description })">
        <BaseTextInput
          id="name"
          v-model="name"
          label="Name*"
          name="name"
          disabled />
        <BaseTextArea
          id="description"
          v-model="description"
          label="Description"
          :maxlength="100"
          placeholder="Add description"
          name="description" />
        <div class="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            @click="() => props.onConfirm({ description })">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  </DialogContainer>
</template>
