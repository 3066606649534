import type { RouteRecordRaw } from 'vue-router';
import type { InstancePanelType } from './types/instance.types';
import WikiVue from './pages/wiki/Wiki.vue';
import LoginVue from './pages/login/Login.vue';
import PageVue from './pages/Page.vue';
import BrandsVue from './pages/brands/Brands.vue';
import InstanceDashboardVue from './pages/instance-dashboard/InstanceDashboard.vue';
import TasksManagementVue from './pages/tasks-management/TasksManagement.vue';
import DashboardVue from './pages/dashboard/Dashboard.vue';
import DashboardsLibraryVue from './pages/dashboards-library/DashboardsLibrary.vue';
import ModulesLibraryVue from './pages/modules-library/ModulesLibrary.vue';
import NewDashboardVue from './pages/new-dashboard/NewDashboard.vue';
import AdminDashboardVue from './pages/admin-dashboard/AdminDashboard.vue';
import UsersPageVue from './pages/admin-dashboard/users/UsersPage.vue';
import TeamsPageVue from './pages/admin-dashboard/teams/TeamsPage.vue';
import NotFoundVue from './pages/not-found/NotFound.vue';
import BrandDashboardVue from './pages/brand-dashboard/BrandDashboard.vue';
import { ROUTE_NAMES } from './constants/routes.constants';
import BrandsPageVue from './pages/admin-dashboard/brands/BrandsPage.vue';
import IsolatedModuleVue from './pages/isolated-module/IsolatedModule.vue';
import StrategyVue from './pages/strategy/Strategy.vue';
import AdminModulesDictionary from './pages/admin-dashboard/modules-dictionary/AdminModulesDictionary.vue';
import AdminApplicationDictionary from './pages/admin-dashboard/application-dictionary/AdminApplicationDictionary.vue';

export interface AppRouteParams {
  brandId?: string;
  instanceId?: string;
  dashboardId?: string;
  moduleId?: string;
  taskId?: string;
  keyId?: string;
}

export interface AppQueryParam {
  panel?: InstancePanelType;
  redirect?: string;
}

export const routes: RouteRecordRaw[] = [
  { path: '/login', name: ROUTE_NAMES.LOGIN, component: LoginVue },
  { path: '', redirect: '/brands' },
  {
    path: '/',
    component: PageVue,
    children: [
      { path: '/wiki', name: ROUTE_NAMES.WIKI, component: WikiVue },
      { path: '/brands', name: ROUTE_NAMES.BRANDS, component: BrandsVue },
      { path: '/brands/:brandId', redirect: { name: ROUTE_NAMES.BRAND } },
      { path: '/brands/:brandId/instances', name: ROUTE_NAMES.BRAND, component: BrandDashboardVue },
      {
        path: '/brands/:brandId/instances/:instanceId/',
        name: ROUTE_NAMES.INSTANCE,
        component: InstanceDashboardVue,
      },
      { path: '/brands/:brandId/instances/:instanceId/wiki', name: ROUTE_NAMES.INSTANCE_WIKI, component: WikiVue },
      { path: '/brands/:brandId/instances/:instanceId/strategy', name: ROUTE_NAMES.STRATEGY, component: StrategyVue },
      {
        path: '/brands/:brandId/instances/:instanceId/modules/:moduleId/:taskId',
        name: ROUTE_NAMES.MODULE,
        component: IsolatedModuleVue,
      },
      {
        path: '/brands/:brandId/instances/:instanceId/tasks_management',
        name: ROUTE_NAMES.TASKS_MANAGEMENT,
        component: TasksManagementVue,
      },
      {
        path: '/brands/:brandId/instances/:instanceId/dashboards/:dashboardId',
        name: ROUTE_NAMES.DASHBOARD,
        component: DashboardVue,
        props: route => ({
          moduleIdToOpen: route.query.moduleId,
        }),
      },
      {
        path: '/brands/:brandId/instances/:instanceId/dashboards',
        name: ROUTE_NAMES.DASHBOARDS_LIBRARY,
        component: DashboardsLibraryVue,
      },

      {
        path: '/brands/:brandId/instances/:instanceId/modules',
        name: ROUTE_NAMES.MODULES_LIBRARY,
        component: ModulesLibraryVue,
      },

      {
        path: '/brands/:brandId/instances/:instanceId/dashboards/new',
        name: ROUTE_NAMES.NEW_DASHBOARD,
        component: NewDashboardVue,
      },
      {
        path: '/admin',
        component: AdminDashboardVue,
        children: [
          {
            path: 'users',
            name: ROUTE_NAMES.ADMIN_USERS,
            component: UsersPageVue,
          },
          {
            path: 'teams',
            name: ROUTE_NAMES.ADMIN_TEAMS,
            component: TeamsPageVue,
          },
          {
            path: 'brands',
            name: ROUTE_NAMES.ADMIN_BRANDS,
            component: BrandsPageVue,
          },
          {
            path: 'application-dictionary',
            name: ROUTE_NAMES.ADMIN_APPLICATION_DICTIONARY,
            component: AdminApplicationDictionary,
          },
          {
            path: 'modules-dictionary',
            name: ROUTE_NAMES.ADMIN_MODULES_DICTIONARY,
            component: AdminModulesDictionary,
          },
        ],
      },
      {
        path: '/brands/:brandId/:pathMatch(.*)',
        component: NotFoundVue,
      },
      {
        path: '/brands/:brandId/instances/:instanceId/:pathMatch(.*)',
        component: NotFoundVue,
      },
      {
        path: '/:pathMatch(.*)',
        component: NotFoundVue,
      },
    ],
  },
];
