import type { MODULES } from '@/constants/modules.constants';
import { M_SEO_SEM_KEYS_TO_EDIT } from './modules/seo-sem.dictionary';

export interface KeyToEdit {
  id: string;
  fields: (string | { id: string; fields: string[] })[];
}

export const MODULES_COMMON_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'widgetHelp',
    fields: ['html'],
  },
] as const;

const AI_CONTENT_FIELDS_TO_EDIT: KeyToEdit[] = [] as const;

const M_ALTERNATES_FIELDS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overviewSection',
    fields: ['label', 'help'],
  },
  {
    id: 'automaticReferenceSection',
    fields: ['label', 'help'],
  },
  {
    id: 'automaticAnalysisSection',
    fields: ['label', 'help'],
  },
  {
    id: 'keepOriginalAlternatesUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'keepOriginalAlternatesServerUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'alternatesRemoveBroken',
    fields: ['label', 'help'],
  },
  {
    id: 'alternatesAlwaysAddSelf',
    fields: ['label', 'help'],
  },
  {
    id: 'enableAutomaticAnalysis',
    fields: ['label', 'help'],
  },
  {
    id: 'useQuickMode',
    fields: ['label', 'help'],
  },
  {
    id: 'ruleAssociationBasedOnVariable',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
] as const;

const URL_EXPLORER_ANALYTIC_FIELDS_TO_EDIT: KeyToEdit[] = [] as const;

const URL_EXPLORER_CRAWL_FIELDS_TO_EDIT: KeyToEdit[] = [] as const;

const MONITORING_FIELDS_TO_EDIT: KeyToEdit[] = [] as const;

export const M_BREADCRUMB_SETTINGS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overviewLeftSection',
    fields: ['label', 'help'],
  },
  {
    id: 'overviewRigthSection',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlForHome',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'breadcrumbsElementSeparatorCharacter',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'dontAddSeparatorElement',
    fields: ['label', 'help'],
  },
  {
    id: 'useBreadcrumbList',
    fields: ['label', 'help'],
  },
  {
    id: 'noMicrodataInHtmlBreadcrumb',
    fields: ['label', 'help'],
  },
  {
    id: 'breadcrumbElementsLabelSource',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'insertMicrodataAsJsonLd',
    fields: ['label', 'help'],
  },
  {
    id: 'includeSubfolderDomainParent',
    fields: ['label', 'help'],
  },
  {
    id: 'ignoreRedirectUrls',
    fields: ['label', 'help'],
  },
  {
    id: 'includeVirtualParents',
    fields: ['label', 'help'],
  },
  {
    id: 'homePageNotIncludedInBreadcrumbMicrodata',
    fields: ['label', 'help'],
  },
  {
    id: 'excludedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_CACHE_CONTROL_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'maxAgeSection',
    fields: ['label', 'help'],
  },
  {
    id: 'otherSection',
    fields: ['label', 'help'],
  },
  {
    id: 'cachecontrolMaxage',
    fields: ['label', 'help'],
  },
  {
    id: 'cachecontrolMaxagePages',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'valueSeconds',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'forceValueSet',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'cachecontrolMaxageResources',
    fields: [
      'label',
      'help',
      {
        id: 'mimeType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'valueSeconds',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'forceValueSet',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'cachecontrolMaxageHtml',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'advancedCachecontrolMaxage',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'regexOnResourceUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'contentType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'maxAge',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'overrideLowerValues',
        fields: ['label', 'help'],
      },
      {
        id: 'overrideMissingValues',
        fields: ['label', 'help'],
      },
      {
        id: 'overrideZeroValues',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'advancedCachecontrolSmaxage',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'regexOnResourceUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'contentType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'maxAge',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'overrideLowerValues',
        fields: ['label', 'help'],
      },
      {
        id: 'overrideMissingValues',
        fields: ['label', 'help'],
      },
      {
        id: 'overrideZeroValues',
        fields: ['label', 'help'],
      },
    ],
  },
];

export const M_CACHE_MANAGEMENT_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'fragmentSection',
    fields: ['label', 'help'],
  },
  {
    id: 'excludeSection',
    fields: ['label', 'help'],
  },
  {
    id: 'fragmentCache',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'advancedFragmentCache',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'cacheMaxAge',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'cacheMimetypeBlacklist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'cacheExcludeHtml',
    fields: ['label', 'help'],
  },
  {
    id: 'usePageurlmapHtmlUrlRegex',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'pageurlmapHtmlHeaders',
    fields: [
      'label',
      'help',
      {
        id: 'header',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'value',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_CANONICAL_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overflowSecion',
    fields: ['label', 'help'],
  },
  {
    id: 'customCanonicalSection',
    fields: ['label', 'help'],
  },
  {
    id: 'keepOriginalCanonicalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'keepOriginalCanonicalServerUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'preservedGetParameters',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'customCanonicalUrls',
    fields: [
      'label',
      'help',
      {
        id: 'requestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'requestedCmsUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'fromOptionalRegexAsFromClause',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'canonicalUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'replaceCanonicalWithHtmlSelector',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'conditions',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'expression',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'canonicalAddSelfIdMissing',
    fields: ['label', 'help'],
  },
  {
    id: 'replaceCmsUrlWithCanonicals',
    fields: ['label', 'help'],
  },
];

export const M_CDN_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'anchorsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'maxAgeSection',
    fields: ['label', 'help'],
  },
  {
    id: 'resourcesMimetypes',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'resourcesUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'resourcesExcludedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'cacheControlSMaxage',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'regexOnResourceUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'contentType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'maxAge',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'overrideLowerValues',
    fields: ['label', 'help'],
  },
  {
    id: 'overrideMissingValues',
    fields: ['label', 'help'],
  },
  {
    id: 'overrideZeroValues',
    fields: ['label', 'help'],
  },
  {
    id: 'ignorePrivateNocacheHeaders',
    fields: ['label', 'help'],
  },
  {
    id: 'maxAgeForCdnResourcesByType',
    fields: [
      'label',
      'help',
      {
        id: 'contentType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'validity',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'maxAgeForCdnResource',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_COOKIES_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'enableCookieProcessing',
    fields: ['label', 'help'],
  },
  {
    id: 'cookiePathManualRewrite',
    fields: [
      'label',
      'help',
      {
        id: 'regexUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'fromRegex',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'toRegex',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'cookieInjectionRules',
    fields: [
      'label',
      'help',
      {
        id: 'cookieName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'cookieValue',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'addToKleecksCrawlerRequests',
        fields: ['label', 'help'],
      },
      {
        id: 'addToConfiguredBOTSRequests',
        fields: ['label', 'help'],
      },
      {
        id: 'requestedUserAgentCondition',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'requestedUrlCondition',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_CRAWLER_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'selectedProfileId',
    fields: ['label', 'help'],
  },
  {
    id: 'crawlerServiceUrl',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'initiallyCrawledUrls',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'seedsFrequency',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'numThreads',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'maxPagesForThread',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'excludedUrls',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'retainPolicy',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'forceCompleteRescan',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'name',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'seedUrls',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_GENERAL_SETTINGS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'immediatelyCleanedUrlMap404',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'serverUrlFromUrlPreserveRequested',
    fields: ['label', 'help'],
  },
  {
    id: 'pageDontStoreNotCanonical',
    fields: ['label', 'help'],
  },
  {
    id: 'aggregatorMaxSize',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'requestAggregatorMaxSize',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'timeoutSettings',
    fields: [
      'enabledLabel',
      'disabledLabel',
      'help',
      {
        id: 'url',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'timeoutSeconds',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'bypassedTimeout',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'advancedGrabConfiguration',
    fields: ['label', 'help'],
  },
  {
    id: 'defaultCacheValidity',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'useAppend',
    fields: ['label', 'help'],
  },
  {
    id: 'dontPreprocessHtml',
    fields: ['label', 'help'],
  },
  {
    id: 'defaultAuthorizationHeader',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'htmlTextReplacement',
    fields: [
      'label',
      'help',
      {
        id: 'findSelector',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'replaceSelector',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'scriptsPreprocessingRules',
    fields: [
      'label',
      'help',
      {
        id: 'findSelectorUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'findSelector',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'replaceSelector',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_VARIABLES_SELECTORS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'titleVariableSection',
    fields: ['label', 'help'],
  },
  {
    id: 'titleEditVariableSection',
    fields: ['label', 'help'],
  },
  {
    id: 'subTitleVariableSection',
    fields: ['label', 'help'],
  },
  {
    id: 'variableLabel',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'variablePageType',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'variableCondition',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'variableExtractSelector',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_HTML_SPEED_OPTIMIZER_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'optimizationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'compressionSection',
    fields: ['label', 'help'],
  },
  {
    id: 'compressionSettingSection',
    fields: ['label', 'help'],
  },
  {
    id: 'compressionSettingKeepsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'compressionSettingRemoveSection',
    fields: ['label', 'help'],
  },
  {
    id: 'compressionSettingReplaceSection',
    fields: ['label', 'help'],
  },
  {
    id: 'extraSettingsLeftSection',
    fields: ['label', 'help'],
  },
  {
    id: 'extraSettingsRightSection',
    fields: ['label', 'help'],
  },
  {
    id: 'useLoadingAttribute',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlCompressor',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlPurification',
    fields: ['label', 'help'],
  },
  {
    id: 'filterAboveTheFold',
    fields: ['label', 'help'],
  },
  {
    id: 'aggressiveLighthouseOptimization',
    fields: ['label', 'help'],
  },
  {
    id: 'useCompression',
    fields: ['label', 'help'],
  },
  {
    id: 'compressInlineCss',
    fields: ['label', 'help'],
  },
  {
    id: 'compressInlineJavascript',
    fields: ['label', 'help'],
  },
  {
    id: 'simplifyExistingDoctype',
    fields: ['label', 'help'],
  },
  {
    id: 'keepsHtmlComments',
    fields: ['label', 'help'],
  },
  {
    id: 'keepsMultipleWhitespacesCharacters',
    fields: ['label', 'help'],
  },
  {
    id: 'preservesOriginalLineBreaks',
    fields: ['label', 'help'],
  },
  {
    id: 'removeValuesFromBooleanTagAttributes',
    fields: ['label', 'help'],
  },
  {
    id: 'removeJavascriptFromInlineEventHandlers',
    fields: ['label', 'help'],
  },
  {
    id: 'removeOptionalAttributesFromScriptTags',
    fields: ['label', 'help'],
  },
  {
    id: 'removeOptionalAttributesFromStyleTags',
    fields: ['label', 'help'],
  },
  {
    id: 'removeOptionalAttributesFromLinkTags',
    fields: ['label', 'help'],
  },
  {
    id: 'removeOptionalAttributesFromFormTags',
    fields: ['label', 'help'],
  },
  {
    id: 'removeOptionalAttributesFromInputTags',
    fields: ['label', 'help'],
  },
  {
    id: 'removesInterTagWhitespaceCharacters',
    fields: ['label', 'help'],
  },
  {
    id: 'removesUnnecessaryTagAttributeQuotes',
    fields: ['label', 'help'],
  },
  {
    id: 'removeSpacesAroundProvidedTags',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'replaceHttpInsideTagAttributes',
    fields: ['label', 'help'],
  },
  {
    id: 'replaceHttpsInsideTagAttributes',
    fields: ['label', 'help'],
  },

  {
    id: 'aboveTheFoldOnlyForPagespeed',
    fields: ['label', 'help'],
  },
  {
    id: 'fontDisplayDescriptor',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'preconnectUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'preloadResources',
    fields: [
      'label',
      'help',
      {
        id: 'condition',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'hRef',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'as',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'optionalMimeType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'crossOrigin',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'media',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_HTTP_HEADERS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'httpHeadersRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'requestHttpHeadersRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'otherSection',
    fields: ['label', 'help'],
  },
  {
    id: 'httpHeadersSetRules',
    fields: [
      'label',
      'help',
      {
        id: 'urlConditionOptionalRegexOnRequestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'nameHttpHeaderName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'valueHttpHeaderValue',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'httpHeadersDeletionRules',
    fields: [
      'label',
      'help',
      {
        id: 'urlConditionOptionalRegexOnRequestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'nameHttpHeaderName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'regexOptionalRegexToBeMatchedAgainstHeaderValues',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'requestHttpHeadersSetRules',
    fields: [
      'label',
      'help',
      {
        id: 'urlConditionOptionalRegexOnRequestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'nameHttpHeaderName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'valueHttpHeaderValue',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'requestHttpHeadersDeletionRules',
    fields: [
      'label',
      'help',
      {
        id: 'urlConditionOptionalRegexOnRequestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'nameHttpHeaderName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'valueOptionalRegexToBeMatchedAgainstHeaderValues',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'cspHeaderReplacementRules',
    fields: [
      'label',
      'help',
      {
        id: 'fromParameter',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'toParameter',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'requestAddAuthorization',
    fields: [
      'label',
      'help',
      {
        id: 'requestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'authorizationHeaderContent',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'forKleecksCrawler',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'varyAcceptEncoding',
    fields: ['label', 'help'],
  },
  {
    id: 'referrerPolicyHeader',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'addLastModifiedHttpHeader',
    fields: ['label', 'help'],
  },
];

export const M_IMAGES_OPTIMIZER_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'compressionSection',
    fields: ['label', 'help'],
  },
  {
    id: 'mimetypesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'stringSection',
    fields: ['label', 'help'],
  },
  {
    id: 'moreSection',
    fields: ['label', 'help'],
  },
  {
    id: 'lazyLoadingSection',
    fields: ['label', 'help'],
  },
  {
    id: 'imagesCompression',
    fields: ['label', 'help'],
  },
  {
    id: 'imagesCompressionQuality',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'imagesCompressionExclusions',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'imagesCheckMimetype',
    fields: ['label', 'help'],
  },
  {
    id: 'overrideImagesWrongMimetype',
    fields: ['label', 'help'],
  },
  {
    id: 'forceImgWidth',
    fields: ['label', 'help'],
  },
  {
    id: 'imageScaling',
    fields: [
      'label',
      'help',
      {
        id: 'regex',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'maxWidth',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'maxHeight',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'preserveOriginalAltAndTitleOfMainImages',
    fields: ['label', 'help'],
  },
  {
    id: 'imagesUseWebp',
    fields: ['label', 'help'],
  },
  {
    id: 'enableLazyLoadingSettings',
    fields: ['label', 'help'],
  },
  {
    id: 'blockedAttributes',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'advancedNotSelectors',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'selectorsWhitelist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'placeholderImage',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'base64DefaultPlaceholder',
    fields: ['label', 'help'],
  },
  {
    id: 'backgroundImagesSelectorsWhitelist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'addNoscriptAlternative',
    fields: ['label', 'help'],
  },
  {
    id: 'addListenerToDomChanges',
    fields: ['label', 'help'],
  },
  {
    id: 'keepImgElementInPictures',
    fields: ['label', 'help'],
  },
];

export const M_INDEXABILITY_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'indexSection',
    fields: ['label', 'help'],
  },
  {
    id: 'noIndexSection',
    fields: ['label', 'help'],
  },
  {
    id: 'metaRobotsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'noFollowSection',
    fields: ['label', 'help'],
  },
  {
    id: 'xRobotsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'noIndexByUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'noIndexByOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'indexByUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'indexByOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'noAddIndexFollow',
    fields: ['label', 'help'],
  },
  {
    id: 'relNofollowLinksByUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'relNofollowLinksByOriginalUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'relNofollowBrokenInternalLinks',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'relNofollowFormsActions',
    fields: ['label', 'help'],
  },
  {
    id: 'xRobotsHeader',
    fields: ['label', 'help'],
  },
];

export const M_INTERNATIONAL_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overflowSection',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlLangCompleteLocaleSection',
    fields: ['label', 'help'],
  },
  {
    id: 'urlsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'languageSection',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlAddDataLocale',
    fields: ['label', 'help'],
  },
  {
    id: 'sourceEditorAddGeoVars',
    fields: ['label', 'help'],
  },
  {
    id: 'htmlLangOverrideEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'addCountryHeader',
    fields: ['label', 'help'],
  },
  {
    id: 'wafCountryHeader',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'htmlLangCompleteLocale',
    fields: ['label', 'help'],
  },
  {
    id: 'urlWithoutLocaleSubpath',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'enableUrlLocalesCheck',
    fields: ['label', 'help'],
  },
  {
    id: 'countryHeaderCustomName',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'enableAcceptLanguageModifications',
    fields: ['label', 'help'],
  },
  {
    id: 'enableAcceptLanguageModificationsBots',
    fields: ['label', 'help'],
  },
  {
    id: 'enableAcceptLanguageModificationsKleecks',
    fields: ['label', 'help'],
  },
];

export const M_BYPASS_RULES_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'generalSection',
    fields: ['label', 'help'],
  },
  {
    id: 'pageTypeSection',
    fields: ['label', 'help'],
  },
  {
    id: 'internalSearchUrlsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'bypassByURLs',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'bypassedURLsExceptions',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'bypassByHTTPReferer',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'bypassUserAgent',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'bypassByCookies',
    fields: [
      'label',
      'help',
      {
        id: 'cookieName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'cookieValue',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'bypassUnthouchableURLs',
    fields: ['label', 'help'],
  },
  {
    id: 'redirectURLsBeforeBypass',
    fields: [
      'label',
      'help',
      {
        id: 'fromParameter',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'toParameter',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'bypassResponse',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludeAdminUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludeUserUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludeGenericUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'selectorSerp',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'serpUrlsRegexIdentificationByUrlRegex',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'serpUrlsIdentificationByPath',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_PERFORMANCE_CSS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'basicAsynchronousCssSection',
    fields: ['label', 'help'],
  },
  {
    id: 'basicCssMinificationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'basicCssAggregationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'basicCriticalCssSection',
    fields: ['label', 'help'],
  },
  {
    id: 'advancedCssMinificationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'advancedCustomCssSection',
    fields: ['label', 'help'],
  },
  {
    id: 'advancedCssAggregation',
    fields: ['label', 'help'],
  },
  {
    id: 'advancedCriticalCss',
    fields: ['label', 'help'],
  },
  {
    id: 'asynchronousCssEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'cssDeferredLoadingUsingPreload',
    fields: ['label', 'help'],
  },
  {
    id: 'cssDeferredLoadingMasquerading',
    fields: ['label', 'help'],
  },
  {
    id: 'cssMinificationEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'cssMinificationForceCommentsRemoval',
    fields: ['label', 'help'],
  },
  {
    id: 'cssCompressorInlineEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'keepCssInline',
    fields: ['label', 'help'],
  },
  {
    id: 'keepExternalCss',
    fields: ['label', 'help'],
  },
  {
    id: 'keepCustomCssInline',
    fields: ['label', 'help'],
  },
  {
    id: 'enableCriticalCss',
    fields: ['label', 'help'],
  },
  {
    id: 'cssMinificationExcludedByUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'customCssPath',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'transformInlineImportToStylesheetInclusions',
    fields: ['label', 'help'],
  },
  {
    id: 'excludeCssFromAggregationByUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'aggregatedCssMaxSize',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'inlineCssBlacklist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'externalCssInternalizationWhitelist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'cssUrlBlacklist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'automaticCriticalCss',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'condition',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'referenceUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_PERFORMANCE_JS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'jsCompressorSection',
    fields: ['label', 'help'],
  },
  {
    id: 'jsMinificationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'customJsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'jsAggregationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'otherSection',
    fields: ['label', 'help'],
  },
  {
    id: 'inlineCompressorJsEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'jsCompressorUseGoogleClosure',
    fields: ['label', 'help'],
  },
  {
    id: 'jsCompressorJson',
    fields: ['label', 'help'],
  },
  {
    id: 'jsCompressorForceCommentsRemoval',
    fields: ['label', 'help'],
  },
  {
    id: 'jsMinificationEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'jsMinificationExcludeByUrl',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'customJsPath',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'jsAggregationEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'aggregatedJsMaxSize',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'excludeJsFromAggregationByUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'keepExternalJs',
    fields: ['label', 'help'],
  },
  {
    id: 'inlineJsExternalizationEnabled',
    fields: ['label', 'help'],
  },
  {
    id: 'inlineJsBlacklist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'externalJsInternalizationWhitelist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'jsUrlBlacklist',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'moveJsToBottom',
    fields: ['label', 'help'],
  },
  {
    id: 'deferredJsEnabled',
    fields: ['label', 'help'],
  },
];

export const M_PREDICTIONS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overviewSection',
    fields: ['label', 'help'],
  },
  {
    id: 'cacheSection',
    fields: ['label', 'help'],
  },
  {
    id: 'activationRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'exclusionRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'deactivationRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'cacheRefreshEngineSection',
    fields: ['label', 'help'],
  },
  {
    id: 'cacheValiditySection',
    fields: ['label', 'help'],
  },
  {
    id: 'diffAlgorithmSection',
    fields: ['label', 'help'],
  },
  {
    id: 'miscellaneousSection',
    fields: ['label', 'help'],
  },
  {
    id: 'enablePredictions',
    fields: ['label', 'help'],
  },
  {
    id: 'cacheDurations',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'expirationSelPTs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'ttl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'expirationSelURLs',
    fields: [
      'label',
      'help',
      {
        id: 'regexUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'ttl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'activationUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'activationPageTypes',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'andWhiteList',
    fields: ['label', 'help'],
  },
  {
    id: 'urlsBlacklistLight',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'pagetypesBlacklistLight',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'blacklistActivationUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'blacklistActivationPageTypes',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'blacklistRegex',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'blacklistCookiesRegex',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'disableCookiesHours',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'disabledRefresh',
    fields: ['label', 'help'],
  },
  {
    id: 'numCacheThreads',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'cacheRefreshSleep',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'cacheRefreshValidity',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'refreshBasicAuth',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'manualCachePurge',
    fields: ['label', 'help'],
  },
  {
    id: 'maxDiffs',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'cacheHints',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'clearCacheSelectors',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'signHtml',
    fields: ['label', 'help'],
  },
  {
    id: 'substList',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'ksethash',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'maxDepth',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'ignoreDiffOnEmptyText',
    fields: ['label', 'help'],
  },
  {
    id: 'disableIgnite',
    fields: ['label', 'help'],
  },
  {
    id: 'useCouchDB',
    fields: ['label', 'help'],
  },
  {
    id: 'editInjectedCode',
    fields: ['label', 'help'],
  },
  {
    id: 'injectedCode',
    fields: ['label', 'help', 'placeholder', 'placeholderReadOnly'],
  },
];

export const M_PRERENDER_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'prerenderSection',
    fields: ['label', 'help'],
  },
  {
    id: 'botSection',
    fields: ['label', 'help'],
  },
  {
    id: 'noBotSection',
    fields: ['label', 'help'],
  },
  {
    id: 'cacheSection',
    fields: ['label', 'help'],
  },
  {
    id: 'configurationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'advancedCacheSection',
    fields: ['label', 'help'],
  },
  {
    id: 'prerender',
    fields: ['label', 'help'],
  },
  {
    id: 'botEnabledUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'botExcludedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'botEnabledOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'botExcludedOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'prerenderUncrawled',
    fields: ['label', 'help'],
  },
  {
    id: 'enabledForKleecksBot',
    fields: ['label', 'help'],
  },
  {
    id: 'enabledUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'enabledOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludedOriginalUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'ignoredGetParameters',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'prerenderCacheValidity',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'prerenderErrorCacheValidity',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'enableCacheForKleecksBot',
    fields: ['label', 'help'],
  },
  {
    id: 'alwaysMobilePrerender',
    fields: ['label', 'help'],
  },
  {
    id: 'alwaysDesktopPrerender',
    fields: ['label', 'help'],
  },
  {
    id: 'blockedResources',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'allowedResources',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'prerenderUserAgentDesktop',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'prerenderUserAgentMobile',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'prerenderUserAgentSuffix',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'prerenderJavascript',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'addBodyMarker',
    fields: ['label', 'help'],
  },
  {
    id: 'replaceRules',
    fields: [
      'label',
      'help',
      {
        id: 'replaceFrom',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'replaceTo',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'removeHtmlElements',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'redirectIfPrerenderedUrlChanges',
    fields: ['label', 'help'],
  },
  {
    id: 'httpHeaders',
    fields: [
      'label',
      'help',
      {
        id: 'name',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'value',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'timeout',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'retry',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'enableDbCache',
    fields: ['label', 'help'],
  },
  {
    id: 'validateWithApiCall',
    fields: ['label', 'help'],
  },
  {
    id: 'validityCheckApiCall',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'useCacheRefreshEngine',
    fields: ['label', 'help'],
  },
  {
    id: 'staleCacheValidity',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_REDIRECTS_HEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overflowSection',
    fields: ['label', 'help'],
  },
  {
    id: 'automaticRedirectionSection',
    fields: ['label', 'help'],
  },
  {
    id: 'enable404ParentRedirectToRoot',
    fields: ['label', 'help'],
  },
  {
    id: 'searchPageWithHtmlExtension',
    fields: ['label', 'help'],
  },
  {
    id: 'predefinedRedirects',
    fields: [
      'label',
      'help',
      {
        id: 'fromRegularExpressionOnRequestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'refererOptionalAdditionalRegexCheckOnReceivedReferrer',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'toReplaceAllDirective',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'redirectType',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'excludeAutomaticRedirectionTo404',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'enableAutomaticRedirectionForUserGeneratedUrl',
    fields: ['label', 'help'],
  },
  {
    id: 'enableAutomaticRedirectionToSimilarPages',
    fields: ['label', 'help'],
  },
  {
    id: 'redirectionToSamePageNameUrl',
    fields: ['label', 'help'],
  },
  {
    id: 'use302ForAutomaticRedirections',
    fields: ['label', 'help'],
  },
  {
    id: 'automaticRedirectionBasedOnRequestIpAndAc',
    fields: ['label', 'help'],
  },
  {
    id: 'preferIpCountryLanguage',
    fields: ['label', 'help'],
  },
  {
    id: 'defaultLanguage',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'excludeBots',
    fields: ['label', 'help'],
  },
  {
    id: 'excludeReferers',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'manualCountriesMapping',
    fields: [
      'label',
      'help',
      {
        id: 'issueActions',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'domainToRedirectTo',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'activateOnEveryPage',
    fields: ['label', 'help'],
  },
  {
    id: 'includedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'excludedUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'manualRedirects',
    fields: [
      'label',
      'help',
      {
        id: 'sourceUrlOrRegularExpressionOnRequested',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'listOfRedirectDestinations',
        fields: ['label', 'help', 'placeholder', 'listItemLabel'],
      },
    ],
  },
  {
    id: 'redirectToAlternate',
    fields: ['label', 'help'],
  },
  {
    id: 'preservedGetParameters',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_ROBOTS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'userAgent',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'allow',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'disallow',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'crawlDelay',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_SECURITY_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'blockSqlInjectionRequest',
    fields: ['label', 'help'],
  },
  {
    id: 'enableRelNoopener',
    fields: ['label', 'help'],
  },
  {
    id: 'enableRelNooferrer',
    fields: ['label', 'help'],
  },
  {
    id: 'bannedUserAgents',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'bannedIps',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'url',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'analysisWindowDuration',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'requests',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'banDuration',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'banEveryRequest',
    fields: ['label', 'help'],
  },
  {
    id: 'whitelistedIP',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_SITEMAP_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'sitemapCreationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'sitemapGenerationSection',
    fields: ['label', 'help'],
  },
  {
    id: 'sitemapCreationType',
    fields: ['label', 'help'],
  },
  {
    id: 'sitemapCreationTypeCombo',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'useHfPages',
    fields: ['label', 'help'],
  },
  {
    id: 'withPageType',
    fields: ['label', 'help'],
  },
  {
    id: 'enableAlternates',
    fields: ['label', 'help'],
  },
  {
    id: 'preservedOriginalCmsSitemaps',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_STATUS_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'urlGtmMappingScript',
    fields: ['label', 'help'],
  },
  {
    id: 'originalUrlVariableName',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'optimizedUrlVariableName',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'gtmScriptIdentifier',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'excludeUrlQueryParameters',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_STATUS_CODE_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'overviewSection',
    fields: ['label', 'help'],
  },
  {
    id: 'statusCodeSection',
    fields: ['label', 'help'],
  },
  {
    id: 'customHtmlSection',
    fields: ['label', 'help'],
  },
  {
    id: 'predefinedErrorsResponse',
    fields: [
      'label',
      'help',
      {
        id: 'url',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'statusCode',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'immediatePlainResponse',
    fields: [
      'label',
      'help',
      {
        id: 'requestedUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'plainTextContent',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'replaceStatusCode',
    fields: [
      'label',
      'help',
      {
        id: 'originalUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'kleecksUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'contentType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'contentTypeBoolean',
        fields: ['label', 'help'],
      },
      {
        id: 'fromStatusCode',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'toStatusCode',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'changeStatus404URL',
    fields: [
      'label',
      'help',
      {
        id: 'errorResource',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'statusCode',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'changeStatusRedirectLocation',
    fields: [
      'label',
      'help',
      {
        id: 'redirectType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'currentUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'locationUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'onlyBots',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'changeStatusHtmlSelector',
    fields: [
      'label',
      'help',
      {
        id: 'selector',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'statusCode',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'onlyKleecks',
        fields: ['label', 'help'],
      },
    ],
  },
  {
    id: 'custom4XXHtml',
    fields: [
      'label',
      'help',
      {
        id: 'statusCode',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'html',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'excludedUrls',
        fields: ['label', 'help', 'placeholder', 'listItemLabel'],
      },
      {
        id: 'includedUrls',
        fields: ['label', 'help', 'placeholder', 'listItemLabel'],
      },
    ],
  },
  {
    id: 'custom5XXHtml',
    fields: [
      'label',
      'help',
      {
        id: 'statusCode',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'html',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'excludedUrls',
        fields: ['label', 'help', 'placeholder', 'listItemLabel'],
      },
      {
        id: 'includedUrls',
        fields: ['label', 'help', 'placeholder', 'listItemLabel'],
      },
    ],
  },
  {
    id: 'custom404Html',
    fields: ['label', 'help'],
  },
];

export const M_URL_FIX_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'urlKleecksSection',
    fields: ['label', 'help'],
  },
  {
    id: 'urlCmsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'urlKlecckToCmsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'configurationKleecksSection',
    fields: ['label', 'help'],
  },
  {
    id: 'requestedUrlReplaceRules',
    fields: [
      'label',
      'help',
      {
        id: 'from',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'to',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'requestHostReplace',
    fields: [
      'label',
      'help',
      {
        id: 'from',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'to',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'replaceRules',
    fields: [
      'label',
      'help',
      {
        id: 'currentUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'exclusion',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'from',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'to',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'processNextRule',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'applyCmsUrlReplaceRulesToLocation',
    fields: ['label', 'help'],
  },

  {
    id: 'httpLocationRewrite',
    fields: [
      'label',
      'help',
      {
        id: 'regexUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'from',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'to',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'serverUrlReplace',
    fields: [
      'label',
      'help',
      {
        id: 'from',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'to',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },

  {
    id: 'serverurlRemoveQueryParameters',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'selectorsForAdditionalUrlInHtmlElements',
    fields: [
      'label',
      'help',
      {
        id: 'selector',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'attribute',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'additionalImagesUrlExtractors',
    fields: [
      'label',
      'help',
      {
        id: 'selector',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'attribute',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'untouchableUrls',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'urlPreserveCase',
    fields: ['label', 'help'],
  },
  {
    id: 'disableUrlCaseCorrection',
    fields: ['label', 'help'],
  },
  {
    id: 'disableUrlFormatCorrection',
    fields: ['label', 'help'],
  },
  {
    id: 'forceHttpsExternalDomains',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'versioningGetParameters',
    fields: [
      'label',
      'help',
      {
        id: 'conditionUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'parameterName',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'value',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
];

export const M_URL_PAGINATION_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'originalCmsGetParameterForPaginations',
    fields: ['label', 'help', 'placeholder'],
  },
  {
    id: 'forceSubpathForPagination',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_RESOURCES_PRIORITIZATION_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'activationRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'CSSRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'iframeRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'jsRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'inlineJsRulesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'activateByUserAgent',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'activateByUrl',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'activateOnPageType',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
  {
    id: 'noDeferCSS',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'cssUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'CSSPriority',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'cssUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'priority',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'noDeferIframe',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'iframeUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'iframePriority',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'iframeUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'priority',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'noDeferJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'deferJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'asyncJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSUrl',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'jsPriorities',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'priority',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'noDeferInlineJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSContains',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'deferInlineJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSContains',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'asyncInlineJs',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSContains',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'inlineJsPriorities',
    fields: [
      'label',
      'help',
      {
        id: 'pageType',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'pageUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'JSUrl',
        fields: ['label', 'help', 'placeholder'],
      },
      {
        id: 'priority',
        fields: ['label', 'help', 'placeholder'],
      },
    ],
  },
  {
    id: 'editInjectedCode',
    fields: ['label', 'help', 'placeholder'],
  },
];

export const M_FILE_MANAGER_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_SOURCE_EDITOR_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_CONTROL_CENTER_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_SOURCE_EDITORS_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_PAGE_DATA_IMPORT_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_SEO_AUTOMATION_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'anchorsTitlesSection',
    fields: ['label', 'help'],
  },
  {
    id: 'imagesSeoAutomationsSection',
    fields: ['label', 'help'],
  },
  {
    id: 'disableAutomaticTitlesForAnchors',
    fields: ['label', 'help'],
  },
  {
    id: 'fillMissingTitleFromExistingAlt',
    fields: ['label', 'help'],
  },
  {
    id: 'fillMissingTitleFromExistingTitle',
    fields: ['label', 'help'],
  },
  {
    id: 'fillMissingAltFromURL',
    fields: ['label', 'help'],
  },
  {
    id: 'fillMissingTitleFromURL',
    fields: ['label', 'help'],
  },
  {
    id: 'excludedByCssSelectors',
    fields: ['label', 'help', 'placeholder', 'listItemLabel'],
  },
];

export const M_SERVICES_TOOLS_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_PAGE_TYPE_CLUSTER_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_SCHEMA_MARKUP_CONFIGURATOR_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_URL_REDIRECT_KEYS_TO_EDIT: KeyToEdit[] = [];

export const M_URL_REWRITE_KEYS_TO_EDIT: KeyToEdit[] = [
  {
    id: 'seoUrlsTitle',
    fields: ['label'],
  },
  {
    id: 'seoUrlsDescription',
    fields: ['label'],
  },
  {
    id: 'seoUrlsSamples',
    fields: ['html'],
  },
  {
    id: 'getParametersTitle',
    fields: ['label'],
  },
  {
    id: 'getParametersDescription',
    fields: ['label'],
  },
  {
    id: 'getParametersSamples',
    fields: ['html'],
  },
  {
    id: 'urlFixTitle',
    fields: ['label'],
  },
  {
    id: 'urlFixDescription',
    fields: ['label'],
  },
  {
    id: 'canonicalTitle',
    fields: ['label'],
  },
  {
    id: 'canonicalDescription',
    fields: ['label'],
  },
  {
    id: 'alternatesTitle',
    fields: ['label'],
  },
  {
    id: 'alternatesDescription',
    fields: ['label'],
  },
];

export const M_CUSTOMER_JOURNEY_KEYS_TO_EDIT: KeyToEdit[] = [];

export const MODULES_FIELDS_MAPPING: { [key in MODULES]?: KeyToEdit[] } = {
  M_CUSTOMER_JOURNEY: M_CUSTOMER_JOURNEY_KEYS_TO_EDIT,
  M_ALTERNATES: M_ALTERNATES_FIELDS_TO_EDIT,
  M_BREADCRUMBS_SETTINGS: M_BREADCRUMB_SETTINGS_KEYS_TO_EDIT,
  M_CACHE_CONTROL: M_CACHE_CONTROL_KEYS_TO_EDIT,
  M_CACHE_MANAGEMENT: M_CACHE_MANAGEMENT_KEYS_TO_EDIT,
  M_CANONICAL: M_CANONICAL_KEYS_TO_EDIT,
  M_CDN: M_CDN_KEYS_TO_EDIT,
  M_CONTROL_CENTER: M_CONTROL_CENTER_KEYS_TO_EDIT,
  M_COOKIES: M_COOKIES_KEYS_TO_EDIT,
  M_CRAWLER: M_CRAWLER_KEYS_TO_EDIT,
  M_FILE_MANAGER: M_FILE_MANAGER_KEYS_TO_EDIT,
  M_GENERAL_SETTINGS: M_GENERAL_SETTINGS_KEYS_TO_EDIT,
  M_VARIABLES_SELECTORS: M_VARIABLES_SELECTORS_KEYS_TO_EDIT,
  M_HTML_SPEED_OPTIMIZER: M_HTML_SPEED_OPTIMIZER_KEYS_TO_EDIT,
  M_SOURCE_EDITORS: M_SOURCE_EDITORS_KEYS_TO_EDIT,
  M_HTTP_HEADERS: M_HTTP_HEADERS_KEYS_TO_EDIT,
  M_IMAGES_OPTIMIZER: M_IMAGES_OPTIMIZER_KEYS_TO_EDIT,
  M_INDEXABILITY: M_INDEXABILITY_KEYS_TO_EDIT,
  M_INTERNATIONAL: M_INTERNATIONAL_KEYS_TO_EDIT,
  M_BYPASS_RULES: M_BYPASS_RULES_KEYS_TO_EDIT,
  M_PAGE_DATA_IMPORT: M_PAGE_DATA_IMPORT_KEYS_TO_EDIT,
  M_PAGE_TYPE_CLUSTER: M_PAGE_TYPE_CLUSTER_KEYS_TO_EDIT,
  M_PERFORMANCE_CSS: M_PERFORMANCE_CSS_KEYS_TO_EDIT,
  M_PERFORMANCE_JS: M_PERFORMANCE_JS_KEYS_TO_EDIT,
  M_PREDICTIONS: M_PREDICTIONS_KEYS_TO_EDIT,
  M_PRERENDER: M_PRERENDER_KEYS_TO_EDIT,
  M_REDIRECTS: M_REDIRECTS_HEYS_TO_EDIT,
  M_ROBOTS: M_ROBOTS_KEYS_TO_EDIT,
  M_SEO_AUTOMATION: M_SEO_AUTOMATION_KEYS_TO_EDIT,
  M_SEO_SEM: M_SEO_SEM_KEYS_TO_EDIT,
  M_SERVICES_TOOLS: M_SERVICES_TOOLS_KEYS_TO_EDIT,
  M_RESOURCES_PRIORITIZATION: M_RESOURCES_PRIORITIZATION_KEYS_TO_EDIT,
  M_SCHEMA_MARKUP_CONFIGURATOR: M_SCHEMA_MARKUP_CONFIGURATOR_KEYS_TO_EDIT,
  M_SECURITY: M_SECURITY_KEYS_TO_EDIT,
  M_SITEMAP: M_SITEMAP_KEYS_TO_EDIT,
  M_SOURCE_EDITOR: M_SOURCE_EDITOR_KEYS_TO_EDIT,
  M_STATUS: M_STATUS_KEYS_TO_EDIT,
  M_STATUS_CODE: M_STATUS_CODE_KEYS_TO_EDIT,
  M_URL_FIX: M_URL_FIX_KEYS_TO_EDIT,
  M_URL_PAGINATION: M_URL_PAGINATION_KEYS_TO_EDIT,
  M_URL_REDIRECT: M_URL_REDIRECT_KEYS_TO_EDIT,
  M_URL_REWRITE: M_URL_REWRITE_KEYS_TO_EDIT,
  M_AI_CONTENT: AI_CONTENT_FIELDS_TO_EDIT,
  M_URL_EXPLORER_ANALYTIC: URL_EXPLORER_ANALYTIC_FIELDS_TO_EDIT,
  M_URL_EXPLORER_CRAWL: URL_EXPLORER_CRAWL_FIELDS_TO_EDIT,
  M_MONITORING: MONITORING_FIELDS_TO_EDIT,
};

// TO DO
// CUSTOMER_JOURNEY: 'M_CUSTOMER_JOURNEY',
// MONITORING: 'M_MONITORING',
// PAGE_TYPE_ADVANCED: 'M_PAGE_TYPE_ADVANCED',
// PAGE_TYPE_CLUSTER: 'M_PAGE_TYPE_CLUSTER',
// IMAGES_OPTIMIZER: 'M_IMAGES_OPTIMIZER',
// SEO_AUTOMATION: 'M_SEO_AUTOMATION',
// SEO_SEM: 'M_SEO_SEM',
// SEO_TOOL: 'M_SEO_TOOL',
// SEO_URLS: 'M_SEO_URLS',
// SERVICES_TOOLS: 'M_SERVICES_TOOLS',
// URL_EXPLORER_ANALYTIC: 'M_URL_EXPLORER_ANALYTIC',
// URL_EXPLORER_CRAWL: 'M_URL_EXPLORER_CRAWL',
// URL_REDIRECT: 'M_URL_REDIRECT',
// URL_REWRITE: 'M_URL_REWRITE',

// A/B Test - Customers Journey
