import { MigrateInstances } from '@/services/graphql/instance.graphql';
import { useMutation } from '@vue/apollo-composable';

export const useRefreshInstances = () => {
  const mutation = () =>
    useMutation<{
      SERVICE_migrateInstances: boolean;
    }>(MigrateInstances);

  const migrateInstances = mutation();

  const refreshInstances = async (): Promise<boolean> => {
    const res = await migrateInstances.mutate();

    return !!res?.data?.SERVICE_migrateInstances;
  };

  return {
    ...migrateInstances,
    refreshInstances,
  };
};
