<script setup lang="ts">
import { MODULES_CATEGORIES, MODULES_NAMES } from '@/constants/modules.constants';
import type { MODULES, ModuleCategory } from '@/constants/modules.constants';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { Toggle } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';

interface Props {
  checked: boolean;
  count: number;
  category: ModuleCategory;
  permissions: MODULES[];
  togglePermission: (module: MODULES) => void;
  toggleAllPackagePermissions: () => void;
  selectAllPackagePermissions: () => void;
}

const props = defineProps<Props>();

const isExpanded = ref(false);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const categoryModules = computed(() =>
  Object.entries(MODULES_CATEGORIES)
    .filter(([, c]) => c.includes(props.category))
    .map(([key]) => key)
    .sort((a, b) => MODULES_NAMES[a]?.localeCompare(MODULES_NAMES[b]))
);
</script>

<template>
  <div
    class="flex w-full cursor-pointer items-center"
    @click="
      () => {
        toggleExpand();
        console.log('clicked');
      }
    ">
    <Toggle
      :checked="props.checked"
      :on-change="toggleAllPackagePermissions"
      size="sm"
      :label="`${props.category} Modules`"
      @click="e => e.stopPropagation()" />

    <div class="flex-1" />
    <div
      class="mr-2 rounded-full px-4 py-[.2rem] text-center text-2xs"
      :class="{
        'bg-kl-blue-100/25': !props.count,
        'bg-kl-blue-100/80 text-white': props.count,
      }">
      {{ props.count }}
    </div>
    <div
      tabindex="0"
      :class="[
        'h-4 w-4 cursor-pointer text-secondary transition-all duration-300 hover:text-active',
        {
          'rotate-180': isExpanded,
        },
      ]"
      @keydown.enter="toggleExpand"
      @click="
        e => {
          e.stopPropagation();
          toggleExpand();
        }
      ">
      <ChevronDownIcon />
    </div>
  </div>
  <div
    v-if="isExpanded"
    class="mt-3 flex flex-col divide-y divide-gray-200 border-t border-kl-gray-200 bg-kl-gray-100 px-2">
    <div
      v-if="categoryModules.length > 0"
      class="cursor-pointer py-3 text-xs text-secondary underline"
      @click="selectAllPackagePermissions">
      Select All {{ props.category }}
    </div>
    <Toggle
      v-for="m in categoryModules"
      :key="m"
      :checked="props.permissions.includes(m as MODULES)"
      :on-change="() => togglePermission(m as MODULES)"
      size="sm"
      class="py-3"
      :label="MODULES_NAMES[m as MODULES]"
      @click="e => e.stopPropagation()" />
  </div>
</template>
