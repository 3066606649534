<script setup lang="ts">
import { useNotificationStore } from '@/stores/useNotificationStore';
import { Notification } from '@kleecks/ui-lib';
import { onMounted, onUnmounted, ref } from 'vue';

const notificationStore = useNotificationStore();
const topPosition = ref(0);
const scrollHandler = () => {
  topPosition.value = window.scrollY;
};
onMounted(() => {
  window.addEventListener('scroll', scrollHandler);
});

onUnmounted(() => {
  window.removeEventListener('scroll', scrollHandler);
});
</script>

<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-x-0 bottom-0 z-[12] flex items-end px-4 py-6 transition-all sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition-group
        appear
        enter-active-class="transform ease-out duration-500 transition-all"
        enter-from-class="translate-x-[50%] opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transition ease-in duration-500"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 translate-x-[100%]">
        <Notification
          v-for="notification in notificationStore.notifications"
          :key="notification.id"
          :type="notification.type"
          :title="notification.title"
          :message="notification.message"
          :duration="5000"
          :on-remove="() => notificationStore.removeNotification(notification.id)" />
      </transition-group>
    </div>
  </div>
</template>
