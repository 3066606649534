<script setup lang="ts">
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TOPIC_ICONS_MAP } from '@/constants/topic-icons.constants';
import { TOPIC_COLORS } from '@kleecks/code-lib';
import type { Dashboard } from '@/types/dashboard.types';
import { useRouter } from 'vue-router';
import { WithTooltip } from '@kleecks/ui-lib';

interface DashboardCardProps {
  dashboard: Dashboard;
}
const props = defineProps<DashboardCardProps>();

const router = useRouter();

const navigateToDashboard = () => {
  router.push({
    name: ROUTE_NAMES.DASHBOARD,
    params: { dashboardId: props.dashboard.id },
  });
};
</script>

<template>
  <div
    class="h-32 cursor-pointer rounded border bg-white hover:bg-gray-50 active:border-kl-green-200"
    @click="navigateToDashboard">
    <div :class="['h-3 w-full rounded-t', TOPIC_COLORS[props.dashboard.topic!] ]" />
    <div class="flex w-[240px] flex-1 flex-col gap-3 p-4">
      <div class="flex items-start gap-x-4">
        <div
          :class="[
            'flex size-8 shrink-0 items-center justify-center rounded p-1',
            TOPIC_COLORS[props.dashboard.topic!],
          ]">
          <component
            :is="TOPIC_ICONS_MAP[props.dashboard.topic!]"
            class="size-5 shrink-0 text-white" />
        </div>

        <p class="line-clamp-2 flex-1 text-kl-sm font-medium text-kl-gray-500">
          {{ props.dashboard.name || 'New Dashboard' }}
        </p>
      </div>

      <WithTooltip
        :label="props.dashboard.description"
        position="top-start"
        :active-middleware="false"
        max-width="max-w-56">
        <p
          :class="{
            'italic text-kl-gray-400/80': !props.dashboard.description,
            'text-kl-gray-400': props.dashboard.description,
          }"
          class="line-clamp-3 text-kl-xs">
          {{ props.dashboard.description || 'No description' }}
        </p>
      </WithTooltip>
    </div>
  </div>
</template>
