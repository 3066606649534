<script setup lang="ts">
// Vue and External Libraries
import { computed, h, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

// External Components
import { FilterBox, MessagePanel, StandardContainer, TaskStatusChip, UserProfileImage } from '@kleecks/ui-lib';
import {
  ArrowsRightLeftIcon,
  FolderIcon,
  PencilIcon,
  TrashIcon,
  WalletIcon,
  ExclamationCircleIcon,
} from '@heroicons/vue/24/outline';

// External Types
import type { VIEW_TYPE, Topic } from '@kleecks/code-lib';

// Internal Components
import TasksContainerBox from '@/components/tasks/tasks-container-box/TasksContainerBox.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import CloneTaskDialog from '@/components/dialogs/clone-task-dialog/CloneTaskDialog.vue';
import CreateTaskDialog from '@/components/dialogs/create-task/CreateTaskDialog.vue';
import EditTaskDialog from '@/components/dialogs/edit-task-dialog/EditTaskDialog.vue';
import PublishTaskDialog from '@/components/dialogs/publish-task-dialog/PublishTaskDialog.vue';
import ScheduleTaskDialog from '@/components/dialogs/schedule-task-dialog/ScheduleTaskDialog.vue';
import TaskDetailsDrawer from '@/components/drawers/task-details-drawer/TaskDetailsDrawer.vue';
import TasksSkeletonLoader from '@/components/skeletons/TasksSkeletonLoader.vue';
import TasksTable from '@/components/tables/tasks-table/TasksTable.vue';
// import TaskEmpty from '@/components/tasks/tasks-empty/TaskEmpty.vue';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';
import NotFound from '@/pages/not-found/NotFound.vue';

// Internal Types
import type { Task, TaskAction, TaskStatus } from '@/types/task.types';
import type { User } from '@/types/user.types';

// Internal Utilities
import { dragAndScrollSlider } from '@/utils/dragAndScrollSlider.utils';

// Internal Constants
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';

// Internal Hooks
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useInstances } from '@/hooks/instance/useInstances';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useBrandWithTasks } from '@/hooks/tasks/useBrandWithTasks';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useUser } from '@/hooks/user/useUser';
import { useCloneTask, useDeleteTask } from '@/services/queries/task.query';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import { useChatbotStore } from '@/stores/useChatbotStore';
import { usePresetTasks } from '@/hooks/tasks/usePresetTasks';

/**
 * COMPONENT LOGIC
 * This is the main logic of the component.
 * TasksManagement is a page that allows the user to manage tasks.
 * It displays a list of tasks and allows the user to perform actions on them.
 */

/**
 * MACRO FEATURE 1: Route Parameters and Instance Management
 * Handles route parameters and instance data for the application.
 */

// Constants (ref, composables)
const { getLabel } = useApplicationLabels();
const router = useRouter();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { user } = useUser();
const { instance, loading: loadingInstance } = useInstance(brandId, instanceId);
const { instances, instancesNames } = useInstances(brandId);
const chatbotStore = useChatbotStore();

const instanceIdForTaskModal = ref<string | undefined>(undefined);

/**
 * MACRO FEATURE 2: Task Filters and View Management
 * Manages task filters and view types (columns, list).
 */

// Constants (ref, composables)
const filters = ref<{
  topics: Topic[];
  statuses: TaskStatus[];
  assignees: string[];
}>({
  topics: ((router.currentRoute.value?.query?.topics as string)?.split(',') as Topic[]) || [],
  statuses: ((router.currentRoute.value?.query?.statuses as string)?.split(',') as TaskStatus[]) || [],
  assignees: (router.currentRoute.value?.query?.assignees as string)?.split(',') || [],
});

const viewType = ref<VIEW_TYPE>('columns');
const viewTypeSet = ref(new Set<VIEW_TYPE>(['columns', 'list']));
const showMyTasksOnly = ref(false);
const taskUserIdFilter = ref('');
const searchValue = ref('');
const tasksContainerBoxSpecificFiltersMap = ref(new Map<string, boolean>());
const disabledGeneralFilters = ref(false);

// Methods
const handelChangeTopic = (value: string[]) => {
  filters.value.topics = value as Topic[];
};

const handleChangeViewType = (newValue: VIEW_TYPE) => {
  if (newValue !== 'columns' && disabledGeneralFilters.value) {
    disabledGeneralFilters.value = false;
  }
  if (newValue === 'columns') {
    disabledGeneralFilters.value = Array.from(tasksContainerBoxSpecificFiltersMap.value.values()).some(v => v);
  }
  viewType.value = newValue;
};

const handleChangeShowMyTasksOnly = (value: boolean) => {
  if (!user.value) return;
  showMyTasksOnly.value = value;
  if (showMyTasksOnly.value) taskUserIdFilter.value = user.value.id;
  else taskUserIdFilter.value = '';
};

const updateSpecificFiltersMap = (boxId: string, value: boolean) => {
  tasksContainerBoxSpecificFiltersMap.value.set(boxId, value);
  disabledGeneralFilters.value = Array.from(tasksContainerBoxSpecificFiltersMap.value.values()).some(v => v);
};

// Lifecycle hooks
watch(
  () => disabledGeneralFilters.value,
  newValue => {
    if (newValue) {
      filters.value = {
        topics: [],
        statuses: [],
        assignees: [],
      };
      searchValue.value = '';
    }
  }
);
watch(
  () => filters.value,
  () => {
    router.push({
      name: ROUTE_NAMES.TASKS_MANAGEMENT,
      query: {
        ...(filters.value.topics?.length && { topics: filters.value.topics?.join(',') }),
        ...(filters.value.statuses?.length && { statuses: filters.value.statuses?.join(',') }),
        ...(filters.value.assignees?.length && { assignees: filters.value.assignees?.join(',') }),
      },
    });
  },
  { deep: true }
);

/**
 * MACRO FEATURE 3: Task Management
 * Filters tasks, manages preset tasks, and groups tasks by instance.
 */

// Constants (ref, composables)
const { filteredTasks, assigneeOptions, loading, tasksCountPerStatus, tasksCountPerAssignee, tasksCountPerTopic } =
  useBrandWithTasks(brandId, {
    userId: taskUserIdFilter,
    search: searchValue,
    filters,
  });

const { tasks: presetTasks, makeTaskPreset } = usePresetTasks();
const taskStatusOptions = computed(() => [
  ...Object.values(TASK_STATUS).map(status => ({ value: status, label: status })),
]);

// Computed properties
const instancesTasks = computed(() => {
  const tasksByInstance: Record<string, Task[]> = {};
  filteredTasks.value.forEach(task => {
    if (task.instance) {
      if (!tasksByInstance[task.instance?.instanceId]) {
        tasksByInstance[task.instance?.instanceId] = [];
      }
      tasksByInstance[task.instance?.instanceId].push(task);
    }
  });
  return tasksByInstance;
});

/**
 * MACRO FEATURE 4: Task Action Management
 * Manages actions available for tasks (edit, delete, switch, etc.).
 */

// Constants (ref, composables)
const { notify } = useNotificationStore();
const deleteAlertStore = useDeleteAlertStore();
const userStore = useUserStore();

const { navigateToSandbox } = useSandboxNavigate();

const { mutate: cloneTask } = useCloneTask();
const { mutate: updateTask } = useUpdateTask();
const { mutate: deleteTask } = useDeleteTask();

const isCreateTaskDialogOpen = ref(false);
const selectedTaskId = ref<string | undefined>(undefined);
const selectedAction = ref<string | undefined>(undefined);
const tasksTableActions = [
  { icon: PencilIcon, code: 'edit' },
  { icon: TrashIcon, code: 'delete' },
  {
    icon: ArrowsRightLeftIcon,
    code: 'switch',
    condition: (task: Task) => task.assignedTo?.find((el: User) => el?.login === user.value?.login) !== undefined,
  },
];

// Methods
const handleAddNewTask = () => {
  isCreateTaskDialogOpen.value = true;
};

const closeActionDialog = () => {
  selectedTaskId.value = undefined;
  selectedAction.value = undefined;
};

const handleTaskAction = async (taskId: string, action: TaskAction) => {
  const task = filteredTasks.value.find(t => t.id === taskId) || presetTasks.value?.find(t => t.id === taskId);

  if (action === 'make-preset') {
    makeTaskPreset(taskId);
    return;
  }

  if (action === 'view-sandbox') {
    if (!task?.instance?.domains?.[0]?.domain) {
      throw new Error(`handleTaskAction: task ${taskId} has no domain`);
    }
    navigateToSandbox({
      userLogin: task?.assignedTo?.[0]?.login,
      taskId,
      url: `https://${task?.instance?.domains?.[0]?.domain}`,
    });
    return;
  }

  if (action === 'edit-preset') {
    selectedTaskId.value = taskId;
    selectedAction.value = 'edit-preset';
    return;
  }

  if (action === 'edit') {
    selectedTaskId.value = taskId;
    selectedAction.value = action;
    return;
  }

  if (action === 'switch') {
    userStore.setSelectedTaskId(taskId);
    notify({
      type: NOTIFICATION_TYPES.INFO,
      title: 'Working task changed',
      message: `Now you're working on task ${task?.title}`,
    });
    return;
  }

  if (action === 'publish') {
    selectedTaskId.value = taskId;
    selectedAction.value = 'publish';
    return;
  }

  if (action === 'schedule') {
    selectedTaskId.value = taskId;
    selectedAction.value = 'schedule';
    return;
  }

  if (action === 'clone') {
    selectedTaskId.value = taskId;
    selectedAction.value = 'clone';
    return;
  }

  if (action === 'delete') {
    deleteAlertStore.showDeleteAlert(`Are you sure you want to delete task ${task?.title}?`, () => {
      deleteTask({ taskId });
    });
    return;
  }

  if (action === 'approve') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.APPROVED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task approved',
      message: `The task ${task?.title} has been approved successfully`,
    });

    return;
  }

  if (action === 'reopen') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.DRAFT,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task reopened',
      message: `The task ${task?.title} has been reopened successfully`,
    });

    return;
  }

  if (action === 'reject') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.REJECTED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task rejected',
      message: `The task ${task?.title} has been rejected successfully`,
    });

    return;
  }

  if (action === 'details') {
    selectedTaskId.value = taskId;
    selectedAction.value = 'details';
    return;
  }

  throw new Error(`Task action ${action} not implemented`);
};

/**
 * MACRO FEATURE 6: Task Drawer Management
 * Handles the drawer for viewing detailed task information.
 */

// Constants (ref, composables)
const dragStatus = ref(false);
const clonedObject = ref<Task>();
const slider = ref<HTMLInputElement>();

// Methods
const closeDetailsDrawer = () => {
  selectedTaskId.value = undefined;
  selectedAction.value = undefined;
};

const deepCloneTask = (obj: Task) => {
  clonedObject.value = JSON.parse(JSON.stringify(obj));
};

const onDrop = async (newInstanceId: string) => {
  if (!clonedObject.value) throw new Error('No cloned object');
  await cloneTask({ cloneTaskId: clonedObject.value?.id, instanceId: newInstanceId });
  notify({
    type: NOTIFICATION_TYPES.INFO,
    title: 'Task cloned',
    message: `Task ${clonedObject.value?.title} cloned on ${newInstanceId}`,
  });
};
// Lifecycle hooks
watch(slider, () => dragAndScrollSlider(slider.value));
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('TASKS_MANAGEMENT.help.text')!"
    modal-title="Tasks Management Info" />
  <NotFound v-if="!loadingInstance && !instance" />
  <EditTaskDialog
    :brand-id="brandId"
    :is-open="selectedAction === 'edit' || selectedAction === 'edit-preset'"
    :is-preset="selectedAction === 'edit-preset'"
    :task="filteredTasks.find(task => task.id === selectedTaskId)! || presetTasks.find(t => t.id === selectedTaskId)"
    @close="closeActionDialog" />
  <PublishTaskDialog
    v-if="selectedAction === 'publish'"
    :brand-id="brandId"
    :is-open="selectedAction === 'publish'"
    :task="filteredTasks.find(task => task.id === selectedTaskId)!"
    :on-close="closeActionDialog" />
  <ScheduleTaskDialog
    v-if="selectedAction === 'schedule'"
    :brand-id="brandId"
    :is-open="selectedAction === 'schedule'"
    :task="filteredTasks.find(task => task.id === selectedTaskId)!"
    :on-close="closeActionDialog" />
  <CloneTaskDialog
    v-if="selectedAction === 'clone' && filteredTasks.find(task => task.id === selectedTaskId)"
    :brand-id="brandId"
    :is-open="selectedAction === 'clone'"
    :task="filteredTasks.find(task => task.id === selectedTaskId)!"
    :on-close="closeActionDialog" />

  <TaskDetailsDrawer
    :is-showing="selectedAction === 'details'"
    :task="filteredTasks.find(task => task.id === selectedTaskId)"
    :on-close="closeDetailsDrawer" />
  <CreateTaskDialog
    :brand-id="brandId"
    :instance-id="instanceIdForTaskModal || instanceId"
    :is-open="isCreateTaskDialogOpen"
    @close="
      () => {
        isCreateTaskDialogOpen = false;
        instanceIdForTaskModal = undefined;
      }
    " />
  <StandardContainer>
    <div
      class="flex flex-col pb-8 pt-4 transition-all duration-100"
      :class="{
        'container-height-chatbot': chatbotStore.isShowInPage,
        'container-height-no-chatbot delay-100': !chatbotStore.isShowInPage,
      }">
      <div class="flex h-full min-h-0 w-full flex-col gap-8">
        <transition-group
          enter-from-class="opacity-0"
          enter-active-class="transition duration-1000">
          <div key="tasks-management-header">
            <DashboardHeader
              v-model:search-value="searchValue"
              title="Tasks Management"
              search-placeholder="Search tasks name or description"
              :sub-title="`The library of the tasks for this instance`"
              :disabled-filters="disabledGeneralFilters"
              :cta="{
                onClick: handleAddNewTask,
                label: 'New Task',
                variant: 'pink',
              }"
              :dashboard-type-enable="showMyTasksOnly"
              dashboard-type-value="My Tasks"
              :view-type="viewType"
              :view-type-options="viewTypeSet"
              :topic-value="filters.topics"
              :counter-for-topic="tasksCountPerTopic"
              :on-change-topic="value => handelChangeTopic(value)"
              :on-change-view-type="handleChangeViewType"
              :on-change-dashboard-toggle="value => handleChangeShowMyTasksOnly(value)">
              <template #extra-header-filters>
                <div class="w-36 flex-1">
                  <FilterBox
                    v-model="filters.statuses"
                    bgClass="bg-white"
                    :class="disabledGeneralFilters ? 'opacity-50 pointer-events-none' : ''"
                    label="Filter by status"
                    :items="taskStatusOptions"
                    placeholder="Filter by status"
                    :render-fn="
                      ({value}) =>
                        h('div', { class: 'flex items-center py-1 gap-2 text-secondary text-sm' }, [
                          h(TaskStatusChip, { task: { status: value } }),
                          h('span', {class: 'text-xs'}, `(${tasksCountPerStatus[value as TaskStatus] || 0})`),
                        ])
                    "
                    filter-placeholder="Insert here the status you want to filter by" />
                </div>
                <div class="w-36 flex-1">
                  <FilterBox
                    v-model="filters.assignees"
                    :items="assigneeOptions"
                    bgClass="bg-white"
                    :class="disabledGeneralFilters ? 'opacity-50 pointer-events-none' : ''"
                    show-filter
                    :render-fn="
                      ({ value, label, image }: any) =>
                        h('div', { class: 'flex items-center gap-2 text-secondary text-sm' }, [
                          h(UserProfileImage, {
                            class: 'size-5 text-kl-icon',
                            size: 'sm',
                            user: {
                              nome: label?.split(' ')[0],
                              cognome: label?.split(' ')[1],
                              image,
                            },
                          }),
                          h('span', label),
                          h('span', { class: 'text-xs' }, `(${tasksCountPerAssignee?.[value] || 0})`),
                        ])
                    "
                    label="Assignee"
                    placeholder="Filter by assignee" />
                </div>
              </template>
            </DashboardHeader>
          </div>

          <template v-if="loading">
            <TasksSkeletonLoader />
          </template>
          <div
            v-if="!loading"
            v-show="viewType === 'columns'"
            key="tasks-list"
            class="flex h-full min-h-0 items-start gap-8 overflow-hidden">
            <TasksContainerBox
              v-model:tasks="presetTasks"
              class="mb-1 h-full w-96"
              box-title="Presets"
              box-id="presets"
              :box-icon="WalletIcon"
              variant="violet"
              :drag-status="dragStatus"
              :drag-options="{
                group: { name: 'tasks', pull: 'clone', put: false },
                isPublishEnabled: false,
                isScheduleEnabled: false,
                isCloneEnabled: true,
                isDraggable: true,
                isPreset: true,
                hideInstance: true,
                onCloneTask: obj => deepCloneTask(obj),
              }"
              :on-send-signal-for-manage-general-filters="
                (boxId, internalFiltersMenuStatus) => updateSpecificFiltersMap(boxId, internalFiltersMenuStatus)
              "
              :on-update-drag-status="status => (dragStatus = status)"
              :on-send-task-action="(taskId, action) => handleTaskAction(taskId, action)" />
            <TasksContainerBox
              v-model:tasks="filteredTasks"
              class="mb-1 h-full w-96"
              box-title="Tasks Library"
              box-id="tasks-library"
              :box-icon="WalletIcon"
              variant="pink"
              :drag-status="dragStatus"
              :drag-options="{
                group: { name: 'tasks', pull: 'clone', put: false },
                isPublishEnabled: false,
                isScheduleEnabled: false,
                isCloneEnabled: true,
                isDraggable: true,
                isPreset: false,
                hideInstance: true,
                onCloneTask: obj => deepCloneTask(obj),
              }"
              :on-send-signal-for-manage-general-filters="
                (boxId, internalFiltersMenuStatus) => updateSpecificFiltersMap(boxId, internalFiltersMenuStatus)
              "
              :on-update-drag-status="status => (dragStatus = status)"
              :on-send-task-action="(taskId, action) => handleTaskAction(taskId, action)">
              <template #extra-body-content="{ taskFiltered }">
                <MessagePanel
                  v-if="filteredTasks.length === 0 || taskFiltered === 0"
                  class="mx-2 my-1"
                  background="white"
                  orientation="horizontal"
                  :messages="[
                    {
                      text: 'No tasks found',
                      icon: ExclamationCircleIcon,
                      iconCss: '!text-kl-red-200 !size-6',
                      visibility: true,
                    },
                  ]" />
              </template>
            </TasksContainerBox>
            <div
              ref="slider"
              class="scrollbar-1 flex min-h-0 flex-1 self-stretch overflow-x-auto pb-1">
              <div class="flex min-h-0 items-center gap-8">
                <TasksContainerBox
                  v-for="i in instances"
                  :key="i.instanceId"
                  v-model:tasks="instancesTasks[i.instanceId]"
                  class="mb-1 h-full w-96"
                  :box-title="instancesNames[i.instanceId]"
                  :box-id="i.instanceId"
                  :box-icon="FolderIcon"
                  variant="gray"
                  :disabled="!i.enabled"
                  :website-visit-href="i.domains?.[0]?.server_baseurl"
                  :drag-status="dragStatus"
                  :drag-options="{
                    group: { name: 'tasks', pull: 'clone', put: false },
                    isPublishEnabled: true,
                    isScheduleEnabled: true,
                    isCloneEnabled: true,
                    isDraggable: true,
                    isPreset: false,
                    hideInstance: false,
                    onCloneTask: obj => deepCloneTask(obj),
                  }"
                  :drop-options="{
                    isDropAgree: i.instanceId !== clonedObject?.instance?.instanceId,
                    dropAgreeMessage: `Move a task here to clone into ${instancesNames[i.instanceId]}`,
                    onDrop: () => onDrop(i.instanceId),
                  }"
                  :add-task-shortcut="
                    user?.admin
                      ? () => {
                          instanceIdForTaskModal = i.instanceId;
                          handleAddNewTask();
                        }
                      : undefined
                  "
                  :on-send-signal-for-manage-general-filters="
                    (boxId, internalFiltersMenuStatus) => updateSpecificFiltersMap(boxId, internalFiltersMenuStatus)
                  "
                  :on-update-drag-status="status => (dragStatus = status)"
                  :on-send-task-action="(taskId, action) => handleTaskAction(taskId, action)">
                  <template #extra-body-content="{ taskFiltered }">
                    <MessagePanel
                      v-if="!instancesTasks[i.instanceId] || taskFiltered === 0"
                      class="mx-2 my-1"
                      background="white"
                      orientation="horizontal"
                      :messages="[
                        {
                          text: 'No tasks found',
                          icon: ExclamationCircleIcon,
                          iconCss: '!text-kl-red-200 !size-6',
                          visibility: true,
                        },
                      ]" />
                    <!-- <div
                      v-if="filteredTasks.length === 0 && indexInstance === 0"
                      class="p-4">
                      <TaskEmpty
                        height="h-12"
                        :create-task="handleAddNewTask" />
                    </div> -->
                  </template>
                </TasksContainerBox>
              </div>
            </div>
          </div>
          <div
            v-if="!loading"
            v-show="viewType === 'list'"
            key="tasks-table"
            class="flex min-h-0 grow flex-col">
            <TasksTable
              :tasks="filteredTasks"
              :actions="tasksTableActions"
              :initial-page-size="20"
              :on-action="handleTaskAction" />
          </div>
        </transition-group>
      </div>
    </div>
  </StandardContainer>
</template>
