<script setup lang="ts">
import { computed } from 'vue';

// When the "values" key corresponds to the label to display, the "labels" parameter should not be passed

interface StatsChartProps {
  values: Record<string, number>;
  colors: Record<string, string>;
  labels?: Record<string, string>;
  classCounter?: string;
  classLabel?: string;
  maxSize: number;
  minSize: number;
}

type SizeBallType = {
  value: number;
  label: string;
  size: number;
  color?: string;
};

const props = withDefaults(defineProps<StatsChartProps>(), {
  maxSize: 100,
  minSize: 30,
  labels: undefined,
  classCounter: 'text-kl-2xl',
  classLabel: 'text-kl-sm',
});

const series = computed(() => Object.values(props.values));

const ballSize = computed(() => {
  const maxValue = Math.max(...series.value);
  const listValues = Object.keys(props.values).reduce((acc: SizeBallType[], el) => {
    const percentValue: number = (props.values[el] / maxValue) * 100;
    const diffSize = props.maxSize - props.minSize;
    let sizeVal = Math.ceil(props.minSize + (diffSize * percentValue) / 100);
    if (maxValue === 0) {
      sizeVal = props.maxSize / 2;
    }
    acc.push({
      value: props.values[el],
      label: props.labels ? props.labels[el] : el,
      size: sizeVal,
      color: props.colors[el],
    });
    return acc;
  }, [] as SizeBallType[]);
  return listValues;
});
</script>
<template>
  <div class="relative mb-12 flex items-center gap-2">
    <div
      v-for="ball in ballSize"
      :key="ball.label"
      class="flex flex-col items-center justify-center gap-2"
      :style="{
        height: `${maxSize}px`,
        width: `${maxSize}px`,
      }">
      <div
        v-if="ball.size"
        :class="`flex items-center justify-center rounded-full text-white ${classCounter}`"
        :style="{
          height: `${ball.size}px`,
          width: `${ball.size}px`,
          background: `${ball.color}`,
        }">
        {{ ball.value }}
      </div>
      <div :class="`absolute top-[calc(100%+9px)] font-medium text-secondary ${classLabel}`">
        {{ ball.label }}
      </div>
    </div>
  </div>
</template>
