<script setup lang="ts">
import AiSearchInput from '@/components/ai/ai-search-input/AiSearchInput.vue';
import CreateTaskDialog from '@/components/dialogs/create-task/CreateTaskDialog.vue';
import DeleteAlert from '@/components/dialogs/delete-alert/DeleteAlert.vue';
import DisclaimerDialog from '@/components/dialogs/disclaimer-dialog/DisclaimerDialog.vue';
import ModuleModal from '@/components/dialogs/module-modal/ModuleModal.vue';
import TaskDetailsDrawer from '@/components/drawers/task-details-drawer/TaskDetailsDrawer.vue';
import Navbar from '@/components/navbar/Navbar.vue';
import NotificationContainer from '@/components/notification-container/NotificationContainer.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useQueryParam } from '@/hooks/common/useQueryParam';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useRainbowTitle } from '@/hooks/general/useRainbowTitle';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import { useSubscriptions } from '@/hooks/subscriptions/useSubscriptions';
import { useLogout } from '@/hooks/user/useLogout';
import { useUser } from '@/hooks/user/useUser';
import { useChatbotStore } from '@/stores/useChatbotStore';
import { useCreateTaskDialog } from '@/stores/useCreateTaskDialogStore';
import { useCurrentTaskDrawerStore } from '@/stores/useCurrentTaskDrawerStore';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import type { Topic } from '@kleecks/code-lib';
import { MainLayout } from '@kleecks/ui-lib';
import { ref, watch } from 'vue';
import type { RouteRecordName } from 'vue-router';
import { useRouter } from 'vue-router';

const router = useRouter();
const chatbotStore = useChatbotStore();

const deleteAlertStore = useDeleteAlertStore();
useSubscriptions();

const { loading: loadingDictionary } = useApplicationLabels();
const { user } = useUser();
const { logout } = useLogout();
const taskDrawerStore = useCurrentTaskDrawerStore();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const panel = useQueryParam('panel');
const createTaskDialog = useCreateTaskDialog();
const showDisclaimer = ref(!!user.value && !user.value.acceptanceClauses);

useRainbowTitle();

const listEnableChatbot: RouteRecordName[] = [
  ROUTE_NAMES.INSTANCE,
  ROUTE_NAMES.TASKS_MANAGEMENT,
  ROUTE_NAMES.DASHBOARDS_LIBRARY,
  ROUTE_NAMES.MODULES_LIBRARY,
];

watch(
  () => router.currentRoute.value,
  () => {
    if (!router.currentRoute.value.name) {
      chatbotStore.setEnableChatbot(false);
    } else {
      chatbotStore.setEnableChatbot(listEnableChatbot.includes(router.currentRoute.value.name));
    }
  },
  { immediate: true }
);

watch(
  user,
  () => {
    if (user.value?.login) {
      if (user.value?.login) {
        if (window.tracker) window.tracker.setUserID(user.value?.login);
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <MainLayout
    v-if="!loadingDictionary"
    class="flex flex-col overflow-x-hidden">
    <Navbar />
    <AiSearchInput
      v-if="chatbotStore.enableChatbot"
      key="ai_search_input" />
    <div
      class="flex w-full flex-1 flex-col transition-all duration-200"
      :class="{
        'pt-[92px]': chatbotStore.isShowInPage && chatbotStore.enableChatbot,
        'pt-0': !chatbotStore.isShowInPage && chatbotStore.enableChatbot,
      }">
      <router-view v-if="!showDisclaimer" />
    </div>
    <!-- <div class="flex w-full flex-1 flex-col">
      <router-view />
    </div> -->
  </MainLayout>
  <NotificationContainer />
  <ModuleModal />
  <TaskDetailsDrawer
    :task="taskDrawerStore.task"
    :is-showing="!!taskDrawerStore.task"
    :on-close="() => taskDrawerStore.closeDrawer()" />
  <DisclaimerDialog
    :is-open="showDisclaimer"
    :on-dismiss="logout"
    :on-subscribe="
      () => {
        showDisclaimer = false;
      }
    " />

  <DeleteAlert
    :message="deleteAlertStore.messageValue"
    :message-component="deleteAlertStore.messageComponentValue"
    :on-close="deleteAlertStore.closeDeleteAlert"
    :is-open="deleteAlertStore.isOpenValue"
    :on-delete="deleteAlertStore.confirmDelete">
  </DeleteAlert>

  <CreateTaskDialog
    :brand-id="brandId"
    :instance-id="instanceId"
    :is-instance-fixed="true"
    :is-open="createTaskDialog.isOpenValue"
    :topic="(panel as Topic)"
    @close="createTaskDialog.closeCreateTaskDialog" />
</template>
