import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { useDashboards } from './useDashboards';
import { useUser } from '../user/useUser';

export const useDashboard = (dashboardId: MaybeRef<string>, brandId: MaybeRef<string>) => {
  const { user } = useUser();

  const { dashboards, loading } = useDashboards(brandId, user);

  const dashboard = computed(() => dashboards.value?.find(d => d.id === unref(dashboardId)));

  return {
    dashboard,
    loading,
  };
};
