import type { MODULES } from '@/constants/modules.constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModuleModalStore = defineStore('moduleModalStore', () => {
  const moduleId = ref<MODULES | undefined>(undefined);
  const taskId = ref<string | undefined>(undefined);
  const instanceId = ref<string | undefined>(undefined);
  const readOnlyMode = ref<boolean | undefined>(false);
  const displayModule = (
    value: MODULES,
    taskIdValue: string | undefined,
    instanceIdvalue: string,
    opts?: {
      isReadOnly?: boolean;
    }
  ) => {
    moduleId.value = value;
    taskId.value = taskIdValue;
    instanceId.value = instanceIdvalue;
    readOnlyMode.value = opts?.isReadOnly;
  };

  const unsetModule = () => {
    moduleId.value = undefined;
  };

  return {
    moduleId,
    taskId,
    instanceId,
    readOnlyMode,
    displayModule,
    unsetModule,
  };
});
