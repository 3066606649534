import type { ExternalModules, MODULES } from '@/constants/modules.constants';
import { KLEECKS_MODULES, MODULES_LAZY_MAP, MODULES_LIBRARY } from '@/constants/modules.constants';
import { usePermissionsInBrand } from '@/hooks/user/usePermissionsInBrand';
import type { Component } from 'vue';
import { computed } from 'vue';
import { useRouteParam } from '../common/useRouteParam';
import { useUser } from '../user/useUser';

const getModulesComponents = (
  map: {
    [key in ExternalModules]?: Component;
  },
  module: string
) =>
  MODULES_LAZY_MAP[module as ExternalModules]
    ? { ...(map || {}), [module]: MODULES_LAZY_MAP[module as ExternalModules] }
    : map;

export const useModules = () => {
  const { isSuperAdmin } = useUser();
  const brandId = useRouteParam('brandId');
  const permissions = usePermissionsInBrand(brandId);

  const modules = computed<{
    [key in MODULES]?: Component;
  }>(() => {
    if (isSuperAdmin.value) {
      return MODULES_LAZY_MAP;
    }

    return Object.keys(permissions.value).reduce<{
      [key in MODULES]?: Component;
    }>(getModulesComponents, {});
  });

  const modulesLibrary = computed<MODULES[]>(() =>
    isSuperAdmin.value
      ? MODULES_LIBRARY.concat(KLEECKS_MODULES)
      : (Object.keys(permissions.value).filter(el => MODULES_LIBRARY.includes(el as any)) as MODULES[])
  );

  return { modules, modulesLibrary };
};
