<script setup lang="ts">
import type { Dashboard, DashboardVisibility } from '@/types/dashboard.types';
import { type Topic } from '@kleecks/code-lib';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { computed, ref, watch } from 'vue';
import * as yup from 'yup';
import { useUpdateDashboard } from '@/hooks/dashboard/useUpdateDashboard';
import { getDashboardVisibility } from '@/utils/dashboard.utils';
import { TOPIC_OPTIONS } from '@kleecks/code-lib';
import {
  DialogContainer,
  Modal,
  Button,
  BaseTextInput,
  Combobox,
  RadioButtonsList,
  BaseTextArea,
} from '@kleecks/ui-lib';
import deepEqual from 'fast-deep-equal';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';

interface EditDashboardModalProps {
  isOpen: boolean;
  dashboard?: Dashboard;
  brandId: string;
  userSettings: {
    teamId?: string;
    login?: string;
    isAdmin?: boolean;
    isSuperAdmin?: boolean;
  };
}

interface DashboardEditPayload {
  id?: string;
  name?: string;
  description?: string;
  topic?: Topic;
  userLogin?: string | null;
  brandId?: string | null;
  teamId?: string | null;
}

const props = defineProps<EditDashboardModalProps>();

const emit = defineEmits<{ (e: 'close'): void }>();

const { notify } = useNotificationStore();
const { updateDashboard, loading } = useUpdateDashboard();

const schema = yup.object().shape({
  name: yup.string().required('The name of the dashboard is required.'),
  topic: yup.string().required('Topic is required'),
});
const { errors, validateSchema } = useSchemaValidation(schema);

const dashboardObjectToEdit = ref<DashboardEditPayload>({});
const dashboardVisibility = ref<DashboardVisibility>();
const dashboardProjectName = ref<string>();

const VISIBILITY_OPTIONS: { keyValue: DashboardVisibility; label: string }[] = [
  { keyValue: 'private', label: 'Make this Dashboard Private' },
  { keyValue: 'global', label: 'Extends this dashboard to me for all brands' },
  {
    keyValue: 'team_universal',
    label: 'Extends this dashboard to all my team users for all team brands',
  },
  { keyValue: 'universal', label: 'Extends this dashboard to all Users for all brands' },
];

const getVisibilityOptions = computed(() => {
  if (props.userSettings.isSuperAdmin) {
    return VISIBILITY_OPTIONS;
  }
  if (props.userSettings.isAdmin) {
    return VISIBILITY_OPTIONS.slice(0, 3);
  }
  return VISIBILITY_OPTIONS.slice(0, 2);
});

const isSaveDisabled = computed(() => {
  if (!props.dashboard) return true;
  return (
    deepEqual(props.dashboard.id, dashboardObjectToEdit.value.id) &&
    deepEqual(props.dashboard.name, dashboardObjectToEdit.value.name) &&
    deepEqual(props.dashboard.description, dashboardObjectToEdit.value.description) &&
    deepEqual(props.dashboard.topic, dashboardObjectToEdit.value.topic) &&
    deepEqual(getDashboardVisibility(props.dashboard), dashboardVisibility.value)
  );
});
const handleClose = () => {
  emit('close');
};

const handleEditDashboard = async () => {
  if (!dashboardObjectToEdit.value) return;
  if (!(await validateSchema(dashboardObjectToEdit.value))) return;
  try {
    // Manage Visibility:
    console.log('visibility', dashboardVisibility.value);
    if (dashboardVisibility.value === 'private') {
      dashboardObjectToEdit.value = {
        ...dashboardObjectToEdit.value,
        brandId: props.brandId,
        userLogin: props.userSettings.login,
        teamId: undefined,
      };
    }
    if (dashboardVisibility.value === 'global') {
      dashboardObjectToEdit.value = {
        ...dashboardObjectToEdit.value,
        userLogin: props.userSettings.login,
        brandId: null,
        teamId: null,
      };
    }
    if (dashboardVisibility.value === 'team_universal') {
      dashboardObjectToEdit.value = {
        ...dashboardObjectToEdit.value,
        teamId: props.userSettings.teamId,
        brandId: null,
        userLogin: null,
      };
    }

    if (dashboardVisibility.value === 'universal') {
      dashboardObjectToEdit.value = {
        ...dashboardObjectToEdit.value,
        teamId: null,
        brandId: null,
        userLogin: null,
      };
    }
    await updateDashboard({
      dashboard: dashboardObjectToEdit.value,
    });

    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Dashboard updated successfully',
      message: `The dashboard ${dashboardObjectToEdit.value.name} has been updated successfully`,
    });

    handleClose();
  } catch (error) {
    notify({
      type: NOTIFICATION_TYPES.ERROR,
      title: 'Error',
      message: `There was an error updating the dashboard: ${error || 'Unknown error'}`,
    });
  }
};

watch(
  () => props.isOpen,
  newValue => {
    if (newValue && props.dashboard) {
      dashboardObjectToEdit.value = {
        id: props.dashboard.id,
        name: props.dashboard.name,
        description: props.dashboard.description,
        topic: props.dashboard.topic,
      };
      dashboardProjectName.value = props.dashboard.brand?.name;
      dashboardVisibility.value = getDashboardVisibility(props.dashboard);
    }
    if (!newValue) {
      setTimeout(() => {
        dashboardObjectToEdit.value = {};
      }, 500);
      if (errors.value) {
        errors.value = {};
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :on-close="handleClose">
    <Modal
      title="Edit Dashboard"
      wrapper-class="w-[700px]"
      :on-close="handleClose">
      <form @submit.prevent="() => isSaveDisabled && handleEditDashboard()">
        <div class="flex flex-col justify-between">
          <div class="flex">
            <div
              v-if="dashboardObjectToEdit"
              class="flex flex-1 flex-col gap-y-4">
              <BaseTextInput
                :id="'dashboard_name'"
                v-model="dashboardObjectToEdit.name"
                label="Dashboard name*"
                type="text"
                :status="{
                  message: errors.name,
                  valid: !errors.name,
                }"
                placeholder="Name..."
                :name="`dashboard_name`" />
              <BaseTextInput
                v-if="dashboardProjectName"
                id="dashboard_brand"
                v-model="dashboardProjectName"
                label="Dashboard Brand"
                name="dashboard_brand"
                type="text"
                :editable="false" />
              <BaseTextArea
                :id="`dashboard_description`"
                v-model="dashboardObjectToEdit.description"
                label="Dashboard description"
                placeholder="Insert a description"
                :name="`dashboard_description`" />

              <Combobox
                id="dashboard_topic"
                v-model="dashboardObjectToEdit.topic"
                label="Topic*"
                value-expr="value"
                display-expr="label"
                name="dashboard_topic"
                :items="TOPIC_OPTIONS" />
            </div>
            <div
              v-if="dashboardObjectToEdit"
              class="ml-4 flex-1 border-l border-gray-200 pl-4">
              <RadioButtonsList
                v-model="dashboardVisibility"
                :options="getVisibilityOptions"
                :dedfault-value="'private'" />
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-4 self-end">
            <Button
              class="w-24"
              variant="secondary"
              :disabled="loading"
              @click="handleClose">
              Cancel
            </Button>
            <Button
              class="w-24"
              variant="primary"
              :isLoading="loading"
              :disabled="isSaveDisabled"
              @click="handleEditDashboard">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  </DialogContainer>
</template>
