<script setup lang="ts">
import CreateBrandDialog from '@/components/dialogs/create-brand/CreateBrandDialog.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import UserDetailsPanel from '@/components/panels/user-details-panel/UserDetailsPanel.vue';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useDeleteBrand } from '@/hooks/brand/useDeleteBrand';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Brand } from '@/types/brand.types';

import type { User } from '@/types/user.types';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import type { Column } from '@kleecks/ui-lib';
import { DrawerContainer, HeadlessTable } from '@kleecks/ui-lib';
import { h, ref } from 'vue';
import EditBrandDialog from '@/components/dialogs/edit-brand/EditBrandDialog.vue';
import BrandTableInstancesCell from './BrandTableInstancesCell.vue';

const searchValue = ref();
const { brands } = useBrands();
const { deleteBrand } = useDeleteBrand();
const { notify } = useNotificationStore();
const { showDeleteAlert } = useDeleteAlertStore();
const selectedBrandToEdit = ref<Brand>();

const handleDeleteBrand = (name: string) => {
  showDeleteAlert(`Are you sure that you want to delete the brand ${name}?`, async () => {
    deleteBrand(name);
    notify(DEFAULT_NOTIFICATIONS.BRAND_DELETED(name));
  });
};

const handleEditBrand = (brand: Brand) => {
  selectedBrandToEdit.value = brand;
};
const columns: Column<Brand>[] = [
  {
    accessorKey: 'name',
    header: 'Id',
    sortable: true,
    filterable: true,
    filterType: 'includesString',
    cellClass: 'font-bold text-sm',
  },
  {
    accessorKey: 'label',
    header: 'Name',
    sortable: true,
    filterable: true,
    filterType: 'includesString',
  },
  {
    header: 'Instances',
    filterable: true,
    filterFn: (row, id, filterValue) => {
      const instances = Object.values(row.instances || {})
        .map(el => el?.instanceId)
        .filter(el => !!el);

      return instances.some(el => el.includes(filterValue));
    },
    accessorFn: (v: Brand) => h(BrandTableInstancesCell, { brand: v }),
  },
  {
    accessorKey: 'address',
    sortable: true,
    filterable: true,
    filterType: 'includesString',
    header: 'Address',
  },
  {
    accessorFn: (v: Brand) => v.taskObject?.totalCount,
    header: 'Tasks',
    columnRendererClass: 'text-center flex-1',
    cellClass: 'text-center w-24',
    sortable: true,
  },
  {
    actions: true,
    component: v =>
      h('div', { class: 'flex justify-end gap-2' }, [
        h(PencilIcon, {
          class: 'text-kl-icon size-5 cursor-pointer hover:text-kl-blue-200',
          onClick: () => handleEditBrand(v),
        }),
        h(TrashIcon, {
          class: 'text-kl-icon size-5 cursor-pointer hover:text-kl-red-200',
          onClick: e => {
            e.stopPropagation();
            handleDeleteBrand(v.name);
          },
        }),
      ]),
  },
];

const selectedUser = ref<User>();
const isCreateBrandDialogOpen = ref(false);

const closeCreateBrandDialog = () => {
  isCreateBrandDialogOpen.value = false;
};

const openCreateBrandDialog = () => {
  isCreateBrandDialogOpen.value = true;
};

const closeUserDetailsDrawer = () => {
  selectedUser.value = undefined;
};
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col py-8">
    <CreateBrandDialog
      :is-open="isCreateBrandDialogOpen"
      :on-close="closeCreateBrandDialog" />

    <EditBrandDialog
      v-if="selectedBrandToEdit"
      :is-open="!!selectedBrandToEdit"
      :on-close="() => (selectedBrandToEdit = undefined)"
      :brand="selectedBrandToEdit" />

    <div class="relative z-10">
      <DrawerContainer
        :title="selectedUser?.nome + ' ' + selectedUser?.cognome"
        :on-close="closeUserDetailsDrawer"
        :isShowing="!!selectedUser">
        <UserDetailsPanel
          v-if="selectedUser"
          v-show="selectedUser"
          :on-close="closeUserDetailsDrawer"
          :user="selectedUser" />
      </DrawerContainer>
    </div>
    <div class="flex min-h-0 w-full flex-1 flex-col items-end">
      <DashboardHeader
        v-model:search-value="searchValue"
        title="Brands"
        search-placeholder="Search brands name"
        sub-title="The list of the brands of the platform."
        :cta="{
          onClick: openCreateBrandDialog,
          label: 'New Brand',
        }" />

      <HeadlessTable
        font-size="xs"
        :global-filter-enabled="true"
        :initial-page-size="20"
        :on-row-click="
          row => {
            handleEditBrand(row);
          }
        "
        :elements="brands"
        :columns="columns" />
    </div>
  </div>
</template>
