<script setup lang="ts">
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Notification, NotificationTaskPayload } from '@/types/notification.types';
import { formatNotificationCTA } from '@/utils/notification.utils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import { TOPIC_COLORS } from '@kleecks/code-lib';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

interface NotificationTaskComponentProps {
  taskPayload: NotificationTaskPayload;
  notification?: Notification;
}

const props = defineProps<NotificationTaskComponentProps>();
const router = useRouter();
const notificationStore = useNotificationStore();

const notificationCTA = computed(() => {
  if (!props.notification) return null;
  return formatNotificationCTA(props.notification);
});

const handleNotificationCTA = () => {
  if (notificationCTA.value) {
    const { brandId } = notificationCTA.value;
    const { instanceId } = notificationCTA.value;
    if (notificationCTA.value.routeName) {
      router.push({ name: notificationCTA.value.routeName });
      return;
    }

    const { action } = notificationCTA.value;

    if (action === 'view-task') {
      if (!brandId || !instanceId) {
        notificationStore.notify(DEFAULT_NOTIFICATIONS.ERROR('An error happened while trying to view the task'));
        throw new Error('BrandId and instanceId are required for this action');
      }

      router.push({
        name: ROUTE_NAMES.INSTANCE,
        params: { brandId, instanceId },
        query: { panel: notificationCTA.value.topic },
      });
    }
  }
};
</script>

<template>
  <div class="relative box-border w-full rounded-lg border-y border-r border-kl-gray-200 px-4 py-2">
    <span
      :class="TOPIC_COLORS[props.taskPayload.topic]"
      class="absolute inset-y-0 left-0 w-1.5 rounded-full" />
    <div class="flex items-center gap-x-4">
      <div class="flex-1 overflow-hidden">
        <div class="mb-0.5 ml-1 truncate text-kl-sm text-primary">
          {{ props.taskPayload.title }}
        </div>
        <p class="ml-1 truncate text-kl-xs text-secondary">
          {{ props.taskPayload.description }}
        </p>
      </div>
      <div class="w-fit">
        <ArrowTopRightOnSquareIcon
          v-if="notificationCTA"
          class="size-4 shrink-0 cursor-pointer text-kl-icon hover:text-kl-green-300"
          @click="handleNotificationCTA" />
      </div>
    </div>
  </div>
</template>
