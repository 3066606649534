import { INSTANCE_MAP } from '@/constants/instance.constants';
import { getBrandQuery } from '@/services/queries/brand.query';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';

export const useInstance = (brandId: MaybeRef<string | undefined>, instanceId: MaybeRef<string | undefined>) => {
  const query = getBrandQuery(brandId);

  const brand = computed(() => query.result.value?.brand);

  const instance = computed(() => {
    if (!unref(instanceId)) return null;
    if (!brand.value) return null;
    if (!brand.value.instances) return null;
    const instances = Object.keys(brand.value.instances).map(
      key => brand.value?.instances?.[key as keyof typeof INSTANCE_MAP]
    );
    if (!instances) return undefined;

    return instances.find(el => el?.instanceId === unref(instanceId));
  });

  const instanceName = computed<string>(() => {
    if (!brand.value) return '';
    if (!brand.value.instances) return '';
    if (!instance.value) return '';

    const instanceObj = Object.keys(brand.value.instances).find(
      key => brand.value?.instances?.[key as keyof typeof INSTANCE_MAP]?.instanceId === unref(instanceId)
    );

    return INSTANCE_MAP[instanceObj as keyof typeof INSTANCE_MAP];
  });

  return {
    instanceName,
    instance,
    ...query,
  };
};
