import type { MODULES } from '@/constants/modules.constants';
import { MODULES_IDS } from '@/constants/modules.constants';
import { useUser } from '@/hooks/user/useUser';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';

export const usePermissionsInBrand = (brandId: MaybeRef<string>) => {
  const { user, isSuperAdmin } = useUser();

  const permissions = computed<{ [key in MODULES]?: boolean }>(() => {
    if (isSuperAdmin.value)
      return Object.values(MODULES_IDS).reduce<{ [key in MODULES]?: boolean }>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
    const brandPermissions = user.value?.permissions?.find(permission => permission.brand === unref(brandId));
    const reducedPermisions =
      brandPermissions?.moduleName.reduce<{ [key in MODULES]?: boolean }>((acc, moduleName) => {
        acc[moduleName as MODULES] = true;
        return acc;
      }, {}) || {};

    return reducedPermisions;
  });

  return permissions;
};
