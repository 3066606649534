<script setup lang="ts">
import { PlusCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { BaseTextInput, BaseToggle, Button, DialogContainer, MessageBox, Modal } from '@kleecks/ui-lib';
import { formatDate } from '@/utils/date.utils';
import type { IpAddressUser } from '@/types/ip-address-user.types';
import { ref } from 'vue';
import type { User } from '@/types/user.types';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';

interface ipAddressProps {
  user: User;
}
const props = defineProps<ipAddressProps>();
const ipAddressList = defineModel<IpAddressUser[]>('ipAddressList');
const showAddIpAddressModal = ref(false);
const newIpAddress = ref('');

const deleteAlertStore = useDeleteAlertStore();

const addIpAddress = () => {
  ipAddressList.value?.push({
    enabled: false,
    ip: newIpAddress.value,
  });
};

const closeDialogAndSave = () => {
  addIpAddress();
  showAddIpAddressModal.value = false;
};

const deleteIpAddress = async (index: number) => {
  deleteAlertStore.showDeleteAlert(
    `
    Are you sure you want to delete the IP Address?
    
  `,
    () => {
      if (ipAddressList.value) {
        ipAddressList.value.splice(index, 1);
      }
    }
  );
};
</script>
<template>
  <MessageBox
    v-if="!ipAddressList || ipAddressList?.length === 0"
    class="mb-4"
    variant="violet"
    message="No IP address associated" />
  <div
    v-for="(ipAddress, index) in ipAddressList"
    :key="ipAddress.lastAccess"
    class="grid grid-cols-12 items-center py-2.5">
    <BaseToggle
      v-model="ipAddress.enabled"
      size="sm" />
    <div class="col-span-5">
      <div class="text-kl-xs font-bold text-primary">{{ ipAddress.ip }}</div>
      <div class="text-kl-2xs text-secondary">
        Last Access {{ ipAddress.lastAccess ? formatDate(ipAddress.lastAccess) : 'Never' }}
      </div>
    </div>
    <div class="col-span-4">
      <div
        v-if="ipAddress.firstAccess"
        class="flex h-4 w-[90px] items-center justify-center rounded-full border border-kl-green-200 px-4 py-1 text-[9px] font-medium text-kl-green-300">
        First Access
      </div>
    </div>
    <TrashIcon
      class="size-5 cursor-pointer text-kl-icon hover:text-active"
      @click="deleteIpAddress(index)" />
  </div>
  <div
    class="group flex w-full cursor-pointer items-center justify-end gap-1 pt-6 text-kl-icon hover:text-active"
    @click="showAddIpAddressModal = true">
    <PlusCircleIcon class="flex size-6 bg-white" />
    <span class="text-xs text-kl-gray-400 group-hover:text-active">Add New IP Address</span>
  </div>
  <DialogContainer
    :is-open="showAddIpAddressModal"
    :on-close="() => (showAddIpAddressModal = false)"
    zIndexClass="z-[11]">
    <Modal
      :on-close="() => (showAddIpAddressModal = false)"
      :title="`Add IP Address to: ${props.user.nome} ${props.user.cognome}`"
      sub-title="Add a new IP Address to associate to user.">
      <div class="min-h-0 w-[40rem] flex-col">
        <BaseTextInput
          v-model="newIpAddress"
          label="IP Address"
          placeholder="Add Ip Address"
          type="text" />
        <div class="mt-11 flex items-center justify-end">
          <Button
            variant="primary"
            type="button"
            :disabled="!newIpAddress"
            @click="closeDialogAndSave">
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  </DialogContainer>
</template>
