<script setup lang="ts">
import EditTaskDialog from '@/components/dialogs/edit-task-dialog/EditTaskDialog.vue';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';
import SidePanel from '@/components/panels/side-panel/SidePanel.vue';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import NotFound from '@/pages/not-found/NotFound.vue';
import { useDeleteTask } from '@/services/queries/task.query';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import type { TaskAction } from '@/types/task.types';
import { ArrowUturnLeftIcon, BuildingStorefrontIcon } from '@heroicons/vue/24/outline';
import { StandardContainer } from '@kleecks/ui-lib';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useChatbotStore } from '@/stores/useChatbotStore';
import InstanceDashboardLeftPanel from './InstanceDashboardLeftPanel.vue';

const userStore = useUserStore();
const chatbotStore = useChatbotStore();

const isEditTaskDialogOpen = ref<boolean>(false);
const taskToEditId = ref<string | undefined>(undefined);
const deleteAlertStore = useDeleteAlertStore();
const { navigateToSandbox } = useSandboxNavigate();
const { notify } = useNotificationStore();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { instance, loading } = useInstance(brandId, instanceId);

const { instanceTasks } = useInstanceTasks(brandId, instanceId);
const { mutate: deleteTask } = useDeleteTask();
const { mutate: updateTask } = useUpdateTask();
const { getLabel } = useApplicationLabels();
const router = useRouter();

const closeEditTaskDialog = () => {
  isEditTaskDialogOpen.value = false;
};

const openEditTaskDialog = (taskId: string) => {
  taskToEditId.value = taskId;
  isEditTaskDialogOpen.value = true;
};

const handleTaskAction = async (taskId: string, action: TaskAction) => {
  const task = instanceTasks.value?.find(t => t.id === taskId);

  if (action === 'view-sandbox') {
    if (!task?.instance?.domains?.[0]?.domain) {
      throw new Error(`handleTaskAction: task ${taskId} has no domain`);
    }
    navigateToSandbox({
      userLogin: task?.assignedTo?.[0]?.login,
      url: `https://${task?.instance?.domains?.[0]?.domain}`,
      taskId,
    });
    return;
  }

  if (action === 'edit') {
    openEditTaskDialog(taskId);
    return;
  }

  if (action === 'approve') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.APPROVED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task approved',
      message: `The task ${task?.title} has been approved successfully`,
    });

    return;
  }

  if (action === 'reopen') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.DRAFT,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task reopened',
      message: `The task ${task?.title} has been reopened successfully`,
    });

    return;
  }

  if (action === 'reject') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.REJECTED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task rejected',
      message: `The task ${task?.title} has been rejected successfully`,
    });

    return;
  }

  if (action === 'switch') {
    userStore.setSelectedTaskId(taskId);
    notify({
      type: NOTIFICATION_TYPES.INFO,
      title: 'Working task changed',
      message: `Now you're working on task ${task?.title}`,
    });
    return;
  }

  if (action === 'delete') {
    deleteAlertStore.showDeleteAlert(`Are you sure you want to delete task ${task?.title}?`, () => {
      deleteTask({ taskId });
    });
    return;
  }
  throw new Error(`handleTaskAction.Not implemented ${taskId} ${action}`);
};
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('INSTANCE_DASHBOARD.help.text')!"
    modal-title="Instance Dashboard info" />
  <EditTaskDialog
    :brand-id="brandId"
    :is-open="isEditTaskDialogOpen"
    :task="instanceTasks.find(task => task.id === taskToEditId)!"
    @close="closeEditTaskDialog" />
  <transition
    enter-from-class="opacity-0"
    enter-active-class="transition duration-1000">
    <StandardContainer v-if="instance && instance.enabled">
      <div
        class="flex gap-x-8 py-8 transition-all duration-100"
        :class="{
          'container-height-chatbot': chatbotStore.isShowInPage,
          'container-height-no-chatbot delay-100': !chatbotStore.isShowInPage,
        }"
        data-testid="instance-dashboard">
        <div class="flex min-w-0 flex-1 flex-col self-stretch">
          <InstanceDashboardLeftPanel :on-task-action="handleTaskAction" />
        </div>

        <div class="min-h-0 w-[410px] self-stretch">
          <SidePanel />
        </div>
      </div>
    </StandardContainer>
  </transition>
  <div v-if="instance && !instance.enabled">
    <div
      v-if="!loading"
      class="flex h-[calc(100vh-7rem)] w-full flex-col bg-sec">
      <div class="mx-auto flex max-w-7xl flex-auto flex-col justify-center p-6">
        <p class="text-center text-base font-semibold leading-8 text-kl-violet-200">No Access</p>
        <h1 class="mt-4 text-center text-3xl font-medium tracking-tight text-kl-gray-600 sm:text-5xl">
          The instance is not enabled
        </h1>
        <p class="mt-6 text-center text-base leading-7 text-kl-gray-400">
          Please enable the instance or select another one
        </p>
        <div
          v-if="brandId"
          class="mt-10 flex cursor-pointer items-center justify-center gap-4"
          @click="router.push({ name: ROUTE_NAMES.BRAND })">
          <div
            class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
            <BuildingStorefrontIcon
              class="h-6 w-6 text-indigo-600"
              aria-hidden="true" />
          </div>
          <div class="flex-col">
            <h3 class="text-sm font-semibold leading-6 text-gray-900">Instances</h3>
            <p class="mt-0 text-sm leading-6 text-gray-600">{{ `Explore all instances for ${brandId}` }}</p>
          </div>
        </div>
        <div class="mt-10">
          <div
            class="flex cursor-pointer items-center justify-center space-x-2 text-sm font-semibold leading-7 text-kl-violet-200 hover:text-kl-green-300 active:text-kl-green-200"
            @click="
              () => {
                router.push('/');
              }
            ">
            <ArrowUturnLeftIcon class="size-4" />
            <span aria-hidden="true"> Back to home</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NotFound v-else-if="!loading && !instance" />
</template>
