import { updateInstanceMutation } from '@/services/queries/instance.query';

export const useUpdateInstance = () => {
  const mutation = updateInstanceMutation();

  const updateInstance = async (instanceId: string, baseUrl: string, notes: string) => {
    await mutation.mutate({
      instanceId,
      baseUrl,
      notes,
    });
  };

  return {
    ...mutation,
    updateInstance,
  };
};
