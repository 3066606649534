<script setup lang="ts">
import type { MODULES, ModuleCategory } from '@/constants/modules.constants';
import { MODULES_CATEGORIES, MODULES_CATEGORY, MODULES_IDS } from '@/constants/modules.constants';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { computed, ref, watch } from 'vue';
import type { Brand } from '@/types/brand.types';
import BrandModuleCategoryPermissionsRow from './BrandModuleCategoryPermissionsRow.vue';

interface Props {
  brand: Brand;
  permissions: MODULES[];
  toggleAllPackagePermissions: (category: ModuleCategory, brandname: string, value: boolean) => void;
  toggleEnableAllPermissions: (newValue: boolean, brandname: string) => void;
  togglePermission: (module: MODULES, brandname: string) => void;
}

const props = defineProps<Props>();
const isExpanded = ref(false);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const moduleEnabledCount = computed(() => props.permissions.length || 0);
const selectAllModules = ref(props.permissions.length === Object.keys(MODULES_IDS).length);

const moduleCategoriesChecked = computed<{ [key in ModuleCategory]: boolean }>(() =>
  Object.values(MODULES_CATEGORY).reduce((acc, category) => {
    const checked = Object.entries(MODULES_CATEGORIES)
      .filter(([, value]) => value.includes(category))
      .map(([key]) => key)
      .some(key => props.permissions.includes(key as MODULES));
    acc[category] = checked;
    return acc;
  }, {} as { [key in ModuleCategory]: boolean })
);

const setSelectAllModules = () => {
  selectAllModules.value = !selectAllModules.value;
  props.toggleEnableAllPermissions(selectAllModules.value, props.brand.name);
};

watch(
  () => props.permissions,
  () => {
    selectAllModules.value = props.permissions.length === Object.keys(MODULES_IDS).length;
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="flex w-full cursor-pointer items-center gap-2 py-2"
    @click="toggleExpand">
    <div class="flex flex-1 items-center gap-2 text-sm text-primary">
      <span> {{ props.brand.label }}</span>
    </div>
    <div
      class="mr-2 rounded-full px-4 py-[.2rem] text-center text-2xs"
      :class="{
        'bg-kl-blue-100/25': !moduleEnabledCount,
        'bg-kl-blue-100/80 text-white': moduleEnabledCount,
      }">
      {{ moduleEnabledCount || 0 }}
    </div>
    <div
      tabindex="0"
      :class="[
        'h-4 w-4 cursor-pointer text-secondary transition-all duration-300 hover:text-active',
        {
          'rotate-180': isExpanded,
        },
      ]"
      @keydown.enter="toggleExpand"
      @click="
        e => {
          e.stopPropagation();
          toggleExpand();
        }
      ">
      <ChevronDownIcon />
    </div>
  </div>
  <div
    v-if="isExpanded"
    class="flex flex-col divide-y divide-gray-200 border-t border-kl-gray-100 bg-kl-gray-100 px-2">
    <div
      class="cursor-pointer py-3 text-xs text-secondary underline"
      @click="setSelectAllModules()">
      {{ selectAllModules ? 'Deselect' : 'Select' }} All modules
    </div>
    <div
      v-for="category in Object.values(MODULES_CATEGORY)"
      :key="category"
      class="py-2">
      <BrandModuleCategoryPermissionsRow
        :checked="moduleCategoriesChecked[category]"
        :category="category"
        :count="props.permissions.filter(p => MODULES_CATEGORIES?.[p as MODULES]?.includes(category)).length"
        :permissions="props.permissions"
        :toggle-permission="m => togglePermission(m, props.brand.name)"
        :toggle-all-package-permissions="
          () => toggleAllPackagePermissions(category, props.brand.name, !moduleCategoriesChecked[category])
        "
        :select-all-package-permissions="() => toggleAllPackagePermissions(category, props.brand.name, true)" />
    </div>
    <!-- <div
              v-for="mod in (Object.keys(sortModulesName) as ExternalModules[])"
              :key="mod"
              class="flex w-full cursor-pointer items-center py-2">
              <span class="flex-1 text-xs"> {{ (MODULES_NAMES as any)[mod] }}</span>
              <Toggle
                :on-change="newValue => togglePermission(mod, props.brandname)"
                :checked="permissions?.find(p => p.brand === props.brandname)?.moduleName?.includes(mod) || false" />
            </div> -->
  </div>
</template>
