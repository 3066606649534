<script setup lang="ts">
import ActivitiesList from '@/components/activities/activities-list/ActivitiesList.vue';
import EditTaskDialog from '@/components/dialogs/edit-task-dialog/EditTaskDialog.vue';
import NotificationList from '@/components/notifications/NotificationList.vue';
import TasksCounterSection from '@/components/tasks-counter-section/TasksCounterSection.vue';
import TasksList from '@/components/tasks/tasks-list/TasksList.vue';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import { useQueryParam } from '@/hooks/common/useQueryParam';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useNotifications } from '@/hooks/notifications/useNotifications';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useUpdateTask } from '@/hooks/tasks/useUpdateTask';
import { useUser } from '@/hooks/user/useUser';
import { useDeleteTask } from '@/services/queries/task.query';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import type { TaskAction } from '@/types/task.types';
import { BaseToggle, Tabs } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

type TabIds = 'tab-notifications' | 'tab-activities' | 'tab-tasks';

const userStore = useUserStore();
const panel = useQueryParam('panel');
const { notifications, totalUnviewed } = useNotifications();
const router = useRouter();

const isEditTaskDialogOpen = ref<boolean>(false);
const isInfoPanelExpanded = ref<boolean>(false);
const taskToEditId = ref<string | undefined>(undefined);
const currentTabId = ref<TabIds | undefined>('tab-tasks');
const deleteAlertStore = useDeleteAlertStore();
const { navigateToSandbox } = useSandboxNavigate();
const { notify } = useNotificationStore();
const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { instance } = useInstance(brandId, instanceId);
const myTasksOnly = ref<boolean>(false);
const { instanceTasks } = useInstanceTasks(brandId, instanceId);
const { user } = useUser();

const isAllTasksPanelShown = computed(() => panel.value === 'tasks');
const tasksToShow = computed(() => {
  if (!myTasksOnly.value) {
    return instanceTasks.value;
  }
  return instanceTasks.value?.filter(task => task.assignedTo?.[0]?.id === user.value?.id);
});
const { mutate: deleteTask } = useDeleteTask();
const instanceActivitiesCount = computed<number>(
  () =>
    instanceTasks.value?.reduce<number>((acc, task) => acc + (task.activitiesObject?.countNotMilestones || 0), 0) ?? 0
);
const { mutate: updateTask } = useUpdateTask();

const tabs = computed<
  {
    id: TabIds;
    name: string;
    count?: number;
  }[]
>(() => [
  {
    id: 'tab-tasks',
    name: 'All Tasks',
    count: instance.value?.taskAggregatedCount?.countByAll,
  },
  {
    id: 'tab-activities',
    name: 'Activities',
    count: instanceActivitiesCount.value,
  },
  {
    id: 'tab-notifications',
    name: 'Notifications',
    count: totalUnviewed.value,
  },
]);

const handleTabChange = (tabId: string) => {
  isInfoPanelExpanded.value = false;
  if (currentTabId.value === tabId) {
    currentTabId.value = undefined;
    return;
  }
  currentTabId.value = tabId as TabIds;
};

const closeEditTaskDialog = () => {
  isEditTaskDialogOpen.value = false;
};

const openEditTaskDialog = (taskId: string) => {
  taskToEditId.value = taskId;
  isEditTaskDialogOpen.value = true;
};

const handleTaskAction = async (taskId: string, action: TaskAction) => {
  const task = instanceTasks.value?.find(t => t.id === taskId);

  if (action === 'view-sandbox') {
    if (!task?.instance?.domains?.[0]?.domain) {
      throw new Error(`handleTaskAction: task ${taskId} has no domain`);
    }
    navigateToSandbox({
      userLogin: task?.assignedTo?.[0]?.login,
      url: `https://${task?.instance?.domains?.[0]?.domain}`,
      taskId,
    });
    return;
  }

  if (action === 'edit') {
    openEditTaskDialog(taskId);
    return;
  }

  if (action === 'approve') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.APPROVED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task approved',
      message: `The task ${task?.title} has been approved successfully`,
    });

    return;
  }

  if (action === 'reopen') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.DRAFT,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task reopened',
      message: `The task ${task?.title} has been reopened successfully`,
    });

    return;
  }

  if (action === 'reject') {
    await updateTask({
      task: {
        id: taskId,
        status: TASK_STATUS.REJECTED,
      },
    });
    notify({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: 'Task rejected',
      message: `The task ${task?.title} has been rejected successfully`,
    });

    return;
  }

  if (action === 'switch') {
    userStore.setSelectedTaskId(taskId);
    notify({
      type: NOTIFICATION_TYPES.INFO,
      title: 'Working task changed',
      message: `Now you're working on task ${task?.title}`,
    });
    return;
  }

  if (action === 'delete') {
    deleteAlertStore.showDeleteAlert(`Are you sure you want to delete the task "${task?.title}" ?`, () => {
      deleteTask({ taskId });
    });
    return;
  }
  throw new Error(`handleTaskAction.Not implemented ${taskId} ${action}`);
};

const navigateToAllTasks = () => {
  router.push({ name: ROUTE_NAMES.TASKS_MANAGEMENT });
};
</script>

<template>
  <EditTaskDialog
    :brand-id="brandId"
    :is-open="isEditTaskDialogOpen"
    :task="instanceTasks.find(task => task.id === taskToEditId)!"
    @close="closeEditTaskDialog" />
  <div class="flex h-full w-full flex-col items-start">
    <span class="mb-2 text-sm font-medium text-primary">My Tasks</span>
    <TasksCounterSection :panel="panel" />
    <div
      class="mt-4 flex w-full flex-col overflow-hidden rounded-lg border-kl-gray-200 bg-white shadow-custom"
      :class="{
        'min-h-0 flex-1 grow': currentTabId,
      }">
      <Tabs
        size="md"
        :tabs="tabs"
        tab-header-size="w-full"
        tab-size-class="flex-1 py-3"
        :on-change="handleTabChange"
        :current-tab-id="currentTabId" />
      <div
        v-if="currentTabId && !isInfoPanelExpanded"
        class="mb-4 flex-1 overflow-y-auto px-2">
        <transition name="fadein">
          <div
            v-show="currentTabId == 'tab-activities'"
            class="mt-4 flex flex-col gap-4">
            <template v-for="task in instanceTasks">
              <ActivitiesList
                v-if="task.activitiesObject?.count! > 0"
                :key="task.id"
                :show-task-title="true"
                class="px-2"
                :task="task" />
            </template>
          </div>
        </transition>

        <transition name="fadein">
          <TasksList
            v-if="currentTabId == 'tab-tasks'"
            :tasks="tasksToShow"
            :is-publish-enabled="false"
            :is-schedule-enabled="false"
            :condensed="true"
            :is-draggable="false"
            :on-task-action="handleTaskAction" />
        </transition>
        <transition name="fadein">
          <NotificationList
            v-if="currentTabId == 'tab-notifications'"
            class="p-2"
            :notifications="notifications" />
        </transition>
      </div>
      <div
        v-if="currentTabId === 'tab-tasks'"
        class="flex items-center border-t border-kl-gray-200 p-4">
        <div class="flex-1">
          <BaseToggle
            v-model="myTasksOnly"
            label="My Tasks Only"
            size="sm" />
        </div>

        <span
          v-if="!isAllTasksPanelShown"
          class="cursor-pointer text-xs text-secondary underline hover:text-active"
          @click="navigateToAllTasks"
          >Show All Tasks</span
        >
      </div>
    </div>

    <!-- <div
      class="mt-8 h-auto w-full cursor-pointer rounded-xl border-kl-gray-200 bg-white p-4 shadow-custom transition-all duration-300 ease-in-out"
      @click="isInfoPanelExpanded = !isInfoPanelExpanded">
      <p class="block text-primary">Introducing Kleecks Dashboard</p>
      <transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-300">
        <div v-if="isInfoPanelExpanded">
          <p class="block whitespace-normal text-xs text-secondary">
            {{ getLabel('SIDE_PANEL.introduction.html') }}
          </p>
        </div>
      </transition>
    </div> -->
  </div>
</template>
