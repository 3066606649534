<script setup lang="ts">
import { TaskStatusChip, Button } from '@kleecks/ui-lib';
import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { TASK_PRIORITY_COLOR, TASK_PRIORITY_LABEL } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useCurrentInstanceTask } from '@/hooks/tasks/useCurrentInstanceTask';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';

interface AiActivityCardProps {
  taskId: string;
}

const props = defineProps<AiActivityCardProps>();
const { task } = useCurrentInstanceTask(props.taskId);

const instanceId = useRouteParam('instanceId');
const userStore = useUserStore();
const { notify } = useNotificationStore();

const workOnTask = () => {
  userStore.setSelectedTaskId(props.taskId);
  notify({
    type: NOTIFICATION_TYPES.INFO,
    title: 'Working task changed',
    message: `Now you're working on task ${task.value?.title}`,
  });
};
</script>

<template>
  <div
    v-if="task"
    class="rounded-md border border-gray-200 bg-white p-2 py-4 shadow-custom">
    <div class="flex items-center">
      <div class="flex-1">
        <div class="mb-1 flex items-center gap-2">
          <TaskStatusChip :task="task" />
          <TopicChip :topic="task.topic" />
        </div>
        <div class="text-xs font-medium text-[#1A202C]">{{ task.title }}</div>
        <div class="text-2xs font-light text-[#6B7280]">{{ task.description }}</div>
      </div>
      <div class="inline-flex items-center justify-end gap-x-1">
        <span :class="[TASK_PRIORITY_COLOR[task.priority]]">
          {{ TASK_PRIORITY_LABEL[task.priority] }}
        </span>
      </div>
    </div>
    <Button
      v-if="task.instance?.instanceId === instanceId"
      size="xs"
      class="mt-2"
      @click="workOnTask">
      Work on this Task
    </Button>
  </div>
</template>
