import { getUserQuery } from '@/services/queries/user.query';
import { computed } from 'vue';

export const useUser = () => {
  const query = getUserQuery();

  const user = computed(() => query.result.value?.user);
  const isSuperAdmin = computed(() => user.value?.team?.id === 'kleecks' && user.value?.admin!);

  return {
    ...query,
    user,
    isSuperAdmin,
  };
};
