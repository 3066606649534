<script setup lang="ts">
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import type { ExternalModules, MODULES } from '@/constants/modules.constants';
import { MODULES_ICON, MODULES_LAZY_MAP, MODULES_NAMES } from '@/constants/modules.constants';
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { useModulesDictionary } from '@/hooks/modules-dictionary/useModulesDictionary';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { ArrowDownOnSquareIcon, ArrowTopRightOnSquareIcon, WalletIcon } from '@heroicons/vue/24/outline';
import { Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { ref } from 'vue';
import DictionaryForm from './DictionaryForm.vue';
import { MODULES_COMMON_KEYS_TO_EDIT, MODULES_FIELDS_MAPPING } from './modules-dictionary.constants';

const language = ref('en');
const { dictionary, updateDictionary } = useModulesDictionary(language);

const notificationStore = useNotificationStore();
const selectedModuleId = ref<MODULES>();
const showPreview = ref(false);
const keyIdForPreview = ref();
const moduleToEdit = ref();

const handleSelectModule = (moduleId: MODULES) => {
  if (selectedModuleId.value === moduleId) {
    selectedModuleId.value = undefined;
    return;
  }
  moduleToEdit.value = JSON.parse(JSON.stringify(dictionary.value?.[moduleId] || {}));
  const keysToEdit = [...MODULES_COMMON_KEYS_TO_EDIT, ...(MODULES_FIELDS_MAPPING[moduleId] || [])];

  keysToEdit?.forEach(x => {
    if (!moduleToEdit.value[x.id]) {
      moduleToEdit.value[x.id] = {};
    }
    x.fields.forEach(f => {
      if (typeof f !== 'string') {
        f.fields.forEach(subF => {
          if (!moduleToEdit.value[x.id][f.id]) {
            moduleToEdit.value[x.id][f.id] = {};
          }
          if (!moduleToEdit.value[x.id][f.id][subF]) {
            moduleToEdit.value[x.id][f.id][subF] = '';
          }
        });
      }
    });
  });
  selectedModuleId.value = moduleId;
};

const saveModuleDictionary = async (moduleId: MODULES) => {
  await updateDictionary({
    ...(dictionary.value || {}),
    [moduleId]: moduleToEdit.value,
  });

  notificationStore.notify({
    type: NOTIFICATION_TYPES.SUCCESS,
    title: 'Dictionary updated successfully',
  });
};

const downloadDictionaryJson = () => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(dictionary.value))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', 'dictionary.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const handleOpenKeyPreview = (keyId: string) => {
  keyIdForPreview.value = keyId;
  showPreview.value = true;
};

const openModulePreview = () => {
  showPreview.value = true;
  keyIdForPreview.value = undefined;
};
</script>

<template>
  <DialogContainer
    v-if="showPreview"
    :on-close="() => (showPreview = false)"
    :is-open="!!showPreview">
    <Modal
      title="Module preview"
      :on-close="() => (showPreview = false)"
      class="w-[1200px]">
      <div class="relative flex min-h-56 flex-col">
        <component
          :is="MODULES_LAZY_MAP[selectedModuleId as ExternalModules]"
          :highlightedKeysId="keyIdForPreview"
          :isReadOnly="false"
          :modules-dictionary="{
          ...dictionary,
          [selectedModuleId as MODULES]: moduleToEdit,
        }" />
      </div>
    </Modal>
  </DialogContainer>
  <div class="flex min-h-0 flex-1 flex-col pt-8">
    <DashboardHeader
      title="Modules dictionary"
      sub-title="Review and edit the labels of the modules" />

    <div class="w-96 pb-4">
      <Combobox
        v-model="language"
        label="Language"
        class="bg-white"
        :items="[
          { label: 'English', value: 'en' },
          { label: 'Spanish', value: 'es' },
          { label: 'Italian', value: 'it' },
        ]" />
    </div>
    <div class="flex min-h-0 flex-1 flex-col gap-8 overflow-visible lg:flex-row lg:items-stretch">
      <div
        class="w-full flex-col self-stretch rounded-md border-sec bg-white shadow-custom lg:w-96"
        :class="{
          'hidden lg:flex': selectedModuleId,
          flex: !selectedModuleId,
        }">
        <div class="flex w-full items-center gap-2 border-b border-kl-gray-200 p-4">
          <WalletIcon class="size-6 text-kl-icon" />
          <span class="flex-1 font-medium text-primary">Modules</span>
          <div
            class="flex cursor-pointer items-center gap-2 text-primary underline hover:text-active"
            @click="downloadDictionaryJson">
            Download
            <ArrowDownOnSquareIcon class="size-5" />
          </div>
        </div>
        <div class="mb-1 flex min-h-0 grow flex-col divide-y divide-kl-gray-200 overflow-y-auto">
          <div
            v-for="moduleId in (Object.keys(MODULES_FIELDS_MAPPING).sort((a,b) => MODULES_NAMES[a].localeCompare(MODULES_NAMES[b])) as MODULES[])"
            :key="moduleId"
            class="flex cursor-pointer items-center gap-4 px-4 py-2 text-primary hover:bg-kl-gray-100"
            :class="{ 'bg-kl-gray-200 hover:bg-kl-gray-200': selectedModuleId === moduleId }"
            @click="handleSelectModule(moduleId)">
            <img
              v-if="typeof MODULES_ICON[moduleId] === 'string'"
              :src="MODULES_ICON[moduleId] as string"
              class="size-7 p-[.15rem]" />
            <component
              :is="MODULES_ICON[moduleId]"
              v-else
              class="size-7 p-[.15rem]" />
            {{ MODULES_NAMES[moduleId] }}
          </div>
        </div>
      </div>

      <div
        v-if="selectedModuleId"
        class="flex min-h-0 flex-1 flex-col self-stretch rounded-md border-sec bg-white pb-2 shadow-custom">
        <div class="flex w-full items-center gap-2 border-b border-kl-gray-200 p-4">
          <img
            v-if="typeof MODULES_ICON[selectedModuleId] === 'string'"
            :src="MODULES_ICON[selectedModuleId] as string"
            class="size-7 p-[.15rem]" />
          <component
            :is="MODULES_ICON[selectedModuleId]"
            v-else
            class="size-7 p-[.15rem]" />
          {{ MODULES_NAMES[selectedModuleId] }}

          <div
            class="flex flex-1 cursor-pointer justify-end gap-2 text-primary underline hover:text-active"
            @click="openModulePreview">
            Preview <ArrowTopRightOnSquareIcon class="size-5" />
          </div>
        </div>
        <div class="flex min-h-0 flex-1 flex-col p-4">
          <DictionaryForm
            v-if="MODULES_FIELDS_MAPPING[selectedModuleId]"
            v-model="moduleToEdit"
            :open-key-preview="handleOpenKeyPreview"
            :on-save="data => saveModuleDictionary(selectedModuleId!)"
            :on-close="() => (selectedModuleId = undefined)"
            :common-keys-to-edit="MODULES_COMMON_KEYS_TO_EDIT"
            :keys-to-edit="MODULES_FIELDS_MAPPING[selectedModuleId]!" />

          <div v-else>No schema configured, please contact the support.</div>
        </div>
      </div>
    </div>
  </div>
</template>
