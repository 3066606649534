<script setup lang="ts">
import type { BreadcrumbPage } from '@/types/breadcrumb';
import { ChevronDownIcon, ChevronRightIcon, HomeIcon } from '@heroicons/vue/24/outline';
import { DropdownWithSearch } from '@kleecks/ui-lib';
import { useRouter } from 'vue-router';

interface BreadcrumbProps {
  pages?: BreadcrumbPage[];
}

const props = defineProps<BreadcrumbProps>();
const router = useRouter();

const handleSelectOption = (value: { routeName: string; routeParams: { [key: string]: string } }) => {
  router.push({
    name: value.routeName,
    params: value.routeParams,
  });
};
</script>

<template>
  <nav
    class="flex items-center"
    aria-label="Breadcrumb">
    <ol
      role="list"
      class="flex flex-1 items-center space-x-2">
      <li>
        <div>
          <router-link
            to="/"
            class="text-kl-icon hover:text-active">
            <HomeIcon
              class="h-5 w-5 shrink-0"
              aria-hidden="true" />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li
        v-for="page in props.pages"
        :key="page.name">
        <div class="flex items-center">
          <ChevronRightIcon
            class="size-4 shrink-0 text-kl-gray-300"
            aria-hidden="true" />
          <router-link
            :to="page.href"
            class="ml-2 text-xs text-primary hover:underline"
            :aria-current="page.current ? 'page' : undefined"
            @keydown.enter="() => router.push(page.href)">
            {{ page.name }}
          </router-link>

          <div
            v-if="page.dropdown?.options?.length"
            class="ml-2">
            <DropdownWithSearch
              :on-click-item="handleSelectOption"
              :hide-filter="!page.dropdown.showFilter"
              :render-fn="page.dropdown.renderFn as any"
              :items="page.dropdown.options">
              <ChevronDownIcon class="size-3 text-kl-icon" />
            </DropdownWithSearch>
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>
