import { useLazyQuery } from '@vue/apollo-composable';
import { GetLoginPin, Login } from '@/services/graphql/authentication.graphql';

export type GetPinResult = {
  login: { pin: number };
};

export type GetPinArguments = {
  username: string;
  password: string;
};

export type LoginResult = {
  login: { jwt: string };
};

export type LoginArguments = {
  username: string;
  password: string;
  pin: number;
};

export const useGetPin = () => {
  const query = useLazyQuery<GetPinResult, GetPinArguments>(GetLoginPin);
  return query;
};

export const useLogin = () => {
  const query = useLazyQuery<LoginResult, LoginArguments>(Login);
  return query;
};
