import { getAllUsersQuery } from '@/services/queries/user.query';
import { computed } from 'vue';

export const useUsers = () => {
  const query = getAllUsersQuery();

  const users = computed(
    () => query.result.value?.users?.toSorted((a, b) => (a.nome + b.nome)?.localeCompare(b.nome + b.cognome)) ?? []
  );

  return {
    ...query,
    users,
  };
};
