import type { MaybeRef } from 'vue';
import { ref, unref } from 'vue';
import { ValidationError, type Schema } from 'yup';

export const useSchemaValidation = (yupSchema: MaybeRef<Schema>) => {
  const errors = ref<{ [key: string]: string }>({});
  const validateSchema = async (values: { [key: string]: any }, schema?: MaybeRef<Schema>) => {
    try {
      await unref(schema ?? yupSchema).validate(values, { abortEarly: false });
      errors.value = {};
      return true;
    } catch (err) {
      if (err instanceof ValidationError) {
        errors.value = err.inner.reduce<{
          [key: string]: string;
        }>((acc, error) => {
          if (error.path) acc[error.path] = error.message;
          return acc;
        }, {});
      }
    }
    return false;
  };

  return { errors, validateSchema };
};
