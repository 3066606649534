<script setup lang="ts">
import { type Component, type FunctionalComponent, ref } from 'vue';
import { WithTooltip } from '@kleecks/ui-lib';
import { FunnelIcon, ArrowTopRightOnSquareIcon, ArrowsUpDownIcon, PlusIcon } from '@heroicons/vue/24/outline';

interface TasksContainerBoxLayoutProps {
  headerVariant: 'gray' | 'pink' | 'violet';
  headerTitle: string;
  headerIcon: FunctionalComponent | Component | string;
  countOfFilters?: {
    order: number;
    general: number;
  };
  disabled?: boolean;
  websiteVisitHref?: string;
  onAddNewTask?: () => void;
}

const props = withDefaults(defineProps<TasksContainerBoxLayoutProps>(), {
  disabled: false,
  countOfFilters: () => ({
    order: 0,
    general: 0,
  }),
  currentFilterMenuType: undefined,
  websiteVisitHref: undefined,
  onAddNewTask: undefined,
});

const filtersMenuIsOpen = ref(false);
const currentFiltersMenuType = ref<'order' | 'general'>();

const openFiltersMenu = (type: 'order' | 'general') => {
  if (filtersMenuIsOpen.value && type === currentFiltersMenuType.value) return;
  filtersMenuIsOpen.value = true;
  currentFiltersMenuType.value = type;
};

const closeFiltersMenu = () => {
  if (!filtersMenuIsOpen.value) return;
  filtersMenuIsOpen.value = false;
  currentFiltersMenuType.value = undefined;
};
</script>

<template>
  <div
    v-bind="$attrs"
    class="relative flex flex-col self-stretch overflow-hidden rounded-md border-sec bg-white pb-4 shadow-custom">
    <div
      v-if="props.disabled"
      class="absolute z-[4] h-full w-full rounded-md bg-white opacity-50"></div>
    <!--Header-->
    <div
      :class="{
        'bg-kl-gray-400': props.headerVariant === 'gray',
        'bg-kl-pink-100': props.headerVariant === 'pink',
        'bg-kl-violet-200': props.headerVariant === 'violet',
      }"
      class="absolute z-[3] flex h-12 w-full items-center rounded-t-md p-4">
      <component
        :is="props.headerIcon"
        class="size-5 shrink-0 text-white" />
      <span class="flex-1 px-3 font-medium text-white">{{ props.headerTitle }}</span>
      <!--Header filters management-->
      <div
        :class="[props.websiteVisitHref || props.onAddNewTask ? 'pr-3' : '']"
        class="inline-flex items-center gap-x-3">
        <span class="relative">
          <FunnelIcon
            :class="[currentFiltersMenuType === 'general' ? 'text-kl-green-200' : 'text-white hover:text-kl-green-200']"
            class="size-5 shrink-0 cursor-pointer transition-all duration-300 ease-in-out"
            @click="openFiltersMenu('general')" />
          <span
            v-show="props.countOfFilters.general > 0"
            class="absolute -bottom-2 -right-2 z-[2] flex h-4 w-4 flex-col items-center justify-center rounded-full border-none bg-kl-green-300 p-0 text-[8px] text-white">
            {{ props.countOfFilters.general }}
          </span>
        </span>
        <span class="relative">
          <ArrowsUpDownIcon
            :class="[currentFiltersMenuType === 'order' ? 'text-kl-green-200' : 'text-white hover:text-kl-green-200']"
            class="size-5 shrink-0 cursor-pointer transition-all duration-300 ease-in-out"
            @click="openFiltersMenu('order')" />
          <span
            v-show="props.countOfFilters.order > 0"
            class="absolute -bottom-2 -right-3 z-[2] flex h-4 w-4 flex-col items-center justify-center rounded-full border-none bg-kl-green-300 p-0 text-[8px] text-white">
            {{ props.countOfFilters.order }}
          </span>
        </span>
      </div>
      <!--Header actions-->
      <div
        v-if="props.websiteVisitHref || props.onAddNewTask"
        class="inline-flex items-center gap-x-3 border-l border-l-white pl-3">
        <WithTooltip
          v-if="props.websiteVisitHref"
          label="Visit website">
          <a
            target="_blank"
            :href="props.websiteVisitHref">
            <ArrowTopRightOnSquareIcon class="size-5 cursor-pointer text-white hover:text-active" />
          </a>
        </WithTooltip>
        <WithTooltip
          v-if="props.onAddNewTask"
          label="Add new task">
          <PlusIcon
            class="size-5 cursor-pointer text-white hover:text-active"
            @click="props.onAddNewTask()" />
        </WithTooltip>
      </div>
    </div>
    <!--Filters Menu-->
    <transition
      enter-active-class="transition-all ease-in-out duration-500"
      enter-from-class="opacity-0 -translate-y-full"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all ease-in-out duration-500"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-full">
      <div
        v-if="filtersMenuIsOpen"
        class="absolute top-12 z-[2] w-full">
        <slot
          name="filters-menu-content"
          :filtersMenuIsOpen="filtersMenuIsOpen"
          :currentFiltersMenuType="currentFiltersMenuType"
          :openFiltersMenu="openFiltersMenu"
          :closeFiltersMenu="closeFiltersMenu">
        </slot>
      </div>
    </transition>
    <!--Body-->
    <div class="mt-12 min-h-0 grow overflow-y-auto overflow-x-hidden">
      <slot name="body-content"> </slot>
    </div>
  </div>
</template>
