import {
  ApplicationDictionaryQuery,
  CreateOrUpdateApplicationDictionary,
} from '@/services/graphql/application-dictionary.graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import type { MaybeRef } from 'vue';
import { unref } from 'vue';

export const applicationDictionaryQuery = (lang: MaybeRef<string>) =>
  useQuery<
    {
      applicationDictionary: any;
    },
    {
      lang: string;
    }
  >(
    ApplicationDictionaryQuery,
    () => ({
      lang: unref(lang),
    }),
    () => ({
      enabled: !!unref(lang),
    })
  );

export const createOrUpdateApplicationDictionaryMutation = () =>
  useMutation<
    {
      createOrUpdateApplicationDictionary: any;
    },
    {
      lang: string;
      data: any;
    }
  >(CreateOrUpdateApplicationDictionary, {
    update: (cache, { data }, { variables }) => {
      const newData = data?.createOrUpdateApplicationDictionary;

      if (newData) {
        cache.writeQuery({
          query: ApplicationDictionaryQuery,
          data: {
            modulesDictionary: newData,
          },
          variables: {
            lang: variables?.lang,
          },
        });
      }
    },
  });
