import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useChatbotStore = defineStore('chatbot', () => {
  const isOpen = ref(false);
  const isShowInPage = ref(false);
  const countChatbotMessages = ref(0);
  const enableChatbot = ref(false);

  const openChatbot = () => {
    isOpen.value = true;
    isShowInPage.value = true;
  };

  const closeChatbot = () => {
    isOpen.value = false;
    isShowInPage.value = false;
  };

  const showInPage = () => {
    isShowInPage.value = true;
  };

  const setCountChatbotMessages = (num: number) => {
    countChatbotMessages.value = num;
  };

  const resetChatbot = () => {
    isOpen.value = false;
    isShowInPage.value = true;
    countChatbotMessages.value = 0;
  };

  const setEnableChatbot = (val: boolean) => {
    enableChatbot.value = val;
  };

  return {
    isOpen,
    isShowInPage,
    countChatbotMessages,
    enableChatbot,
    openChatbot,
    closeChatbot,
    showInPage,
    setCountChatbotMessages,
    resetChatbot,
    setEnableChatbot,
  };
});
