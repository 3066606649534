<script setup lang="ts">
import ModuleApprovedBanner from '@/components/modules/module-approved-banner/ModuleApprovedBanner.vue';
import ModuleCreatingBanner from '@/components/modules/module-creating-banner/ModuleCreatingBanner.vue';
import ModuleInReviewBanner from '@/components/modules/module-in-review-banner/ModuleInReviewBanner.vue';
import ModulePublishedBanner from '@/components/modules/module-published-banner/ModulePublishedBanner.vue';
import { WIDGET_SIZE_CONFIGURATION, type WidgetSizeConfiguration } from '@/constants/modules.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import type { TaskStatus } from '@/types/task.types';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { DropdownMenu, TaskStatusChip } from '@kleecks/ui-lib';
import type { MenuOption } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';

interface DashboardWidgetContainerProps {
  onRemove?: () => void;
  onEdit?: () => void;
  navigate?: () => void;
  name: string;
  description?: string;
  canEdit?: boolean;
  moduleId: string;
  hasPermission: boolean;
  widthConfiguration?: WidgetSizeConfiguration;
  taskStatus?: TaskStatus;
}

const props = defineProps<DashboardWidgetContainerProps>();

// Generate a random id to be used in the slot
const afterHeaderId = ref<string>(
  `${props.moduleId}_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`
);

const menuOptions = computed(() => {
  const res: MenuOption[] = [];

  if (!props.canEdit) return [];
  if (props.onEdit) {
    res.push({
      label: 'Edit',
      operation: 'edit',
      icon: PencilIcon,
    });
  }
  if (props.onRemove)
    res.push({
      label: 'Delete',
      operation: 'delete',
      icon: TrashIcon,
    });
  return res;
});

const handleMenuAction = (action: string) => {
  if (action === 'edit') {
    props.onEdit?.();
  }
  if (action === 'delete') {
    props.onRemove?.();
  }
};

const showTaskStatus = computed(() =>
  props.taskStatus
    ? [TASK_STATUS.IN_REVIEW, TASK_STATUS.PUBLISHED, TASK_STATUS.APPROVED].includes(props.taskStatus as any)
    : false
);
</script>

<template>
  <div
    :class="{
      'md:col-span-2 lg:col-span-4 2xl:col-span-8': props.widthConfiguration === WIDGET_SIZE_CONFIGURATION.FULL_SCREEN,
      'col-span-2': !props.widthConfiguration || props.widthConfiguration === WIDGET_SIZE_CONFIGURATION.TWO_COLUMNS,
      'col-span-2 lg:col-span-4': props.widthConfiguration === WIDGET_SIZE_CONFIGURATION.FOUR_COLUMNS,
    }">
    <div
      class="w-full rounded-lg border border-gray-secondary bg-white p-6 hover:shadow-md"
      data-testid="dashboard-widget-container">
      <div
        class="flex w-full items-start"
        :class="{
          'handle cursor-grab': props.canEdit,
        }">
        <div class="flex flex-1 flex-col gap-1">
          <div class="flex items-center gap-2">
            <TaskStatusChip
              v-if="showTaskStatus && hasPermission"
              :task="{
              status: props.taskStatus as TaskStatus,
            }" />
            <p
              class="flex-1 font-medium text-primary"
              data-testid="name">
              {{ props.name }}
            </p>
          </div>
          <p
            v-if="props.description"
            class="max-w-[90%] text-sm text-kl-gray-400">
            {{ props.description }}
          </p>
        </div>
        <div class="flex items-center">
          <div :id="afterHeaderId"></div>
          <DropdownMenu
            v-if="menuOptions.length > 0"
            data-testid="dashboard-widget-container-menu"
            :menu-options="menuOptions"
            :on-send-option-operation="(o: string) => handleMenuAction(o as string)" />
        </div>
      </div>
      <div
        v-if="!hasPermission"
        class="h-56 w-full pt-4 text-center">
        <div
          class="flex h-full items-center justify-center rounded-md border-2 border-dashed border-gray-200 bg-neutral-50">
          <span class="text-sm text-gray-500">
            You don't have permissions to view this module. Ask to your administrator
          </span>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-2"
        data-testid="widget-body">
        <ModuleCreatingBanner
          v-if="props.taskStatus === TASK_STATUS.CREATING"
          class="mt-2" />
        <ModuleInReviewBanner
          v-if="props.taskStatus === TASK_STATUS.IN_REVIEW"
          class="mt-2" />
        <ModuleApprovedBanner
          v-if="props.taskStatus === TASK_STATUS.APPROVED"
          class="mt-2" />
        <ModulePublishedBanner
          v-if="props.taskStatus === TASK_STATUS.PUBLISHED"
          class="mt-2" />

        <div class="flex flex-col pt-8">
          <div class="relative flex min-h-56 flex-col">
            <slot :afterHeaderId="afterHeaderId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
