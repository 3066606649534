import {
  AdjustmentsHorizontalIcon,
  ComputerDesktopIcon,
  ExclamationTriangleIcon,
  LightBulbIcon,
  MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';
import type { Topic } from '@kleecks/code-lib';
import type { FunctionalComponent } from 'vue';

export const TOPIC_ICONS_MAP: {
  [key in Topic]?: FunctionalComponent;
} = {
  strategy: LightBulbIcon,
  seo: MagnifyingGlassIcon,
  ux: ComputerDesktopIcon,
  issues: ExclamationTriangleIcon,
  settings: AdjustmentsHorizontalIcon,
};
