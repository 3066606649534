import { disableInstanceMutation } from '@/services/queries/instance.query';

export const useDisableInstance = () => {
  const mutation = disableInstanceMutation();

  const disableInstance = async (instanceId: string): Promise<boolean> => {
    const res = await mutation.mutate({
      instanceId,
    });

    return !!res?.data?.disableInstance;
  };

  return {
    ...mutation,
    disableInstance,
  };
};
