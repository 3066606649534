<script setup lang="ts">
import type { Brand } from '@/types/brand.types';

interface BrandCardProps {
  brand: Brand;
}

const props = defineProps<BrandCardProps>();
</script>

<template>
  <div
    class="virtualized-brand cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-[0_1px_3px_#00000029] ring-kl-green-200 transition duration-300 ease-in-out hover:border-kl-green-200 focus:shadow-lg focus:outline-none focus:ring-1 active:ring-1">
    <div class="flex items-start">
      <div class="mr-8 flex-1">
        <span
          class="text-xs font-medium text-kl-green-300"
          :data-cy="`brand-card-url`"
          >{{ props.brand.mainUrl }}</span
        >
        <div class="text-lg text-primary">
          {{ props.brand.label || props.brand.name?.charAt(0)?.toUpperCase() + props.brand.name?.slice(1) }}
        </div>
      </div>
      <!-- <Counter
        class="size-12"
        label="Tasks"
        color="pink"
        :count="props.brand.taskObject?.totalCount || 0" /> -->
    </div>

    <div class="mt-8 h-36 w-full">
      <img
        :src="props.brand.image"
        class="h-full w-full object-cover" />
    </div>
  </div>
</template>
