import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';
import { getAllDashboardsQuery } from '@/services/queries/dashboard.query';
import type { Dashboard } from '@/types/dashboard.types';
import type { User } from '@/types/user.types';

/**
 * User/Admin private ->  userId and brandId are present.
 * User/Admin global -> only userId is present.
 * Admin universal -> only teamId is present and match user.teat.id.
 * SuperAdmin universal -> no userId, brandId, teamId.
 */
export const useDashboards = (brandId: MaybeRef<string>, user: MaybeRef<User | undefined>) => {
  const query = getAllDashboardsQuery();

  const dashboards = computed(() => query.result.value?.dashboards || []);

  const privateDashboards = computed<Dashboard[]>(() => {
    if (!user) return [] as Dashboard[];
    // console.log(dashboards.value);
    return dashboards.value.filter(dash => {
      // Mandatory condition: check if createdBy exists and has the same id as user
      const isCreatedByUser = Boolean(dash.createdBy?.id === unref(user)?.id);

      // Check if both user.id and brand.id are defined
      const hasUserAndBrand = Boolean(dash.user?.id && dash.brand?.id && dash.brand.name === unref(brandId));

      // Check if only user.id is defined and not brand.id
      const hasOnlyUser = Boolean(dash.user?.id && !dash.brand?.id);

      // Return true if the dashboard was created by the current user and either
      // has both user.id and brand.id defined, or only user.id defined
      return isCreatedByUser && (hasUserAndBrand || hasOnlyUser);
    });
  });

  // Team Dashboards: Only teamId is present and must match user.team.id
  const teamDashboards = computed<Dashboard[]>(() => {
    if (!user || !unref(user)?.team?.id) return [] as Dashboard[];

    return dashboards.value
      .filter(dash => {
        const isTeamDashboard = Boolean(dash.team?.id && dash.team.id === unref(user)?.team?.id);
        const isNotPrivateOrBranded = Boolean(!dash.brand?.id && !dash.user?.id);

        return isTeamDashboard && isNotPrivateOrBranded;
      })
      ?.sort((a, b) => a.name.localeCompare(b.name));
  });

  const kleecksDashboards = computed<Dashboard[]>(() => {
    if (!user) return [] as Dashboard[];

    return dashboards.value
      .filter(dash => {
        const isKleecksDashboard = Boolean(!dash.user?.id && !dash.brand?.id && !dash.team?.id);
        return isKleecksDashboard;
      })
      ?.sort((a, b) => a.name.localeCompare(b.name));
  });

  const allDashboards = computed<Dashboard[]>(() => [
    ...(kleecksDashboards.value || []),
    ...(teamDashboards.value || []),
    ...(privateDashboards.value || []),
  ]);

  return {
    ...query,
    dashboards,
    allDashboards,
    privateDashboards,
    teamDashboards,
    kleecksDashboards,
  };
};
