import type { VNode } from 'vue';
import GoogleSearchConsoleIcon from '@/assets/icons/google-search-console.svg';
import GoogleAdsIcon from '@/assets/icons/google-ads.svg';
import { CONNECTORS_LABELS, GOOGLE_CONNECTORS } from '@/types/connectors.types';

export type ConnectorsIds = 'google_ads' | 'google_gsc';
export const AVAILABLE_CONNECTORS: {
  icon: VNode;
  label: string;
  id: ConnectorsIds;
}[] = [
  {
    icon: GoogleAdsIcon as any,
    label: CONNECTORS_LABELS[GOOGLE_CONNECTORS.GOOGLE_ADS],
    id: 'google_ads',
  },
  {
    icon: GoogleSearchConsoleIcon as any,
    label: CONNECTORS_LABELS[GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE],
    id: 'google_gsc',
  },
];
