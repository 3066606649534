<script setup lang="ts">
import deepEqual from 'fast-deep-equal';
import rfdc from 'rfdc';
import { computed, ref, watch, h } from 'vue';

import { XMarkIcon } from '@heroicons/vue/24/outline';
import { Combobox, Button, BaseTextInput, FilterBox, TaskStatusChip, UserProfileImage } from '@kleecks/ui-lib';

import { type Topic, TOPIC_OPTIONS } from '@kleecks/code-lib';

import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';

import type { TaskFilters } from '@/types/task.types';
import { DEFAULT_TASK_FILTERS, TASK_STATUS } from '@/constants/task.constants';
import { thereAreFilters } from '@/utils/tasks.utils';

interface TasksContainerBoxFiltersMenuProps {
  isOpen: boolean;
  filtersObject: TaskFilters;
  filterType: 'order' | 'general';
  assignersOptions: { value: string; label: string; image?: string }[];
  onApplySomeFilters: (filters: TaskFilters, fromFilterType: 'order' | 'general') => void;
}

const props = defineProps<TasksContainerBoxFiltersMenuProps>();

const clone = rfdc();
const emit = defineEmits<{ (e: 'close'): void }>();
const filtersObjectCopy = ref<TaskFilters>(clone(DEFAULT_TASK_FILTERS));

// Feature: Manage Filters Box filters
const orderByItems = [
  { value: 'status', label: 'Task Status' },
  { value: 'lastModification', label: 'Last Modification' },
];

const orderDirectionItems = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

// Feature: Manage Filters Box Visibility
const handleClose = () => {
  emit('close');
};

// Manage Filter Editing
const taskStatusOptions = computed(() => [
  ...Object.values(TASK_STATUS).map(status => ({ value: status, label: status })),
]);

const isResetDisabled = computed(() => {
  if (props.filterType === 'order') {
    return deepEqual(filtersObjectCopy.value.orderBy, DEFAULT_TASK_FILTERS.orderBy);
  }
  return (
    deepEqual(filtersObjectCopy.value.searchKey, DEFAULT_TASK_FILTERS.searchKey) &&
    deepEqual(filtersObjectCopy.value.filterByAssignees, DEFAULT_TASK_FILTERS.filterByAssignees) &&
    deepEqual(filtersObjectCopy.value.filterByStatus, DEFAULT_TASK_FILTERS.filterByStatus) &&
    deepEqual(filtersObjectCopy.value.filterByTopics, DEFAULT_TASK_FILTERS.filterByTopics)
  );
});
const isApplyDisabled = computed(() => deepEqual(filtersObjectCopy.value, props.filtersObject));

const resetFiltersObject = () => {
  if (props.filterType === 'order') {
    filtersObjectCopy.value.orderBy = {
      by: DEFAULT_TASK_FILTERS.orderBy.by,
      direction: DEFAULT_TASK_FILTERS.orderBy.direction,
    };
  } else {
    filtersObjectCopy.value.searchKey = DEFAULT_TASK_FILTERS.searchKey;
    filtersObjectCopy.value.filterByAssignees = [...DEFAULT_TASK_FILTERS.filterByAssignees];
    filtersObjectCopy.value.filterByStatus = [...DEFAULT_TASK_FILTERS.filterByStatus];
    filtersObjectCopy.value.filterByTopics = [...DEFAULT_TASK_FILTERS.filterByTopics];
  }
};

const handleApplyFilters = () => {
  if (props.filterType === 'order') props.onApplySomeFilters(filtersObjectCopy.value, 'order');
  else props.onApplySomeFilters(filtersObjectCopy.value, 'general');
  handleClose();
};

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      if (thereAreFilters(props.filtersObject)) {
        filtersObjectCopy.value = clone(props.filtersObject);
      } else {
        filtersObjectCopy.value = clone(DEFAULT_TASK_FILTERS);
      }
    }
  },
  { immediate: true }
);

watch(
  () => filtersObjectCopy.value,
  () => {
    if (filtersObjectCopy.value?.orderBy.by === undefined) {
      filtersObjectCopy.value.orderBy.by = '';
    }
    if (filtersObjectCopy.value.orderBy.direction === undefined) {
      filtersObjectCopy.value.orderBy.direction = '';
    }
  },
  { deep: true }
);
</script>

<template>
  <div
    v-if="props.isOpen"
    class="flex w-full flex-col border border-kl-gray-200 bg-kl-gray-100 px-2.5 py-3 shadow-lg transition-all duration-300 ease-in-out">
    <div class="flex justify-between gap-x-4">
      <span class="flex-1 text-kl-sm font-bold text-kl-gray-500">
        {{ props.filterType === 'order' ? 'Order' : 'Filters' }}
      </span>
      <XMarkIcon
        class="size-5 shrink-0 cursor-pointer text-kl-icon transition-all duration-300 ease-in-out hover:rotate-90"
        @click="handleClose" />
    </div>
    <div class="mt-4 flex w-full flex-col gap-y-2.5">
      <template v-if="props.filterType === 'order'">
        <Combobox
          id="order-by-combobox-id"
          v-model="filtersObjectCopy.orderBy.by"
          class="bg-white"
          name="order-by-combobox-name"
          placeholder="Select Order By"
          :items="orderByItems" />
        <Combobox
          id="order-direction-combobox-id"
          v-model="filtersObjectCopy.orderBy.direction"
          class="bg-white"
          name="order-direction-combobox-name"
          placeholder="Select Order Direction"
          :items="orderDirectionItems" />
      </template>
      <template v-if="props.filterType === 'general'">
        <BaseTextInput
          v-model="filtersObjectCopy.searchKey"
          input-classes="bg-white"
          placeholder="Search Tasks name or description"
          type="search" />
        <FilterBox
          v-model="filtersObjectCopy.filterByTopics"
          bgClass="bg-white"
          :items="TOPIC_OPTIONS"
          label="Filter by topic"
          :render-fn="
              ({value}: any) => h('div', { class: 'flex items-center gap-2 py-1 text-secondary' }, [
                h(TopicChip, { topic: value as Topic }),              
              ])
            "
          filter-placeholder="Insert here the topic you want to filter by"
          placeholder="Filter by topic" />
        <FilterBox
          v-model="filtersObjectCopy.filterByStatus"
          bgClass="bg-white"
          label="Filter by status"
          :items="taskStatusOptions"
          placeholder="Filter by status"
          :render-fn="
            ({ value }) =>
              h('div', { class: 'flex items-center py-1 gap-2 text-secondary text-sm' }, [
                h(TaskStatusChip, { task: { status: value } }),
              ])
          "
          filter-placeholder="Insert here the status you want to filter by" />
        <FilterBox
          v-model="filtersObjectCopy.filterByAssignees"
          :items="props.assignersOptions"
          show-filter
          bgClass="bg-white"
          :render-fn="
            ({ value, label, image }: any) =>
              h('div', { class: 'flex items-center gap-2 text-secondary text-sm' }, [
                h(UserProfileImage, {
                  class: 'size-5 text-kl-icon',
                  size: 'sm',
                  user: {
                    nome: label?.split(' ')[0],
                    cognome: label?.split(' ')[1],
                    image,
                  },
                }),
                h('span', label),
              ])
          "
          label="Assignee"
          placeholder="Filter by assignee" />
      </template>
    </div>
    <div class="mt-6 flex items-center justify-between gap-x-2">
      <span
        :class="{
          'pointer-events-none opacity-50': isResetDisabled,
          'cursor-pointer  hover:text-kl-gray-600': !isResetDisabled,
        }"
        class="text-kl-xs text-kl-gray-400 underline"
        @click="resetFiltersObject()">
        {{ `Reset ${props.filterType === 'order' ? 'Order' : 'Filters'}` }}
      </span>
      <Button
        variant="blue"
        class="!h-6 !w-24"
        :disabled="isApplyDisabled"
        @click="handleApplyFilters()">
        {{ props.filterType === 'order' ? 'Order' : 'Filter' }}
      </Button>
    </div>
  </div>
</template>
