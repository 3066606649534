<script setup lang="ts">
import type { MODULES } from '@/constants/modules.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useModuleActivities } from '@/hooks/modules/useModuleActivities';
import { useModuleDiffs } from '@/hooks/tasks/useModuleDiffs';

interface ActivityDiffListProps {
  taskId: string;
  onEditModule?: (keyId?: string) => void;
  moduleId: MODULES;
}

const props = defineProps<ActivityDiffListProps>();
const instanceId = useRouteParam('instanceId');
const { activities, loading } = useModuleActivities(props.taskId, props.moduleId, instanceId);
const { diffs } = useModuleDiffs(activities);
</script>

<template>
  <div
    v-if="diffs.length"
    class="flex w-full flex-col gap-2">
    <div
      v-for="diff in diffs"
      :key="diff.id"
      class="w-full">
      <div
        class="flex flex-1 items-center divide-x divide-kl-gray-200 rounded-md border bg-kl-gray-100 py-1"
        :class="{
          'cursor-pointer hover:bg-kl-gray-200': props.onEditModule !== undefined,
        }"
        @click="() => onEditModule?.(diff.key)">
        <div class="flex flex-1 items-center px-2">
          <p
            class="flex-1 text-xs text-kl-gray-400"
            v-html="diff.label" />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="!loading"
    class="text-xs text-kl-gray-400">
    No changes to display
  </div>
</template>
