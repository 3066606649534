<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useCreateTaskDialog } from '@/stores/useCreateTaskDialogStore';
import { useUser } from '@/hooks/user/useUser';

interface TaskEmptyProps {
  height?: string;
  topic?: string;
}
const props = withDefaults(defineProps<TaskEmptyProps>(), {
  height: 'h-full',
  topic: '',
});

const createTaskDialog = useCreateTaskDialog();
const { user } = useUser();

const openDialog = () => {
  if (user?.value?.admin) createTaskDialog.showCreateTaskDialog();
};
</script>
<template>
  <div
    :class="`flex ${props.height} w-full cursor-pointer items-center justify-center rounded-md border border-dashed border-kl-gray-300`"
    @click="openDialog">
    <div class="flex items-center justify-center gap-4">
      <PlusIcon
        v-if="user?.admin"
        class="size-5 text-kl-gray-300" />
      <p class="text-xs text-kl-gray-400/80">
        No {{ topic }} tasks available
        <span
          v-if="user?.admin"
          class="text-xs text-kl-pink-100 underline"
          >Create a Task</span
        >
        <span v-else>Please, contact an administrator</span>
      </p>
    </div>
  </div>
</template>
