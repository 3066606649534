<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import type { Notification } from '@/types/notification.types';
import NotificationItem from './NotificationItem.vue';

interface NotificationListProps {
  notifications: Notification[];
}
const props = defineProps<NotificationListProps>();
</script>

<template>
  <div class="flex w-full flex-col divide-y divide-kl-gray-200">
    <template
      v-for="notification in props.notifications"
      :key="notification.id">
      <NotificationItem
        :notification="notification"
        class="py-4" />
    </template>
  </div>
</template>
