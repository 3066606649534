<script setup lang="ts">
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import ModalTooltipButton from '../modal-tooltip-button/ModalTooltipButton.vue';

interface Props {
  modalContent: string;
  modalTitle: string;
}

const props = defineProps<Props>();
</script>

<template>
  <ModalTooltipButton
    :modal-content="props.modalContent"
    :modal-title="props.modalTitle">
    <div
      class="fixed bottom-4 left-4 z-[2] cursor-pointer rounded-md border border-kl-gray-300 bg-kl-violet-200/80 p-2 shadow-custom hover:bg-kl-violet-200">
      <QuestionMarkCircleIcon class="size-6 text-white" />
    </div>
  </ModalTooltipButton>
</template>
