<script setup lang="ts">
import DashboardIcon from '@/assets/dashboard_icon.svg';
import logoKleecky from '@/assets/logo_kleecky_gray.gif';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import DashboardsPopover from '@/components/dashboards/dashboards-popover/DashboardsPopover.vue';
import AdminSettingsMenu from '@/components/navbar/admin-settings-menu/AdminSettingsMenu.vue';
import NotificationMenu from '@/components/navbar/notification-menu/NotificationMenu.vue';
import { MODULES_IDS } from '@/constants/modules.constants';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TASK_STATUS } from '@/constants/task.constants';
import { useBreadcrumbs } from '@/hooks/breadcrumb/useBreadcrumbs';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useInstanceTasks } from '@/hooks/instance/useInstanceTasks';
import { useSandboxNavigate } from '@/hooks/sandbox/useSandboxNavigate';
import { useUser } from '@/hooks/user/useUser';
import { useChatbotStore } from '@/stores/useChatbotStore';
import { useCurrentTaskDrawerStore } from '@/stores/useCurrentTaskDrawerStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/useUserStore';
import { BookOpenIcon, ChartBarIcon, ExclamationCircleIcon, RocketLaunchIcon } from '@heroicons/vue/24/outline';
import { StandardContainer, TaskStatusChip, WithTooltip, logoMainSm } from '@kleecks/ui-lib';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import NavbarDropdown from './navbar-dropdown/NavbarDropdown.vue';
import UserMenu from './user-menu/UserMenu.vue';

const userStore = useUserStore();
const breadcrumbs = useBreadcrumbs();
const router = useRouter();
const chatbotStore = useChatbotStore();

const { user, isSuperAdmin } = useUser();

const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const notificationStore = useNotificationStore();
const { instanceTasks, loading: loadingTasks } = useInstanceTasks(brandId, instanceId);

const navigateToDashboardsLibrary = () => {
  router.push({
    name: ROUTE_NAMES.DASHBOARDS_LIBRARY,
    params: {
      brandId: router.currentRoute.value.params.brandId,
      instance: router.currentRoute.value.params.instance,
    },
  });
};

const navigateToAllTasks = () => {
  router.push({
    name: ROUTE_NAMES.TASKS_MANAGEMENT,
  });
};
const userTasks = computed(() =>
  instanceTasks.value?.filter(el => el.assignedTo?.find(u => u.login === user.value?.login))
);

const instanceTasksOptions = computed(() =>
  userTasks.value
    ?.filter(
      el =>
        el.status !== TASK_STATUS.PUBLISHED &&
        el.id !== userStore.selectedTask?.id &&
        el.status !== TASK_STATUS.CREATING
    )
    ?.map(el => ({
      label: el.title,
      value: el.id,
    }))
);

const { instance } = useInstance(brandId, instanceId);
const taskDrawerStore = useCurrentTaskDrawerStore();

const openCurrentTaskDetails = () => {
  if (userStore.selectedTask) {
    taskDrawerStore.openDrawer(userStore.selectedTask?.id);
  }
};

const { navigateToSandbox } = useSandboxNavigate();

const navigateToSEOTool = () => {
  navigateToSandbox({
    module: MODULES_IDS.SEO_TOOL,
  });
};

const addNewDashboard = () => {
  router.push({
    name: ROUTE_NAMES.NEW_DASHBOARD,
    params: {
      brandId: router.currentRoute.value.params.brandId,
      instance: router.currentRoute.value.params.instance,
    },
  });
};

const navigateToStrategy = () => {
  router.push({ name: ROUTE_NAMES.STRATEGY });
};
const navigateToHome = () => {
  router.push('/');
};

const switchWorkingTask = (taskId: string) => {
  userStore.setSelectedTaskId(taskId);

  if (userStore.selectedTask) {
    notificationStore.notify({
      title: 'Task changed',
      message: `Now you're working on ${userStore.selectedTask.title}`,
      type: 'info',
    });
  }
};

const openChatbot = () => {
  if (chatbotStore.countChatbotMessages > 0) {
    chatbotStore.openChatbot();
  } else {
    chatbotStore.showInPage();
  }
};

const navigateToWiki = () => {
  if (!instanceId.value) {
    router.push({
      name: ROUTE_NAMES.WIKI,
    });
  } else
    router.push({
      name: ROUTE_NAMES.INSTANCE_WIKI,
    });
};
</script>

<template>
  <div
    as="nav"
    data-testid="navbar"
    class="relative z-[6] border-b border-gray-200 bg-white"
    :class="{
      'h-16': !breadcrumbs,
      'h-28': !!breadcrumbs,
    }">
    <StandardContainer>
      <div class="flex h-16 items-center">
        <div class="flex-1">
          <img
            class="-ml-1 h-6 w-auto cursor-pointer"
            :src="logoMainSm"
            alt="Kleecks"
            @click="navigateToHome" />
        </div>

        <div class="flex items-center gap-3 divide-x divide-kl-gray-200">
          <template v-if="instance">
            <div class="flex items-center gap-3 pl-3">
              <div
                v-if="!chatbotStore.isShowInPage && chatbotStore.enableChatbot"
                class="flex h-8 cursor-pointer items-center gap-2 overflow-hidden rounded-full border border-kl-gray-300 bg-kl-gray-100 pl-2 pr-2 text-sm italic text-secondary"
                @click="openChatbot">
                <img
                  :src="logoKleecky"
                  class="size-7" />
                <div
                  v-if="chatbotStore.countChatbotMessages > 0"
                  class="flex items-center gap-4">
                  I’m waiting a question<span
                    class="flex size-4 items-center justify-center rounded-full bg-kl-red-200 text-kl-2xs not-italic text-white"
                    >{{ chatbotStore.countChatbotMessages }}</span
                  >
                </div>
                <div v-else>Hi! Ask me something…</div>
              </div>
              <WithTooltip label="Strategy">
                <ChartBarIcon
                  tabindex="0"
                  class="size-6 cursor-pointer hover:text-active hover:underline lg:block"
                  :class="{
                    'text-active': router.currentRoute.value.name === ROUTE_NAMES.STRATEGY,
                    'text-kl-icon': router.currentRoute.value.name !== ROUTE_NAMES.STRATEGY,
                  }"
                  @click="navigateToStrategy" />
              </WithTooltip>
              <WithTooltip
                v-if="userStore.selectedTask"
                label="SEO Tools">
                <RocketLaunchIcon
                  data-testid="seo-tool"
                  tabindex="0"
                  class="size-6 cursor-pointer text-kl-icon hover:text-active hover:underline lg:block"
                  @click="navigateToSEOTool" />
              </WithTooltip>
            </div>
            <div class="flex items-center gap-2 pl-3">
              <WithTooltip label="Dashboards library">
                <img
                  :src="DashboardIcon"
                  class="h-5 w-auto cursor-pointer"
                  @click="navigateToDashboardsLibrary" />
              </WithTooltip>
              <!--Serve Ancora ?-->
              <DashboardsPopover
                v-if="instance"
                :on-add-new-dashboard="addNewDashboard" />
            </div>
          </template>
          <div class="flex items-center gap-3 pl-3 text-kl-icon">
            <AdminSettingsMenu v-if="isSuperAdmin" />
            <NotificationMenu />
            <BookOpenIcon
              tabindex="0"
              class="size-6 cursor-pointer hover:text-active hover:underline lg:block"
              @click="navigateToWiki" />
            <UserMenu />
          </div>
        </div>
      </div>
    </StandardContainer>

    <div
      v-if="breadcrumbs?.length"
      class="border-t border-gray-secondary">
      <StandardContainer>
        <div class="flex h-12 items-center">
          <Breadcrumb
            v-if="breadcrumbs?.length"
            :pages="breadcrumbs"
            class="flex-1" />

          <div
            v-if="!loadingTasks && instance"
            class="flex items-center gap-2 divide-x divide-gray-secondary">
            <div
              class="flex cursor-pointer items-center gap-2 text-primary hover:text-active hover:underline"
              @click="navigateToAllTasks">
              <span class="text-xs">All Tasks</span>
            </div>
            <div
              v-if="userStore.selectedTask"
              class="flex max-w-96 cursor-pointer items-center pl-2 text-xs"
              @click="openCurrentTaskDetails">
              <p class="flex w-full items-center hover:underline">
                <span class="mr-1 whitespace-nowrap text-secondary">Current Task: </span>
                <span class="line-clamp-1 text-ellipsis font-medium text-primary">
                  {{ userStore.selectedTask.title }}
                </span>
              </p>
              <TaskStatusChip
                :task="userStore.selectedTask"
                class="ml-2 whitespace-nowrap" />
            </div>
            <div
              v-else
              class="flex items-center gap-2 pl-2 text-xs text-kl-red-200">
              <ExclamationCircleIcon class="size-4" />
              <span v-if="instanceTasksOptions.length">Warning, no tasks selected</span>
              <span v-else>Warning, no tasks available. You're in readonly mode</span>
            </div>

            <span
              v-if="!userStore.selectedTask"
              class="pl-2 text-xs text-secondary"
              >Please contact an administrator</span
            >

            <NavbarDropdown
              v-if="instanceTasksOptions.length"
              class="pl-2"
              data-testid="select-task-dropdown"
              :label="userStore.selectedTask ? 'Change Task' : 'Please, select a Task'"
              :options="instanceTasksOptions"
              @select-option="switchWorkingTask" />
          </div>
        </div>
      </StandardContainer>
    </div>
  </div>
</template>
