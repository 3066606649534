<script setup lang="ts">
import { TOPIC_OPTIONS } from '@kleecks/code-lib';
import { BaseTextArea, BaseTextInput, Button, Combobox, RadioButtonsList } from '@kleecks/ui-lib';
import { type DashboardVisibility } from '@/types/dashboard.types';
import { computed, reactive, ref } from 'vue';
import * as yup from 'yup';
import { ValidationError } from 'yup';

export type NewDashboardObject = {
  name: string;
  description: string;
  topic?: string;
  public: boolean;
};

interface NewDashboardPanelProps {
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  isPublicEnabled: boolean;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSave: (obj: NewDashboardObject, visibility: DashboardVisibility) => void;
}
const props = defineProps<NewDashboardPanelProps>();
const errors = ref<{
  [key: string]: string;
}>({});

const schema = yup.object().shape({
  name: yup.string().required('The name of the dashboard is required.'),
  topic: yup.string().required('Topic is required'),
});

const dashboardValues = reactive<NewDashboardObject>({
  name: '',
  description: '',
  topic: TOPIC_OPTIONS[0].value,
  public: false,
});

const dashboardVisibility = ref<DashboardVisibility>('private');

const VISIBILITY_OPTIONS: { keyValue: DashboardVisibility; label: string }[] = [
  { keyValue: 'private', label: 'Make this Dashboard Private' },
  { keyValue: 'global', label: 'Extends this dashboard to me for all brands' },
  {
    keyValue: 'team_universal',
    label: 'Extends this dashboard to all my team users for all team brands',
  },
  { keyValue: 'universal', label: 'Extends this dashboard to all Users for all brands' },
];

const getVisibilityOptions = computed(() => {
  if (props.isSuperAdmin) {
    return VISIBILITY_OPTIONS;
  }
  if (props.isAdmin) {
    return VISIBILITY_OPTIONS.slice(0, 3);
  }
  return VISIBILITY_OPTIONS.slice(0, 2);
});

const validateForm = async () => {
  try {
    await schema.validate(dashboardValues, { abortEarly: false });
    errors.value = {
      name: '',
      topic: '',
    };
    return true;
  } catch (err) {
    if (err instanceof ValidationError) {
      errors.value = err.inner.reduce<{
        [key: string]: string;
      }>((acc, error) => {
        if (error.path) acc[error.path] = error.message;
        return acc;
      }, {});
    }
    return false;
  }
};

const handleSaveNewDashboard = async () => {
  if (await validateForm()) {
    props.onSave(
      {
        ...dashboardValues,
        public: props.isPublicEnabled ? dashboardValues.public : false,
      },
      dashboardVisibility.value
    );
  }
};
</script>

<template>
  <div class="flex h-full w-full flex-col border-l border-gray-200 bg-white py-4 pl-8">
    <div class="flex flex-1 flex-col gap-4">
      <div class="flex">
        <span class="flex-1 text-base font-medium text-primary">Create new dashboard</span>
      </div>
      <BaseTextInput
        :id="'dashboard_name'"
        v-model="dashboardValues.name"
        label="Dashboard name*"
        type="text"
        :status="{
          message: errors.name,
          valid: !errors.name,
        }"
        placeholder="Name..."
        :name="`dashboard_name`" />
      <BaseTextArea
        :id="`dashboard_description`"
        v-model="dashboardValues.description"
        label="Dashboard description"
        placeholder="Insert a description"
        :name="`dashboard_description`" />

      <Combobox
        id="dashboard_topic"
        v-model="dashboardValues.topic"
        label="Topic*"
        value-expr="value"
        display-expr="label"
        name="dashboard_topic"
        :items="TOPIC_OPTIONS" />
      <RadioButtonsList
        v-model="dashboardVisibility"
        class="mt-4 border-t border-gray-200 pt-4"
        :options="getVisibilityOptions"
        :dedfault-value="'private'" />
      <!-- <BaseToggle
        v-if="props.isAdmin"
        v-model="dashboardValues.public"
        :disabled="!props.isPublicEnabled"
        name="dashboard_public"
        label="Is Global?" />
      <p
        v-if="!props.isPublicEnabled"
        class="text-xs text-kl-red-200">
        You can't create a public dashboard. In order to do that you must include no widgets related to instance
        configurations.
      </p> -->
    </div>

    <div class="mt-4 text-right">
      <Button
        variant="primary"
        size="md"
        :is-loading="props.isLoading"
        @click="handleSaveNewDashboard()">
        Save
      </Button>
    </div>
  </div>
</template>
