<script setup lang="ts">
import { ArrowPathIcon, ArrowTopRightOnSquareIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import type { Column } from '@kleecks/ui-lib';
import { HeadlessTable, StandardContainer } from '@kleecks/ui-lib';
// import { type VIEW_TYPE } from '@kleecks/code-lib';
import BrandCard from '@/components/cards/brand-card/BrandCard.vue';
import BrandsStatsCard from '@/components/cards/brands-stats-card/BrandsStatsCard.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import InfiniteScrollContainer from '@/components/infinite-scroll/InfiniteScrollContainer.vue';
import BrandsSkeletonLoader from '@/components/skeletons/BrandsSkeletonLoader.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { type Brand } from '@/types/brand.types';
import { formatDate } from '@/utils/date.utils';
import { computed, h, ref } from 'vue';
import { useRouter } from 'vue-router';

import CreateBrandDialog from '@/components/dialogs/create-brand/CreateBrandDialog.vue';
import PageInfoFloatingButton from '@/components/page-info-floating-button/PageInfoFloatingButton.vue';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useDeleteBrand } from '@/hooks/brand/useDeleteBrand';
import { usePaginatedBrands } from '@/hooks/brand/usePaginatedBrands';
import { useRefreshInstances } from '@/hooks/instance/useRefreshInstances';
import { useApplicationLabels } from '@/hooks/labels/useApplicationLabels';
import { useUser } from '@/hooks/user/useUser';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';

const router = useRouter();
const isCreateBrandDialogOpen = ref(false);
const searchValue = ref('');
const { brands, loading, fetchMore, hasMore } = usePaginatedBrands(searchValue);
const { getLabel } = useApplicationLabels();

const { isSuperAdmin } = useUser();
const { brands: totalBrands, loading: loadingBrands } = useBrands();
const { deleteBrand } = useDeleteBrand();
const { notify } = useNotificationStore();
const { showDeleteAlert } = useDeleteAlertStore();
const { refreshInstances } = useRefreshInstances();

const totalTasks = computed(() =>
  totalBrands?.value?.reduce((acc, brand) => acc + (brand.taskObject?.totalCount || 0), 0)
);
const handleSelectBrand = (brand: Brand, editMode: boolean) => {
  router.push({
    name: ROUTE_NAMES.BRAND,
    params: {
      brandId: brand.name,
    },
    query: {
      editMode: editMode.toString(),
    },
  });
};

const handleDeleteBrand = (name: string) => {
  showDeleteAlert(`Are you sure that you want to delete the brand ${name}?`, async () => {
    deleteBrand(name);
    notify(DEFAULT_NOTIFICATIONS.BRAND_DELETED(name));
  });
};

// const viewType = ref<VIEW_TYPE>('grid');

// const onChangeViewType = (value: VIEW_TYPE) => {
//     viewType.value = value;
// };

const fetchMoreBrands = () => {
  if (loading.value) return;
  fetchMore();
};

const search = (value: string) => {
  searchValue.value = value;
};

// Feature 1: Manage brand visualization

const viewType = ref<'grid' | 'list'>('grid');
const onChangeViewType = (newViewType: 'grid' | 'list') => {
  if (newViewType === viewType.value) return;
  viewType.value = newViewType;
};

// Feature 2: Mange Brands Table

const columns: Column<Brand>[] = [
  {
    header: 'Preview',
    accessorFn: (value: Brand) => h('div', [h('img', { src: value.image, alt: value.label, class: 'size-12' })]),
  },
  {
    accessorKey: 'label',
    header: 'Name',
    sortable: true,
  },
  {
    accessorKey: 'mainUrl',
    header: 'Url',
    sortable: true,
    cellClass: 'text-kl-green-300 ',
    component: (value: string) =>
      h('a', { href: value, target: '_blank', class: 'hover:underline hover:cursor-pointer' }, value),
  },
  {
    header: 'Projects/Instances',
    accessorFn: b => Object.values(b.instances || {})?.filter(x => x?.instanceId)?.length,
    sortable: true,
  },
  {
    header: 'Tasks',
    sortable: true,
    headerClass: 'text-right',
    cellClass: 'text-right',
    columnRendererClass: 'text-right w-full',
    accessorFn: (value: Brand) => value.taskObject?.totalCount || 0,
  },
  {
    accessorKey: 'createdAt',
    header: 'Created On',
    sortable: true,
    accessorFn: (value: Brand) => (value.createdAt ? formatDate(value.createdAt) : 'Never'),
  },
  {
    actions: true,
    component: (row: Brand) => {
      const elem = h('div', { class: 'flex justify-end gap-2' }, [
        h(TrashIcon, {
          class: 'text-kl-icon size-5 cursor-pointer hover:text-kl-green-300',
          onClick: e => {
            e.stopPropagation();
            handleDeleteBrand(row.name);
          },
        }),
        h(ArrowPathIcon, {
          class: `text-kl-icon size-5 cursor-pointer hover:text-kl-green-300`,
          onClick: refreshInstances,
        }),
        h(PencilSquareIcon, {
          class: 'text-kl-icon size-5 cursor-pointer hover:text-kl-green-300',
          onClick: () => handleSelectBrand(row, true),
        }),
        h(ArrowTopRightOnSquareIcon, {
          class: 'text-kl-icon size-5 cursor-pointer hover:text-kl-green-300',
          onClick: e => {
            e.stopPropagation();
            handleSelectBrand(row, false);
          },
        }),
      ]);
      return elem;
    },
  },
];

const closeCreateBrandDialog = (newBrandId?: string) => {
  isCreateBrandDialogOpen.value = false;
  if (newBrandId) {
    router.push({ name: ROUTE_NAMES.BRAND, params: { brandId: newBrandId } });
  }
};

const openCreateBrandDialog = () => {
  isCreateBrandDialogOpen.value = true;
};
</script>

<template>
  <PageInfoFloatingButton
    :modal-content="getLabel('BRANDS_PAGE.hero.help')"
    modal-title="Brands Page Info" />
  <StandardContainer data-testid="brands-page">
    <CreateBrandDialog
      :is-open="isCreateBrandDialogOpen"
      :on-close="closeCreateBrandDialog" />
    <div
      class="grid grid-cols-1 grid-rows-2 gap-x-8 gap-y-6 pt-8 sm:grid-cols-2 sm:grid-rows-1 lg:grid-cols-3 lg:grid-rows-1 2xl:grid-cols-4 2xl:grid-rows-1">
      <div class="row-start-2 sm:col-span-1 sm:row-auto lg:col-span-2 lg:row-auto 2xl:col-span-2 2xl:row-auto">
        <DashboardHeader
          v-model:search-value="searchValue"
          :title="getLabel('BRANDS_PAGE.hero.title')"
          search-placeholder="Search brands name"
          :sub-title="getLabel('BRANDS_PAGE.hero.subtitle')"
          :cta="
            isSuperAdmin
              ? {
                  onClick: openCreateBrandDialog,
                  label: getLabel('BRANDS_PAGE.hero.cta'),
                }
              : undefined
          "
          :view-type="viewType"
          :on-change-search="search"
          :on-change-view-type="onChangeViewType" />
      </div>
      <div
        class="row-start-1 flex justify-end gap-x-4 sm:col-span-1 sm:row-auto lg:col-span-1 lg:row-auto 2xl:col-span-2 2xl:row-auto">
        <BrandsStatsCard
          :value="totalBrands?.length"
          label="Brands"
          data-testid="brands-stats-card"
          :color="'green'" />
        <BrandsStatsCard
          data-testid="tasks-stats-card"
          :value="totalTasks"
          label="Tasks"
          :color="'pink'" />
      </div>
    </div>
    <InfiniteScrollContainer
      v-if="!loading || brands.length > 0"
      :has-more="hasMore"
      @on-end-reached="fetchMoreBrands">
      <template v-if="viewType === 'grid'">
        <transition-group
          :key="1"
          name="fadein"
          tag="div"
          data-testid="brands-list"
          class="grid grid-cols-1 gap-8 py-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          <template
            v-for="brand in brands"
            :key="brand.id">
            <BrandCard
              tabindex="0"
              data-testid="brand-card"
              :brand="brand"
              @keydown.enter="handleSelectBrand(brand, false)"
              @click="handleSelectBrand(brand, false)" />
          </template>
        </transition-group>
      </template>
      <template v-else>
        <transition
          :key="2"
          name="fadein"
          data-testid="brands-list-table"
          class="flex max-h-[calc(100vh-14rem)] flex-col py-8">
          <HeadlessTable
            :loading="loadingBrands"
            :columns="columns"
            :initial-page-size="10"
            font-size="xs"
            :elements="totalBrands?.toSorted((a, b) => a.name.localeCompare(b.name))"
            :row-bg-class-fn="row => 'hover:bg-kl-green-200/5'" />
        </transition>
      </template>
    </InfiniteScrollContainer>

    <BrandsSkeletonLoader v-else />
  </StandardContainer>
</template>
