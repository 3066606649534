<script setup lang="ts">
import { PlusCircleIcon, NoSymbolIcon } from '@heroicons/vue/24/outline';
import { MessagePanel } from '@kleecks/ui-lib';
import { ref } from 'vue';

interface DragAndDropOverlayProps {
  label: string;
  isVisible: boolean;
  isDropDisabled: boolean;
  onDrop: () => void;
}
const props = defineProps<DragAndDropOverlayProps>();

const isDragOver = ref(false);

const executeDrop = () => {
  if (!props.isDropDisabled) {
    props.onDrop();
  }
};
</script>

<template>
  <MessagePanel
    v-if="props.isVisible"
    class="mx-2 my-1 h-12"
    :class="{
      'border-kl-green-200': isDragOver && !props.isDropDisabled,
    }"
    background="white"
    orientation="horizontal"
    :messages="[
      {
        icon: NoSymbolIcon,
        iconCss: '!text-kl-icon !size-7',
        visibility: Boolean(props.isDropDisabled),
      },
      {
        text: props.label,
        visibility: !Boolean(props.isDropDisabled),
        icon: PlusCircleIcon,
        iconCss: '!tex-kl-icon !size-5',
      },
    ]"
    @dragover.prevent="isDragOver = true"
    @dragleave.prevent="isDragOver = false"
    @drop="() => executeDrop()" />
</template>
