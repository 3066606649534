import { getBrandQuery } from '@/services/queries/brand.query';
import type { MaybeRef } from 'vue';
import { computed } from 'vue';

export const useBrand = (name: MaybeRef<string | undefined>) => {
  const query = getBrandQuery(name);
  const brand = computed(() => query.result.value?.brand);

  return { brand, ...query };
};
