<script setup lang="ts">
import { onUnmounted, onMounted, watch, ref } from 'vue';

interface InfiniteScrollContainerProps {
  hasMore: boolean;
}

const props = defineProps<InfiniteScrollContainerProps>();

const containerRef = ref();

const emits = defineEmits<{
  (e: 'onEndReached'): void;
}>();

const scrollEventListener = () => {
  const isEndReached = Math.round(window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  if (isEndReached) {
    emits('onEndReached');
  }
};

onMounted(() => {
  // Trigger scroll event if window is too large
  if (props.hasMore) emits('onEndReached');
  window.addEventListener('scroll', scrollEventListener);
});

onUnmounted(() => {
  window.removeEventListener('scroll', scrollEventListener);
});

watch(
  () => props.hasMore,
  () => {
    if (!props.hasMore) {
      window.removeEventListener('scroll', scrollEventListener);
    }
  }
);
</script>

<template>
  <div ref="containerRef">
    <slot />
  </div>
</template>
