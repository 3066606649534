<script setup lang="ts">
import ActivitiesList from '@/components/activities/activities-list/ActivitiesList.vue';
import { DropdownMenu, TaskStatusChip, UserProfileImage, WithTooltip } from '@kleecks/ui-lib';

import TopicChip from '@/components/atoms/topic-chip/TopicChip.vue';
import { TASK_PRIORITY_COLOR, TASK_PRIORITY_LABEL, TASK_STATUS } from '@/constants/task.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useInstance } from '@/hooks/instance/useInstance';
import { useTaskActions } from '@/hooks/tasks/useTasksActions';
import { useUser } from '@/hooks/user/useUser';
import type { Task, TaskAction } from '@/types/task.types';
import { formatDate } from '@/utils/date.utils';
import { CalendarIcon, FolderIcon } from '@heroicons/vue/24/outline';
import { useIntersectionObserver } from '@vueuse/core';
import { computed, ref, toRef, watch } from 'vue';

interface TasksListItemProps {
  task: Task;
  isPublishEnabled: boolean;
  isScheduleEnabled: boolean;
  isDraggable?: boolean;
  isCloneEnabled?: boolean;
  hideInstance?: boolean;
  isExpanded: boolean;
  isPreset?: boolean;
  root?: HTMLElement | null;
  toggleExpand: () => void;
  onAction: (taskId: string, action: TaskAction) => void;
}

const props = defineProps<TasksListItemProps>();
const instanceId = useRouteParam('instanceId');
const brandId = useRouteParam('brandId');
const { instanceName } = useInstance(brandId, props.task.instance?.instanceId);
const { user } = useUser();
const isMyTask = computed(() => props.task.assignedTo?.find(el => el.login === user.value?.login));
const isFollowedUser = computed(
  () => !isMyTask.value && user.value?.follows?.find(u1 => props.task?.assignedTo?.find(u2 => u2.id === u1.id))
);
const taskRef = toRef(() => props.task);
const isExpandable = computed(() => props.task.status !== TASK_STATUS.CREATING);
const { actions } = useTaskActions(brandId, instanceId, taskRef, {
  isScheduleEnabled: props.isScheduleEnabled,
  isCloneEnabled: props.isCloneEnabled,
  isPublishEnabled: props.isPublishEnabled,
  isPreset: props.isPreset,
});

// FEATURE 2: Manage tooltip visibility
// In this case we want to close the tooltip when the TaskListItem scroll out of the viewport
const target = ref(null);
const isVisible = ref(false);
const dropDownMenuRef = ref<typeof DropdownMenu>();

useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
  },
  { root: props.root }
);

watch(isVisible, newValue => {
  if (!newValue && dropDownMenuRef.value) {
    if (dropDownMenuRef.value.isOpen) {
      dropDownMenuRef.value.changeToolTipVisibility('close');
      if (props.isExpanded) props.toggleExpand();
    }
  }
});
</script>

<template>
  <li
    class="m-2 p-1"
    :data-taskid="props.task.id"
    data-testid="tasks-list-item virtualized-task">
    <div
      ref="target"
      class="relative rounded-md border p-2"
      :class="{
        'hover:bg-white hover:ring-2 hover:ring-kl-blue-100': isExpandable,
        'cursor-grab': props.isDraggable && isExpandable,
        'cursor-pointer': !props.isDraggable && isExpandable,
        'bg-white': isExpanded,
        'bg-kl-gray-100': !isExpanded,
        'ring-2 ring-kl-blue-100': isExpanded,
      }"
      @click="
        () => {
          if (isExpandable) props.toggleExpand();
        }
      ">
      <div class="flex items-center">
        <div class="flex-1">
          <div class="mb-1 flex items-center gap-2">
            <TaskStatusChip :task="props.task" />
            <TopicChip
              v-if="props.task.topic"
              :topic="props.task.topic" />

            <div
              v-if="isFollowedUser"
              class="inline-flex h-4 items-center rounded-xl border border-kl-cyan bg-white px-4 py-[.15rem] text-2xs text-secondary">
              Followed
            </div>
            <div
              v-if="isMyTask"
              class="inline-flex h-4 items-center rounded-xl border border-kl-green-300 bg-white px-4 py-[.15rem] text-2xs text-secondary">
              My Tasks
            </div>
          </div>
        </div>
        <div class="inline-flex items-center justify-end gap-x-1">
          <span :class="[TASK_PRIORITY_COLOR[props.task.priority]]">
            {{ TASK_PRIORITY_LABEL[props.task.priority] }}
          </span>
          <DropdownMenu
            v-if="actions?.[0]?.length > 0 || actions?.[1]?.length > 0"
            ref="dropDownMenuRef"
            data-testid="task-dropdown-menu"
            :menu-options="actions"
            :on-send-option-operation="(operation: string) => props.onAction(task.id, operation as TaskAction)" />
        </div>
      </div>

      <p
        class="text-sm font-medium text-kl-gray-500"
        data-testid="task-title"
        :class="{
          'line-clamp-2 text-ellipsis': !isExpanded,
          'line-clamp-none': isExpanded,
        }">
        {{ props.task.title }}
      </p>
      <p
        data-testid="task-description"
        class="my-1 text-xs font-normal text-kl-gray-400"
        :class="{
          'line-clamp-2 text-ellipsis': !isExpanded,
          'line-clamp-none': isExpanded,
        }">
        {{ props.task.description }}
      </p>
      <transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-1000">
        <ActivitiesList
          v-if="isExpanded"
          :task="task"
          :show-no-activities-message="true"
          class="my-4 max-h-64 cursor-default overflow-y-auto px-2"
          @click="e => e.stopPropagation()" />
      </transition>

      <div class="mt-4 flex items-end gap-2">
        <div class="flex flex-1 items-center gap-2">
          <div
            v-if="!props.hideInstance"
            class="flex items-center gap-1">
            <div class="relative bottom-[.05rem] size-4">
              <FolderIcon class="text-kl-icon" />
            </div>
            <span class="text-xs font-normal text-kl-gray-400">{{ instanceName }}</span>
          </div>
          <div
            v-if="task.createdAt"
            class="flex items-center gap-1 leading-3">
            <div class="relative bottom-[.05rem] size-4">
              <CalendarIcon class="text-kl-icon" />
            </div>
            <span class="text-xs font-normal text-kl-gray-400">{{ formatDate(task.createdAt) }}</span>
          </div>
        </div>
        <WithTooltip
          v-if="task.assignedTo?.[0] && !props.isPreset"
          :label="`${task.assignedTo[0]?.nome} ${task.assignedTo[0]?.cognome}`">
          <UserProfileImage
            :user="task.assignedTo?.[0]"
            size="md" />
        </WithTooltip>
      </div>
    </div>
  </li>
</template>
