<script setup lang="ts">
import { useGetPin } from '@/services/queries/authentication';
import { ApolloError } from '@apollo/client';
import { isApolloCustomError } from '@kleecks/code-lib';
import { BaseTextInput, Button } from '@kleecks/ui-lib';
import { ref } from 'vue';

interface ErrorStatus {
  valid: boolean;
  message: string;
}
const email = ref('');
const password = ref('');
const error = ref('');
const errorEmail = ref<ErrorStatus>({ valid: true, message: '' });
const errorPassword = ref<ErrorStatus>({ valid: true, message: '' });

const { load: getPin, refetch: getPinRefetch, onResult: onGetPinResult, loading: isGetPinLoading } = useGetPin();

const emits = defineEmits<{
  (e: 'pinReceived', obj: { username: string; password: string }): void;
  (e: 'invalidIp'): void;
  (e: 'forgotPassword'): void;
}>();

const handleGetAuthCode = async () => {
  const payload = { username: email.value, password: password.value };
  try {
    // eslint-disable-next-line no-unused-expressions
    await (getPin(null, payload) || getPinRefetch(payload));
  } catch (err) {
    if (err instanceof ApolloError && isApolloCustomError(err.graphQLErrors?.[0])) {
      errorPassword.value = {
        valid: true,
        message: '',
      };

      errorEmail.value = {
        valid: true,
        message: '',
      };

      error.value = '';
      if (err.graphQLErrors[0].extensions.code === 'AUTHORIZATION_ERROR') {
        if (err.graphQLErrors[0].message === 'Access not recognized') {
          emits('invalidIp');
        }

        if (err.graphQLErrors[0].message === 'Username not found') {
          errorEmail.value = {
            valid: false,
            message: 'User not found. Please check your email and try again.',
          };
        }
        if (err.graphQLErrors[0].message === 'Password not correct') {
          errorPassword.value = {
            valid: false,
            message: 'Password not valid',
          };
        }
      }
    } else {
      error.value = 'An error occured, please try again.';
    }
  }
};

onGetPinResult(result => {
  if (!result.data?.login) {
    error.value = 'Invalid credentials';
    return;
  }

  if (result.data.login.pin) {
    emits('pinReceived', { username: email.value, password: password.value });
  }
});
</script>

<template>
  <form
    class="mt-8 flex flex-col"
    @submit.prevent="handleGetAuthCode">
    <BaseTextInput
      id="email"
      v-model="email"
      class="mb-4"
      name="email"
      type="text"
      autocomplete="email"
      label="Your email"
      :status="errorEmail"
      @input="() => (errorEmail = { valid: true, message: '' })" />

    <BaseTextInput
      id="password"
      v-model="password"
      name="password"
      type="password"
      :status="errorPassword"
      label="Your password"
      autocomplete="current-password"
      @input="() => (errorPassword = { valid: true, message: '' })" />

    <div class="mt-12 flex items-center gap-x-4">
      <div class="flex-1">
        <span
          class="cursor-pointer text-kl-xs text-kl-gray-400 underline hover:text-kl-gray-600"
          @click="() => emits('forgotPassword')"
          >Forgot Password?</span
        >
      </div>
      <Button
        variant="blue"
        size="sm"
        :disabled="!email || !password"
        data-testid="get-code-button"
        type="submit"
        :isLoading="isGetPinLoading"
        @click="handleGetAuthCode">
        Get Auth Code
      </Button>
    </div>
  </form>
</template>
