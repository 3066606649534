<script setup lang="ts">
import { useModuleModalStore } from '@/stores/useModuleModalStore';
import { useModules } from '@/hooks/modules/useModules';
import { Modal, DialogContainer } from '@kleecks/ui-lib';
import { MODULES_NAMES } from '@/constants/modules.constants';

const moduleDialogStore = useModuleModalStore();
const { modules } = useModules();
</script>

<template>
  <DialogContainer
    :on-close="
      () => {
        /* DO NOTHING */
      }
    "
    class="z-[9]"
    :is-open="moduleDialogStore.moduleId !== undefined">
    <template v-if="moduleDialogStore.moduleId">
      <Modal
        :on-close="moduleDialogStore.unsetModule"
        class="max-w-5xl"
        :title="MODULES_NAMES[moduleDialogStore.moduleId]"
        :sub-title="`Edit the module ${MODULES_NAMES[moduleDialogStore.moduleId]}`">
        <div class="relative flex min-h-72 min-w-[800px] flex-col">
          <Transition name="fade">
            <component
              :is="modules[moduleDialogStore.moduleId]"
              v-bind="{
                isReadOnly: moduleDialogStore.readOnlyMode,
                taskId: moduleDialogStore.taskId,
                instanceId: moduleDialogStore.instanceId,
                showFooter: false,
              }" />
          </Transition>
        </div>
      </Modal>
    </template>
  </DialogContainer>
</template>
