import {
  applicationDictionaryQuery,
  createOrUpdateApplicationDictionaryMutation,
} from '@/services/queries/application-dictionary';
import type { MaybeRef } from 'vue';
import { computed, unref } from 'vue';

export const useApplicationDictionary = (lang?: MaybeRef<string>) => {
  const query = applicationDictionaryQuery(lang || 'en');
  const mutation = createOrUpdateApplicationDictionaryMutation();
  const dictionary = computed(() => query.result.value?.applicationDictionary || {});

  const updateDictionary = async (data: any) => {
    await mutation.mutate({
      lang: unref(lang)!,
      data,
    });
  };
  return {
    ...query,
    dictionary,
    updateDictionary,
  };
};
