import { COOKIES, removeCookie } from '@kleecks/code-lib';

export const useLogout = () => {
  const logout = () => {
    removeCookie(COOKIES.TOKEN_KEY);
    window.location.href = '/';
  };

  return { logout };
};
