import { TASK_STATUS } from '@/constants/task.constants';
import { useUserStore } from '@/stores/useUserStore';
import type { Task } from '@/types/task.types';
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  ArrowsRightLeftIcon,
  CalendarIcon,
  CheckCircleIcon,
  CloudArrowUpIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import type { MenuOption } from '@kleecks/ui-lib';
import { computed, toRef, unref, type MaybeRef } from 'vue';
import { useUser } from '../user/useUser';

export const useTaskActions = (
  brandId: MaybeRef<string | undefined>,
  instanceId: MaybeRef<string | undefined>,
  inputTask: MaybeRef<Task | undefined>,
  options: {
    isScheduleEnabled: boolean;
    isPublishEnabled: boolean;
    isCloneEnabled: boolean;
    isPreset: boolean;
  }
) => {
  const { user, isSuperAdmin } = useUser();

  const task = toRef(() => unref(inputTask));

  const isMyTask = computed(() => unref(task)?.assignedTo?.find(el => el.login === user.value?.login));
  const isFollowedUser = computed(
    () => !isMyTask.value && user.value?.follows?.find(u1 => unref(task)?.assignedTo?.find(u2 => u2.id === u1.id))
  );
  const userStore = useUserStore();
  const actions = computed(() => {
    const res = [[], []] as MenuOption[][];

    if (unref(task)?.status === TASK_STATUS.CREATING && (isMyTask.value || isSuperAdmin.value))
      return [
        [
          {
            label: 'Delete',
            operation: 'delete',
            icon: TrashIcon,
          },
        ],
      ];

    if (options.isPreset) {
      if (!isSuperAdmin.value) {
        return [];
      }
      return [
        [
          {
            label: 'Edit Preset',
            operation: 'edit-preset',
            icon: PencilIcon,
          },
          {
            label: 'Delete',
            operation: 'delete',
            icon: TrashIcon,
          },
        ],
      ];
    }
    if (isSuperAdmin.value) {
      res[0].push({
        label: 'Make preset',
        operation: 'make-preset',
        icon: DocumentDuplicateIcon,
      });
    }
    if (isFollowedUser.value || user.value?.admin || isMyTask.value) {
      res[0].push({
        label: 'View preview',
        operation: 'view-sandbox',
        icon: ArrowTopRightOnSquareIcon,
      });
    }

    if (
      unref(task)?.instance?.instanceId === unref(instanceId) &&
      isMyTask.value &&
      unref(task)?.status !== TASK_STATUS.PUBLISHED &&
      userStore.selectedTask?.id !== unref(task)?.id
    )
      res[0].push({
        label: 'Work on this task',
        operation: 'switch',
        icon: ArrowsRightLeftIcon,
      });

    if (user.value?.admin) {
      if (unref(task)?.status !== TASK_STATUS.PUBLISHED) {
        res[0].push({
          label: 'Edit Task',
          operation: 'edit',
          icon: PencilIcon,
        });
      }
      // TODO: Implement delete task later
      if (unref(task)?.status !== TASK_STATUS.PUBLISHED) {
        res[0].push({
          label: 'Delete',
          operation: 'delete',
          icon: TrashIcon,
        });
      }

      if (unref(task)?.status !== TASK_STATUS.PUBLISHED && user.value?.admin) {
        if (unref(task)?.status === TASK_STATUS.IN_REVIEW)
          res[1].push({
            label: 'Reject',
            operation: 'reject',
            icon: XMarkIcon,
          });

        if (
          unref(task)?.status === TASK_STATUS.DRAFT ||
          unref(task)?.status === TASK_STATUS.IN_REVIEW ||
          unref(task)?.status === TASK_STATUS.REJECTED
        ) {
          res[1].push({
            label: 'Approve',
            operation: 'approve',
            icon: CheckCircleIcon,
          });
        }

        if (unref(task)?.status === TASK_STATUS.APPROVED) {
          res[1].push({
            label: 'Re-open',
            operation: 'reopen',
            icon: ArrowLeftIcon,
          });
        }
      }
    }

    if (user.value?.admin && options.isScheduleEnabled && unref(task)?.status === TASK_STATUS.APPROVED)
      res[0].push({
        label: 'Schedule',
        operation: 'schedule',
        icon: CalendarIcon,
      });

    if (user.value?.admin && options.isPublishEnabled && unref(task)?.status === TASK_STATUS.APPROVED)
      res[1].push({
        label: 'Publish',
        operation: 'publish',
        icon: CloudArrowUpIcon,
      });

    if (options.isCloneEnabled)
      res[1].push({
        label: 'Clone',
        operation: 'clone',
        icon: DocumentDuplicateIcon,
      });

    return res;
  });

  return { actions };
};
