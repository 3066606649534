export const ROUTE_NAMES = {
  LOGIN: 'login',
  AUTHENTICATE: 'authenticate',
  BRANDS: 'brands',
  BRAND: 'brand',
  INSTANCE: 'instance',
  MODULES_LIBRARY: 'modules_library',
  DASHBOARDS_LIBRARY: 'dashboards_library',
  DASHBOARD: 'dashboard',
  NEW_DASHBOARD: 'new_dashboard',
  TASKS_MANAGEMENT: 'tasks_management',
  ADMIN_DASHBOARD: 'admin_dashboard',
  ADMIN_USERS: 'admin_users',
  ADMIN_BRANDS: 'admin_brands',
  PROJECT_TASKS: 'project_tasks',
  NOT_FOUND: 'not_found',
  ADMIN_TEAMS: 'admin_teams',
  URL_EXPLORER_CRAWL: 'url_explorer_crawl',
  URL_EXPLORER_ANALYTIC: 'url_explorer_analytic',
  AI_CONTENT: 'ai_content',
  MODULE: 'module',
  ADMIN_MODULES_DICTIONARY: 'admin_dictionary',
  ADMIN_APPLICATION_DICTIONARY: 'admin_application_dictionary',
  STRATEGY: 'strategy',
  WIKI: 'wiki',
  INSTANCE_WIKI: 'instance_wiki',
} as const;
