import { ChatbotMutation, ChatbotCleanMemoryMutation } from '@/services/graphql/chatbot.graphql';
import type { ChatbotMemory, ChatbotOutput } from '@/types/chatbot.types';
import { useMutation } from '@vue/apollo-composable';

interface ChatbotVariables {
  input: string;
  memory?: ChatbotMemory[];
  useRag: boolean;
}

export interface ChatbotResult {
  output: ChatbotOutput;
  error: string;
  memory: ChatbotMemory[];
}

export const chatbotMutation = () => useMutation<{ chatbot: ChatbotResult }, ChatbotVariables>(ChatbotMutation);
export const chatbotCleanMemoryMutation = () =>
  useMutation<{
    chatbotCleanMemory: {
      Status: string;
    };
  }>(ChatbotCleanMemoryMutation);
