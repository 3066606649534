<script setup lang="ts">
import CreateUserDialog from '@/components/dialogs/create-user/CreateUserDialog.vue';
import DashboardHeader from '@/components/headers/dashboard-header/DashboardHeader.vue';
import UserDetailsPanel from '@/components/panels/user-details-panel/UserDetailsPanel.vue';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useDeleteUser } from '@/hooks/user/useDeleteUser';
import { useUsers } from '@/hooks/user/useUsers';
import { useDeleteAlertStore } from '@/stores/useDeleteAlertStore';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { User } from '@/types/user.types';
import { formatDate } from '@/utils/date.utils';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import type { Column } from '@kleecks/ui-lib';
import { DrawerContainer, HeadlessTable } from '@kleecks/ui-lib';
import { h, ref } from 'vue';

const { users } = useUsers();
const selectedUser = ref<User>();
const isCreateUserDialogOpen = ref(false);
const { mutate: deleteUser, onError: onErrorDeleteUser } = useDeleteUser();
const deleteAlertStore = useDeleteAlertStore();
const { notify } = useNotificationStore();
const onConfirmUserDialog = () => {
  isCreateUserDialogOpen.value = false;
};

onErrorDeleteUser(() => {
  notify(DEFAULT_NOTIFICATIONS.ERROR('There was an error deleting the user.'));
});
const handleDeleteUser = async (user: User) => {
  deleteAlertStore.showDeleteAlert(
    `
    Are you sure you want to delete the user ${user.nome} ${user.cognome}?
  
  `,
    async () => {
      const res = await deleteUser({ login: user.login });
      if (res) notify(DEFAULT_NOTIFICATIONS.USER_DELETED(`${user.nome} ${user.cognome}`));
    }
  );
};

const closeCreateUserDialog = () => {
  isCreateUserDialogOpen.value = false;
};

const openCreateUserDialog = () => {
  isCreateUserDialogOpen.value = true;
};

const handleSelectUser = (user: User) => {
  selectedUser.value = user;
};

const closeUserDetailsDrawer = () => {
  selectedUser.value = undefined;
};

const columns: Column<User>[] = [
  {
    header: 'Name',
    sortable: true,
    accessorFn: (value: User) => `${value.nome} ${value.cognome}`,
    component: (value: string) => h('div', { class: 'text-sm font-bold' }, `${value}`),
  },
  {
    accessorKey: 'login',
    header: 'Email',
    sortable: true,
  },
  {
    accessorKey: 'team',
    header: 'Team',
    accessorFn: (value: User) => value.team?.name || 'Not defined',
    sortable: true,
  },
  {
    accessorKey: 'admin',
    header: 'Admin',
    sortable: true,
    columnRendererClass: 'text-center',
    cellClass: ' pl-2 w-24',
    component: (value: boolean) =>
      value
        ? h('div', { class: 'flex justify-center w-12' }, [
            h(CheckCircleIcon, {
              class: [value ? 'text-green-500' : 'text-gray-400', 'size-4'],
            }),
          ])
        : undefined,
  },
  {
    accessorKey: 'brandsCount',
    header: 'Brands',
    sortable: true,
    cellClass: 'text-center w-24',
    columnRendererClass: 'text-center w-full',
    accessorFn: (value: User) => value.brandsCount || 0,
  },
  {
    accessorKey: 'lastAccess',
    header: 'Last Access',
    accessorFn: (row: User) => {
      let ip = '';
      const data = JSON.parse(JSON.stringify(row.restrictionsIP || []));
      if (row.restrictionsIP) {
        const sortDate = data.sort(
          (a: any, b: any) => new Date(b.lastAccess || '').getTime() - new Date(a.lastAccess || '').getTime()
        );
        if (sortDate && sortDate.length > 0) {
          ip = sortDate[0].ip;
        }
      }
      const renders = h('div', { class: 'flex flex-col' }, [
        h('div', { class: 'font-bold' }, ip),
        h('div', { class: 'font-bold' }, row.lastAccess ? formatDate(row.lastAccess) : 'Never'),
      ]);
      return renders;
    },
    sortable: true,
  },
  {
    actions: true,
    component: (row: User) =>
      h('div', { class: 'flex justify-end gap-2' }, [
        h(PencilIcon, {
          class: 'text-kl-icon size-4 cursor-pointer hover:text-kl-blue-200',
          onClick: e => {
            e.stopPropagation();
            handleSelectUser(row);
          },
        }),
        h(TrashIcon, {
          disabled: true,
          class: 'text-kl-icon size-4 cursor-pointer hover:text-kl-red-200',
          onClick: e => {
            e.stopPropagation();
            handleDeleteUser(row);
          },
        }),
      ]),
  },
];
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col py-8">
    <CreateUserDialog
      :on-confirm="onConfirmUserDialog"
      :is-open="isCreateUserDialogOpen"
      :on-close="closeCreateUserDialog" />
    <div class="relative z-10">
      <DrawerContainer
        :title="selectedUser?.nome + ' ' + selectedUser?.cognome"
        :on-close="closeUserDetailsDrawer"
        :isShowing="!!selectedUser">
        <UserDetailsPanel
          v-if="selectedUser"
          v-show="selectedUser"
          :on-close="closeUserDetailsDrawer"
          :user="selectedUser" />
      </DrawerContainer>
    </div>
    <div class="flex min-h-0 w-full flex-1 flex-col items-end">
      <DashboardHeader
        title="Users"
        sub-title="The list of the users of the platform."
        :cta="{
          onClick: openCreateUserDialog,
          label: 'New User',
        }" />

      <HeadlessTable
        v-if="users"
        font-size="xs"
        :elements="users.toSorted((a, b) => (a.nome + a.cognome).localeCompare(b.nome + b.cognome))"
        :row-bg-class-fn="row => (row.id === selectedUser?.id ? 'bg-kl-green-200/20' : 'hover:bg-kl-green-200/10')"
        :global-filter-enabled="true"
        :on-row-click="handleSelectUser"
        :initial-page-size="20"
        :columns="columns" />
    </div>
  </div>
</template>
