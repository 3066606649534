// import { getNotificationsQuery } from '@/services/queries/notification.query';
import { getNotificationCountsQuery, getNotificationsQuery } from '@/services/queries/notification.query';
import type { Notification } from '@/types/notification.types';
import { computed } from 'vue';

export const useNotifications = () => {
  const query = getNotificationsQuery();
  const totalCountQuery = getNotificationCountsQuery();

  const notifications = computed<Notification[]>(() => query.result.value?.notifications || []);
  const totalUnviewed = computed(() => totalCountQuery.result?.value?.notificationCounts?.unviewed || 0);

  return {
    notifications,
    totalUnviewed,
  };
};
