<script setup lang="ts">
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { BriefcaseIcon, Cog6ToothIcon, DocumentIcon, UsersIcon } from '@heroicons/vue/24/outline';
import type { FunctionalComponent } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

type AdminSettingsAction =
  | 'instances'
  | 'teams'
  | 'users'
  | 'logs'
  | 'brands'
  | 'modules-dictionary'
  | 'application-dictionary';

interface AdminSettingsItem {
  id: AdminSettingsAction;
  label: string;
  icon: FunctionalComponent;
  description: string;
}

const router = useRouter();

const ADMIN_SETTINGS_ITEMS = computed<AdminSettingsItem[]>(() => {
  const res: AdminSettingsItem[] = [
    {
      id: 'teams',
      label: 'Teams',
      icon: BriefcaseIcon,
      description: 'Manage teams',
    },
    {
      id: 'users',
      label: 'Users',
      icon: UsersIcon,
      description: 'Manage users',
    },
    {
      id: 'modules-dictionary',
      label: 'Modules dictionary',
      icon: DocumentIcon,
      description: 'Manage modules labels',
    },
    {
      id: 'application-dictionary',
      label: 'Application dictionary',
      icon: DocumentIcon,
      description: 'Manage application labels',
    },
  ];

  return res;
});

const handleAction = (action: AdminSettingsAction) => {
  if (action === 'brands') {
    router.push({ name: ROUTE_NAMES.ADMIN_BRANDS });
    return;
  }

  if (action === 'modules-dictionary') {
    router.push({ name: ROUTE_NAMES.ADMIN_MODULES_DICTIONARY });
    return;
  }

  if (action === 'application-dictionary') {
    router.push({ name: ROUTE_NAMES.ADMIN_APPLICATION_DICTIONARY });
    return;
  }
  if (action === 'users') {
    router.push({ name: ROUTE_NAMES.ADMIN_USERS });
    return;
  }

  if (action === 'teams') {
    router.push({ name: ROUTE_NAMES.ADMIN_TEAMS });
    return;
  }

  throw new Error(`Unknown action: ${action}`);
};
</script>

<template>
  <Menu
    as="div"
    class="relative">
    <MenuButton class="flex rounded-full bg-white text-kl-icon hover:text-active focus:ring-offset-2">
      <span class="sr-only">Open user menu</span>

      <Cog6ToothIcon class="h-6 w-6" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="absolute right-0 top-8 z-10 min-w-80 origin-top-right rounded-md bg-white shadow-xl ring-1 ring-kl-gray-200 focus:outline-none">
        <div class="border-b border-kl-gray-200 p-4">
          <span class="text-base font-medium text-primary">Admin Settings</span>
        </div>
        <div class="divide-y divide-kl-gray-200">
          <MenuItem
            v-for="item in ADMIN_SETTINGS_ITEMS"
            :key="item.id"
            v-slot="{ active }"
            @click="handleAction(item.id)">
            <div :class="[active ? 'bg-gray-100' : '', 'flex cursor-pointer items-start p-2 text-sm text-gray-700']">
              <component
                :is="item.icon"
                class="size-5 text-kl-violet-400/50" />

              <div class="ml-2">
                <div class="text-sm font-medium text-kl-gray-600">
                  {{ item.label }}
                </div>
                <div class="text-xs font-light text-kl-gray-400">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
