<script setup lang="ts">
import {
  MODULES_CATEGORIES,
  MODULES_CATEGORY_COLOR,
  STANDALONE_MODULES,
  type MODULES,
} from '@/constants/modules.constants';
import { ArrowsPointingOutIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { type Topic } from '@kleecks/code-lib';
import PlaceholderImage from '@/assets/placeholder-image.jpg';
import { computed, type FunctionalComponent } from 'vue';
import { WithTooltip } from '@kleecks/ui-lib';

interface ModuleCardProps {
  id: MODULES;
  icon?: string | FunctionalComponent;
  name: string;
  description: string;
  topics?: Topic[];
  isDisabled?: boolean;
  hideAssignButton?: boolean;
  onClick: () => void;
}
const props = defineProps<ModuleCardProps>();

const isStandalone = computed(() => STANDALONE_MODULES.includes(props.id));
</script>

<template>
  <div
    class="relative flex h-fit gap-4 rounded-lg border border-transparent p-4 py-4 shadow-custom transition duration-300 ease-in-out"
    :class="{
      'cursor-pointer bg-white hover:border hover:border-kl-green-200 ': !props.isDisabled,
      'cursor-not-allowed bg-kl-gray-100 opacity-70': props.isDisabled,
    }"
    @click="
      () => {
        if (!props.isDisabled) props.onClick();
      }
    ">
    <div class="flex flex-1 items-start gap-3">
      <div class="relative size-28 rounded-md border border-kl-gray-200 p-8">
        <div
          v-if="isStandalone"
          class="absolute bottom-1 right-1 flex size-5 items-center justify-center bg-kl-gray-100 text-kl-gray-400">
          <WithTooltip label="Full Width Widget">
            <ArrowsPointingOutIcon class="size-full" />
          </WithTooltip>
        </div>
        <img
          v-if="typeof props.icon === 'string'"
          :src="props.icon"
          class="size-full" />
        <component
          :is="props.icon"
          v-else-if="props.icon"
          class="size-full" />
        <img
          v-else
          :src="PlaceholderImage"
          alt="Module Image"
          class="h-full w-full" />
      </div>

      <div class="flex flex-1 flex-col gap-2">
        <div class="flex items-center gap-2 pt-1">
          <div
            v-for="category in MODULES_CATEGORIES[props.id]"
            :key="category"
            class="inline-flex items-center">
            <div
              data-testid="topic-chip"
              :class="`inline-flex h-4 items-center rounded-xl border border-${MODULES_CATEGORY_COLOR[category]} px-2 py-[.15rem] text-2xs text-${MODULES_CATEGORY_COLOR[category]} font-medium`">
              {{ category }}
            </div>
          </div>
        </div>

        <h4 class="text-base leading-none text-kl-gray-500">{{ props.name }}</h4>
        <p class="line-clamp-3 h-fit text-xs leading-[.875rem] text-secondary hover:line-clamp-none">
          {{ props.description }}
        </p>
      </div>

      <div
        v-if="!props.hideAssignButton"
        class="absolute right-2 top-2 size-7 cursor-pointer rounded-full bg-kl-violet-100 p-1 hover:shadow-custom">
        <PlusIcon class="h-full w-full text-white" />
      </div>
    </div>
  </div>
</template>
