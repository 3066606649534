/* eslint-disable no-console */
import { GOOGLE_OAUTH_CLIENT_ID } from '@/constants/settings.constants';
import { connectGoogleServiceMutation, disconnectGoogleServiceMutation } from '@/services/queries/connectors.query';
import { CONNECTORS_LABELS, GOOGLE_CONNECTORS, type GoogleConnectorsType } from '@/types/connectors.types';
import { computed, ref, unref, type MaybeRef } from 'vue';
import { googleSdkLoaded } from 'vue3-google-login';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrand } from '../brand/useBrand';

const GOOGLE_ADS_SCOPE = 'https://www.googleapis.com/auth/adwords';
const GOOGLE_OAUTH_SCOPE = 'email profile openid';
const GOOGLE_SEARCH_CONSOLE_SCOPE = 'https://www.googleapis.com/auth/webmasters';

export const useGoogleConnectors = (brandId: MaybeRef<string | undefined>) => {
  const connectMutation = connectGoogleServiceMutation();
  const disconnectMutation = disconnectGoogleServiceMutation();
  const { brand, loading: loadingConnectors } = useBrand(brandId);
  const notificationStore = useNotificationStore();
  const loadingGoogleAds = ref(false);
  const loadingGoogleSearchConsole = ref(false);
  const searchConsoleConnector = computed(() => brand.value?.connectors?.Google_search_console);
  const adsConnector = computed(() => brand.value?.connectors?.Google_ads);

  const disconnectAccount = async (type: GoogleConnectorsType) => {
    if (type === GOOGLE_CONNECTORS.GOOGLE_ADS) loadingGoogleAds.value = true;
    if (type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE) loadingGoogleSearchConsole.value = true;
    try {
      await disconnectMutation.mutate({
        brand: unref(brandId)!,
        type,
      });
      notificationStore.notify(DEFAULT_NOTIFICATIONS.CONNECTOR_DISCONNECTED(CONNECTORS_LABELS[type]));
    } finally {
      if (type === GOOGLE_CONNECTORS.GOOGLE_ADS) loadingGoogleAds.value = false;
      if (type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE) loadingGoogleSearchConsole.value = false;
    }
  };
  const connectAccount = async (code: string, type: GoogleConnectorsType) => {
    if (type === GOOGLE_CONNECTORS.GOOGLE_ADS) loadingGoogleAds.value = true;
    if (type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE) loadingGoogleSearchConsole.value = true;
    try {
      await connectMutation.mutate({
        brand: unref(brandId)!,
        code,
        type,
      });
      notificationStore.notify(DEFAULT_NOTIFICATIONS.CONNECTOR_CONNECTED(CONNECTORS_LABELS[type]));
    } finally {
      if (type === GOOGLE_CONNECTORS.GOOGLE_ADS) loadingGoogleAds.value = false;
      if (type === GOOGLE_CONNECTORS.GOOGLE_SEARCH_CONSOLE) loadingGoogleSearchConsole.value = false;
    }
  };

  const triggerLogin = (type: GoogleConnectorsType) => {
    const scope = `${GOOGLE_OAUTH_SCOPE} ${
      type === 'GOOGLE_SEARCH_CONSOLE' ? GOOGLE_SEARCH_CONSOLE_SCOPE : GOOGLE_ADS_SCOPE
    }`;

    googleSdkLoaded(google => {
      google.accounts.oauth2
        .initCodeClient({
          client_id: GOOGLE_OAUTH_CLIENT_ID,
          ux_mode: 'popup',
          scope,
          callback: async signInResponse => {
            console.log(signInResponse);
            if (!signInResponse.code) {
              console.error('No code received');
            }

            connectAccount(signInResponse.code, type);
          },
        })
        .requestCode();
    });
  };

  return {
    triggerLogin,
    connectAccount,
    disconnectAccount,
    loadingConnectors,
    loadingGoogleAds,
    loadingGoogleSearchConsole,
    searchConsoleConnector,
    adsConnector,
  };
};
