import { getLabelFromDictionary } from '@kleecks/code-lib/src';
import { useApplicationDictionary } from '../application-dictionary/useApplicationDictionary';

export const useApplicationLabels = () => {
  const { dictionary, loading } = useApplicationDictionary('en');

  const getLabel = (key: string, hideFallback?: boolean) => getLabelFromDictionary(dictionary.value, key, hideFallback);
  return {
    loading,
    getLabel,
  };
};
