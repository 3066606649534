import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { deleteDashboardMutation } from '@/services/queries/dashboard.query';
import { useNotificationStore } from '@/stores/useNotificationStore';

export const useDeleteDashboard = () => {
  const { mutate, loading } = deleteDashboardMutation();
  const { notify } = useNotificationStore();

  const deleteDashboard = async (id: string) => {
    const res = await mutate({
      id,
    });
    if (res?.data?.deleteDashboard.name) notify(DEFAULT_NOTIFICATIONS.DASHBOARD_DELETED(res.data.deleteDashboard.name));
  };

  return {
    deleteDashboard,
    loading,
  };
};
