<script setup lang="ts" generic="T">
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

export type NavbarDropdown<T> = {
  label?: string;
  options?: {
    label: string;
    value: T;
  }[];
};

const emit = defineEmits<{
  (e: 'select-option', value: T): void;
}>();

const props = defineProps<NavbarDropdown<T>>();
</script>

<template>
  <Menu
    as="div"
    class="relative inline-block text-left">
    <MenuButton
      class="flex h-full justify-center"
      @click="e => e.stopPropagation()">
      <span class="group flex items-center justify-center gap-1 text-xs hover:text-active">
        {{ props.label }}
        <ChevronDownIcon
          class="size-3 cursor-pointer text-kl-violet-400/50 group-hover:text-active ui-open:rotate-180" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        data-testid="navbar-dropdown-menu-items"
        class="scrollbar-2 absolute right-0 z-[2] mt-4 max-h-40 w-fit min-w-28 max-w-96 origin-top-right divide-y divide-kl-gray-400 overflow-y-auto rounded-md border-kl-gray-200 bg-kl-gray-500 p-2 text-kl-sm text-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <MenuItem
          v-for="(option, index) in props.options"
          :key="index"
          tabindex="0"
          class="group flex w-full items-center border-0 p-2 hover:cursor-pointer hover:bg-kl-gray-400"
          @click="emit('select-option', option.value)">
          <p class="truncate text-kl-gray-200 group-hover:text-kl-gray-300">
            {{ option.label }}
          </p>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
