import { ROUTE_NAMES } from '@/constants/routes.constants';
import { computed, h } from 'vue';
import { useRoute } from 'vue-router';
import type { BreadcrumbPage } from '@/types/breadcrumb';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import type { MODULES } from '@/constants/modules.constants';
import { MODULES_NAMES } from '@/constants/modules.constants';
import { useRouteParam } from '../common/useRouteParam';
import { useDashboard } from '../dashboard/useDashboard';
import { useInstance } from '../instance/useInstance';
import { useBrand } from '../brand/useBrand';
import { useBrands } from '../brand/useBrands';
import { useInstances } from '../instance/useInstances';

export const useBreadcrumbs = () => {
  const route = useRoute();

  const brandId = useRouteParam('brandId');
  const instanceId = useRouteParam('instanceId');

  const dashboardId = useRouteParam('dashboardId');

  const { dashboard } = useDashboard(dashboardId, brandId);
  const { instanceName, loading } = useInstance(brandId, instanceId);
  const { brand } = useBrand(brandId);
  const { brands } = useBrands();
  const { instances, instancesNames } = useInstances(brandId);

  const breadcrumbsArr = computed<BreadcrumbPage[] | undefined>(() => {
    const res: BreadcrumbPage[] = [];

    if (!brandId.value) return undefined;

    if (brandId.value && brand.value?.label) {
      res.push({
        name: brand.value.label,
        href: `/brands/${brandId.value}/instances`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}`,
        dropdown: {
          renderFn: (v: { brandLink: string }, label) =>
            h('div', { class: 'flex justify-between gap-2' }, [
              label,
              h(ArrowTopRightOnSquareIcon, {
                class: 'size-4',
                onClick: e => {
                  e.stopPropagation();
                  window.open(v.brandLink, '_blank');
                },
              }),
            ]),
          options: brands.value
            ?.filter(x => x.name !== brandId.value)
            ?.map(b => ({
              label: b.label || b.name,
              value: {
                routeName: ROUTE_NAMES.BRAND,
                routeParams: { brandId: b.name },
                brandLink: b.mainUrl,
              },
            })),
          showFilter: true,
        },
      });
    }

    if (loading.value) return res;

    if (instanceId.value) {
      res.push({
        name: instanceName.value,
        href: `/brands/${brandId.value}/instances/${instanceId.value}`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}`,
        dropdown: {
          renderFn: (v: { brandLink: string }, label) =>
            h('div', { class: 'flex justify-between gap-2' }, [
              label,
              h(ArrowTopRightOnSquareIcon, {
                class: 'size-4',
                onClick: e => {
                  e.stopPropagation();
                  window.open(v.brandLink, '_blank');
                },
              }),
            ]),
          showFilter: false,
          options: instances.value.map(i => ({
            label: instancesNames.value[i.instanceId] || i.instanceId,
            value: {
              routeName: ROUTE_NAMES.INSTANCE,
              routeParams: { brandId: brandId.value, instanceId: i.instanceId },
              brandLink: i.baseUrl,
            },
          })),
        },
      });
    }

    if (route.path.includes('/dashboards')) {
      res.push({
        name: 'Dashboards',
        href: `/brands/${brandId.value}/instances/${instanceId.value}/dashboards`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}/dashboards`,
      });

      if (dashboard.value?.name) {
        res.push({
          name: dashboard.value.name,
          href: `/brands/${brandId.value}/instances/${instanceId.value}/dashboards/${dashboardId.value}`,
          current:
            route.path === `/brands/${brandId.value}/instances/${instanceId.value}/dashboards/${dashboardId.value}`,
        });
      }
    }

    if (route.path.includes('/modules')) {
      res.push({
        name: 'Modules',
        href: `/brands/${brandId.value}/instances/${instanceId.value}/modules`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}/modules`,
      });
    }

    if (route.name === ROUTE_NAMES.MODULE) {
      res.push({
        name: MODULES_NAMES[route.params.moduleId as MODULES],
        href: `/brands/${brandId.value}/instances/${instanceId.value}/modules/${route.params.moduleId}/${route.params.taskId}`,
        current: true,
      });
    }

    if (route.path.includes('/tasks_management')) {
      res.push({
        name: 'All tasks',
        href: `/brands/${brandId.value}/instances/${instanceId.value}/tasks_management`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}/tasks_management`,
      });
    }

    // TODO: use this when admin dashboard is ready
    // if (route.path.includes('/admin')) {
    //     res.push({
    //         name: 'Admin',
    //         href: `/admin`,
    //         current: route.path === `/admin`,
    //     });
    // }

    if (route.name === ROUTE_NAMES.ADMIN_BRANDS) {
      res.push({
        name: 'Brands',
        href: `/admin/brands`,
        current: route.path === `/admin/brands`,
      });
    }

    if (route.name === ROUTE_NAMES.ADMIN_USERS) {
      res.push({
        name: 'Users',
        href: `/admin/users`,
        current: route.path === `/admin/users`,
      });
    }

    if (route.name === ROUTE_NAMES.ADMIN_TEAMS) {
      res.push({
        name: 'Teams',
        href: `/admin/teams`,
        current: route.path === `/admin/teams`,
      });
    }

    if (route.name === ROUTE_NAMES.STRATEGY) {
      res.push({
        name: 'Strategy',
        href: `/brands/${brandId.value}/instances/${instanceId.value}/Strategy`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}/Strategy`,
      });
    }

    if (route.name === ROUTE_NAMES.INSTANCE_WIKI) {
      res.push({
        name: 'Wiki',
        href: `/brands/${brandId.value}/instances/${instanceId.value}/wiki`,
        current: route.path === `/brands/${brandId.value}/instances/${instanceId.value}/wiki`,
      });
    }

    return res;
  });

  return breadcrumbsArr;
};
