import {
  BrandUpdatedSubscription,
  GetBrand,
  GetBrands,
  UpdateBrandMutation,
  GetBrandTasks,
  CreateBrandMutation,
  DeleteBrandMutation,
  GetBrandTasksTimeline,
} from '@/services/graphql/brand.graphql';
import { type Brand } from '@/types/brand.types';
import type { Task } from '@/types/task.types';
import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable';
import type { MaybeRef } from 'vue';
import { unref } from 'vue';

export const getBrandsQuery = () =>
  useQuery<{
    brands: Brand[];
  }>(GetBrands);

export const getBrandQuery = (name: MaybeRef<string | undefined>) =>
  useQuery<{
    brand: Brand;
  }>(
    GetBrand,
    () => ({
      name: unref(name),
    }),
    () => ({
      enabled: !!unref(name),
      fetchPolicy: 'cache-first',
    })
  );

export const getBrandTasksQuery = (name: MaybeRef<string | undefined>) =>
  useQuery<{
    brand: {
      taskObject: Brand['taskObject'];
      instances: Brand['instances'];
    };
  }>(
    GetBrandTasks,
    () => ({
      name: unref(name),
    }),
    () => ({
      enabled: unref(name) !== undefined,
      fetchPolicy: 'cache-first',
    })
  );

export const updateBrandMutation = () =>
  useMutation<
    { updateBrand: Brand },
    {
      brand: Partial<Brand> & {
        instances: {
          PREPROD?: string;
          PROD?: string;
          STAGING?: string;
        };
      };
    }
  >(UpdateBrandMutation);

export const brandUpdatedSubscription = () =>
  useSubscription<{
    BrandUpdated: Brand;
  }>(BrandUpdatedSubscription);

export const createBrandMutation = () =>
  useMutation<{ createBrand: Brand }, { brand: Partial<Brand> }>(CreateBrandMutation, {
    update: (cache, { data }) => {
      if (!data?.createBrand) return;
      const getCreateBrandQuery = cache.readQuery<{ brands: Brand[] }>({
        query: GetBrands,
        variables: {
          orderBy: {
            label: 'asc',
          },
        },
      });

      if (getCreateBrandQuery) {
        cache.writeQuery({
          query: GetBrands,
          variables: {
            orderBy: {
              label: 'asc',
            },
          },
          data: {
            brands: [
              ...getCreateBrandQuery.brands,
              {
                ...data?.createBrand,
                taskObject: { tasks: [], totalCount: 0 },
                instances: {
                  prod: null,
                  staging: null,
                  preprod: null,
                },
              },
            ],
          },
        });
      }
    },
  });

export const deleteBrandMutation = () =>
  useMutation<{ deleteBrand: Brand }, { name: string }>(DeleteBrandMutation, {
    update: (cache, { data }) => {
      if (!data?.deleteBrand) return;
      const getCreateBrandQuery = cache.readQuery<{ brands: Brand[] }>({
        query: GetBrands,
        variables: {
          orderBy: {
            label: 'asc',
          },
        },
      });

      if (!getCreateBrandQuery?.brands.find(brand => brand.id === data.deleteBrand.id)) return;
      if (getCreateBrandQuery) {
        cache.writeQuery({
          query: GetBrands,
          variables: {
            orderBy: {
              label: 'asc',
            },
          },
          data: {
            brands: getCreateBrandQuery.brands.filter(brand => brand.id !== data.deleteBrand.id),
          },
        });
      }
    },
  });

export const getBrandTasksTimelineQuery = (name: MaybeRef<string | undefined>) =>
  useQuery<{
    brand: {
      tasks: Partial<Task>[];
    };
  }>(
    GetBrandTasksTimeline,
    () => ({
      name: unref(name),
    }),
    () => ({
      enabled: !!unref(name),
    })
  );
