<script setup lang="ts">
import TasksCounter from '@/components/tasks-counter-section/tasks-counter/TasksCounter.vue';
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';

import { useInstance } from '@/hooks/instance/useInstance';
import type { InstancePanelType } from '@/types/instance.types';
import { useRouter } from 'vue-router';

interface TasksCounterSection {
  panel?: string;
}

const props = defineProps<TasksCounterSection>();

const router = useRouter();

const brandId = useRouteParam('brandId');
const instanceId = useRouteParam('instanceId');
const { instance } = useInstance(brandId, instanceId);

const handleTasksClick = () => {
  router.push({
    name: ROUTE_NAMES.TASKS_MANAGEMENT,
  });
};

const handleDisplayPanel = (newPanel: InstancePanelType) => {
  router.push({
    name: ROUTE_NAMES.INSTANCE,
    params: {
      brandId: brandId.value,
      instanceId: instanceId.value,
    },
    query: {
      panel: newPanel === props.panel ? 'overview' : newPanel,
    },
  });
};
</script>

<template>
  <div
    v-if="instance"
    data-testid="tasks-counter-section"
    class="grid w-full grid-cols-6 gap-2">
    <TasksCounter
      :total="instance.taskAggregatedCount?.countByStrategy"
      :assigned="instance.taskAggregatedCount?.countByAssignedToMe?.countByStrategy"
      tabindex="0"
      data-testid="strategy-counter"
      label="Strategy"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'strategy' ? 'gray' : 'aqua'"
      @keydown.enter="handleDisplayPanel('strategy')"
      @click="handleDisplayPanel('strategy')" />

    <TasksCounter
      :total="instance.taskAggregatedCount.countBySeo"
      :assigned="instance.taskAggregatedCount?.countByAssignedToMe?.countBySeo"
      label="Seo"
      data-testid="seo-counter"
      tabindex="0"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'seo' ? 'gray' : 'yellow'"
      @keydown.enter="handleDisplayPanel('seo')"
      @click="handleDisplayPanel('seo')" />
    <TasksCounter
      :total="instance.taskAggregatedCount.countByUx"
      tabindex="0"
      :assigned="instance.taskAggregatedCount?.countByAssignedToMe?.countByUx"
      label="Ux"
      data-testid="ux-counter"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'ux' ? 'gray' : 'darkviolet'"
      @keydown.enter="handleDisplayPanel('ux')"
      @click="handleDisplayPanel('ux')" />
    <TasksCounter
      :total="instance.taskAggregatedCount.countByIssues"
      :assigned="instance.taskAggregatedCount?.countByAssignedToMe?.countByIssues"
      tabindex="0"
      label="Issues"
      data-testid="issues-counter"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'issues' ? 'gray' : 'red'"
      @keydown.enter="handleDisplayPanel('issues')"
      @click="handleDisplayPanel('issues')" />

    <TasksCounter
      :total="instance.taskAggregatedCount.countBySettings"
      :assigned="instance.taskAggregatedCount?.countByAssignedToMe?.countBySettings"
      label="Settings"
      tabindex="0"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'settings' ? 'gray' : 'violet'"
      data-testid="settings-counter"
      @keydown.enter="handleDisplayPanel('settings')"
      @click="handleDisplayPanel('settings')" />
    <TasksCounter
      :total="instance.taskAggregatedCount.countByAll"
      :assigned="
        instance.taskAggregatedCount?.countByAssignedToMe?.countBySettings +
        instance.taskAggregatedCount?.countByAssignedToMe?.countByIssues +
        instance.taskAggregatedCount?.countByAssignedToMe?.countByUx +
        instance.taskAggregatedCount?.countByAssignedToMe?.countBySeo +
        instance.taskAggregatedCount?.countByAssignedToMe?.countByStrategy
      "
      tabindex="0"
      label="All tasks"
      data-testid="tasks-counter"
      :color="props.panel !== undefined && props.panel !== 'overview' && props.panel !== 'tasks' ? 'gray' : 'pink'"
      @keydown.enter="handleTasksClick()"
      @click="() => handleTasksClick()" />
  </div>
</template>
