<script setup lang="ts">
interface BrandsStatsCardProps {
  value: number;
  total?: number;
  label: string;
  color: 'red' | 'green' | 'pink';
}

const props = defineProps<BrandsStatsCardProps>();
</script>

<template>
  <div
    :class="[
      'flex h-28 w-28 flex-col rounded-md shadow-md',
      { 'bg-kl-red-200': props.color === 'red', 'bg-kl-green-200': props.color === 'green' },
      {
        'bg-kl-pink-100': props.color === 'pink',
      },
    ]">
    <div class="flex flex-1 flex-col justify-center px-4 pb-1 pt-4 text-left">
      <span class="text-4xl font-medium text-white">{{ props.value }}</span>
      <span
        v-if="props.total"
        :class="[
          'text-xs font-medium',
          { 'text-gray-900/50': props.color === 'red', 'text-primary-dark': props.color === 'green' },
          {
            'text-white': props.color === 'pink',
          },
        ]"
        >{{ props.total }} total</span
      >
    </div>
    <div class="whitespace-nowrap rounded-b-md border-gray-50 bg-[#0000002e] p-2 text-center text-kl-sm text-white">
      {{ props.label }}
    </div>
  </div>
</template>
