<script setup lang="ts">
import type { ExternalModules, MODULES, ModuleCategory } from '@/constants/modules.constants';
import { INTERNAL_MODULES, MODULES_CATEGORIES, MODULES_IDS } from '@/constants/modules.constants';
import { DEFAULT_NOTIFICATIONS } from '@/constants/notification.constants';
import { useBrands } from '@/hooks/brand/useBrands';
import { useTeams } from '@/hooks/team/useTeams';
import { useUpdateUser } from '@/hooks/user/useUpdateUser';
import { useUsers } from '@/hooks/user/useUsers';
import { useSchemaValidation } from '@/hooks/validation/useSchemaValidation';
import type { UserUpdateInput } from '@/services/queries/user.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { User } from '@/types/user.types';
import { formatDate } from '@/utils/date.utils';
import { BaseTextInput, BaseToggle, Button, Combobox, Tabs, Toggle } from '@kleecks/ui-lib';
import { computed, ref } from 'vue';
import * as yup from 'yup';
import type { IpAddressUser } from '@/types/ip-address-user.types';
import BrandPermissionsRow from './BrandPermissionsRow.vue';
import IpAddressRow from './IpAddressRow.vue';

interface UserDetailsPanelProps {
  user: User;
  onClose: () => void;
}

const selectedTabId = ref<'details' | 'permissions' | 'ipAddress' | 'follows'>('details');
const props = defineProps<UserDetailsPanelProps>();
const { mutate: updateUser, loading } = useUpdateUser();
const { notify } = useNotificationStore();
const { brands } = useBrands();
const { users } = useUsers();
const { teams } = useTeams();

const yupSchema = yup.object().shape({
  nome: yup.string().required('First name is required'),
  cognome: yup.string().required('Last name is required'),
  team: yup.string().required('Team is required'),
  login: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string(),
  confirmPassword: yup.string().when('password', (password: string[]) => {
    if (password?.[0]) return yup.string().oneOf([yup.ref('password')], 'Passwords must match');
    return yup.string();
  }),
});

const { validateSchema, errors } = useSchemaValidation(yupSchema);

const teamsOptions = computed(() =>
  teams.value.map(t => ({
    label: t.name,
    value: t.id,
  }))
);

const permissions = ref(props.user.permissions || []);
const follows = ref<string[]>((props.user.follows || []).map(f => f.id));
const restrictionsIPList = ref<IpAddressUser[]>(JSON.parse(JSON.stringify(props.user.restrictionsIP || [])));

const tabs = computed(() => [
  { id: 'details', name: 'Details' },
  { id: 'permissions', name: 'Permissions' },
  { id: 'ipAddress', name: 'IP Address' },
  { id: 'follows', name: 'Follow', count: follows.value.length || 0 },
]);

const userValues = ref<{
  nome: string;
  cognome: string;
  team?: string;
  login: string;
  password?: string;
  isAdmin: boolean;
  confirmPassword?: string;
  acceptanceClauses: boolean;
}>({
  nome: props.user?.nome || '',
  cognome: props.user?.cognome || '',
  team: props.user?.team?.id || teamsOptions.value?.[0]?.value,
  login: props.user?.login!,
  password: undefined,
  isAdmin: props.user?.admin || false,
  acceptanceClauses: !!props.user?.acceptanceClauses,
  confirmPassword: '',
});

const filteredUsersPerTeam = computed<User[]>(() => {
  if (!userValues.value?.team) return [];
  return users.value.filter(u => u.team?.id === userValues.value.team && u.login !== props.user?.login);
});

const saveUser = async () => {
  if (!(await validateSchema(userValues.value))) return;

  const payload: UserUpdateInput = {
    nome: userValues.value.nome,
    cognome: userValues.value.cognome,
    permissions: permissions.value
      .map(p => ({
        brand: p.brand,
        moduleName: p.moduleName,
      }))
      .filter(p => p.moduleName.length > 0),
    teamId: userValues.value.team,
    password: userValues.value.password,
    follows: follows.value,
    admin: userValues.value.isAdmin,
    restrictionsIP: restrictionsIPList.value.map(r => ({ enabled: r.enabled, ip: r.ip })),
  };

  if (userValues.value.acceptanceClauses !== props.user.acceptanceClauses) {
    payload.acceptanceClauses = userValues.value.acceptanceClauses;
  }

  await updateUser({ user: payload, login: props.user?.login! });
  props.onClose();
  notify(DEFAULT_NOTIFICATIONS.USER_UPDATED(userValues.value.nome));
};

const toggleUserFollow = (id: string) => {
  if (follows.value.includes(id)) {
    follows.value = follows.value.filter(f => f !== id);
  } else {
    follows.value = [...follows.value, id];
  }
};

const toggleEnableAllPermissions = (value: boolean, brandId: string) => {
  const permission = permissions.value.find(p => p.brand === brandId);

  const allModules = Object.keys(MODULES_IDS).reduce((res, k) => {
    if (!(INTERNAL_MODULES as any).includes(k)) res.push(MODULES_IDS[k as keyof typeof MODULES_IDS] as ExternalModules);
    return res;
  }, [] as ExternalModules[]);

  if (!permission) {
    permissions.value = [
      ...permissions.value,
      {
        brand: brandId,
        moduleName: value ? allModules : [],
      },
    ];
    return;
  }

  const newPermissions = permissions.value.map(p => {
    if (p.brand === brandId) {
      return {
        brand: brandId,
        moduleName: value ? allModules : [],
      };
    }

    return p;
  });

  permissions.value = newPermissions;
};
const toggleAllPackagePermissions = (category: ModuleCategory, brandId: string, enable: boolean) => {
  let newModules = [...(permissions.value.find(p => p.brand === brandId)?.moduleName || [])];

  const categoryModules = Object.entries(MODULES_CATEGORIES)
    .filter(([, c]) => c.includes(category))
    .map<MODULES>(([k]) => k as MODULES);

  if (enable) {
    categoryModules.forEach(x => {
      if (!newModules.includes(x)) {
        newModules.push(x);
      }
    });
  }

  if (!enable) {
    newModules = newModules.filter(x => !categoryModules.includes(x));
  }

  if (!permissions.value.find(p => p.brand === brandId)) {
    permissions.value = [
      ...permissions.value,
      {
        brand: brandId,
        moduleName: newModules,
      },
    ];
    return;
  }

  const newPermissions = permissions.value.map(p => {
    if (p.brand === brandId) {
      return {
        ...p,
        moduleName: newModules,
      };
    }

    return p;
  });

  permissions.value = newPermissions;
};
const togglePermission = (module: MODULES, brandId: string) => {
  const permission = permissions.value.find(p => p.brand === brandId);
  if (!permission) {
    permissions.value = [
      ...permissions.value,
      {
        brand: brandId,
        moduleName: [module],
      },
    ];

    return;
  }

  const newPermissions = permissions.value.map(p => {
    if (p.brand === brandId) {
      return {
        ...p,
        moduleName: p.moduleName.includes(module) ? p.moduleName.filter(m => m !== module) : [...p.moduleName, module],
      };
    }

    return p;
  });

  permissions.value = newPermissions;
};
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col gap-4 pt-8">
    <Tabs
      :currentTabId="selectedTabId"
      :tabs="tabs"
      tab-header-size="w-full"
      tab-size-class="flex-1"
      :on-change="tabId => (selectedTabId = tabId as never)"
      size="md"
      :selected-tab="0" />

    <div class="flex min-h-0 flex-1 flex-col overflow-y-auto pe-2">
      <div
        v-if="selectedTabId === 'details'"
        class="flex flex-col gap-4 p-1">
        <BaseTextInput
          :id="'user-name'"
          v-model="userValues.nome"
          :status="{
            valid: !errors.nome,
            message: errors.nome,
          }"
          label="Fist name"
          placeholder="First name..."
          :name="`user-name`" />

        <BaseTextInput
          :id="'user-last-name'"
          v-model="userValues.cognome"
          label="Last name"
          placeholder="Last name..."
          :status="{
            valid: !errors.cognome,
            message: errors.cognome,
          }"
          :name="`user-last-name`" />

        <Combobox
          :id="'user-team'"
          v-model="userValues.team"
          label="Team"
          value-expr="value"
          display-expr="label"
          :status="{
            valid: !errors.team,
            message: errors.team,
          }"
          :items="teamsOptions"
          :name="`user-team`" />

        <BaseTextInput
          :id="'user-login'"
          v-model="userValues.login"
          label="Email"
          :status="{
            valid: !errors.login,
            message: errors.login,
          }"
          :disabled="true"
          placeholder="Login..."
          :name="`user-login`" />

        <BaseTextInput
          :id="'user-password'"
          v-model="userValues.password"
          label="Password"
          type="password"
          autocomplete="new-password"
          placeholder="Password..."
          :status="{
            valid: !errors.password,
            message: errors.password,
          }"
          :name="`user-password`" />

        <BaseTextInput
          id="confirm-password"
          v-model="userValues.confirmPassword"
          label="Confirm password"
          type="password"
          placeholder="Confirm password..."
          :status="{
            valid: !errors.confirmPassword,
            message: errors.confirmPassword,
          }"
          :name="`confirm-password`" />

        <div class="flex flex-col gap-4 border-t border-kl-gray-200 pt-4">
          <BaseToggle
            v-model="userValues.isAdmin"
            label="Admin" />

          <BaseToggle
            v-model="userValues.acceptanceClauses"
            :disabled="!props.user.acceptanceClauses"
            label="Privacy policy checked" />

          <p
            v-if="props.user.acceptanceDate"
            class="text-xs text-secondary">
            Policy accepted on {{ formatDate(props.user.acceptanceDate) }}
          </p>
        </div>
      </div>

      <div
        v-if="selectedTabId === 'permissions'"
        class="flex flex-col divide-y divide-gray-200">
        <BrandPermissionsRow
          v-for="brand in brands.toSorted((a, b) => a.name.localeCompare(b.name))"
          :key="brand.name"
          :toggle-enable-all-permissions="toggleEnableAllPermissions"
          :toggle-all-package-permissions="toggleAllPackagePermissions"
          :toggle-permission="togglePermission"
          :permissions="permissions.find(p => p.brand === brand.name)?.moduleName || []"
          :brand="brand" />
      </div>

      <div
        v-if="selectedTabId === 'ipAddress'"
        class="flex flex-col divide-y divide-gray-200"
        :class="{ 'divide-none': !restrictionsIPList || restrictionsIPList.length === 0 }">
        <IpAddressRow
          v-model:ipAddressList="restrictionsIPList"
          :user="props.user" />
      </div>

      <ul
        v-if="selectedTabId === 'follows'"
        class="flex flex-col gap-2 divide-y divide-gray-200">
        <li
          v-for="u in filteredUsersPerTeam"
          :key="u.id"
          class="flex w-full cursor-pointer items-center pt-2">
          <span class="flex-1 text-xs"> {{ u.nome }} {{ u.cognome }}</span>
          <Toggle
            :on-change="newValue => toggleUserFollow(u.id)"
            :checked="follows.includes(u.id)" />
        </li>
      </ul>
    </div>

    <div class="mt-4 justify-end text-right">
      <Button
        variant="primary"
        size="md"
        :is-loading="loading"
        :disabled="loading"
        @click="saveUser">
        Save
      </Button>
    </div>
  </div>
</template>
