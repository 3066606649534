<script setup lang="ts">
import ActivityCard from '@/components/cards/activity-card/ActivityCard.vue';
import type { Task, TaskActivity } from '@/types/task.types';
import type { User } from '@/types/user.types';
import { formatDate } from '@/utils/date.utils';
import { UserProfileImage } from '@kleecks/ui-lib';

interface ActivityWrapper {
  activity: TaskActivity;
  task: Task;
  user: User;
}

const props = defineProps<ActivityWrapper>();
</script>
<template>
  <div
    v-if="props.activity.who"
    class="ml-4">
    <div class="relative -left-7 flex items-center gap-1">
      <UserProfileImage
        :user="props.user"
        size="sm" />
      <span class="text-xs font-medium text-kl-gray-500"
        >{{ props.activity.who.nome }} {{ props.activity.who.cognome }}</span
      >
      <span class="text-2xs text-kl-gray-400">{{ formatDate(props.activity.when) }}</span>
    </div>
    <ActivityCard
      v-if="props.activity"
      :task="props.task"
      :activity="props.activity" />
  </div>
</template>
