<script setup lang="ts">
import { NOTIFICATION_TYPES } from '@/constants/notification.constants';
import { useInstances } from '@/hooks/instance/useInstances';
import { useCloneTask } from '@/services/queries/task.query';
import { useNotificationStore } from '@/stores/useNotificationStore';
import type { Task } from '@/types/task.types';
import { BaseTextInput, Button, Combobox, DialogContainer, Modal } from '@kleecks/ui-lib';
import { computed, ref, toRefs } from 'vue';

interface CloneTaskDialogProps {
  isOpen: boolean;
  brandId?: string;
  task: Task;
  onClose: () => void;
}

const props = defineProps<CloneTaskDialogProps>();

const { mutate: cloneTask, loading } = useCloneTask();
const { notify } = useNotificationStore();

const brandId = ref(props.brandId);
const instanceId = ref<string>();

const { brand, title } = toRefs(props.task);

const { instances, instancesNames } = useInstances(brandId);

const instancesOptions = computed(() =>
  instances.value?.map(el => ({
    label: instancesNames.value[el.instanceId || ''] || el.instanceId,
    value: el.instanceId,
  }))
);

const handleCloneTask = async () => {
  if (!instanceId.value) {
    notify({
      type: NOTIFICATION_TYPES.ERROR,
      title: 'Error',
      message: 'Please select an instance',
    });
    return;
  }

  await cloneTask({ cloneTaskId: props.task.id, instanceId: instanceId.value });
  notify({
    type: NOTIFICATION_TYPES.INFO,
    title: 'Task cloned',
    message: `Task ${props.task.title} cloned on ${instanceId.value}`,
  });
  props.onClose();
};
</script>

<template>
  <DialogContainer
    :is-open="props.isOpen"
    :full-screen="false"
    :on-close="props.onClose">
    <Modal
      :on-close="props.onClose"
      class="w-96"
      title="Clone to"
      sub-title="Select the instance to clone the task to">
      <div
        v-if="props.task"
        class="flex flex-col gap-4">
        <BaseTextInput
          id="task_name"
          v-model="title"
          type="text"
          label="Task Name*"
          name="task_name"
          :disabled="true" />

        <BaseTextInput
          id="task_brand"
          v-model="brand.name"
          :disabled="true"
          label="Brand*"
          name="task_brand" />

        <Combobox
          id="task_instance"
          v-model="instanceId"
          label="Project*"
          name="task_instance"
          :items="instancesOptions" />

        <Button
          class="mt-4 self-end"
          type="button"
          variant="primary"
          :is-loading="loading"
          :disabled="loading || !instanceId"
          @click="handleCloneTask">
          Clone
        </Button>
      </div>
    </Modal>
  </DialogContainer>
</template>
