<script setup lang="ts">
import { ROUTE_NAMES } from '@/constants/routes.constants';
import { TOPIC_ICONS_MAP } from '@/constants/topic-icons.constants';
import { useRouteParam } from '@/hooks/common/useRouteParam';
import { useDashboards } from '@/hooks/dashboard/useDashboards';
import { useUser } from '@/hooks/user/useUser';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon, PlusCircleIcon } from '@heroicons/vue/24/outline';
import type { Topic } from '@kleecks/code-lib';
import { TOPIC_COLORS } from '@kleecks/code-lib';
import { BaseTextInput } from '@kleecks/ui-lib';
import Fuse from 'fuse.js';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

interface DashboardsPopoverProps {
  onAddNewDashboard: () => void;
}
const router = useRouter();
const props = defineProps<DashboardsPopoverProps>();
const searchValue = ref('');
const brandId = useRouteParam('brandId');
const { user } = useUser();
const navigateToModulesLibrary = () => {
  router.push({ name: ROUTE_NAMES.MODULES_LIBRARY });
};

const { allDashboards } = useDashboards(brandId, user);

const filteredDashboards = computed(() => {
  if (!searchValue.value) return allDashboards.value?.toSorted((a, b) => a.name.localeCompare(b.name));

  const fuse = new Fuse(allDashboards.value, {
    keys: ['name', 'description', 'topic'],
    location: 0,
    threshold: 0.1,
  });
  return fuse
    .search(searchValue.value)
    ?.map(({ item }) => item)
    ?.toSorted((a, b) => a.name.localeCompare(b.name));
});

const navigateToDashboardsLibrary = () => {
  router.push({ name: ROUTE_NAMES.DASHBOARDS_LIBRARY });
};

const navigateToDashboard = (dashboardId: string) => {
  router.push({ name: ROUTE_NAMES.DASHBOARD, params: { dashboardId } });
};
</script>

<template>
  <Popover
    data-cy="dashboards-popover"
    class="md:relative">
    <PopoverButton
      data-cy="popover-button"
      as="div"
      class="group flex cursor-pointer items-center text-sm text-kl-icon outline-0 hover:text-active"
      @click="() => (searchValue = '')">
      <ChevronDownIcon class="size-4" />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1">
      <PopoverPanel
        v-slot="{ close }"
        class="absolute right-0 top-16 z-10 w-screen md:max-w-2xl">
        <div
          class="w-full flex-auto overflow-hidden rounded-lg border border-gray-secondary bg-white text-sm leading-6 shadow-popover">
          <div class="m-2 mx-8 flex items-center border-b border-sec py-2">
            <div class="mr-8 flex-1">
              <p class="text-lg font-medium text-kl-gray-500">Dashboards</p>
            </div>
            <div class="w-60 rounded-md shadow-sm">
              <BaseTextInput
                v-model="searchValue"
                type="search"
                aria-autocomplete="none"
                autocomplete="one-time-code"
                placeholder="Search dashboards" />
            </div>
          </div>

          <div
            v-if="allDashboards"
            class="mt-4 grid w-full grid-cols-2 gap-2 px-8">
            <div
              v-for="dashboard in filteredDashboards?.slice(0, 6) || []"
              :key="dashboard.id"
              :data-cy="`dashboard-${dashboard.id}`"
              :class="[
                'flex cursor-pointer items-start gap-x-3 rounded-md p-1 hover:bg-kl-gray-200/30 focus:ring-1 focus:ring-kl-green-300 active:ring-1 active:ring-kl-green-300',
              ]"
              tabindex="0"
              @keydown.enter="
                () => {
                  navigateToDashboard(dashboard.id);
                  close();
                }
              "
              @click="
                () => {
                  navigateToDashboard(dashboard.id);
                  close();
                }
              ">
              <div :class="['size-12 items-center rounded p-2', TOPIC_COLORS[dashboard.topic!]]">
                <component
                  :is="TOPIC_ICONS_MAP[dashboard.topic as Topic]"
                  class="size-full" />
              </div>
              <div class="min-w-0 flex-1 leading-3">
                <p class="text-sm font-medium capitalize text-primary">{{ dashboard.name }}</p>
                <p class="line-clamp-2 text-xs text-secondary">{{ dashboard.description }}</p>
              </div>
            </div>
          </div>

          <div class="mt-4 flex gap-4 rounded-b-md bg-sec px-8 py-4">
            <div class="flex-1">
              <PopoverButton
                as="span"
                class="flex cursor-pointer items-center text-sm text-secondary underline hover:text-active"
                @click="props.onAddNewDashboard">
                Add new dashboard <PlusCircleIcon class="ml-2 size-6" />
              </PopoverButton>
            </div>

            <PopoverButton
              :as="'span'"
              class="cursor-pointer text-sm text-secondary underline hover:text-active"
              @click="navigateToModulesLibrary">
              View all modules
            </PopoverButton>
            <PopoverButton
              :as="'span'"
              class="cursor-pointer text-sm text-secondary underline hover:text-active"
              @click="navigateToDashboardsLibrary">
              View all dashboards
            </PopoverButton>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
